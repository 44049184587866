// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-integration-amazon-image {
    width: 30px;
    margin-right: 7px;
}

.amazon-login-button {
    border: 1px solid #134074;
    background-color: white;
    padding: 3px 15px;
    color: #134074;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/AddAmazonIntegration/AddAmazonIntegration.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".add-integration-amazon-image {\n    width: 30px;\n    margin-right: 7px;\n}\n\n.amazon-login-button {\n    border: 1px solid #134074;\n    background-color: white;\n    padding: 3px 15px;\n    color: #134074;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
