import { RefObject, useEffect } from "react";

type UseClickOutsideOfBlockProps = {
	blockRef: RefObject<HTMLDivElement>;
	showValue: boolean;
	setShowValue: (value: boolean) => void;
};

const useClickOutsideOfBlock = ({
	blockRef,
	showValue,
	setShowValue,
}: UseClickOutsideOfBlockProps) => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				blockRef.current &&
				!blockRef.current.contains(event.target as Node)
			) {
				setShowValue(false);
			}
		};

		if (showValue) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [blockRef, showValue, setShowValue]);

	return {};
};

export default useClickOutsideOfBlock;
