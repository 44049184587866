
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { FC } from "react"

import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";
import { useSubmitTrackingInformationMutation } from "../../../api/ordersAPI";

type TTrackingInfoProps = {
    show: boolean,
    onHide: () => void,
    fulfillment: number
}

export type TAddNewTrackingInformation = {
    carrier_code: string,
    tracking_number: string,
    fulfillment: number,
}
const AddTrackingInformation: FC<TTrackingInfoProps> = ({ show, onHide, fulfillment }) => {
    const intl = useIntl()

    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()
    const { register, handleSubmit, formState: { errors } } = useForm<TAddNewTrackingInformation>({
        defaultValues: {
            fulfillment,
            carrier_code: '',
            tracking_number: '',
        },
    });

    const [submitTrackingInformation] = useSubmitTrackingInformationMutation()

    const onSubmit = (data: TAddNewTrackingInformation) => {
        submitTrackingInformation(data).then((response) => {
            if ('error' in response) {
                handleCustomError(response.error)
            } else {
                setCustomError(null)
                onHide()
                toast.success(intl.formatMessage({ id: "trackingInformationUpdatedSuccessfully" }))
            }
        })
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="addTrackingInformation" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className='form-input-label'>
                            <FormattedMessage id="trackingNumber" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({ id: "trackingNumber" })}`}
                            className="form-input-field"
                            type="text"
                            {...register("tracking_number")}
                        />
                        {(errors.tracking_number || customError === 'Tracking number error.') && (
                            <p className="mb-0 form-field-error">
                                {
                                    errors.tracking_number
                                        ? errors.tracking_number.message
                                        : <FormattedMessage id="trackingNumberError" />
                                }
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className='form-input-label'>
                            <FormattedMessage id="carrierCode" />
                        </Form.Label>
                        <Form.Control
                            className="form-input-field"
                            type="text"
                            placeholder={`${intl.formatMessage({ id: "carrierCode" })}`}
                            {...register("carrier_code")}
                        />
                        {(errors.carrier_code || customError === 'Carrier code error.') && (
                            <p className="mb-0 form-field-error">
                                {
                                    errors.carrier_code
                                        ? errors.carrier_code.message
                                        : <FormattedMessage id="carrierCodeError" />
                                }
                            </p>
                        )}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='submit' className="dashboard-menu-button margin-top">
                            <FormattedMessage id="add" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddTrackingInformation