import { IoCheckmark, IoCloseOutline } from "react-icons/io5";
import { FormattedMessage, useIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { FC, useState } from "react";

import {
	useDeleteIntegrationMutation,
	useGetIntegrationsQuery,
	useImportProductsMutation,
} from "../../../../api/integrationsAPI";

import AddShopifyIntegration from "../../../modals/AddShopifyIntegration/AddShopifyIntegration";
import AddAmazonIntegration from "../../../modals/AddAmazonIntegration/AddAmazonIntegration";
import AddKytIntegration from "../../../modals/AddKytIntegration/AddKytIntegration";

import Arrow from "../../../assets/images/arrow.svg";
import EmptyState from "../../common/EmptyState/EmptyState";

type TSettingsIntegrationsBlock = {
	isPublic: boolean;
	handleEditIntegration: (id: number) => void;
};

const SettingsIntegrationsBlock: FC<TSettingsIntegrationsBlock> = ({
	isPublic,
	handleEditIntegration,
}) => {
	const [addShopify, setAddShopify] = useState(false);
	const [addAmazon, setAddAmazon] = useState(false);
	const [addKyt, setAddKyt] = useState(false);

	const [importProducts] = useImportProductsMutation();
	const [deleteIntegration] = useDeleteIntegrationMutation();
	const { data: integrations } = useGetIntegrationsQuery(undefined, {
		skip: isPublic,
	});

	const intl = useIntl();

	const handleDeleteIntegration = (id: number) => {
		deleteIntegration(id);
	};

	const handleImportProducts = (id: number) => {
		importProducts({ integration_id: id }).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({ id: "productImportFailed" })
					);
				}
			} else {
				toast.success(
					intl.formatMessage({ id: "productImportStarted" })
				);
			}
		});
	};

	return (
		<div className="settings-users__block">
			<div className="settings-users__block-body">
				<div className="settings-users__block-body-header">
					<h5 className="settings-users__block-title">
						<FormattedMessage id="integrations" />
					</h5>
					<div className="settings-users__block-body-header-buttons">
						<Dropdown className="d-flex justify-content-end dropdown-without-triangle">
							<Dropdown.Toggle
								data-testid="add-integration-toggle"
								className="user-header-dropdown default-dropdown-toggle p-0"
							>
								<div className="default-dropdown-button">
									<FormattedMessage id="addNewIntegration" />
									<img
										className="arrow-icon"
										src={Arrow}
										alt=""
									/>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu className="default-dropdown-menu-wrapper w-100">
								<div
									data-testid="integrations-menu"
									className="default-dropdown-menu"
								>
									<Dropdown.Item
										data-testid="add-amazon-integration"
										onClick={() => setAddAmazon(true)}
										className="default-dropdown-item"
									>
										Amazon FBA
									</Dropdown.Item>
									<Dropdown.Item
										data-testid="add-shopify-integration"
										onClick={() => setAddShopify(true)}
										className="default-dropdown-item"
									>
										Shopify
									</Dropdown.Item>
									<Dropdown.Item
										data-testid="add-kyt-integration"
										onClick={() => setAddKyt(true)}
										className="default-dropdown-item"
									>
										KYT
									</Dropdown.Item>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				<div className="default-table-no-wrapper">
					{integrations && integrations.results.length === 0 ? (
						<EmptyState message="noIntegrationsFound" />
					) : (
						<table className="table">
							<thead className="thead-light">
								<tr>
									<th>
										<FormattedMessage id="integrationName" />
									</th>
									<th>
										<FormattedMessage id="seller" />
									</th>
									<th>
										<FormattedMessage id="vendor" />
									</th>
									<th>
										<FormattedMessage id="fulfillmentService" />
									</th>
									<th>
										<FormattedMessage id="status" />
									</th>
									<th>
										<FormattedMessage id="partner" />
									</th>
									<th>
										<FormattedMessage id="action" />
									</th>
								</tr>
							</thead>
							<tbody>
								{integrations &&
									integrations.results.length > 0 &&
									integrations.results.map((integration) => (
										<tr
											data-testid="integration-row"
											key={integration.id}
										>
											<td className="text-center">
												{integration.name}
											</td>
											<td className="text-center">
												{integration.is_seller ? (
													<IoCheckmark size={22} />
												) : (
													<IoCloseOutline size={24} />
												)}
											</td>
											<td className="text-center">
												{integration.is_vendor ? (
													<IoCheckmark size={22} />
												) : (
													<IoCloseOutline size={24} />
												)}
											</td>
											<td className="text-center">
												{integration.is_fulfillment_service ? (
													<IoCheckmark size={22} />
												) : (
													<IoCloseOutline size={24} />
												)}
											</td>
											<td className="text-center">
												{integration.status}
											</td>
											<td className="text-center">
												{integration.partner}
											</td>
											<td className="text-center">
												<Dropdown className="d-flex justify-content-center">
													<Dropdown.Toggle
														data-testid="integration-action"
														className="user-header-dropdown default-dropdown-toggle p-0"
													>
														<div className="default-dropdown-button">
															<FormattedMessage id="select" />
															<img
																className="arrow-icon"
																src={Arrow}
																alt=""
															/>
														</div>
													</Dropdown.Toggle>
													<Dropdown.Menu className="default-dropdown-menu-wrapper">
														<div
															data-testid="integration-action-menu"
															className="default-dropdown-menu"
														>
															<Dropdown.Item
																data-testid="edit-integration"
																className="default-dropdown-item"
																onClick={() =>
																	handleEditIntegration(
																		integration.id
																	)
																}
															>
																<FormattedMessage id="edit" />
															</Dropdown.Item>
															{integration.partner ===
																"Amazon Integration" && (
																<Dropdown.Item
																	data-testid="import-products"
																	className="default-dropdown-item"
																	onClick={() =>
																		handleImportProducts(
																			integration.id
																		)
																	}
																>
																	<FormattedMessage id="importProducts" />
																</Dropdown.Item>
															)}
															<Dropdown.Item
																data-testid="delete-integration"
																className="default-dropdown-item"
																onClick={() =>
																	handleDeleteIntegration(
																		integration.id
																	)
																}
															>
																<FormattedMessage id="delete" />
															</Dropdown.Item>
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					)}
				</div>
			</div>
			{addShopify && (
				<AddShopifyIntegration
					show={addShopify}
					onHide={() => setAddShopify(false)}
				/>
			)}
			{addAmazon && (
				<AddAmazonIntegration
					show={addAmazon}
					onHide={() => setAddAmazon(false)}
				/>
			)}
			{addKyt && (
				<AddKytIntegration
					show={addKyt}
					onHide={() => setAddKyt(false)}
				/>
			)}
		</div>
	);
};

export default SettingsIntegrationsBlock;
