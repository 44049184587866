import { HTML5Backend } from "react-dnd-html5-backend";
import { FormattedMessage, useIntl } from "react-intl";
import { TbFileUpload } from "react-icons/tb";
import { FaFileExcel } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { toast } from "react-toastify";
import { FC, useState } from "react";

import { TBulkUploadError } from "../../../api/api.types";
import {
    useGetTemplateForUploadMutation,
    useUploadOrdersMutation,
} from "../../../api/ordersAPI";

import TargetBox from "../BulkUpload/TargetBox";

import "../BulkUpload/BulkUpload.css";

type TUploadError = {
    data: {
        error: TBulkUploadError[];
    };
};

type TUploadOrders = {
    show: boolean;
    onHide: () => void;
    setBulkUploadErrors: (value: TBulkUploadError[]) => void;
    selectedFulfillmentMethod: number | null;
};

const UploadOrders: FC<TUploadOrders> = ({
    show,
    onHide,
    setBulkUploadErrors,
    selectedFulfillmentMethod,
}) => {
    const [file, setFile] = useState<File | null>(null);

    const intl = useIntl();

    const [getTemplateFile] = useGetTemplateForUploadMutation();
    const [uploadOrders] = useUploadOrdersMutation();

    const handleGetTemplateFile = () => {
        getTemplateFile().then((response) => {
            if ("error" in response) return;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "orders_template.xlsx");
            document.body.appendChild(link);
            link.click();
        });
    };

    const handleUploadOrders = () => {
        if (!file || !selectedFulfillmentMethod) return;

        const formData = new FormData();
        formData.append("xlsx_file", file);
        formData.append("integration_id", selectedFulfillmentMethod.toString());

        uploadOrders(formData).then((response) => {
            if ("error" in response) {
                const errors = response.error as TUploadError;
                setBulkUploadErrors(errors.data.error as TBulkUploadError[]);
                onHide();
            } else {
                toast.success(
                    intl.formatMessage({ id: "ordersUploadedSuccessfully" })
                );
                setFile(null);
                onHide();
            }
        });
    };

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title className="d-flex align-items-center gap-1">
                    <TbFileUpload size={24} />
                    <FormattedMessage id="ordersUpload" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ gap: "12px" }}
                className="pt-0 d-flex flex-column"
            >
                <p className="bulk-upload-description">
                    <FormattedMessage id="bulkOrdersUploadFromAnExcelFile" />
                </p>
                <button
                    onClick={handleGetTemplateFile}
                    className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0 w-fit"
                >
                    <FormattedMessage id="downloadTemplate" />
                </button>
                <p className="bulk-upload-description">
                    <FormattedMessage id="afterYouHavePreparedTheFileDragAndDropOrClick" />
                </p>
                {file ? (
                    <div className="bulk-upload-file-block">
                        <div className="bulk-upload-file">
                            <FaFileExcel color="#495057" size={24} />
                            <div className="bulk-upload-file-info">
                                <span>{file.name}</span>
                                <span className="bulk-upload-file-size">
                                    {Math.round(file.size / 1024)} kb
                                </span>
                            </div>
                        </div>
                        <MdDelete
                            className="bulk-upload-file-delete"
                            color="#d0d4d7"
                            size={24}
                        />
                    </div>
                ) : (
                    <DndProvider backend={HTML5Backend}>
                        <TargetBox setFile={setFile} />
                    </DndProvider>
                )}
                <div className="d-flex align-items-center justify-content-end mt-2">
                    <button
                        disabled={!file}
                        onClick={handleUploadOrders}
                        className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0"
                    >
                        <FormattedMessage id="upload" />
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default UploadOrders;
