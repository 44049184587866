import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "./apiConfig";

import { TAddStripePaymentMethodData, TPaypalTransactionsResponse, TStripePaymentMethod, TStripePaymentMethodsResponse, TStripeReceiptsResponse, TWiseReceipt, TWiseReceiptsResponse, TWiseTransfersResponse } from "./api.types";
import { TCompletePaymentObject } from "../app/components/common/PayPalButton/PayPalButton";
import { TAddCreditsSubmitData } from "../app/modals/AddCredits/AddCredits";

export const paymentsApi = createApi({
    reducerPath: 'paymentsApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['StripeReceipts', 'StripePaymentMethods', 'WiseReceipts', 'StripePaymentMethod', 'WiseReceipt'],
    endpoints: builder => ({
        getStripeReceipts: builder.query<TStripeReceiptsResponse, { activePage: string, pageSize: string, defaultFilter: string }>({
            query: ({ activePage, pageSize, defaultFilter }) => {
                return {
                    url: `/financial/stripe/payment_intents/?page=${activePage}${pageSize ? `&page_size=${pageSize}` : ''}${defaultFilter ? `&status=${defaultFilter}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['StripeReceipts']
        }),
        createWiseReceipt: builder.mutation<void, { data: any }>({
            query: (data) => {
                return {
                    url: `/financial/wise/recipient/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['WiseReceipts']
        }),
        confirmStripePayment: builder.mutation<void, number>({
            query: (id) => {
                return {
                    url: `/financial/stripe/payment_intents/${id}/`,
                    method: 'PUT',
                    body: {},
                }
            },
            invalidatesTags: ['StripeReceipts']
        }),
        completeOrderTransaction: builder.mutation<void, TCompletePaymentObject>({
            query: (data) => {
                return {
                    url: `/financial/transactions/`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        getTransactions: builder.query<TPaypalTransactionsResponse, { activePage: string, pageSize: string, defaultFilter: string }>({
            query: ({ activePage, pageSize, defaultFilter }) => {
                return {
                    url: `/financial/transactions/?expand=order&page=${activePage}${pageSize ? `&page_size=${pageSize}` : ''}${defaultFilter ? `&kind=${defaultFilter}` : ''}`,
                    method: 'GET',
                }
            }
        }),
        getStripePaymentMethods: builder.query<TStripePaymentMethodsResponse, void>({
            query: () => {
                return {
                    url: `/financial/stripe/payment_methods/`,
                    method: 'GET',
                }
            },
            providesTags: ['StripePaymentMethods']
        }),
        addStripePaymentMethods: builder.mutation<void, TAddStripePaymentMethodData>({
            query: (data) => {
                return {
                    url: `/financial/stripe/payment_methods/`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['StripePaymentMethods']
        }),
        deleteStripePaymentMethod: builder.mutation<void, number>({
            query: (id) => {
                return {
                    url: `/financial/stripe/payment_methods/${id}/`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['StripePaymentMethods']
        }),
        getWiseReceipts: builder.query<TWiseReceiptsResponse, void>({
            query: () => {
                return {
                    url: `/financial/wise/recipient/`,
                    method: 'GET',
                }
            },
            providesTags: ['WiseReceipts']
        }),
        deleteWiseReceipt: builder.mutation<void, number>({
            query: (id) => {
                return {
                    url: `/financial/wise/recipient/${id}/`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['WiseReceipts']
        }),
        getStripePaymentMethod: builder.query<TStripePaymentMethod, number>({
            query: (id) => {
                return {
                    url: `/financial/stripe/payment_methods/${id}/`,
                    method: 'GET',
                }
            },
            providesTags: ['StripePaymentMethod']
        }),
        updateStripePaymentMethod: builder.mutation<void, { data: { default: boolean }, id: number }>({
            query: ({ data, id }) => {
                return {
                    url: `/financial/stripe/payment_methods/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
        }),
        getWiseTransfers: builder.query<TWiseTransfersResponse, { activePage: string, pageSize: string }>({
            query: ({ activePage, pageSize }) => {
                return {
                    url: `/financial/wise/transfers/?page=${activePage}${pageSize ? `&page_size=${pageSize}` : ''}`,
                    method: 'GET'
                }
            }
        }),
        addCredits: builder.mutation<void, TAddCreditsSubmitData>({
            query: (data) => {
                return {
                    url: `/financial/credits/`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        getWiseReceipt: builder.query<TWiseReceipt, number>({
            query: (id) => {
                return {
                    url: `/financial/wise/recipient/${id}/`,
                    method: 'GET',
                }
            },
            providesTags: ['WiseReceipt']
        }),
        updateWiseReceipt: builder.mutation<void, { data: { default: boolean }, id: number }>({
            query: ({ data, id }) => {
                return {
                    url: `/financial/wise/recipient/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
        })
    })
})

export const {
    useGetStripeReceiptsQuery,
    useCreateWiseReceiptMutation,
    useConfirmStripePaymentMutation,
    useCompleteOrderTransactionMutation,
    useGetTransactionsQuery,
    useGetStripePaymentMethodsQuery,
    useAddStripePaymentMethodsMutation,
    useDeleteStripePaymentMethodMutation,
    useGetWiseReceiptsQuery,
    useDeleteWiseReceiptMutation,
    useGetStripePaymentMethodQuery,
    useUpdateStripePaymentMethodMutation,
    useGetWiseTransfersQuery,
    useAddCreditsMutation,
    useGetWiseReceiptQuery,
    useUpdateWiseReceiptMutation
} = paymentsApi