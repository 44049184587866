import { FormattedMessage, useIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { useMemo, useState } from "react";
import { Range } from "react-date-range";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	ArcElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from "chart.js";

import {
	useGetGraphsDataQuery,
	useGetPieDataQuery,
	useGetStatsDataQuery,
} from "../../../../api/statsAPI";
import {
	calculatePercentageOfNumber,
	getDateByFilterType,
} from "../../../../helpers/functions";
import { TOTAL_ORDERS_DATES_TRANSLATION_OPTIONS } from "../../../../helpers/constants";
import { TOTAL_ORDERS_CHART_OPTIONS } from "../../../../helpers/charts";

import DateRangeSelector from "../../../components/common/DateRangeSelector/DateRangeSelector";

import Calendar from "../../../assets/images/calendar.svg";
import Stats from "../../../assets/images/stats.svg";
import Arrow from "../../../assets/images/arrow.svg";

import "./TotalOrders.css";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	ArcElement,
	Title,
	Tooltip,
	Filler,
	Legend
);

const TotalOrders = () => {
	const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);
	const [type, setType] = useState("dropshipping");
	const [dateType, setDateType] = useState("This Week");
	const [filterDate, setFilterDate] = useState({
		start_date: getDateByFilterType("This Week").startDate,
		end_date: getDateByFilterType("This Week").endDate,
	});

	const intl = useIntl();

	const { data: graphsData } = useGetGraphsDataQuery({ type, filterDate });
	const { data: statsData } = useGetStatsDataQuery({ type, filterDate });
	const { data: pieCategoriesData } = useGetPieDataQuery();

	const totalCategoriesLength = pieCategoriesData
		? Object.keys(pieCategoriesData).reduce((acc, key) => {
				return acc + pieCategoriesData[key];
			}, 0)
		: 0;

	const labels = useMemo(() => {
		if (graphsData) {
			return graphsData.retail_orders.map((el) => el.date);
		}
	}, [graphsData]);

	const retailOrders = useMemo(() => {
		if (graphsData) {
			return graphsData.retail_orders.map((el) => el.value);
		}
	}, [graphsData]);

	const retailProducts = useMemo(() => {
		if (graphsData) {
			return graphsData.retail_products.map((el) => el.value);
		}
	}, [graphsData]);

	const retailSales = useMemo(() => {
		if (graphsData) {
			return graphsData.retail_sales.map((el) =>
				String(Number(el.value) / 100)
			);
		}
	}, [graphsData]);

	const data = {
		labels: labels,
		datasets: [
			{
				fill: false,
				lineTension: 0.5,
				label: intl.formatMessage({
					id:
						type === "retail"
							? "retailOrders"
							: "dropshippingOrders",
				}),
				data: retailOrders,
				borderColor: "rgb(230, 230, 230)",
				pointRadius: 0,
			},
			{
				fill: false,
				lineTension: 0.5,
				label: intl.formatMessage({
					id:
						type === "retail"
							? "retailProducts"
							: "dropshippingProducts",
				}),
				data: retailProducts,
				borderColor: "rgb(0, 0, 0)",
				pointRadius: 0,
			},
			{
				fill: false,
				lineTension: 0.5,
				label: intl.formatMessage({
					id: type === "retail" ? "retailSales" : "dropshippingSales",
				}),
				data: retailSales,
				borderColor: "rgb(77, 184, 255)",
				pointRadius: 0,
			},
		],
	};

	const handleDateFilter = (value: string | null) => {
		if (value) {
			const response = getDateByFilterType(value);

			if (response.startDate && response.endDate) {
				setDateType(value);
				setFilterDate({
					start_date: response.startDate,
					end_date: response.endDate,
				});
			}
		}
	};

	const handleSelectCustomPeriod = () => {
		setShowDateRangeSelector(true);
	};

	const handleResetSelectedDateRange = () => {
		setDateType("This Week");
		setFilterDate({
			start_date: getDateByFilterType("This Week").startDate,
			end_date: getDateByFilterType("This Week").endDate,
		});
	};

	const handleApplySelectedDateRange = (range: Range[]) => {
		const startDate = range[0].startDate;
		const endDate = range[0].endDate;

		if (!startDate || !endDate) return;

		const startDateString = `${startDate.getFullYear()}-${
			startDate.getMonth() + 1
		}-${startDate.getDate()}`;
		const endDateString = `${endDate.getFullYear()}-${
			endDate.getMonth() + 1
		}-${endDate.getDate()}`;

		setDateType("Custom Range");
		setFilterDate({
			start_date: startDateString,
			end_date: endDateString,
		});
	};

	return (
		<div className="total-orders__content-wrapper">
			<div className="total-orders__content">
				<div className="total-orders__tab-selector">
					<div
						onClick={() => setType("dropshipping")}
						className={`total-orders__tab-item ${type === "dropshipping" ? "active-tab" : ""}`}
					>
						<FormattedMessage id="dropshippingData" />
					</div>
					<div
						data-testid="retail-tab"
						onClick={() => setType("retail")}
						className={`total-orders__tab-item ${type === "retail" ? "active-tab" : ""}`}
					>
						<FormattedMessage id="retailData" />
					</div>
				</div>
			</div>
			<div className="total-orders_page-main-content">
				<div className="total-orders__charts-container">
					<div
						data-testid="line-chart"
						className="total-orders__line-chart-block"
					>
						<div className="total-orders__line-chart">
							<h6 className="total-order__line-chart-title">
								<FormattedMessage id="profitForTheSelectedPeriod" />
							</h6>
							<div
								style={{ maxHeight: "380px", height: "380px" }}
							>
								<Line
									options={TOTAL_ORDERS_CHART_OPTIONS}
									data={data}
								/>
							</div>
						</div>
						<div className="total-orders__line-chart-info">
							<Dropdown onSelect={handleDateFilter}>
								<Dropdown.Toggle
									data-testid="period-filter"
									id="dropdown-basic"
									className="user-header-dropdown total-orders_dropdown-toggle w-100 p-0"
								>
									<div className="total-orders__filter">
										<div className="d-flex align-items-center gap-2">
											<img src={Calendar} alt="" />
											<span className="total-orders__selected-filter">
												<FormattedMessage
													id={`${TOTAL_ORDERS_DATES_TRANSLATION_OPTIONS[dateType as keyof typeof TOTAL_ORDERS_DATES_TRANSLATION_OPTIONS]}`}
												/>
											</span>
										</div>
										<img
											className="arrow-icon"
											src={Arrow}
											alt=""
										/>
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu className="total-order__dropdown-menu">
									<div
										data-testid="period-menu"
										className="total-order__dropdown-menu-wrapper"
									>
										<Dropdown.Item
											className="total-order__dropdown-menu-item"
											eventKey="This Week"
										>
											<FormattedMessage id="thisWeek" />
										</Dropdown.Item>
										<Dropdown.Item
											className="total-order__dropdown-menu-item"
											eventKey="This Month"
										>
											<FormattedMessage id="thisMonth" />
										</Dropdown.Item>
										<Dropdown.Item
											className="total-order__dropdown-menu-item"
											eventKey="Last Week"
										>
											<FormattedMessage id="lastWeek" />
										</Dropdown.Item>
										<Dropdown.Item
											className="total-order__dropdown-menu-item"
											eventKey="Last 2 Weeks"
										>
											<FormattedMessage id="lastTwoWeeks" />
										</Dropdown.Item>
										<Dropdown.Item
											data-testid="last-30-days"
											className="total-order__dropdown-menu-item"
											eventKey="Last 30 Days"
										>
											<FormattedMessage id="last30Days" />
										</Dropdown.Item>
										<Dropdown.Item
											className="total-order__dropdown-menu-item"
											eventKey="Last 12 Months"
										>
											<FormattedMessage id="last12Months" />
										</Dropdown.Item>
										<Dropdown.Item
											className="total-order__dropdown-menu-item"
											onClick={handleSelectCustomPeriod}
										>
											<FormattedMessage id="customRange" />
										</Dropdown.Item>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div
								data-testid="chart-identifiers"
								className="total-orders__chart-identifiers"
							>
								<div className="total-orders__chart-identifier">
									<span className="total-orders__chart-color black"></span>
									<span className="total-orders__chart-title">
										{intl.formatMessage({
											id:
												type === "retail"
													? "retailProducts"
													: "dropshippingProducts",
										})}
									</span>
								</div>
								<div className="total-orders__chart-identifier">
									<span className="total-orders__chart-color grey"></span>
									<span className="total-orders__chart-title">
										{intl.formatMessage({
											id:
												type === "retail"
													? "retailOrders"
													: "dropshippingOrders",
										})}
									</span>
								</div>
								<div className="total-orders__chart-identifier">
									<span className="total-orders__chart-color blue"></span>
									<span className="total-orders__chart-title">
										{intl.formatMessage({
											id:
												type === "retail"
													? "retailSales"
													: "dropshippingSales",
										})}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div
						data-testid="categories"
						className="total-orders__product-categories"
					>
						<h6 className="total-order__product-categories-title">
							<FormattedMessage id="productCategories" />
							<img src={Stats} alt="" />
						</h6>
						{pieCategoriesData && (
							<div className="total-order__product-categories-items-wrapper">
								{Object.keys(pieCategoriesData).map(
									(key, index) => (
										<div
											className="total-order__product-categories-item"
											key={index}
										>
											<div
												style={{
													width:
														calculatePercentageOfNumber(
															totalCategoriesLength,
															pieCategoriesData[
																key
															]
														) + "%",
												}}
												className="total-order__product-categories-item-percentage"
											></div>
											<span className="total-order__product-categories-item-label">
												{key}
											</span>
										</div>
									)
								)}
							</div>
						)}
					</div>
				</div>
				<div className="total-orders__products-stats">
					<div
						data-testid="stats-item"
						className="total-orders__products-stats-item"
					>
						<h6 className="total-orders__products-stats-item-title">
							{type === "retail" ? (
								<FormattedMessage id="totalRetailsOrders" />
							) : (
								<FormattedMessage id="totalOrdersCamelCase" />
							)}
						</h6>
						<h3 className="total-orders__products-stats-item-amount">
							{statsData?.total_orders
								? statsData?.total_orders
								: "0"}
						</h3>
					</div>
					{type === "retail" && (
						<div
							data-testid="stats-item"
							className="total-orders__products-stats-item"
						>
							<h6 className="total-orders__products-stats-item-title">
								<FormattedMessage id="totalReturnedOrder" />
							</h6>
							<h3 className="total-orders__products-stats-item-amount">
								{statsData?.total_returned_orders
									? statsData?.total_returned_orders
									: "0"}
							</h3>
						</div>
					)}
					<div
						data-testid="stats-item"
						className="total-orders__products-stats-item"
					>
						<h6 className="total-orders__products-stats-item-title">
							{type === "retail" ? (
								<FormattedMessage id="totalRetailProducts" />
							) : (
								<FormattedMessage id="totalProducts" />
							)}
						</h6>
						<h3 className="total-orders__products-stats-item-amount">
							{statsData?.total_products
								? statsData?.total_products
								: "0"}
						</h3>
					</div>
				</div>
				<div className="total-orders__products-stats">
					<div
						data-testid="stats-item"
						className="total-orders__products-stats-item"
					>
						<h6 className="total-orders__products-stats-item-title">
							{type === "retail" ? (
								<FormattedMessage id="totalRetailSales" />
							) : (
								<FormattedMessage id="totalSales" />
							)}
						</h6>
						<h3 className="total-orders__products-stats-item-amount">
							$
							{statsData?.total_sales
								? Number(statsData?.total_sales)
								: "0"}
						</h3>
					</div>
					<div
						data-testid="stats-item"
						className="total-orders__products-stats-item"
					>
						<h6 className="total-orders__products-stats-item-title">
							<FormattedMessage id="totalBalance" />
						</h6>
						<h3 className="total-orders__products-stats-item-amount">
							$
							{statsData?.total_balance
								? statsData?.total_balance
								: "0"}
						</h3>
					</div>
					<div
						data-testid="stats-item"
						className="total-orders__products-stats-item"
					>
						<h6 className="total-orders__products-stats-item-title">
							<FormattedMessage id="credit" />
						</h6>
						<h3 className="total-orders__products-stats-item-amount">
							${statsData?.credit ? statsData?.credit : "0"}
						</h3>
					</div>
				</div>
			</div>
			{showDateRangeSelector && (
				<DateRangeSelector
					onHide={() => setShowDateRangeSelector(false)}
					handleApply={handleApplySelectedDateRange}
					handleReset={handleResetSelectedDateRange}
					currentPeriod={{
						startDate: new Date(filterDate.start_date),
						endDate: new Date(filterDate.end_date),
					}}
				/>
			)}
		</div>
	);
};

export default TotalOrders;
