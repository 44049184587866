import { Col, Container, Dropdown, Form, Nav, Row } from "react-bootstrap";
import { TbFileExport, TbFileUpload } from "react-icons/tb";
import { ChangeEvent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { BiSearch } from "react-icons/bi";
import { GoTrash } from "react-icons/go";
import { toast } from "react-toastify";

import {
    useCancelProductReviewMutation,
    useDeleteProductsMutation,
    useGetProductsQuery,
    useMakeProductPrivateMutation,
    useMakeProductPublicMutation,
} from "../../../../api/productsAPI";
import {
    TBulkUploadError,
    TProduct,
    TUploadedProductsResponse,
} from "../../../../api/api.types";
import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { detectProductStatus } from "../../../../helpers/functions";
import usePagination from "../../../../hooks/usePagination";
import useFilters from "../../../../hooks/useFilters";

import AttachIntegrationModal from "../../../modals/AttachIntegrationModal/AttachIntegrationModal";
import SelectIntegration from "../../../modals/SelectIntegration/SelectIntegration";
import PagePagination from "../../../components/common/Pagination/PagePagination";
import BulkUploadErrors from "../../../modals/BulkUpload/BulkUploadErrors";
import BulkUploadImages from "../../../modals/BulkUpload/BulkUploadImages";
import BulkOrigination from "../../../modals/BulkUpload/BulkOrigination";
import BulkUpload from "../../../modals/BulkUpload/BulkUpload";
import ExportCsv from "../../../modals/ExportCsv/ExportCsv";
import ImportCsv from "../../../modals/ImportCsv/ImportCsv";
import MyProductsTable from "./MyProductsTable";

import "./MyProducts.css";

export type ProductDataUpdated = TProduct & { expand: boolean };

const MyProducts = () => {
    const [justUploadedProducts, setJustUploadedProducts] = useState<
        TUploadedProductsResponse[] | null
    >(null);
    const [showErrorsModal, setShowErrorsModal] = useState<
        TBulkUploadError[] | null
    >(null);
    const [bulkUploadIntegration, setBulkUploadIntegration] = useState<
        number | null
    >(null);
    const [attachIntegrationModal, setAttachIntegrationModal] = useState(false);
    const [productData, setProductData] = useState<ProductDataUpdated[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
    const [openBulkOrigination, setOpenBulkOrigination] = useState(false);
    const [defaultFilter, setDefaultFilter] = useState<string>("All");
    const [selectIntegration, setSelectIntegration] = useState(false);
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [exportCsv, setExportCsv] = useState(false);
    const [importCsv, setImportCsv] = useState(false);
    const [openBulk, setOpenBulk] = useState(false);

    const intl = useIntl();

    const {
        search,
        handleSearch,
        handleSearchFilter,
        pageSize,
        handlePageSize,
        clearFilters,
        searchFilter,
        productStatusFilter,
        handleProductsStatusFilter,
        onKeyDownSearch,
    } = useFilters();
    const [activePage, pages, handlePage, handlePagesCount] = usePagination(
        "page",
        pageSize
    );
    const [isPublic] = useDetectSubdomainOrPublic();

    const { data } = useGetProductsQuery({
        activePage,
        search: searchFilter,
        pageSize,
        statusFilters: productStatusFilter,
        requiredAction: defaultFilter === "Required Action" ? true : false,
    });
    const [makeProductPublic] = useMakeProductPublicMutation();
    const [makeProductPrivate] = useMakeProductPrivateMutation();
    const [cancelProductReview] = useCancelProductReviewMutation();
    const [deleteProducts] = useDeleteProductsMutation();

    const handleOpenModal = () => {
        setSelectIntegration(true);
    };

    const handleSearchInputClick = () => {
        handleSearchFilter(handlePage);
    };

    const handlePageSizeDropdown = (e: string | null) => {
        handlePageSize(e, handlePage);
    };

    const handleSelectFilter = (selectedKey: string | null) => {
        if (!selectedKey) return;

        setDefaultFilter(selectedKey);
    };

    const handleAddIntegration = () => {
        if (selectedProducts.length > 0 || selectAll) {
            setAttachIntegrationModal(true);
        } else {
            toast.error(
                intl.formatMessage({
                    id: "pleaseSelectProductsToAddIntegration",
                })
            );
        }
    };

    const handleMakePublic = () => {
        if (selectedProducts.length > 0 || selectedAll) {
            const product_status = detectProductStatus(productStatusFilter);
            makeProductPublic({
                ids: selectedProducts,
                select_all: selectedAll,
                product_status,
            });
            setSelectedProducts([]);
            setSelectAll(false);
            setSelectedAll(false);
        }
    };

    const handleCancelReview = () => {
        if (selectedProducts.length > 0 || selectedAll) {
            const product_status = detectProductStatus(productStatusFilter);
            cancelProductReview({
                ids: selectedProducts,
                select_all: selectedAll,
                product_status,
            });
            setSelectedProducts([]);
            setSelectAll(false);
            setSelectedAll(false);
        }
    };

    const handleMakePrivate = () => {
        if (selectedProducts.length > 0 || selectedAll) {
            const product_status = detectProductStatus(productStatusFilter);
            makeProductPrivate({
                ids: selectedProducts,
                select_all: selectedAll,
                product_status,
            });
            setSelectedProducts([]);
            setSelectAll(false);
            setSelectedAll(false);
        }
    };

    const handleSelectPageAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            const listProducts = productData.map((el) => el.id);
            setSelectedProducts(listProducts);
            setSelectAll(true);
        } else {
            setSelectedProducts([]);
            setSelectAll(false);
        }
    };

    const handleSelectProduct = (
        e: ChangeEvent<HTMLInputElement>,
        id: number
    ) => {
        if (e.target.checked) {
            setSelectedProducts((prev) => {
                return [...prev, id];
            });
        } else {
            setSelectedProducts((prev) => {
                return prev.filter((el) => el !== id);
            });
        }
    };

    const handleExpandData = (id: number) => {
        const updatedData = productData.map((el) =>
            el.id === id ? { ...el, expand: !el.expand } : el
        );
        setProductData(updatedData);
    };

    const handleRemoveProducts = () => {
        deleteProducts({ ids_list: selectedProducts }).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({
                            id: "somethingWentWrongPleaseTryAgainLater",
                        })
                    );
                }
            } else {
                toast.success(
                    intl.formatMessage({
                        id: "theProductsHasBeenDeletedSuccessfully",
                    })
                );
                setSelectedProducts([]);
            }
        });
    };

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedAll(true);
        } else {
            setSelectedAll(false);
        }
    };

    const handleOpenCsvModal = () => {
        setExportCsv(true);
    };

    const handleOpenImportCsvModal = () => {
        setImportCsv(true);
    };

    const handleOpenBulkModal = () => {
        setOpenBulkOrigination(true);
    };

    useEffect(() => {
        if (data) {
            handlePagesCount(data?.count);
            const updatedData = data.results.map((el) => {
                return { ...el, expand: false };
            });
            setProductData(updatedData);
        }
    }, [data, handlePagesCount]);

    return (
        <section className="p-0">
            <Container fluid className="header-dashboard">
                <Col className="col-lg-12 d-flex justify-content-between align-items-center pe-0">
                    <div className="d-flex align-items-center">
                        <div className="search-box d-flex flex-row align-items-center m-0 me-2 ms-1">
                            <div className="d-flex flex-row align-items-center justify-content-between w-100 px-2 m-0">
                                <input
                                    value={search}
                                    onChange={(e) =>
                                        handleSearch(e.target.value)
                                    }
                                    onKeyDown={(e) =>
                                        onKeyDownSearch(e, handlePage)
                                    }
                                    className="catalog-search-input"
                                    placeholder={`${intl.formatMessage({
                                        id: "search",
                                    })}`}
                                />
                                <button
                                    onClick={handleSearchInputClick}
                                    className="catalog-search-button pb-1"
                                >
                                    <BiSearch size={20} />
                                </button>
                            </div>
                        </div>
                        <ul className="dashboard-menu-ul d-flex align-items-start ps-0 m-0">
                            <li className="max-height-38">
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={handlePageSizeDropdown}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            <FormattedMessage
                                                id="resultsPerPage"
                                                values={{
                                                    amount: pageSize,
                                                }}
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                className="dashboard-header-dwopdown-item"
                                                eventKey={5}
                                            >
                                                <FormattedMessage
                                                    id="resultsPerPage"
                                                    values={{ amount: 5 }}
                                                />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="dashboard-header-dwopdown-item"
                                                eventKey={10}
                                            >
                                                <FormattedMessage
                                                    id="resultsPerPage"
                                                    values={{ amount: 10 }}
                                                />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="dashboard-header-dwopdown-item"
                                                eventKey={20}
                                            >
                                                <FormattedMessage
                                                    id="resultsPerPage"
                                                    values={{ amount: 20 }}
                                                />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="dashboard-header-dwopdown-item"
                                                eventKey={50}
                                            >
                                                <FormattedMessage
                                                    id="resultsPerPage"
                                                    values={{ amount: 50 }}
                                                />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li className="max-height-38">
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown
                                        onSelect={(e) =>
                                            handleProductsStatusFilter(
                                                e,
                                                handlePage
                                            )
                                        }
                                    >
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            <FormattedMessage id="filterByStatus" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                className="dashboard-header-dwopdown-item"
                                                eventKey="public"
                                            >
                                                <FormattedMessage id="public" />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="dashboard-header-dwopdown-item"
                                                eventKey="private"
                                            >
                                                <FormattedMessage id="private" />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className="dashboard-header-dwopdown-item"
                                                eventKey="in_review"
                                            >
                                                <FormattedMessage id="inReview" />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li>
                                <button
                                    onClick={() => clearFilters(handlePage)}
                                    className="catalog-button-blue dashboard-catalog-button max-height-38"
                                >
                                    <GoTrash size={18} className="me-2" />{" "}
                                    <FormattedMessage id="clearFiltersSearch" />
                                </button>
                            </li>
                            {!isPublic && (
                                <li>
                                    <button
                                        onClick={handleOpenCsvModal}
                                        className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38"
                                    >
                                        <TbFileExport
                                            size={18}
                                            className="me-2"
                                        />{" "}
                                        <FormattedMessage id="export" />
                                    </button>
                                </li>
                            )}
                            {!isPublic && (
                                <li>
                                    <button
                                        onClick={handleOpenImportCsvModal}
                                        className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38"
                                    >
                                        <TbFileExport
                                            size={18}
                                            className="me-2"
                                        />{" "}
                                        <FormattedMessage id="import" />
                                    </button>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="d-flex align-items-start">
                        {!isPublic && (
                            <button
                                style={{ gap: "6px" }}
                                onClick={handleOpenBulkModal}
                                className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0"
                            >
                                <TbFileUpload size={18} />
                                <FormattedMessage id="bulkUpload" />
                            </button>
                        )}
                    </div>
                </Col>
            </Container>
            <div className="page-wrapper">
                <div className="dashboard-body">
                    <Row className="align-items-center mt-1">
                        <Col className="col-lg-6">
                            <Nav
                                className="orders-nav-links"
                                activeKey={
                                    defaultFilter ? defaultFilter : "All"
                                }
                                onSelect={(selectedKey) =>
                                    handleSelectFilter(selectedKey)
                                }
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="All">
                                        <FormattedMessage id="all" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Required Action">
                                        <FormattedMessage id="requiredAction" />
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col className="col-lg-6 d-flex justify-content-end">
                            <div className="d-flex align-items-center">
                                <FormattedMessage id="selectAllWithDots" />
                                <Form.Check
                                    className="ms-2 me-3"
                                    checked={selectedAll}
                                    onChange={(e) => handleSelectAll(e)}
                                />
                            </div>
                            <Dropdown className="d-flex justify-content-center">
                                <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                    <FormattedMessage id="action" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={handleMakePublic}
                                        className="dropdown-item-hover-green"
                                    >
                                        <FormattedMessage id="makePublic" />
                                    </Dropdown.Item>
                                    {isPublic ? (
                                        <Dropdown.Item
                                            onClick={handleCancelReview}
                                            className="dropdown-item-hover-green"
                                        >
                                            <FormattedMessage id="makePrivate" />
                                        </Dropdown.Item>
                                    ) : (
                                        <>
                                            <Dropdown.Item
                                                onClick={handleMakePrivate}
                                                className="dropdown-item-hover-green"
                                            >
                                                <FormattedMessage id="makePrivate" />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={handleOpenModal}
                                                className="dropdown-item-hover-blue"
                                            >
                                                <FormattedMessage id="exportToStore" />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={handleAddIntegration}
                                                className="dropdown-item-hover-blue"
                                            >
                                                <FormattedMessage id="attachIntegration" />
                                            </Dropdown.Item>
                                        </>
                                    )}
                                    {!isPublic && (
                                        <Dropdown.Item
                                            onClick={handleRemoveProducts}
                                            className="dropdown-item-hover-red"
                                        >
                                            <FormattedMessage id="remove" />
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <MyProductsTable
                            productData={productData}
                            selectAll={selectAll}
                            selectedAll={selectedAll}
                            handleSelectPageAll={handleSelectPageAll}
                            handleSelectProduct={handleSelectProduct}
                            selectedProducts={selectedProducts}
                            handleExpandData={handleExpandData}
                        />
                        {pages.length > 1 && (
                            <PagePagination
                                pages={pages}
                                activePage={activePage}
                                handlePage={handlePage}
                            />
                        )}
                    </Row>
                </div>
            </div>
            {selectIntegration && (
                <SelectIntegration
                    setSelectedProducts={setSelectedProducts}
                    selectedProducts={selectedProducts}
                    show={selectIntegration}
                    onHide={() => setSelectIntegration(false)}
                />
            )}
            <ExportCsv show={exportCsv} onHide={() => setExportCsv(false)} />
            <ImportCsv show={importCsv} onHide={() => setImportCsv(false)} />
            {openBulk && (
                <BulkUpload
                    show={openBulk}
                    onHide={() => setOpenBulk(false)}
                    setShowErrorsModal={setShowErrorsModal}
                    setJustUploadedProducts={setJustUploadedProducts}
                    bulkUploadIntegration={bulkUploadIntegration}
                />
            )}
            {showErrorsModal && showErrorsModal.length > 0 && (
                <BulkUploadErrors
                    show={showErrorsModal ? true : false}
                    onHide={() => setShowErrorsModal(null)}
                    errors={showErrorsModal}
                />
            )}
            {justUploadedProducts && justUploadedProducts.length > 0 && (
                <BulkUploadImages
                    show={justUploadedProducts ? true : false}
                    onHide={() => setJustUploadedProducts(null)}
                    products={justUploadedProducts}
                />
            )}
            {openBulkOrigination && (
                <BulkOrigination
                    show={openBulkOrigination}
                    onHide={() => setOpenBulkOrigination(false)}
                    setOpenBulk={setOpenBulk}
                    setBulkUploadIntegration={setBulkUploadIntegration}
                />
            )}
            {attachIntegrationModal && (
                <AttachIntegrationModal
                    show={attachIntegrationModal}
                    onHide={() => setAttachIntegrationModal(false)}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
            )}
        </section>
    );
};

export default MyProducts;
