import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FC } from "react";

import { usePasswordForInvitedUserValidationSchema } from "./validator";
import { useAcceptUserAsNewMutation } from "../../../api/authAPI";
import { LOGIN_PAGE_URL } from "../../../helpers/constants";

type TSetInvitedUserPasswordProps = {
	show: boolean;
	onHide: () => void;
	code: string;
};

export type TSetInvitedUserPasswordSubmitData = {
	password1: string;
	password2: string;
};

const SetInvitedUserPassword: FC<TSetInvitedUserPasswordProps> = ({
	show,
	onHide,
	code,
}) => {
	const intl = useIntl();
	const passwordForInvitedUserValidationSchema =
		usePasswordForInvitedUserValidationSchema();

	const navigate = useNavigate();

	const [acceptUserAsNew, { isSuccess }] = useAcceptUserAsNewMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<TSetInvitedUserPasswordSubmitData>({
		resolver: yupResolver(passwordForInvitedUserValidationSchema),
	});

	const redirectToLogin = () => {
		navigate(LOGIN_PAGE_URL);
	};

	const onSubmit = (data: TSetInvitedUserPasswordSubmitData) => {
		acceptUserAsNew({ data, key: code });
	};

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="createPassword" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				{isSuccess ? (
					<div className="d-flex flex-column align-items-center justify-content-center py-5">
						<h5 className="p-0 m-0" style={{ color: "#191919" }}>
							<FormattedMessage id="invitationAcceptedSuccessfully" />
						</h5>
						<button
							className="ice-button mt-2"
							onClick={redirectToLogin}
						>
							<FormattedMessage id="goToLoginPage" />
						</button>
					</div>
				) : (
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group>
							<Form.Label className="default-input-label">
								<FormattedMessage id="newPassword" />
							</Form.Label>
							<Form.Control
								placeholder={`${intl.formatMessage({ id: "password" })}`}
								className="default-input"
								type="password"
								{...register("password1")}
							/>
							{errors.password1 && (
								<p className="mb-0 form-field-error">
									{errors.password1.message}
								</p>
							)}
						</Form.Group>
						<Form.Group className="mt-2">
							<Form.Label className="default-input-label">
								<FormattedMessage id="confirmNewPassword" />
							</Form.Label>
							<Form.Control
								placeholder={`${intl.formatMessage({ id: "confirmPassword" })}`}
								className="default-input"
								type="password"
								{...register("password2")}
							/>
							{errors.password2 && (
								<p className="mb-0 form-field-error">
									{errors.password2.message}
								</p>
							)}
						</Form.Group>
						<div className="d-flex justify-content-end mt-3">
							<button type="submit" className="ice-button w-100">
								<FormattedMessage id="save" />
							</button>
						</div>
					</Form>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default SetInvitedUserPassword;
