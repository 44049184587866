import { IoCloseOutline } from "react-icons/io5";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { TCommonFilter } from "../../../../api/api.types";

import "./FilterBlock.css";

type TSelectedFilters = {
	[key: string]: string;
};

type TFilterHandlers = {
	[key: string]: (
		value: string | null,
		handlePage?: (value: string) => void
	) => void;
};

type FilterBlockProps = {
	filters: TCommonFilter;
	selectedFilters: TSelectedFilters;
	handlers: TFilterHandlers;
	handlePage?: (value: string) => void;
	onClose: () => void;
};

const FilterBlock: FC<FilterBlockProps> = ({
	filters,
	selectedFilters,
	handlers,
	handlePage,
	onClose,
}) => {
	const [selectedOptions, setSelectedOptions] = useState<{
		[key: string]: string;
	}>({});

	const handleSelectFilterOption = (field: string, value: string) => {
		setSelectedOptions((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	const handleResetFilters = () => {
		Object.keys(selectedOptions).forEach((key) => {
			handlers[key]("", handlePage);
		});
	};

	const handleApplyFilters = () => {
		Object.keys(selectedOptions).forEach((key) => {
			handlers[key](selectedOptions[key], handlePage);
		});
		onClose();
	};

	useEffect(() => {
		setSelectedOptions(selectedFilters);
	}, [selectedFilters]);

	return (
		<div data-testid="filter-block" className="filter-block__container">
			<div className="filter-block__header">
				<h6 className="filter-block__title">
					<FormattedMessage id="filters" />
				</h6>
				<div onClick={onClose} className="filter-block__close">
					<IoCloseOutline size={28} color="#666666" />
				</div>
			</div>
			<div className="filter-block__content">
				{Object.keys(filters).map((filterKey, index) => (
					<div key={index} className="filter-block__filter">
						<h6 className="filter-block__filter-title">
							<FormattedMessage id={filterKey} />
						</h6>
						<div className="filter-block__filter-options">
							{filters[filterKey]?.options.map(
								(filter, index) => (
									<div
										data-testid="filter-option"
										key={index}
										className="filter-block__filter-option"
									>
										<div
											data-testid="filter-option-circle"
											onClick={() =>
												handleSelectFilterOption(
													filters[filterKey].field,
													filter.value
												)
											}
											className={`filter-block__filter-option-circle ${selectedOptions[filters[filterKey].field] === filter.value ? "selected" : ""}`}
										></div>
										<label
											className="filter-block__filter-option-label"
											htmlFor={filter.name}
										>
											<FormattedMessage
												id={filter.name}
											/>
										</label>
									</div>
								)
							)}
						</div>
					</div>
				))}
			</div>
			<div className="filter-block__buttons">
				<button
					data-testid="reset-filters"
					onClick={handleResetFilters}
					style={{ height: "40px", width: "100%" }}
					className="secondary-button"
				>
					<FormattedMessage id="reset" />
				</button>
				<button
					data-testid="apply-filters"
					onClick={handleApplyFilters}
					style={{ height: "40px", width: "100%" }}
					className="ice-button"
				>
					<FormattedMessage id="applyFilters" />
				</button>
			</div>
		</div>
	);
};

export default FilterBlock;
