import { combineReducers } from "@reduxjs/toolkit";

// Reducers
import authReducer from "./reducers/auth/authSlice";

// RTK Query API
import { authApi } from "../api/authAPI";
import { jobsApi } from "../api/jobsAPI";
import { statsApi } from "../api/statsAPI";
import { ordersApi } from "../api/ordersAPI";
import { settingsApi } from "../api/settingsAPI";
import { paymentsApi } from "../api/paymentsAPI";
import { productsApi } from "./../api/productsAPI";
import { affiliateApi } from "../api/affiliateAPI";
import { categoriesApi } from "../api/categoriesAPI";
import { integrationsApi } from "../api/integrationsAPI";
import { notificationsApi } from "../api/notificationsAPI";

const rootReducers = combineReducers({
	auth: authReducer,
	[authApi.reducerPath]: authApi.reducer,
	[jobsApi.reducerPath]: jobsApi.reducer,
	[statsApi.reducerPath]: statsApi.reducer,
	[ordersApi.reducerPath]: ordersApi.reducer,
	[paymentsApi.reducerPath]: paymentsApi.reducer,
	[settingsApi.reducerPath]: settingsApi.reducer,
	[productsApi.reducerPath]: productsApi.reducer,
	[affiliateApi.reducerPath]: affiliateApi.reducer,
	[categoriesApi.reducerPath]: categoriesApi.reducer,
	[integrationsApi.reducerPath]: integrationsApi.reducer,
	[notificationsApi.reducerPath]: notificationsApi.reducer,
});

export default rootReducers;
