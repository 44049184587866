import { useIntl } from 'react-intl';
import * as yup from 'yup'

export const useCreateTaskValidationSchema = () => {
    const intl = useIntl()

    return yup.object().shape({
        function_name: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        function_arguments: yup
            .string()
            .default("")
            .notRequired(),
        periodic_schedule: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        allow_retries: yup
            .string()
            .default("no")
            .notRequired(),
        queue: yup
            .string()
            .default("")
            .notRequired(),
        is_scheduled_for_retry: yup
            .string()
            .default("no")
            .notRequired(),
        retry_times: yup
            .number()
            .default(0)
            .transform((value, originalValue) => {
                return originalValue.trim() === '' ? undefined : value;
            })
            .notRequired(),
        max_retries: yup
            .number()
            .default(0)
            .transform((value, originalValue) => {
                return originalValue.trim() === '' ? undefined : value;
            })
            .notRequired(),
        retry_seconds_interval: yup
            .number()
            .default(0)
            .transform((value, originalValue) => {
                return originalValue.trim() === '' ? undefined : value;
            })
            .notRequired(),
        disabled: yup
            .boolean()
            .default(false)
            .notRequired(),
        is_one_off: yup
            .boolean()
            .default(false)
            .notRequired(),
    });
}