import { DropTargetMonitor, useDrop } from "react-dnd"
import { NativeTypes } from "react-dnd-html5-backend"
import { TbFileUpload } from "react-icons/tb"
import { FC, useRef } from "react"
import { FormattedMessage } from "react-intl"

type TTargetBox = {
    setFile: (files: File) => void
}

const TargetBox: FC<TTargetBox> = ({ setFile }) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop(item: { files: File[] }) {
                if (handleFileDrop) {
                    handleFileDrop(item)
                }
            },
            canDrop() {
                return true
            },
            collect: (monitor: DropTargetMonitor) => {
                return {
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                }
            },
        }),
        [],
    )

    const isActive = canDrop && isOver

    const handleChooseFile = () => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    const handleFileUploaded = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0])
        }
    }

    const handleFileDrop = (item: { files: File[] }) => {
        if (item.files.length === 0 || item.files.length > 1) {
            return
        }

        const file = item.files[0]

        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return
        }

        setFile(file)
    }

    return (
        <div className={isActive ? "bulk-upload-dnd-wrapper-active" : "bulk-upload-dnd-wrapper"} ref={drop}>
            <TbFileUpload size={34} />
            <h5>
                <FormattedMessage id="dropToUpload" />
            </h5>
            <button onClick={handleChooseFile} className="bulk-upload-choose-files">
                <FormattedMessage id="chooseFile" />
            </button>
            <input
                onChange={handleFileUploaded}
                accept=".xlsx"
                className="d-none"
                ref={inputRef}
                type="file"
            />
        </div>
    )
}

export default TargetBox