// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search__wrapper {
	height: 54px;
	max-width: 500px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 16px;
	gap: 16px;
	background-color: #ffffff;
	border: 1px solid #e6e6e6;
	border-radius: 8px;
}

.search__input {
	width: calc(100% - 40px);
	color: #191919;
	font-size: 16px;
	line-height: 20px;
}

.search__input::placeholder {
	color: #666666;
}

.search__input-button {
	background-color: transparent;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/Search/Search.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,gBAAgB;CAChB,WAAW;CACX,aAAa;CACb,mBAAmB;CACnB,eAAe;CACf,SAAS;CACT,yBAAyB;CACzB,yBAAyB;CACzB,kBAAkB;AACnB;;AAEA;CACC,wBAAwB;CACxB,cAAc;CACd,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,6BAA6B;CAC7B,SAAS;CACT,UAAU;CACV,aAAa;CACb,mBAAmB;CACnB,eAAe;AAChB","sourcesContent":[".search__wrapper {\n\theight: 54px;\n\tmax-width: 500px;\n\twidth: 100%;\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 0 16px;\n\tgap: 16px;\n\tbackground-color: #ffffff;\n\tborder: 1px solid #e6e6e6;\n\tborder-radius: 8px;\n}\n\n.search__input {\n\twidth: calc(100% - 40px);\n\tcolor: #191919;\n\tfont-size: 16px;\n\tline-height: 20px;\n}\n\n.search__input::placeholder {\n\tcolor: #666666;\n}\n\n.search__input-button {\n\tbackground-color: transparent;\n\tmargin: 0;\n\tpadding: 0;\n\tdisplay: flex;\n\talign-items: center;\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
