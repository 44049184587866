// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.system-schedule__content-wrapper {
	background-color: white;
	min-height: calc(100vh - 1px);
	display: flex;
	flex-direction: column;
	padding: 18px 24px 24px 0;
	gap: 24px;
}

.system-schedule__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 160px;
	gap: 16px;
}

.system-schedule__content {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 12px;
	padding: 24px;
	background-color: #f2f2f2;
	border-radius: 10px;
}

.system-schedule__filters {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}

.system-schedule__page-title {
	font-size: 20px;
	line-height: 25px;
	font-weight: 500;
	color: #191919;
}

.system-schedule__checkbox input {
	width: 20px;
	height: 20px;
	margin: 0;
}

.system-schedule__filters-buttons {
	display: flex;
	align-items: center;
	gap: 16px;
}

.system-schedule__status {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

.system-schedule__status-circle {
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/System/System.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,6BAA6B;CAC7B,aAAa;CACb,sBAAsB;CACtB,yBAAyB;CACzB,SAAS;AACV;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,oBAAoB;CACpB,SAAS;AACV;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,WAAW;CACX,SAAS;CACT,aAAa;CACb,yBAAyB;CACzB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,SAAS;AACV;;AAEA;CACC,eAAe;CACf,iBAAiB;CACjB,gBAAgB;CAChB,cAAc;AACf;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,SAAS;AACV;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;AACV;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,QAAQ;AACT;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,kBAAkB;AACnB","sourcesContent":[".system-schedule__content-wrapper {\n\tbackground-color: white;\n\tmin-height: calc(100vh - 1px);\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding: 18px 24px 24px 0;\n\tgap: 24px;\n}\n\n.system-schedule__header {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n\tpadding-right: 160px;\n\tgap: 16px;\n}\n\n.system-schedule__content {\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100%;\n\tgap: 12px;\n\tpadding: 24px;\n\tbackground-color: #f2f2f2;\n\tborder-radius: 10px;\n}\n\n.system-schedule__filters {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n\tgap: 16px;\n}\n\n.system-schedule__page-title {\n\tfont-size: 20px;\n\tline-height: 25px;\n\tfont-weight: 500;\n\tcolor: #191919;\n}\n\n.system-schedule__checkbox input {\n\twidth: 20px;\n\theight: 20px;\n\tmargin: 0;\n}\n\n.system-schedule__filters-buttons {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 16px;\n}\n\n.system-schedule__status {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tgap: 8px;\n}\n\n.system-schedule__status-circle {\n\twidth: 10px;\n\theight: 10px;\n\tborder-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
