import { FormattedMessage } from 'react-intl';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const SystemNav = () => {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto settings-nav">
                    <NavLink to="/dashboard/system-schedule/">
                        <FormattedMessage id="scheduleManagement" />
                    </NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default SystemNav