import { HTML5Backend } from "react-dnd-html5-backend";
import { FormattedMessage, useIntl } from "react-intl";
import { FaFileExcel } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { toast } from "react-toastify";
import { FC, useState } from "react";

import {
	useGetCsvFileMutation,
	useImportCsvFileMutation,
} from "../../../api/productsAPI";

import TargetBox from "../BulkUpload/TargetBox";

type TImportCsvProps = {
	show: boolean;
	onHide: () => void;
};

const ImportCsv: FC<TImportCsvProps> = ({ show, onHide }) => {
	const [file, setFile] = useState<File | null>(null);

	const intl = useIntl();

	const [importFile] = useImportCsvFileMutation();
	const [exportCsvFile] = useGetCsvFileMutation();

	const handleExportCsvFile = async () => {
		exportCsvFile().then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({ id: "somethingWentWrong" })
					);
				}
			} else {
				downloadCsv(res.data, "products.csv");
				onHide();
			}
		});
	};

	const downloadCsv = (data: string, filename: string) => {
		const blob = new Blob([data], { type: "text/csv" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = url;
		link.download = filename;
		link.click();

		URL.revokeObjectURL(url);
	};

	const handleSubmitFile = () => {
		if (file) {
			const formData = new FormData();

			formData.append("csv_file", file);

			importFile(formData).then((res) => {
				if ("error" in res) {
					toast.error(
						intl.formatMessage({ id: "somethingWentWrong" })
					);
				} else {
					if (res.data.created) {
						toast.success(
							intl.formatMessage({
								id: "updateOfTheProductListStartedYouWillReceiveANotification",
							})
						);
						onHide();
					} else {
						toast.error(
							intl.formatMessage({
								id: "updateOfProductsListAlreadyStarted",
							})
						);
						onHide();
					}
				}
			});
		}
	};

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="bulkProductsUpdate" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{ gap: "12px" }}
				className="pt-0 d-flex flex-column"
			>
				<p className="upload-orders__description">
					<FormattedMessage id="whenSavingAFilePleaseUseACommaDelimiter" />{" "}
					<FormattedMessage id="forMacAndWindowsSaveAsType" />
				</p>
				<button
					onClick={handleExportCsvFile}
					className="light-grey-button"
				>
					<FormattedMessage id="downloadProductsFile" />
				</button>
				<span className="upload-orders__description">
					<FormattedMessage id="pleaseMakeSureThatYouAreUsingCSVFileWithComma" />
				</span>
				<div>
					{file ? (
						<div className="bulk-upload-file-block">
							<div className="bulk-upload-file">
								<FaFileExcel color="#495057" size={24} />
								<div
									data-testid="file-info"
									className="bulk-upload-file-info"
								>
									<span>{file.name}</span>
									<span className="bulk-upload-file-size">
										{Math.round(file.size / 1024)} kb
									</span>
								</div>
							</div>
							<MdDelete
								data-testid="delete-file"
								className="bulk-upload-file-delete"
								onClick={() => setFile(null)}
								color="#d0d4d7"
								size={24}
							/>
						</div>
					) : (
						<DndProvider backend={HTML5Backend}>
							<TargetBox acceptFiles=".csv" setFile={setFile} />
						</DndProvider>
					)}
				</div>
				<div className="d-flex justify-content-end">
					<button
						data-testid="upload-button"
						className="ice-button w-100"
						onClick={handleSubmitFile}
					>
						<FormattedMessage id="update" />
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ImportCsv;
