import {
    FaFingerprint,
    FaUserTag,
    FaHandsHelping,
    FaCogs,
    FaCheck,
} from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { RxEnvelopeClosed } from "react-icons/rx";
import { AiOutlinePlus } from "react-icons/ai";
import { Dropdown } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { FiUsers } from "react-icons/fi";
import { toast } from "react-toastify";
import { FC, useState } from "react";

import {
    useDeleteIntegrationMutation,
    useGetIntegrationsQuery,
    useImportProductsMutation,
} from "../../../../api/integrationsAPI";

import AddShopifyIntegration from "../../../modals/AddShopifyIntegration/AddShopifyIntegration";
import AddAmazonIntegration from "../../../modals/AddAmazonIntegration/AddAmazonIntegration";
import AddKytIntegration from "../../../modals/AddKytIntegration/AddKytIntegration";

import AmazonFba from "../../../assets/images/amazonfba.jpg";
import Shopify from "../../../assets/images/shopify.jpg";
import Kyt from "../../../assets/images/kyt.png";

type TSettingsIntegrationsBlock = {
    isPublic: boolean;
    handleEditIntegration: (id: number) => void;
};

const SettingsIntegrationsBlock: FC<TSettingsIntegrationsBlock> = ({
    isPublic,
    handleEditIntegration,
}) => {
    const [addShopify, setAddShopify] = useState(false);
    const [addAmazon, setAddAmazon] = useState(false);
    const [addKyt, setAddKyt] = useState(false);

    const [importProducts] = useImportProductsMutation();
    const [deleteIntegration] = useDeleteIntegrationMutation();
    const { data: integrations } = useGetIntegrationsQuery(undefined, {
        skip: isPublic,
    });

    const intl = useIntl();

    const handleDeleteIntegration = (id: number) => {
        deleteIntegration(id);
    };

    const handleImportProducts = (id: number) => {
        importProducts({ integration_id: id }).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({ id: "productImportFailed" })
                    );
                }
            } else {
                toast.success(
                    intl.formatMessage({ id: "productImportStarted" })
                );
            }
        });
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="settings-card px-3 mt-4 pb-2">
                    <div className="row dashboard-menu-sub d-flex align-items-center">
                        <div className="col-lg-7">
                            <div className="left">
                                <ul className="dashboard-menu-ul">
                                    <li className="dashboard-menu-li">
                                        <span className="dashboard-menu-a">
                                            <FormattedMessage id="integrations" />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="right text-right">
                                <Dropdown className="d-flex justify-content-end dropdown-without-triangle">
                                    <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                        <FormattedMessage id="addNewIntegration" />{" "}
                                        <AiOutlinePlus size={18} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => setAddAmazon(true)}
                                            className="d-flex align-items-center"
                                        >
                                            <img
                                                className="add-integration-image"
                                                src={AmazonFba}
                                                alt=""
                                            />
                                            <h5 className="mb-0">Amazon FBA</h5>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => setAddShopify(true)}
                                            className="d-flex align-items-center"
                                        >
                                            <img
                                                className="add-integration-image"
                                                src={Shopify}
                                                alt=""
                                            />
                                            <h5 className="mb-0">Shopify</h5>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => setAddKyt(true)}
                                            className="d-flex align-items-center"
                                        >
                                            <img
                                                className="add-integration-image"
                                                src={Kyt}
                                                alt=""
                                            />
                                            <h5 className="mb-0">KYT</h5>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className="settingsUesrManagetable">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className="text-center" scope="col">
                                        <FaFingerprint size={20} />{" "}
                                        <FormattedMessage id="integrationName" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaUserTag size={20} />{" "}
                                        <FormattedMessage id="seller" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaUserTag size={20} />{" "}
                                        <FormattedMessage id="vendor" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FiUsers size={20} />{" "}
                                        <FormattedMessage id="fulfillmentService" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <RxEnvelopeClosed size={20} />{" "}
                                        <FormattedMessage id="status" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaHandsHelping size={20} />{" "}
                                        <FormattedMessage id="partner" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaCogs size={20} />{" "}
                                        <FormattedMessage id="action" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {integrations &&
                                    integrations.results.length > 0 &&
                                    integrations.results.map((integration) => (
                                        <tr key={integration.id}>
                                            <td className="text-center">
                                                {integration.name}
                                            </td>
                                            <td className="text-center">
                                                {integration.is_seller ? (
                                                    <FaCheck size={18} />
                                                ) : (
                                                    <GrClose size={18} />
                                                )}
                                            </td>
                                            <td className="text-center">
                                                {integration.is_vendor ? (
                                                    <FaCheck size={18} />
                                                ) : (
                                                    <GrClose size={18} />
                                                )}
                                            </td>
                                            <td className="text-center">
                                                {integration.is_fulfillment_service ? (
                                                    <FaCheck size={18} />
                                                ) : (
                                                    <GrClose size={18} />
                                                )}
                                            </td>
                                            <td className="text-center">
                                                {integration.status}
                                            </td>
                                            <td className="text-center">
                                                {integration.partner}
                                            </td>
                                            <td className="text-center">
                                                <Dropdown className="d-flex justify-content-center">
                                                    <Dropdown.Toggle
                                                        style={{
                                                            width: "110px",
                                                        }}
                                                        className="catalog-button-blue dropdown-settings-button"
                                                    >
                                                        <FormattedMessage id="select" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                handleEditIntegration(
                                                                    integration.id
                                                                )
                                                            }
                                                        >
                                                            <FormattedMessage id="edit" />
                                                        </Dropdown.Item>
                                                        {integration.partner ===
                                                            "Amazon Integration" && (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    handleImportProducts(
                                                                        integration.id
                                                                    )
                                                                }
                                                            >
                                                                <FormattedMessage id="importProducts" />
                                                            </Dropdown.Item>
                                                        )}
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                handleDeleteIntegration(
                                                                    integration.id
                                                                )
                                                            }
                                                        >
                                                            <FormattedMessage id="delete" />
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {addShopify && (
                <AddShopifyIntegration
                    show={addShopify}
                    onHide={() => setAddShopify(false)}
                />
            )}
            {addAmazon && (
                <AddAmazonIntegration
                    show={addAmazon}
                    onHide={() => setAddAmazon(false)}
                />
            )}
            {addKyt && (
                <AddKytIntegration
                    show={addKyt}
                    onHide={() => setAddKyt(false)}
                />
            )}
        </div>
    );
};

export default SettingsIntegrationsBlock;
