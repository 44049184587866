import { FormattedMessage } from "react-intl";
import { FC } from "react";

import "./Loading.css";

type LoadingProps = {
	isLoading: boolean;
	children: React.ReactNode;
	loadingText?: string;
	customClass?: string;
};

const Loading: FC<LoadingProps> = ({
	isLoading,
	children,
	loadingText,
	customClass,
}) => {
	return (
		<>
			{isLoading ? (
				<div
					className={`loading__container ${customClass ? customClass : ""}`}
				>
					<div className="loader__animation"></div>
					{loadingText && (
						<p className="loading__text">
							<FormattedMessage id={loadingText} />
						</p>
					)}
				</div>
			) : (
				<>{children}</>
			)}
		</>
	);
};

export default Loading;
