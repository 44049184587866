import { useIntl } from "react-intl";
import * as yup from "yup";

export const useEditIntegrationValidationSchema = () => {
	const intl = useIntl();

	return yup.object().shape({
		name: yup
			.string()
			.required(intl.formatMessage({ id: "thisFieldIsRequired" })),
		is_seller: yup
			.mixed<string | boolean>()
			.default("no")
			.required(intl.formatMessage({ id: "thisFieldIsRequired" })),
		is_vendor: yup
			.mixed<string | boolean>()
			.default("no")
			.required(intl.formatMessage({ id: "thisFieldIsRequired" })),
		is_fulfillment_service: yup
			.mixed<string | boolean>()
			.default("no")
			.required(intl.formatMessage({ id: "thisFieldIsRequired" })),
		status: yup
			.string()
			.required(intl.formatMessage({ id: "thisFieldIsRequired" })),
	});
};
