import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { ChangeEvent, FC, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { FaCcStripe } from "react-icons/fa";
import { toast } from "react-toastify";

import { useAddStripePaymentMethodsMutation } from "../../../../api/paymentsAPI";

import "./StripeCardForm.css";

type TStripeCardFormProps = {
    onHide: () => void;
};

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: "Arial, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const StripeCardForm: FC<TStripeCardFormProps> = ({ onHide }) => {
    const [error, setError] = useState("");

    const intl = useIntl();
    const stripe = useStripe();
    const elements = useElements();

    const [addStripePaymentMethod] = useAddStripePaymentMethodsMutation();

    const handleChangeCard = (event: any) => {
        setError(event?.error?.message);
    };

    const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!elements || !stripe) {
            return;
        }

        const { paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement) as any,
        });

        if (paymentMethod) {
            const createMethodData = {
                stripe_id: paymentMethod.id,
                last4: paymentMethod.card?.last4,
                exp_month: paymentMethod.card?.exp_month,
                exp_year: paymentMethod.card?.exp_year,
                brand: paymentMethod.card?.brand,
                default: true,
            };

            addStripePaymentMethod(createMethodData).then((res) => {
                if ("error" in res) {
                    const status = (res.error as { status: number }).status;

                    if (status !== 403) {
                        toast.error(
                            intl.formatMessage({ id: "somethingWentWrong" })
                        );
                    }
                } else {
                    onHide();
                    toast.success(
                        intl.formatMessage({
                            id: "theStripeCardHasBeenAddedSuccessfully",
                        })
                    );
                }
            });
        }
    };

    return (
        <div className="d-flex justify-content-center">
            <Container>
                <Row>
                    <form
                        className="card-element-form mt-3"
                        onSubmit={handleSubmit}
                    >
                        <p className="form-field-error text-center">{error}</p>
                        <CardElement
                            className="card-element"
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={handleChangeCard}
                        />
                        <span className="card-element-form-text">
                            <FormattedMessage id="theCreditCardInformationIsStoredInStripe" />{" "}
                            <FaCcStripe /> <FormattedMessage id="onlyWithDot" />{" "}
                            <FormattedMessage id="cloudOrderDoesNotSaveAnyPaymentInformation" />
                        </span>
                        <div className="d-flex justify-content-end">
                            <button
                                disabled={error ? true : false}
                                className="dark-button"
                            >
                                <FormattedMessage id="saveCard" />
                            </button>
                        </div>
                    </form>
                </Row>
            </Container>
        </div>
    );
};

export default StripeCardForm;
