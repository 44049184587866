import { Col, Form, Modal, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { FC, useEffect } from 'react'

import { useGetShopifyLinksMutation } from '../../../api/integrationsAPI'

import shopify from '../../assets/images/shopify.jpg'

import './AddShopifyIntegration.css'

export type TAddShopifyIntegrationSubmitData = {
    shop: string,
}

type TAddShopifyIntegrationProps = {
    show: boolean,
    onHide: () => void,
}

const AddShopifyIntegration: FC<TAddShopifyIntegrationProps> = ({ show, onHide }) => {
    const intl = useIntl()

    const [getShopifyLinks, { data: shopifyLink }] = useGetShopifyLinksMutation()

    const { register, handleSubmit, formState: { errors } } = useForm<TAddShopifyIntegrationSubmitData>();

    const onSubmit = (data: TAddShopifyIntegrationSubmitData) => {
        getShopifyLinks(data)
    }

    useEffect(() => {
        if (shopifyLink) {
            window.location.href = shopifyLink
        }
    }, [shopifyLink])

    return (
        <Modal size='xl' centered show={show} onHide={onHide}>
            <Modal.Header className="border-0 pb-1" closeButton></Modal.Header>
            <Modal.Body className="px-3 pt-0 d-flex">
                <Form onSubmit={handleSubmit(onSubmit)} className="w-100 mt-2">
                    <Row className='p-0 m-0'>
                        <Col className='col-lg-6 pe-2'>
                            <div className='d-flex align-items-center'>
                                <img width={50} src={shopify} alt="" />
                                <span className='shopify-logo-title'>Shopify</span>
                            </div>
                            <p className='mt-2'>
                                <FormattedMessage id="enabling" />
                                <span className='text-bold ms-1 me-1'>
                                    <FormattedMessage id="only" />
                                </span>
                                <FormattedMessage id="ordersWillAllowYouTo" />
                            </p>
                            <p>
                                - <FormattedMessage id="syncYourSalesChannelOrders" />
                            </p>
                            <p>
                                - <FormattedMessage id="shipOrdersWithCarrierIntegrations" />
                            </p>
                            <p>
                                - <FormattedMessage id="manageCustomerInfo" />
                            </p>
                            <p className='mt-2'>
                                <FormattedMessage id="you" />
                                <span className='text-bold ms-1 me-1'>
                                    <FormattedMessage id="willNot" />
                                </span>
                                <FormattedMessage id="beAbleToUseWithDots" />
                            </p>
                            <p>
                                - <FormattedMessage id="dashboardAnalytics" />
                            </p>
                            <p>
                                - <FormattedMessage id="reportsWithDot" />
                            </p>
                            <p className='mt-2'>
                                <FormattedMessage id="toUseTheseFeaturesYou" />
                                <span className='text-bold ms-1 me-1'>
                                    <FormattedMessage id="mustEnable" />
                                </span>
                                <FormattedMessage id="inventoryManagement" />
                            </p>
                            <Form.Group className='mt-2'>
                                <Form.Label className='form-input-label ps-1'>
                                    <FormattedMessage id="shopName" />
                                    <span className='red-color'> *</span>
                                </Form.Label>
                                <Form.Control
                                    className="form-input-field"
                                    placeholder={`${intl.formatMessage({ id: 'shopName' })}`}
                                    type="text"
                                    {...register("shop", {
                                        required: `${intl.formatMessage({ id: 'thisFieldIsRequired' })}`,
                                    })}
                                />
                                {errors.shop && <p className="mb-0 form-field-error">{errors.shop.message}</p>}
                            </Form.Group>
                        </Col>
                        <Col className='col-lg-6 ps-2'>
                            <div className='d-flex flex-column aling-items-center'>
                                <h3 className='red-color text-center'>
                                    <FormattedMessage id="integrationGuideWithDots" />
                                </h3>
                                <p className='red-color mt-3 text-center'>
                                    <span className='text-bold red-color me-1'>
                                        <FormattedMessage id="pleaseNoteWithDots" />
                                    </span>
                                    <FormattedMessage id="cloudOrderIsNotCompatibleWithMultipleWarehouseConfigurations" />
                                </p>
                            </div>
                            <ul className='red-color'>
                                <li className='list-item-disc'>
                                    <FormattedMessage id="selectWhatYouWantToSyncFromYourSalesChannel" />
                                </li>
                                <li className='list-item-disc'>
                                    <FormattedMessage id="beAwareIfYouChooseOrdersAndInventoryManagement" />
                                </li>
                                <li className='list-item-disc'>
                                    <FormattedMessage id="inputYourStoreNameAndPressConnect" />
                                </li>
                                <li className='list-item-disc'>
                                    <FormattedMessage id="onceYouHaveDoneThisYouWillBeRedirectedToShopifyLogin" />
                                </li>
                                <li className='list-item-disc'>
                                    <FormattedMessage id="thisWillForwardYouToYourStoreWhereYouWillNeedToTellShopify" />
                                </li>
                                <li className='list-item-disc'>
                                    <FormattedMessage id="onceAgreedItWillTakeYouBackToCloudOrder" />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <div className='d-flex justify-content-end'>
                        <button className="dashboard-menu-button me-3" type="submit">
                            <FormattedMessage id="connect" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddShopifyIntegration