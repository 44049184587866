import { useState } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { TCustomErrorType } from "../api/api.types";

const useHandleCustomRtkQueryError = (): [
	string | null,
	(value: string | null) => void,
	(error: FetchBaseQueryError | SerializedError | undefined) => void,
] => {
	const [customError, setCustomError] = useState<null | string>(null);

	const handleCustomError = (
		error: FetchBaseQueryError | SerializedError | undefined
	) => {
		if (error) {
			if ("status" in error) {
				try {
					let customErrorObject = "";

					if ("error" in error) {
						setCustomError(error.error);
					} else {
						customErrorObject = JSON.stringify(error.data);
					}

					const err = JSON.parse(
						customErrorObject
					) as TCustomErrorType;

					for (let error in err) {
						setCustomError(err[error][0]);
					}
				} catch (e) {
					setCustomError("Something went wrong!");
				}
			} else {
				if (error.message) {
					setCustomError(error.message);
				}
			}
		}
	};

	return [customError, setCustomError, handleCustomError];
};

export default useHandleCustomRtkQueryError;
