import { DateRangePicker, Range } from "react-date-range";
import { FormattedMessage } from "react-intl";
import { zhCN, enUS } from "date-fns/locale";
import { FC, useEffect, useState } from "react";

import { useTranslationsContext } from "../../../../contexts/useTranslationsContext";

import { Modal } from "react-bootstrap";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

type DateRangeSelectorProps = {
	onHide: () => void;
	handleReset: () => void;
	handleApply: (range: Range[]) => void;
	currentPeriod?: {
		startDate: Date;
		endDate: Date;
	};
};

const DateRangeSelector: FC<DateRangeSelectorProps> = ({
	onHide,
	handleReset,
	handleApply,
	currentPeriod,
}) => {
	const [range, setRange] = useState<Range[]>([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	]);

	const { userLocale } = useTranslationsContext();

	const onReset = () => {
		handleReset();
		onHide();
	};

	const onApply = () => {
		handleApply(range);
		onHide();
	};

	useEffect(() => {
		if (currentPeriod) {
			setRange([
				{
					startDate: currentPeriod.startDate,
					endDate: currentPeriod.endDate,
					key: "selection",
				},
			]);
		}
	}, [currentPeriod]);

	return (
		<Modal className="modal-400" centered show={true} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="selectCustomDateRange" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex flex-column gap-4 w-100 align-items-center">
				<DateRangePicker
					locale={userLocale === "zh" ? zhCN : enUS}
					onChange={(item) => setRange([item.selection])}
					moveRangeOnFirstSelection={false}
					ranges={range}
					direction="vertical"
					preventSnapRefocus={true}
					scroll={{ enabled: true }}
					calendarFocus="backwards"
					color="#4db8ff"
					rangeColors={["#4db8ff"]}
					inputRanges={[]}
					staticRanges={[]}
				/>
				<div className="d-flex align-items-center gap-2 w-100">
					<button
						onClick={onReset}
						className="secondary-button w-100"
					>
						<FormattedMessage id="reset" />
					</button>
					<button onClick={onApply} className="ice-button w-100">
						<FormattedMessage id="apply" />
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default DateRangeSelector;
