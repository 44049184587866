import { FormattedMessage } from 'react-intl'
import { Modal } from 'react-bootstrap'
import { FC } from 'react'

import { TTenantDetail } from '../../../../../redux/reducers/auth/authTypes'

import './TenantsModal.css'

type TListTenantsModalProps = {
    show: boolean,
    tenants: null | TTenantDetail[],
}

const TenantsModal: FC<TListTenantsModalProps> = ({ show, tenants }) => {

    const handleVisitTenant = (url: string) => {
        window.location.href = url
    }

    return (
        <Modal centered show={show} >
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="tenantsList" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {tenants && tenants.map((tenant) => (
                    <div className='tenant-block' key={tenant.id}>
                        <span className='tenant-domain'>
                            {tenant.subdomain}
                        </span>
                        <button
                            onClick={() => handleVisitTenant(tenant.full_url)}
                            className='dark-button tenant-visit-button'
                        >
                            <FormattedMessage id="visit" />
                        </button>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    )
}

export default TenantsModal