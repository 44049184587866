import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi2";
import { FormattedMessage, useIntl } from "react-intl";
import { IoIosRemoveCircle } from "react-icons/io";
import { FC, useEffect, useState } from "react";
import { IoAddCircle } from "react-icons/io5";
import { FaRegFile } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { useGetOffAmazonProductsQuery } from "../../../api/productsAPI";
import { useCreateManualOrderMutation } from "../../../api/ordersAPI";
import { TProductVariants } from "../../../api/api.types";

import "./CreateOrder.css";

type TCreateOrder = {
    show: boolean;
    onHide: () => void;
    selectedFulfillmentMethod: number | null;
};

type TProductVariantWithQuantity = TProductVariants & {
    quantity: number;
};

type TCreateManualOrderForm = {
    name: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    postal_code: string;
    country_code: string;
    phone: string;
    email: string;
};

const CreateOrder: FC<TCreateOrder> = ({
    show,
    onHide,
    selectedFulfillmentMethod,
}) => {
    const [selectedVariants, setSelectedVariants] = useState<
        TProductVariantWithQuantity[]
    >([]);
    const [variantsToDisplay, setVariantsToDisplay] = useState<
        TProductVariants[]
    >([]);
    const [noSelectedProductsIssue, setNoSelectedProductsIssue] =
        useState(false);
    const [variants, setVariants] = useState<TProductVariants[]>([]);
    const [showProductsList, setShowProductsList] = useState(false);
    const [search, setSearch] = useState("");

    const intl = useIntl();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TCreateManualOrderForm>();

    const { data } = useGetOffAmazonProductsQuery(
        {
            integrationId: selectedFulfillmentMethod || 0,
        },
        { skip: !selectedFulfillmentMethod }
    );
    const [createManualOrder] = useCreateManualOrderMutation();

    const handleFocusSearch = () => {
        setShowProductsList(true);
    };

    const handleAddVariantToSelected = (variant: TProductVariants) => {
        setSelectedVariants([...selectedVariants, { ...variant, quantity: 1 }]);
        setNoSelectedProductsIssue(false);
    };

    const handleRemoveSelectedVariant = (id: number) => {
        setSelectedVariants(
            selectedVariants.filter((variant) => variant.id !== id)
        );
    };

    const handleBlurSearch = () => {
        setTimeout(() => {
            setShowProductsList(false);
            setSearch("");
        }, 200);
    };

    console.log("variantsToDisplay", variantsToDisplay);

    const handleDecreaseQuantity = (id: number) => {
        setSelectedVariants(
            selectedVariants.map((variant) => {
                if (variant.id === id) {
                    return {
                        ...variant,
                        quantity:
                            variant.quantity > 1 ? variant.quantity - 1 : 1,
                    };
                }

                return variant;
            })
        );
    };

    const handleIncreaseQuantity = (id: number) => {
        setSelectedVariants(
            selectedVariants.map((variant) => {
                if (variant.id === id) {
                    return { ...variant, quantity: variant.quantity + 1 };
                }

                return variant;
            })
        );
    };

    const handleChangeVariantQuantity = (id: number, quantity: string) => {
        if (!Number(quantity)) {
            setSelectedVariants(
                selectedVariants.map((variant) => {
                    if (variant.id === id) {
                        return { ...variant, quantity: 0 };
                    }

                    return variant;
                })
            );
        } else {
            setSelectedVariants(
                selectedVariants.map((variant) => {
                    if (variant.id === id) {
                        return { ...variant, quantity: Number(quantity) };
                    }

                    return variant;
                })
            );
        }
    };

    const saveOrder = (data: TCreateManualOrderForm) => {
        if (selectedVariants.length === 0 || !selectedFulfillmentMethod) {
            setNoSelectedProductsIssue(true);
            return;
        }

        const lineItems = selectedVariants.map((variant) => ({
            sku: variant.sku,
            quantity: variant.quantity,
        }));

        createManualOrder({
            line_items: lineItems,
            integration_id: selectedFulfillmentMethod,
            shipping_address: {
                name: data.name,
                address_line1: data.address_line_1,
                address_line2: data.address_line_2,
                city: data.city,
                state: data.state,
                postal_code: data.postal_code,
                country_code: data.country_code,
                phone: data.phone,
                email: data.email,
            },
        }).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({ id: "failedToCreateOrder" })
                    );
                }
            } else {
                onHide();
                toast.success(
                    intl.formatMessage({ id: "orderCreatedSuccessfully" })
                );
            }
        });
    };

    useEffect(() => {
        if (search) {
            const variantsToDisplay: TProductVariants[] = [];

            variants.forEach((variant) => {
                if (
                    (variant.title
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                        variant.sku
                            .toLowerCase()
                            .includes(search.toLowerCase())) &&
                    selectedVariants.every(
                        (selectedVariant) => selectedVariant.id !== variant.id
                    )
                ) {
                    variantsToDisplay.push(variant);
                }
            });

            setVariantsToDisplay(variantsToDisplay);
        } else {
            const variantsToDisplay: TProductVariants[] = [];

            variants.forEach((variant) => {
                if (
                    selectedVariants.every(
                        (selectedVariant) => selectedVariant.id !== variant.id
                    )
                ) {
                    variantsToDisplay.push(variant);
                }
            });

            setVariantsToDisplay(variantsToDisplay);
            setSearch("");
        }
    }, [search, variants, selectedVariants]);

    useEffect(() => {
        if (data) {
            const variantsList: TProductVariants[] = [];

            data.results.forEach((product) => {
                product.variants.forEach((variant) => {
                    variantsList.push(variant);
                });
            });

            setVariants(variantsList);
        }
    }, [data]);

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title className="d-flex align-items-center gap-1">
                    <FaRegFile size={21} />
                    <FormattedMessage id="orderCreate" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0 d-flex flex-column">
                <form
                    onSubmit={handleSubmit(saveOrder)}
                    style={{ gap: "12px" }}
                    className="d-flex flex-column"
                >
                    <p className="bulk-upload-description">
                        <FormattedMessage id="createAnOrderByFillingInTheFormBelow" />
                    </p>
                    <label
                        style={{ marginBottom: "-10px" }}
                        className="bulk-upload-description"
                    >
                        <FormattedMessage id="selectProducts" />
                        <span className="create-order__required">*</span>
                    </label>
                    <div
                        className={`create-order__selected-products ${
                            noSelectedProductsIssue
                                ? "create-order__selected-products--error"
                                : ""
                        }`}
                    >
                        <input
                            placeholder={intl.formatMessage({
                                id: "searchForProducts",
                            })}
                            onFocus={handleFocusSearch}
                            onBlur={handleBlurSearch}
                            onChange={(e) => setSearch(e.target.value)}
                            className="create-order__search"
                            value={search}
                            type="text"
                        />
                        {showProductsList && (
                            <div className="create-orders__variants-list">
                                {variantsToDisplay.length === 0 ? (
                                    <p className="create-order__no-selected-label">
                                        <FormattedMessage id="noProductsFound" />
                                    </p>
                                ) : (
                                    <>
                                        {variantsToDisplay.map((variant) => (
                                            <div
                                                key={variant.id}
                                                className="create-order__variant"
                                            >
                                                <div className="create-order__variant-info-wrapper">
                                                    {variant?.images[0]
                                                        ?.image ? (
                                                        <img
                                                            src={
                                                                variant
                                                                    ?.images[0]
                                                                    ?.image
                                                            }
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <div className="create-order__variant-empty-image"></div>
                                                    )}
                                                    <div className="create-order__variant-info">
                                                        <p className="create-order__variant-name">
                                                            {variant.title}
                                                        </p>
                                                        <p className="create-order__variant-sku">
                                                            {variant.sku}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button
                                                    onClick={() =>
                                                        handleAddVariantToSelected(
                                                            variant
                                                        )
                                                    }
                                                    className="create-order__add-button"
                                                    type="button"
                                                >
                                                    <IoAddCircle
                                                        size={24}
                                                        color="var(--button)"
                                                    />
                                                </button>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        )}
                        {selectedVariants.length > 0 ? (
                            <div className="create-order__selected-variants-list">
                                {selectedVariants.map((variant) => (
                                    <div
                                        key={variant.id}
                                        className="create-order__variant"
                                    >
                                        <div className="create-order__variant-info-wrapper">
                                            {variant.images[0]?.image ? (
                                                <img
                                                    src={
                                                        variant.images[0]?.image
                                                    }
                                                    alt=""
                                                />
                                            ) : (
                                                <div className="create-order__variant-empty-image"></div>
                                            )}
                                            <div className="create-order__variant-info">
                                                <p className="create-order__variant-name">
                                                    {variant.title}
                                                </p>
                                                <p className="create-order__variant-sku">
                                                    {variant.sku}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="create-order__selected-variant-options">
                                            <div className="create-order__quantity-input-wrapper">
                                                <div
                                                    onClick={() =>
                                                        handleDecreaseQuantity(
                                                            variant.id
                                                        )
                                                    }
                                                    className="create-order__quantity-input-decrease"
                                                >
                                                    <HiOutlineMinus size={18} />
                                                </div>
                                                <input
                                                    onChange={(e) =>
                                                        handleChangeVariantQuantity(
                                                            variant.id,
                                                            e.target.value
                                                        )
                                                    }
                                                    className="create-order__quantity-input"
                                                    value={variant.quantity}
                                                    type="text"
                                                />
                                                <div
                                                    onClick={() =>
                                                        handleIncreaseQuantity(
                                                            variant.id
                                                        )
                                                    }
                                                    className="create-order__quantity-input-increase"
                                                >
                                                    <HiOutlinePlus size={18} />
                                                </div>
                                            </div>
                                            <button
                                                onClick={() =>
                                                    handleRemoveSelectedVariant(
                                                        variant.id
                                                    )
                                                }
                                                className="create-order__add-button"
                                                type="button"
                                            >
                                                <IoIosRemoveCircle
                                                    size={24}
                                                    color="var(--button)"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="create-order__no-selected-label">
                                <FormattedMessage id="noSelectedProducts" />
                            </p>
                        )}
                    </div>
                    <div className="create-order__input-wrapper">
                        <label className="bulk-upload-description mb-0">
                            <FormattedMessage id="customerName" />
                            <span className="create-order__required">*</span>
                        </label>
                        <input
                            {...register("name", {
                                required: `${intl.formatMessage({
                                    id: "customerNameIsRequired",
                                })}`,
                            })}
                            placeholder={intl.formatMessage({
                                id: "customerName",
                            })}
                            className="create-order__search"
                            type="text"
                        />
                    </div>
                    <div className="create-order__input-wrapper">
                        <label className="bulk-upload-description mb-0">
                            <FormattedMessage id="addressLine1" />
                            <span className="create-order__required">*</span>
                        </label>
                        <input
                            {...register("address_line_1", {
                                required: `${intl.formatMessage({
                                    id: "addressLine1IsRequired",
                                })}`,
                            })}
                            className={`create-order__search ${
                                errors.address_line_1
                                    ? "create-order__search--error"
                                    : ""
                            }`}
                            placeholder={intl.formatMessage({
                                id: "addressLine1",
                            })}
                            type="text"
                        />
                    </div>
                    <div className="create-order__input-wrapper">
                        <label className="bulk-upload-description mb-0">
                            <FormattedMessage id="addressLine2" />
                        </label>
                        <input
                            placeholder={intl.formatMessage({
                                id: "addressLine2",
                            })}
                            className="create-order__search"
                            {...register("address_line_2")}
                            type="text"
                        />
                    </div>
                    <div className="create-order__input-wrapper">
                        <label className="bulk-upload-description mb-0">
                            <FormattedMessage id="city" />
                            <span className="create-order__required">*</span>
                        </label>
                        <input
                            {...register("city", {
                                required: `${intl.formatMessage({
                                    id: "cityIsRequired",
                                })}`,
                            })}
                            className={`create-order__search ${
                                errors.city ? "create-order__search--error" : ""
                            }`}
                            placeholder={intl.formatMessage({ id: "city" })}
                            type="text"
                        />
                    </div>
                    <div className="create-order__input-wrapper">
                        <label className="bulk-upload-description mb-0">
                            <FormattedMessage id="state" />
                            <span className="create-order__required">*</span>
                        </label>
                        <input
                            {...register("state", {
                                required: `${intl.formatMessage({
                                    id: "stateIsRequired",
                                })}`,
                            })}
                            className={`create-order__search ${
                                errors.state
                                    ? "create-order__search--error"
                                    : ""
                            }`}
                            placeholder={intl.formatMessage({ id: "state" })}
                            type="text"
                        />
                    </div>
                    <div className="create-order__input-wrapper">
                        <label className="bulk-upload-description mb-0">
                            <FormattedMessage id="postalCode" />
                            <span className="create-order__required">*</span>
                        </label>
                        <input
                            {...register("postal_code", {
                                required: `${intl.formatMessage({
                                    id: "postalCodeIsRequired",
                                })}`,
                            })}
                            className={`create-order__search ${
                                errors.postal_code
                                    ? "create-order__search--error"
                                    : ""
                            }`}
                            placeholder={intl.formatMessage({
                                id: "postalCode",
                            })}
                            type="text"
                        />
                    </div>
                    <div className="create-order__input-wrapper">
                        <label className="bulk-upload-description mb-0">
                            <FormattedMessage id="countryCode" />
                            <span className="create-order__required">*</span>
                        </label>
                        <input
                            {...register("country_code", {
                                required: `${intl.formatMessage({
                                    id: "countryCodeIsRequired",
                                })}`,
                                maxLength: 2,
                                minLength: 2,
                            })}
                            className={`create-order__search ${
                                errors.country_code
                                    ? "create-order__search--error"
                                    : ""
                            }`}
                            placeholder={intl.formatMessage({
                                id: "countryCode",
                            })}
                            maxLength={2}
                            type="text"
                        />
                    </div>
                    <div className="create-order__input-wrapper">
                        <label className="bulk-upload-description mb-0">
                            <FormattedMessage id="phone" />
                            <span className="create-order__required">*</span>
                        </label>
                        <input
                            {...register("phone", {
                                required: `${intl.formatMessage({
                                    id: "phoneIsRequired",
                                })}`,
                            })}
                            placeholder={`${intl.formatMessage({
                                id: "phone",
                            })}`}
                            className="create-order__search"
                            type="text"
                        />
                    </div>
                    <div className="create-order__input-wrapper">
                        <label className="bulk-upload-description mb-0">
                            <FormattedMessage id="email" />
                            <span className="create-order__required">*</span>
                        </label>
                        <input
                            {...register("email", {
                                required: `${intl.formatMessage({
                                    id: "emailIsRequired",
                                })}`,
                            })}
                            placeholder={`${intl.formatMessage({
                                id: "email",
                            })}`}
                            className="create-order__search"
                            type="text"
                        />
                    </div>
                    <button className="create-order__create-button">
                        <FormattedMessage id="createOrder" />
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateOrder;
