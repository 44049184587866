import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { useResetPasswordMutation } from '../../../../../api/authAPI';
import { useResetPasswordValidationSchema } from './validator';

import Loader from '../../../../components/common/Loader/Loader';

import emailVerification from '../../../../assets/images/email_verification.png'
import SignIn from '../../../../assets/images/sing-in.png'

import './ResetPassword.css'

export type TResetPasswordForm = {
    email: string,
}

const ResetPassword = () => {
    const intl = useIntl()
    const resetPasswordValidationSchema = useResetPasswordValidationSchema()

    const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation()

    const { register, handleSubmit, formState: { errors } } = useForm<TResetPasswordForm>({
        resolver: yupResolver(resetPasswordValidationSchema),
    });

    const onSubmit = (data: TResetPasswordForm) => {
        resetPassword(data)
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className='reset-password-wrapper'>
            <div className='reset-password-content'>
                <Link to="/login">
                    <div className='login__back'>
                        <FaArrowLeftLong size={20} color="#FFFFFF" />
                    </div>
                </Link>
                {isSuccess ? (
                    <div className="register-pending-wrapper">
                        <h2 className="mb-2">
                            <FormattedMessage id="resetPasswordDefault" />
                        </h2>
                        <div className="login-wrap">
                            <div>
                                <label className='mb-1'>
                                    <FormattedMessage id="pleaseCheckYourEmailInboxToResetPassword" />
                                </label>
                                <div className="mt-3">
                                    <img className="register-pending-img" src={emailVerification} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Form className='login-wrap' onSubmit={handleSubmit(onSubmit)}>
                        <h3 className="login-title">
                            <FormattedMessage id="resetPasswordDefault" />
                        </h3>
                        <Form.Group className='mt-3'>
                            <Form.Control
                                placeholder={intl.formatMessage({ id: 'email' })}
                                className="auth-form-input"
                                type="text"
                                {...register("email")}
                            />
                            {errors.email && <p className="mb-0 form-field-error">{errors.email.message}</p>}
                        </Form.Group>
                        <div className="d-flex justify-content-start mt-4">
                            <button
                                className="dark-button auth-form-button"
                                type="submit"
                            >
                                <FormattedMessage id="sendEmail" />
                            </button>
                        </div>
                    </Form>
                )}
            </div>
            <div className='register-right-side'>
                <img src={SignIn} alt="" />
            </div>
        </div>
    )
}

export default ResetPassword