// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch__wrapper {
	display: flex;
	align-items: center;
	gap: 8px;
}

.switch__wrapper .form-check-input {
	height: 24px;
	width: 48px;
	background-color: #f2f2f7;
	border: none;
	margin-top: 0;
	border-radius: 20px;
}

.switch__label {
	font-size: 14px;
	line-height: 17px;
	color: #666666;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/Switch/Switch.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,QAAQ;AACT;;AAEA;CACC,YAAY;CACZ,WAAW;CACX,yBAAyB;CACzB,YAAY;CACZ,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,iBAAiB;CACjB,cAAc;AACf","sourcesContent":[".switch__wrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 8px;\n}\n\n.switch__wrapper .form-check-input {\n\theight: 24px;\n\twidth: 48px;\n\tbackground-color: #f2f2f7;\n\tborder: none;\n\tmargin-top: 0;\n\tborder-radius: 20px;\n}\n\n.switch__label {\n\tfont-size: 14px;\n\tline-height: 17px;\n\tcolor: #666666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
