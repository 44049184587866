import {
    BsClipboardData,
    BsFillCalendarCheckFill,
    BsFillCalendarFill,
} from "react-icons/bs";
import { BiLeftArrowAlt, BiMessageX } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { HiCode } from "react-icons/hi";
import { FaInfo } from "react-icons/fa";

import { useGetJobByIdQuery } from "../../../../../api/jobsAPI";
import { CRON_TIME } from "../../../../../helpers/constants";

import "./JobDetails.css";

const JobDetails = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const { data: jobData } = useGetJobByIdQuery(id ? id : "");

    const navigateToScheduleManagement = () => {
        navigate("/dashboard/system-schedule/");
    };

    return (
        <section className="p-0">
            <Container fluid className="header-dashboard">
                <Row className="ps-1">
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <button
                            onClick={navigateToScheduleManagement}
                            className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38"
                        >
                            <BiLeftArrowAlt size={20} />{" "}
                            <FormattedMessage id="back" />
                        </button>
                    </Col>
                </Row>
            </Container>
            <div className="page-wrapper">
                <div className="d-flex justify-content-between">
                    <div className="dashboard-body w-49">
                        <p className="jod-details-title mb-1">
                            <FormattedMessage id="taskDetails" />
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="nameWithDots" />{" "}
                            {jobData?.function_name}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="argumentsWithDots" />{" "}
                            {jobData?.function_arguments
                                ? JSON.stringify(jobData?.function_arguments)
                                : ""}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="queueWithDots" />{" "}
                            {jobData?.queue}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="createdWithDots" />{" "}
                            {jobData?.created_at.split("T")[0]}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="lastRunWithDots" />{" "}
                            {jobData?.last_run_at
                                ? jobData?.last_run_at.split("T")[0]
                                : ""}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="intervalWithDots" />{" "}
                            {
                                CRON_TIME[
                                    jobData?.periodic_schedule as keyof typeof CRON_TIME
                                ]
                            }
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="statusWithDots" />{" "}
                            {jobData?.status}
                        </p>
                    </div>
                    <div className="dashboard-body w-49">
                        <p className="jod-details-title mb-1">
                            <FormattedMessage id="additionalInformation" />
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="disabledWithDots" />{" "}
                            {jobData?.disabled ? (
                                <FormattedMessage id="yes" />
                            ) : (
                                <FormattedMessage id="no" />
                            )}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="retriesAllowedWithDots" />{" "}
                            {jobData?.allow_retries ? (
                                <FormattedMessage id="yes" />
                            ) : (
                                <FormattedMessage id="no" />
                            )}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="runningOnceWithDots" />{" "}
                            {jobData?.is_one_off ? (
                                <FormattedMessage id="yes" />
                            ) : (
                                <FormattedMessage id="no" />
                            )}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="scheduledForRetryWithDots" />{" "}
                            {jobData?.is_scheduled_for_retry ? (
                                <FormattedMessage id="yes" />
                            ) : (
                                <FormattedMessage id="no" />
                            )}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="maximumRetriesWithDots" />{" "}
                            {jobData?.max_retries}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="retryIntervalSecondsWithDots" />{" "}
                            {jobData?.retry_seconds_interval}
                        </p>
                        <p className="job-details-text">
                            <FormattedMessage id="retryTimesWithDots" />{" "}
                            {jobData?.retry_times}
                        </p>
                    </div>
                </div>
                <div className="dashboard-body mt-3">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th className="text-center" scope="col">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <BsFillCalendarFill
                                            className="me-1"
                                            size={16}
                                        />{" "}
                                        <FormattedMessage id="startDate" />
                                    </div>
                                </th>
                                <th className="text-center" scope="col">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <BsFillCalendarCheckFill
                                            className="me-1"
                                            size={16}
                                        />{" "}
                                        <FormattedMessage id="finishDate" />
                                    </div>
                                </th>
                                <th className="text-center" scope="col">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <FaInfo className="me-1" size={18} />{" "}
                                        <FormattedMessage id="type" />
                                    </div>
                                </th>
                                <th className="text-center" scope="col">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <BiMessageX
                                            className="me-1"
                                            size={18}
                                        />{" "}
                                        <FormattedMessage id="errorMessage" />
                                    </div>
                                </th>
                                <th className="text-center" scope="col">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <HiCode className="me-1" size={18} />{" "}
                                        <FormattedMessage id="traceback" />
                                    </div>
                                </th>
                                <th className="text-center" scope="col">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <BsClipboardData
                                            className="me-1"
                                            size={18}
                                        />{" "}
                                        <FormattedMessage id="result" />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobData &&
                                jobData.results.length > 0 &&
                                jobData.results.map((result, idx) => (
                                    <tr key={idx} className="table-row-body">
                                        <td className="text-center job-details-text">
                                            {result.started_at.split("T")[0]}
                                        </td>
                                        <td className="text-center job-details-text">
                                            {result.finished_at.split("T")[0]}
                                        </td>
                                        <td className="text-center job-details-text">
                                            {result.exc_type}
                                        </td>
                                        <td className="text-center job-details-text">
                                            {result.exc_message}
                                        </td>
                                        <td
                                            style={{ maxWidth: "450px" }}
                                            className="text-center job-details-text max-width-td"
                                        >
                                            <span className="wrapped-table-row traceback">
                                                {result.exc_traceback}
                                            </span>
                                        </td>
                                        <td className="text-center job-details-text max-width-td">
                                            <span className="wrapped-table-row">
                                                {result.result}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default JobDetails;
