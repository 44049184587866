import { FormattedMessage, useIntl } from "react-intl";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TbFileUpload } from "react-icons/tb";
import { FaFileExcel } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { toast } from "react-toastify";
import { FC, useState } from "react";

import {
    TBulkUploadError,
    TUploadedProductsResponse,
} from "../../../api/api.types";
import {
    useDownloadBulkUploadTemplateMutation,
    useProductsBulkUploadMutation,
} from "../../../api/productsAPI";

import TargetBox from "./TargetBox";

import "./BulkUpload.css";

type TUploadError = {
    data: {
        error: TBulkUploadError[];
    };
};

type TBulkUpload = {
    show: boolean;
    onHide: () => void;
    setShowErrorsModal: (value: TBulkUploadError[] | null) => void;
    setJustUploadedProducts: (value: TUploadedProductsResponse[]) => void;
    bulkUploadIntegration: number | null;
};

const BulkUpload: FC<TBulkUpload> = ({
    show,
    onHide,
    setShowErrorsModal,
    setJustUploadedProducts,
    bulkUploadIntegration,
}) => {
    const [file, setFile] = useState<File | null>(null);

    const intl = useIntl();

    const [productsBulkUpload] = useProductsBulkUploadMutation();
    const [downloadBulkTemplate] = useDownloadBulkUploadTemplateMutation();

    const handleDeleteFile = () => {
        setFile(null);
    };

    const handleUploadProducts = () => {
        if (!file) return;

        const formData = new FormData();
        formData.append("xlsx_file", file);

        if (bulkUploadIntegration) {
            formData.append("integration_id", bulkUploadIntegration.toString());
        }

        productsBulkUpload(formData).then((response) => {
            if ("error" in response) {
                const errors = response.error as TUploadError;
                setShowErrorsModal(errors.data.error as TBulkUploadError[]);
                onHide();
            } else {
                if (response.data.length === 0) {
                    toast.success(
                        intl.formatMessage({
                            id: "allProductsAreAlreadyUploaded",
                        })
                    );
                } else {
                    setJustUploadedProducts(response.data);
                }

                setFile(null);
                onHide();
            }
        });
    };

    const handleDownloadTemplate = () => {
        downloadBulkTemplate().then((response) => {
            if ("error" in response) {
                return;
            } else {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "products_template.xlsx");
                document.body.appendChild(link);
                link.click();
            }
        });
    };

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title className="d-flex align-items-center gap-1">
                    <TbFileUpload size={24} />
                    <FormattedMessage id="bulkProductsUpload" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ gap: "12px" }}
                className="pt-0 d-flex flex-column"
            >
                <p className="bulk-upload-description">
                    <FormattedMessage id="bulkProductsUploadFromAnExcelFile" />
                </p>
                <button
                    onClick={handleDownloadTemplate}
                    className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0 w-fit"
                >
                    <FormattedMessage id="downloadTemplate" />
                </button>
                <p className="bulk-upload-description">
                    <FormattedMessage id="afterYouHavePreparedTheFileDragAndDropOrClick" />
                </p>
                {file ? (
                    <div className="bulk-upload-file-block">
                        <div className="bulk-upload-file">
                            <FaFileExcel color="#495057" size={24} />
                            <div className="bulk-upload-file-info">
                                <span>{file.name}</span>
                                <span className="bulk-upload-file-size">
                                    {Math.round(file.size / 1024)} kb
                                </span>
                            </div>
                        </div>
                        <MdDelete
                            className="bulk-upload-file-delete"
                            onClick={handleDeleteFile}
                            color="#d0d4d7"
                            size={24}
                        />
                    </div>
                ) : (
                    <DndProvider backend={HTML5Backend}>
                        <TargetBox setFile={setFile} />
                    </DndProvider>
                )}
                <div className="d-flex align-items-center justify-content-end mt-2">
                    <button
                        disabled={!file}
                        onClick={handleUploadProducts}
                        className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0"
                    >
                        <FormattedMessage id="upload" />
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BulkUpload;
