import { RiBarcodeFill } from 'react-icons/ri'
import { AiOutlinePlus } from "react-icons/ai"
import { FormattedMessage } from 'react-intl'
import { FC, useState } from "react"

import AddTrackingInformation from "../../../modals/AddTrackingInformation/AddTrackingInformation"
import { useGetTrackingByIdQuery } from '../../../../api/ordersAPI'
import { TOrderFulfillment } from "../../../../api/api.types";

import './OrderDetails.css'

type TOrderDetails = {
    fulfillment: TOrderFulfillment,
}

const OrderTracking: FC<TOrderDetails> = ({ fulfillment }) => {
    const [addTrackingDataModal, setAddTrackingDataModal] = useState(false)

    const { data: trackingData } = useGetTrackingByIdQuery(fulfillment.id, { skip: !fulfillment.id })

    return (
        <table className='table'>
            <thead className="thead-light">
                <tr>
                    <th className='text-center' scope="col">
                        <div className='d-flex align-items-center justify-content-center'>
                            <RiBarcodeFill className='me-1' size={19} /> <FormattedMessage id="trackingNumberDefault" />
                        </div>
                    </th>
                    <th className='text-center' scope="col">
                        <div className='d-flex align-items-center justify-content-center'>
                            <RiBarcodeFill className='me-1' size={18} /> <FormattedMessage id="carrierCode" />
                        </div>
                    </th>
                    <th className='text-center' scope="col">
                        <div className='d-flex align-items-center justify-content-center'>
                            {!trackingData?.results || trackingData.results.length === 0 ? (
                                <button
                                    type="button"
                                    className="dashboard-menu-button margin-top"
                                    onClick={() => setAddTrackingDataModal(true)}
                                >
                                    <AiOutlinePlus size={18} /> <FormattedMessage id="add" />
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="dashboard-menu-button margin-top disabled-button"
                                    disabled
                                >
                                    <AiOutlinePlus size={18} /> <FormattedMessage id="add" />
                                </button>
                            )}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {trackingData?.results && trackingData?.results.map((trackingItem) => (
                    <tr className='table-row-body' key={fulfillment.id}>
                        <td className='text-center order-details-info-text max-width-td'>
                            <div className='d-flex flex-column'>
                                <p className='wrapped-table-row'>{trackingItem.tracking_number}</p>
                            </div>
                        </td>
                        <td className='text-center order-details-info-text max-width-td'>
                            <div className='d-flex flex-column'>
                                <p className=''>{trackingItem.carrier_code}</p>
                            </div>
                        </td>
                        <td className='text-center order-details-info-text max-width-td'>
                            <div className='d-flex flex-column'>
                            </div>
                        </td>
                    </tr>

                ))}
            </tbody>
            {addTrackingDataModal && <AddTrackingInformation show={addTrackingDataModal} onHide={() => setAddTrackingDataModal(false)} fulfillment={fulfillment.id} />}
        </table>
    );
}

export default OrderTracking