// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jod-details-title {
    font-weight: 500;
    font-size: 18px;
    color: rgb(26, 64, 116);
}

.job-details-text {
    font-size: 0.8rem;
    color: rgb(97, 97, 97);
    margin-bottom: 6px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/System/JobDetails/JobDetails.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,6BAA6B;AACjC","sourcesContent":[".jod-details-title {\n    font-weight: 500;\n    font-size: 18px;\n    color: rgb(26, 64, 116);\n}\n\n.job-details-text {\n    font-size: 0.8rem;\n    color: rgb(97, 97, 97);\n    margin-bottom: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
