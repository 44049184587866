import { Modal, Tab, Tabs } from 'react-bootstrap'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FaCcStripe } from 'react-icons/fa'
import axios from 'axios'

import { TWiseRequirementData, TWiseRequirementResponse } from '../../../api/api.types'

import AddPaymentForm from '../../components/layout/AddPaymentForm/AddPaymentForm';
import CreditCard from '../../components/common/CreditCard/CreditCard'

import './AddPaymentMethod.css'

type TAddPaymentMethodProps = {
    show: boolean,
    onHide: () => void,
}

const AddPaymentMethod: FC<TAddPaymentMethodProps> = ({ show, onHide }) => {
    const [requirements, setRequirements] = useState<TWiseRequirementData[]>([])
    const [selectedTab, setSelectedTab] = useState<string>("")
    const [addCard, setAddCard] = useState(false)

    const handleTab = (value: string | null) => {
        if (value) {
            setSelectedTab(value)
            getWiseRequirements()
        }
    }

    const getWiseRequirements = async () => {
        const response = await axios.get<any, TWiseRequirementResponse>(`https://api.sandbox.transferwise.tech/v1/account-requirements?source=USD&target=USD&sourceAmount=1000`, {
            headers: {
                "Authorization": 'Bearer 964b55ef-2bd3-4fe0-a550-fb100d0a098a',
                'Accept-Minor-Version': 1
            },
        })

        setRequirements(response.data)
    }

    const getExtraWiseRequirements = async (data: { [key: string]: string | { [key: string]: string } }): Promise<TWiseRequirementResponse> => {
        const response = await axios.post<any, TWiseRequirementResponse>(`https://api.sandbox.transferwise.tech/v1/account-requirements?source=USD&target=USD&sourceAmount=1000`, { details: data }, {
            headers: {
                "Authorization": 'Bearer 964b55ef-2bd3-4fe0-a550-fb100d0a098a',
                'Accept-Minor-Version': 1
            },
        })

        return response
    }

    useEffect(() => {
        if (requirements.length > 0) {
            setSelectedTab((prev) => {
                if (requirements.some((el) => el.title === prev)) {
                    return prev
                } else {
                    return requirements[0].title
                }
            })
        }
    }, [requirements])

    useEffect(() => {
        getWiseRequirements()
    }, [])

    return (
        <Modal size='lg' centered show={show} onHide={onHide}>
            <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
            <Modal.Body className="pt-0">
                <div className='payment-method-content'>
                    <label onClick={() => setAddCard(!addCard)} htmlFor="switch" className='payment-method-switch-label'>
                        <div className={addCard ? 'payment-method-toggle switched-to-cart' : 'payment-method-toggle'}></div>
                        <div className='paymnet-method-names'>
                            <p>
                                <FormattedMessage id="addBankAccount" />
                            </p>
                            <p>
                                <FormattedMessage id="addCard" /> <FaCcStripe size={18} />
                            </p>
                        </div>
                    </label>
                </div>
                {!addCard ? (
                    <div className='payment-bank-account-block'>
                        <div className='payment-bank-account-wrapper'>
                            <Tabs className='payment-method-tabs' onSelect={handleTab} activeKey={selectedTab}>
                                {requirements && requirements.length > 0 && requirements.map((el, idx) => (
                                    <Tab className='w-100 pt-2' key={idx} eventKey={el.title} title={el.title}>
                                        <AddPaymentForm
                                            getExtraWiseRequirements={getExtraWiseRequirements}
                                            setRequirements={setRequirements}
                                            selectedTab={selectedTab}
                                            onHide={onHide}
                                            el={el}
                                        />
                                    </Tab>
                                ))}
                            </Tabs>
                        </div>
                    </div>
                ) : (
                    <div className='payment-card-block'>
                        <CreditCard onHide={onHide} />
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default AddPaymentMethod