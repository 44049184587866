// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-verified-text {
	font-size: 20px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 15px;
	color: #191919;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/LendingPage/Auth/Register/VerifyEmail/VerifyEmail.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,gBAAgB;CAChB,kBAAkB;CAClB,mBAAmB;CACnB,cAAc;AACf","sourcesContent":[".email-verified-text {\n\tfont-size: 20px;\n\tfont-weight: 600;\n\ttext-align: center;\n\tmargin-bottom: 15px;\n\tcolor: #191919;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
