import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { TbFilter } from "react-icons/tb";
import { toast } from "react-toastify";

import {
	useCancelProductReviewMutation,
	useDeleteProductsMutation,
	useGetProductsQuery,
	useMakeProductPrivateMutation,
	useMakeProductPublicMutation,
} from "../../../../api/productsAPI";
import {
	TBulkUploadError,
	TProduct,
	TUploadedProductsResponse,
} from "../../../../api/api.types";
import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { detectProductStatus } from "../../../../helpers/functions";
import { MY_PRODUCTS_FILTERS } from "../../../../helpers/constants";
import usePagination from "../../../../hooks/usePagination";
import useFilters from "../../../../hooks/useFilters";

import AttachIntegrationModal from "../../../modals/AttachIntegrationModal/AttachIntegrationModal";
import SelectIntegration from "../../../modals/SelectIntegration/SelectIntegration";
import PagePagination from "../../../components/common/Pagination/PagePagination";
import useClickOutsideOfBlock from "../../../../hooks/useClickOutsideOfBlock";
import FilterBlock from "../../../components/common/FilterBlock/FilterBlock";
import BulkUploadErrors from "../../../modals/BulkUpload/BulkUploadErrors";
import BulkUploadImages from "../../../modals/BulkUpload/BulkUploadImages";
import EmptyState from "../../../components/common/EmptyState/EmptyState";
import Loading from "../../../components/common/Loading/Loading";
import BulkUpload from "../../../modals/BulkUpload/BulkUpload";
import Search from "../../../components/common/Search/Search";
import ExportCsv from "../../../modals/ExportCsv/ExportCsv";
import ImportCsv from "../../../modals/ImportCsv/ImportCsv";
import MyProductsTable from "./MyProductsTable";

import Arrow from "../../../assets/images/arrow.svg";

import "./MyProducts.css";

export type ProductDataUpdated = TProduct & { expand: boolean };

const MyProducts = () => {
	const [justUploadedProducts, setJustUploadedProducts] = useState<
		TUploadedProductsResponse[] | null
	>(null);
	const [showErrorsModal, setShowErrorsModal] = useState<
		TBulkUploadError[] | null
	>(null);
	const [attachIntegrationModal, setAttachIntegrationModal] = useState(false);
	const [productData, setProductData] = useState<ProductDataUpdated[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
	const [defaultFilter, setDefaultFilter] = useState<string>("All");
	const [selectIntegration, setSelectIntegration] = useState(false);
	const [expandFilters, setExpandFilters] = useState(false);
	const [sortOption, setSortOption] = useState<string>("");
	const [selectAll, setSelectAll] = useState(false);
	const [exportCsv, setExportCsv] = useState(false);
	const [importCsv, setImportCsv] = useState(false);
	const [openBulk, setOpenBulk] = useState(false);

	const filterRef = useRef<HTMLDivElement>(null);

	const intl = useIntl();
	useClickOutsideOfBlock({
		blockRef: filterRef,
		showValue: expandFilters,
		setShowValue: setExpandFilters,
	});

	const {
		search,
		handleSearch,
		handleSearchFilter,
		pageSize,
		searchFilter,
		productStatusFilter,
		handleProductsStatusFilter,
		onKeyDownSearch,
	} = useFilters();
	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		pageSize
	);
	const [isPublic] = useDetectSubdomainOrPublic();

	const { data, isLoading, isFetching } = useGetProductsQuery(
		{
			activePage,
			search: searchFilter,
			pageSize,
			statusFilters: productStatusFilter,
			requiredAction: defaultFilter === "Required Action" ? true : false,
			sortOption,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);
	const [makeProductPublic] = useMakeProductPublicMutation();
	const [makeProductPrivate] = useMakeProductPrivateMutation();
	const [cancelProductReview] = useCancelProductReviewMutation();
	const [deleteProducts] = useDeleteProductsMutation();

	const handleOpenModal = () => {
		setSelectIntegration(true);
	};

	const handleSelectFilter = (selectedKey: string | null) => {
		setDefaultFilter(selectedKey || "");
	};

	const handleAddIntegration = () => {
		if (selectedProducts.length > 0 || selectAll) {
			setAttachIntegrationModal(true);
		} else {
			toast.error(
				intl.formatMessage({
					id: "pleaseSelectProductsToAddIntegration",
				})
			);
		}
	};

	const handleMakePublic = () => {
		if (selectedProducts.length > 0) {
			const product_status = detectProductStatus(productStatusFilter);
			makeProductPublic({
				ids: selectedProducts,
				select_all: false,
				product_status,
			});
			setSelectedProducts([]);
			setSelectAll(false);
		}
	};

	const handleCancelReview = () => {
		if (selectedProducts.length > 0) {
			const product_status = detectProductStatus(productStatusFilter);
			cancelProductReview({
				ids: selectedProducts,
				select_all: false,
				product_status,
			});
			setSelectedProducts([]);
			setSelectAll(false);
		}
	};

	const handleMakePrivate = () => {
		if (selectedProducts.length > 0) {
			const product_status = detectProductStatus(productStatusFilter);
			makeProductPrivate({
				ids: selectedProducts,
				select_all: false,
				product_status,
			});
			setSelectedProducts([]);
			setSelectAll(false);
		}
	};

	const handleSelectPageAll = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			const listProducts = productData.map((el) => el.id);
			setSelectedProducts(listProducts);
			setSelectAll(true);
		} else {
			setSelectedProducts([]);
			setSelectAll(false);
		}
	};

	const handleSelectProduct = (
		e: ChangeEvent<HTMLInputElement>,
		id: number
	) => {
		if (e.target.checked) {
			setSelectedProducts((prev) => {
				return [...prev, id];
			});
		} else {
			setSelectedProducts((prev) => {
				return prev.filter((el) => el !== id);
			});
		}
	};

	const handleExpandData = (id: number) => {
		const updatedData = productData.map((el) =>
			el.id === id ? { ...el, expand: !el.expand } : el
		);
		setProductData(updatedData);
	};

	const handleRemoveProducts = () => {
		deleteProducts({ ids_list: selectedProducts }).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({
							id: "somethingWentWrongPleaseTryAgainLater",
						})
					);
				}
			} else {
				toast.success(
					intl.formatMessage({
						id: "theProductsHasBeenDeletedSuccessfully",
					})
				);
				setSelectedProducts([]);
			}
		});
	};

	const handleOpenBulkModal = () => {
		setOpenBulk(true);
	};

	const handleOpenBulkUpdate = () => {
		setImportCsv(true);
	};

	const handleSetSortFormat = (newFormat: string) => {
		setSortOption(newFormat);

		if (activePage !== "1") {
			handlePage("1");
		}
	};

	useEffect(() => {
		if (data) {
			handlePagesCount(data?.count);
			const updatedData = data.results.map((el) => {
				return { ...el, expand: false };
			});
			setProductData(updatedData);
		}
	}, [data, handlePagesCount]);

	return (
		<div className="my-products__content-wrapper">
			<div className="my-products__header">
				<Search
					search={search}
					handleSearchFilter={handleSearchFilter}
					handleSearch={handleSearch}
					onKeyDownSearch={onKeyDownSearch}
					handlePage={handlePage}
				/>
				<div className="my-products__buttons-wrapper">
					{!isPublic && (
						<>
							<button
								data-testid="bulk-update"
								onClick={handleOpenBulkUpdate}
								className="ice-button"
							>
								<FormattedMessage id="bulkUpdate" />
							</button>
							<button
								data-testid="bulk-add"
								onClick={handleOpenBulkModal}
								className="ice-button"
							>
								<FormattedMessage id="bulkAdd" />
							</button>
						</>
					)}
				</div>
			</div>
			<div
				data-testid="products-content"
				className="my-products__content"
			>
				<div className="my-products__content-filters-header">
					<h5 className="orders__page-title">
						<FormattedMessage id="myProducts" />
					</h5>
					<div className="my-products__filters-wrapper">
						{productData?.length > 0 && (
							<PagePagination
								pages={pages}
								activePage={activePage}
								handlePage={handlePage}
							/>
						)}
						<Dropdown className="d-flex justify-content-center">
							<Dropdown.Toggle
								data-testid="products-dropdown-toggle"
								className="user-header-dropdown orders__dropdown-toggle p-0"
							>
								<div className="orders__dropdown-button">
									<FormattedMessage id="action" />
									<img
										className="arrow-icon"
										src={Arrow}
										alt=""
									/>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu className="orders__dropdown-menu-wrapper">
								<div className="orders__dropdown-menu">
									<Dropdown.Item
										data-testid="make-public"
										onClick={handleMakePublic}
										className="orders__dropdown-item"
									>
										<FormattedMessage id="makePublic" />
									</Dropdown.Item>
									{isPublic ? (
										<Dropdown.Item
											data-testid="make-private"
											onClick={handleCancelReview}
											className="orders__dropdown-item"
										>
											<FormattedMessage id="makePrivate" />
										</Dropdown.Item>
									) : (
										<>
											<Dropdown.Item
												data-testid="make-private"
												onClick={handleMakePrivate}
												className="orders__dropdown-item"
											>
												<FormattedMessage id="makePrivate" />
											</Dropdown.Item>
											<Dropdown.Item
												onClick={handleOpenModal}
												className="orders__dropdown-item"
											>
												<FormattedMessage id="exportToStore" />
											</Dropdown.Item>
											<Dropdown.Item
												onClick={handleAddIntegration}
												className="orders__dropdown-item"
											>
												<FormattedMessage id="attachIntegration" />
											</Dropdown.Item>
										</>
									)}
									{!isPublic && (
										<Dropdown.Item
											data-testid="remove-products"
											onClick={handleRemoveProducts}
											className="orders__dropdown-item"
										>
											<FormattedMessage id="remove" />
										</Dropdown.Item>
									)}
								</div>
							</Dropdown.Menu>
						</Dropdown>
						<div
							ref={filterRef}
							className="orders__page-filters-wrapper"
						>
							<div
								data-testid="filter-button"
								onClick={() => setExpandFilters(!expandFilters)}
								className={`orders__page-filters-button ${expandFilters ? "expanded" : ""}`}
							>
								<TbFilter
									size={22}
									color={
										expandFilters ? "#FFFFFF" : "#666666"
									}
								/>
							</div>
							{expandFilters && (
								<FilterBlock
									selectedFilters={{
										status:
											detectProductStatus(
												productStatusFilter
											) || "",
										action: defaultFilter,
									}}
									filters={MY_PRODUCTS_FILTERS}
									handlers={{
										status: handleProductsStatusFilter,
										action: handleSelectFilter,
									}}
									handlePage={handlePage}
									onClose={() => setExpandFilters(false)}
								/>
							)}
						</div>
					</div>
				</div>
				<Loading isLoading={isLoading || isFetching}>
					{productData?.length > 0 ? (
						<div className="my-products__table-wrapper">
							<MyProductsTable
								productData={productData}
								selectAll={selectAll}
								selectedAll={false}
								handleSelectPageAll={handleSelectPageAll}
								handleSelectProduct={handleSelectProduct}
								selectedProducts={selectedProducts}
								handleExpandData={handleExpandData}
								sortOption={sortOption}
								handleSetSortFormat={handleSetSortFormat}
							/>
						</div>
					) : (
						<EmptyState message="noProductsFound" />
					)}
				</Loading>
			</div>
			{selectIntegration && (
				<SelectIntegration
					setSelectedProducts={setSelectedProducts}
					selectedProducts={selectedProducts}
					show={selectIntegration}
					onHide={() => setSelectIntegration(false)}
				/>
			)}
			<ExportCsv show={exportCsv} onHide={() => setExportCsv(false)} />
			<ImportCsv show={importCsv} onHide={() => setImportCsv(false)} />
			{openBulk && (
				<BulkUpload
					show={openBulk}
					onHide={() => setOpenBulk(false)}
					setShowErrorsModal={setShowErrorsModal}
					setJustUploadedProducts={setJustUploadedProducts}
				/>
			)}
			{showErrorsModal && showErrorsModal.length > 0 && (
				<BulkUploadErrors
					show={showErrorsModal ? true : false}
					onHide={() => setShowErrorsModal(null)}
					errors={showErrorsModal}
				/>
			)}
			{justUploadedProducts && justUploadedProducts.length > 0 && (
				<BulkUploadImages
					show={justUploadedProducts ? true : false}
					onHide={() => setJustUploadedProducts(null)}
					products={justUploadedProducts}
				/>
			)}
			{attachIntegrationModal && (
				<AttachIntegrationModal
					show={attachIntegrationModal}
					onHide={() => setAttachIntegrationModal(false)}
					selectedProducts={selectedProducts}
					setSelectedProducts={setSelectedProducts}
				/>
			)}
		</div>
	);
};

export default MyProducts;
