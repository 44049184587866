import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

import {
	CRON_TIME,
	SYSTEM_SCHEDULE_STATUS_TO_INFO,
} from "../../../../../helpers/constants";
import {
	useGetJobByIdQuery,
	useRunJobManuallyMutation,
} from "../../../../../api/jobsAPI";

import Loading from "../../../../components/common/Loading/Loading";

import "./JobDetails.css";

const JobDetails = () => {
	const { id } = useParams();

	const navigate = useNavigate();

	const {
		data: jobData,
		refetch,
		isLoading,
		isFetching,
	} = useGetJobByIdQuery(id ? id : "");
	const [runJob] = useRunJobManuallyMutation();

	const navigateToScheduleManagement = () => {
		navigate("/dashboard/system-schedule/");
	};

	const handleRunManually = () => {
		if (!jobData) return;

		runJob(jobData.id).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;
				if (status !== 403) {
					toast.error(<FormattedMessage id="somethingWentWrong" />);
				}
			} else {
				toast.success(<FormattedMessage id="jobStarted" />);
			}
		});
		refetch();
	};

	return (
		<div className="job-details__content-wrapper">
			<div className="job-details__header">
				<div
					onClick={navigateToScheduleManagement}
					className="job-details__back"
				>
					<IoIosArrowRoundBack color="#666666" size={28} />
				</div>
				<button
					data-testid="run-manually"
					onClick={handleRunManually}
					className="ice-button"
				>
					<FormattedMessage id="runManually" />
				</button>
			</div>
			<Loading isLoading={isLoading || isFetching}>
				<div className="job-details__content">
					<div
						data-testid="job-information"
						className="job-details__main-information"
					>
						<div className="job-details__mail-information-block">
							<div className="job-details__mail-information-block-header">
								<p className="job-details__mail-information-title">
									<FormattedMessage id="taskDetails" />
								</p>
								<div className="system-schedule__status">
									<div
										style={{
											backgroundColor: `${SYSTEM_SCHEDULE_STATUS_TO_INFO[jobData?.status as keyof typeof SYSTEM_SCHEDULE_STATUS_TO_INFO]?.color || "#D24023"}`,
										}}
										className="system-schedule__status-circle"
									></div>
									<FormattedMessage
										id={
											SYSTEM_SCHEDULE_STATUS_TO_INFO[
												jobData?.status as keyof typeof SYSTEM_SCHEDULE_STATUS_TO_INFO
											]?.label || "failed"
										}
									/>
								</div>
							</div>
							<div className="job-details__mail-information-params">
								<p className="job-details__mail-information-text">
									<FormattedMessage id="nameWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.function_name}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="argumentsWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.function_arguments
											? JSON.stringify(
													jobData?.function_arguments
												)
											: ""}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="queueWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.queue}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="createdWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.created_at.split("T")[0]}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="lastRunWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.last_run_at
											? jobData?.last_run_at.split("T")[0]
											: ""}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="intervalWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{
											CRON_TIME[
												jobData?.periodic_schedule as keyof typeof CRON_TIME
											]
										}
									</span>
								</p>
							</div>
						</div>
						<div className="job-details__mail-information-block">
							<p className="job-details__mail-information-title">
								<FormattedMessage id="additionalInformation" />
							</p>
							<div className="job-details__mail-information-params">
								<p className="job-details__mail-information-text">
									<FormattedMessage id="disabledWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.disabled ? (
											<FormattedMessage id="yes" />
										) : (
											<FormattedMessage id="no" />
										)}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="retriesAllowedWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.allow_retries ? (
											<FormattedMessage id="yes" />
										) : (
											<FormattedMessage id="no" />
										)}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="runningOnceWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.is_one_off ? (
											<FormattedMessage id="yes" />
										) : (
											<FormattedMessage id="no" />
										)}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="scheduledForRetryWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.is_scheduled_for_retry ? (
											<FormattedMessage id="yes" />
										) : (
											<FormattedMessage id="no" />
										)}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="maximumRetriesWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.max_retries}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="retryIntervalSecondsWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.retry_seconds_interval}
									</span>
								</p>
								<p className="job-details__mail-information-text">
									<FormattedMessage id="retryTimesWithDots" />{" "}
									<span className="job-details__mail-information-value">
										{jobData?.retry_times}
									</span>
								</p>
							</div>
						</div>
					</div>
					<div
						data-testid="job-results-table"
						className="default-table"
					>
						<table className="table">
							<thead className="thead-light">
								<tr>
									<th>
										<FormattedMessage id="startDate" />
									</th>
									<th>
										<FormattedMessage id="finishDate" />
									</th>
									<th>
										<FormattedMessage id="type" />
									</th>
									<th>
										<FormattedMessage id="errorMessage" />
									</th>
									<th>
										<FormattedMessage id="traceback" />
									</th>
									<th>
										<FormattedMessage id="result" />
									</th>
								</tr>
							</thead>
							<tbody>
								{jobData &&
									jobData.results.length > 0 &&
									jobData.results.map((result, idx) => (
										<tr key={idx}>
											<td className="text-center ">
												{
													result.started_at.split(
														"T"
													)[0]
												}
											</td>
											<td className="text-center ">
												{
													result.finished_at.split(
														"T"
													)[0]
												}
											</td>
											<td className="text-center ">
												{result.exc_type}
											</td>
											<td className="text-center ">
												{result.exc_message}
											</td>
											<td
												style={{ maxWidth: "450px" }}
												className="text-center  max-width-td"
											>
												<span className="wrapped-table-row traceback">
													{result.exc_traceback}
												</span>
											</td>
											<td className="text-center  max-width-td">
												<span className="wrapped-table-row">
													{result.result}
												</span>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			</Loading>
		</div>
	);
};

export default JobDetails;
