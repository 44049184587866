// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader-gif {
    width: 50px;
    height: 50px;
}

.loader-text {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
    color: rgb(18, 63, 115);
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/Loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".loader-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.loader-gif {\n    width: 50px;\n    height: 50px;\n}\n\n.loader-text {\n    font-size: 0.9rem;\n    font-weight: 600;\n    text-align: center;\n    margin-top: 15px;\n    color: rgb(18, 63, 115);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
