import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
	useDeleteAffiliateCodeMutation,
	useGetAffiliateCodesQuery,
	usePayAffiliateCodeMutation,
} from "../../../../api/affiliateAPI";
import useHandleCustomRtkQueryError from "../../../../hooks/useHandleCustomRtkQueryError";
import AffiliateCode from "../../../modals/AffiliateCode/AffiliateCode";
import usePagination from "../../../../hooks/usePagination";
import { TAffiliateCode } from "../../../../api/api.types";

import PagePagination from "../../../components/common/Pagination/PagePagination";
import EmptyState from "../../../components/common/EmptyState/EmptyState";
import Loading from "../../../components/common/Loading/Loading";

import "./AffiliateManagement.css";

const AffiliateManagement = () => {
	const [showModal, setShowModal] = useState(false);
	const [affiliate, setAffiliate] = useState<null | TAffiliateCode>(null);

	const intl = useIntl();

	const [customError, setCustomError, handleCustomError] =
		useHandleCustomRtkQueryError();
	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		10
	);

	const [deleteAffiliateCode] = useDeleteAffiliateCodeMutation();
	const [payAffiliateCode] = usePayAffiliateCodeMutation();

	const {
		data: affiliateCodes,
		isLoading,
		isFetching,
	} = useGetAffiliateCodesQuery(activePage);

	const handleShowAffiliateCode = (affiliate?: TAffiliateCode) => {
		if (affiliate) {
			setAffiliate(affiliate);
		} else {
			setAffiliate(null);
		}
		setShowModal(true);
	};

	const handleHideAffiliateCode = () => {
		setAffiliate(null);
		setShowModal(false);
	};

	const handleDeleteCode = (id: number) => {
		if (
			affiliateCodes &&
			affiliateCodes.results?.length === 1 &&
			activePage !== "1"
		) {
			handlePage(String(Number(activePage) - 1));
		}
		deleteAffiliateCode(id);
	};

	const handlePayCode = (id: number) => {
		payAffiliateCode({ id: id }).then((res) => {
			if ("error" in res) {
				handleCustomError(res.error);
			} else {
				setCustomError(null);
				toast.success(
					intl.formatMessage({ id: "theAffiliatePayoutCompleted" })
				);
			}
		});
	};

	useEffect(() => {
		toast.error(customError);
	}, [customError]);

	useEffect(() => {
		if (affiliateCodes) {
			handlePagesCount(affiliateCodes?.count);
		}
	}, [affiliateCodes, handlePagesCount]);

	return (
		<div className="affiliate-management__content-wrapper">
			<div className="affiliate-management__content-header">
				<button
					data-testid="create-affiliate-button"
					onClick={() => handleShowAffiliateCode()}
					className="ice-button"
				>
					<FormattedMessage id="createNewAffiliate" />
				</button>
			</div>
			<div
				data-testid="affiliate-content"
				className="affiliate-management__content"
			>
				<div className="affiliate-management__content-filters">
					<h5 className="affiliate-management__content-title">
						<FormattedMessage id="manageAffiliates" />
					</h5>
					{affiliateCodes?.results &&
						affiliateCodes?.results.length > 0 && (
							<PagePagination
								pages={pages}
								activePage={activePage}
								handlePage={handlePage}
							/>
						)}
				</div>
				<Loading isLoading={isLoading || isFetching}>
					{affiliateCodes?.results.length === 0 ? (
						<EmptyState message="thereAreNoAffiliates" />
					) : (
						<div className="default-table">
							<table className="table">
								<thead className="thead-light">
									<tr>
										<th>
											<FormattedMessage id="name" />
										</th>
										<th>
											<FormattedMessage id="community" />
										</th>
										<th>
											<FormattedMessage id="code" />
										</th>
										<th>
											<FormattedMessage id="codeAttributesDefault" />
										</th>
										<th>
											<FormattedMessage id="codeUsage" />
										</th>
										<th>
											<FormattedMessage id="totalOrders" />
										</th>
										<th>
											<FormattedMessage id="currentOrders" />
										</th>
										<th>
											<FormattedMessage id="actions" />
										</th>
									</tr>
								</thead>
								<tbody>
									{affiliateCodes &&
										affiliateCodes.results.length > 0 &&
										affiliateCodes.results.map(
											(affiliate) => (
												<tr
													data-testid="affiliate-row"
													key={affiliate.id}
												>
													<td className="align-middle text-center">
														{affiliate.first_name}{" "}
														{affiliate.last_name}
													</td>
													<td className="align-middle text-center">
														{
															affiliate.community_name
														}
													</td>
													<td
														data-testid="affiliate-code"
														className="align-middle text-center"
													>
														{affiliate.code}
													</td>
													<td className="align-middle text-center">
														$
														{
															affiliate.revenue_per_order
														}{" "}
														/{" "}
														<FormattedMessage id="order" />
														<br />
														{
															affiliate.benefits_discount_period
														}
														,{" "}
														{
															affiliate.benefits_discount
														}
														%
													</td>
													<td className="align-middle text-center">
														{affiliate.code_usage}
													</td>
													<td className="align-middle text-center">
														{affiliate.total_orders}
													</td>
													<td className="align-middle text-center">
														{
															affiliate.total_unpaid_orders
														}
													</td>
													<td
														width="290px"
														className="align-middle text-center"
													>
														<button
															onClick={() =>
																handleShowAffiliateCode(
																	affiliate
																)
															}
															type="button"
															className="ice-button"
														>
															<FormattedMessage id="addCode" />
														</button>
														<button
															onClick={() =>
																handlePayCode(
																	affiliate.id
																)
															}
															type="button"
															className="ice-button ms-2"
														>
															<FormattedMessage id="pay" />
														</button>
														<button
															data-testid="delete-affiliate-button"
															onClick={() =>
																handleDeleteCode(
																	affiliate.id
																)
															}
															type="button"
															className="ice-button ms-2"
														>
															<FormattedMessage id="delete" />
														</button>
													</td>
												</tr>
											)
										)}
								</tbody>
							</table>
						</div>
					)}
				</Loading>
			</div>
			{showModal && (
				<AffiliateCode
					show={showModal}
					onHide={handleHideAffiliateCode}
					affiliate={affiliate}
				/>
			)}
		</div>
	);
};

export default AffiliateManagement;
