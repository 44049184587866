import { MdOutlineCurrencyExchange, MdOutlineDescription } from 'react-icons/md';
import { AiOutlineInfoCircle, AiOutlineNumber } from 'react-icons/ai';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { HiOutlineCog } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { BsSortDown } from 'react-icons/bs';
import { GoTrash } from 'react-icons/go';

import { useConfirmStripePaymentMutation, useGetStripeReceiptsQuery } from '../../../../../api/paymentsAPI';
import usePagination from '../../../../../hooks/usePagination';
import useFilters from '../../../../../hooks/useFilters';

import PagePagination from '../../../../components/common/Pagination/PagePagination';
import StripePayment from '../../../../modals/StripePayment/StripePayment';

import '../Billing.css'

const BillingReceipts = () => {
    const [clientSecret, setClientSecret] = useState<string | null>(null)
    const [confirmPayment, setConfirmPayment] = useState(false)
    const [searchParams] = useSearchParams();

    const { pageSize, handlePageSize, clearFilters, handleDefaultFilter, defaultFilter } = useFilters('status')
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', pageSize)

    const navigate = useNavigate()

    const [confirmStripe] = useConfirmStripePaymentMutation()

    const { data: stripeReceiptsData } = useGetStripeReceiptsQuery({
        activePage,
        pageSize,
        defaultFilter,
    })

    const handleConfirmPayment = (secret: string) => {
        setConfirmPayment(true)
        setClientSecret(secret)
    }

    useEffect(() => {
        if (stripeReceiptsData) {
            handlePagesCount(stripeReceiptsData.count)
        }
    }, [stripeReceiptsData, handlePagesCount])

    useEffect(() => {
        const payment_intent = searchParams.get('payment_intent')
        const payment_intent_client_secret = searchParams.get('payment_intent_client_secret')
        const redirect_status = searchParams.get('redirect_status')

        if (payment_intent && payment_intent_client_secret && redirect_status && redirect_status === 'succeeded') {
            const orderId = stripeReceiptsData?.results.find((el) => el.client_secret === payment_intent_client_secret)?.id

            if (orderId) {
                confirmStripe(orderId)
                navigate('/dashboard/billing-receipts/')
            }
        }
    }, [searchParams, confirmStripe, stripeReceiptsData, navigate])

    return (
        <section className='p-0'>
            <Container fluid className="header-dashboard">
                <Row className='ps-1'>
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <ul className="dashboard-menu-ul d-flex align-items-start ps-0 m-0 ms-1">
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handlePageSize(e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            <FormattedMessage id="resultsPerPage" values={{ amount: pageSize }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={5}>
                                                <FormattedMessage id="resultsPerPage" values={{ amount: 5 }} />
                                            </Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={10}>
                                                <FormattedMessage id="resultsPerPage" values={{ amount: 10 }} />
                                            </Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={20}>
                                                <FormattedMessage id="resultsPerPage" values={{ amount: 20 }} />
                                            </Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={50}>
                                                <FormattedMessage id="resultsPerPage" values={{ amount: 50 }} />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handleDefaultFilter('status', e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            <BsSortDown size={18} className='me-2' /> <FormattedMessage id="filterByStatus" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                className='dashboard-header-dwopdown-item'
                                                eventKey="requires_action"
                                            >
                                                <FormattedMessage id="requiresAction" />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className='dashboard-header-dwopdown-item'
                                                eventKey="succeeded"
                                            >
                                                <FormattedMessage id="succeeded" />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className='dashboard-header-dwopdown-item'
                                                eventKey="requires_payment_method"
                                            >
                                                <FormattedMessage id="requiresPaymentMethod" />
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className='dashboard-header-dwopdown-item'
                                                eventKey="requires_confirmation"
                                            >
                                                <FormattedMessage id="requiresConfirmationDefault" />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li>
                                <button onClick={() => clearFilters(handlePage)} className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38">
                                    <GoTrash size={18} className='me-2' /> <FormattedMessage id="clearFilters" />
                                </button>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <div className='dashboard-body'>
                    <Row className="billing-title-no-data">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AiOutlineNumber size={21} />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AiOutlineInfoCircle size={18} className="me-1" /> <FormattedMessage id="status" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FaRegMoneyBillAlt size={19} className="me-1" /> <FormattedMessage id="amount" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <MdOutlineCurrencyExchange size={17} className="me-1" /> <FormattedMessage id="currency" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <MdOutlineDescription size={19} className="me-1" /> <FormattedMessage id="description" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <HiOutlineCog size={19} className="me-1" /> <FormattedMessage id="action" />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {stripeReceiptsData && stripeReceiptsData.results.length > 0 && stripeReceiptsData.results.map((receipt) => (
                                    <tr key={receipt.id}>
                                        <td className='text-center'>{receipt.id}</td>
                                        <td className='text-center'>{receipt.status}</td>
                                        <td className='text-center'>{receipt.amount}</td>
                                        <td className='text-center'>{receipt.currency}</td>
                                        <td className='text-center max-width-td'>
                                            <span className='wrapped-table-row'>
                                                {receipt.description}
                                            </span>
                                        </td>
                                        <td className='text-center'>
                                            <div>
                                                {receipt.status === "requires_action" ? (
                                                    <button onClick={() => handleConfirmPayment(receipt.client_secret)} className="dashboard-menu-button" style={{ minWidth: '160px' }}>
                                                        <FormattedMessage id="confirmPayment" />
                                                    </button>
                                                ) : (
                                                    <a target="_blank" rel="noreferrer" href={receipt.receipt_url}>
                                                        <button className="dashboard-menu-button" style={{ minWidth: '160px' }}>
                                                            <FormattedMessage id="viewReceipt" />
                                                        </button>
                                                    </a>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Row>
                    {pages.length > 1 && (
                        <PagePagination
                            pages={pages}
                            activePage={activePage}
                            handlePage={handlePage}
                        />
                    )}
                </div>
            </div>
            {confirmPayment && clientSecret && (
                <StripePayment
                    show={confirmPayment}
                    onHide={() => setConfirmPayment(false)}
                    clientSecret={clientSecret}
                />
            )}
        </section>
    )
}

export default BillingReceipts