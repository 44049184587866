import { FormattedMessage } from "react-intl";
import { FC } from "react";

import { TGetOrderResult } from "../../../../api/api.types";

type TOrderCustomerShippingInfoBlock = {
	orderData: TGetOrderResult | undefined;
};

const OrderCustomerShippingInfoBlock: FC<TOrderCustomerShippingInfoBlock> = ({
	orderData,
}) => {
	return (
		<div
			data-testid="order-details-block"
			className="order-details__customer-orders-details"
		>
			<div className="order-details__customer-orders-details-block">
				<p className="order-details__order-information-title">
					<FormattedMessage id="customer" />
				</p>
				<div className="order-details__info-fields-list">
					<p className="order-details__order-info-text">
						<FormattedMessage id="emailWithDots" />{" "}
						<span className="order-details__order-info-text-value">
							{orderData?.shipping_address?.email}
						</span>
					</p>
					<p className="order-details__order-info-text">
						<FormattedMessage id="phoneWithDots" />{" "}
						<span className="order-details__order-info-text-value">
							{orderData?.shipping_address?.phone}
						</span>
					</p>
				</div>
			</div>
			<div className="order-details__customer-orders-details-block">
				<p className="order-details__order-information-title">
					<FormattedMessage id="shippingAddress" />
				</p>
				<div className="order-details__info-fields-list">
					<p className="order-details__order-info-text">
						<FormattedMessage id="address1WithDots" />{" "}
						<span className="order-details__order-info-text-value">
							{orderData?.shipping_address?.address_line1}
						</span>
					</p>
					<p className="order-details__order-info-text">
						<FormattedMessage id="address2WithDots" />{" "}
						<span className="order-details__order-info-text-value">
							{orderData?.shipping_address?.address_line2}
						</span>
					</p>
					<p className="order-details__order-info-text">
						<FormattedMessage id="countryWithDots" />{" "}
						<span className="order-details__order-info-text-value">
							{orderData?.shipping_address?.country_code}
						</span>
					</p>
					<p className="order-details__order-info-text">
						<FormattedMessage id="cityWithDots" />{" "}
						<span className="order-details__order-info-text-value">
							{orderData?.shipping_address?.city}
						</span>
					</p>
					<p className="order-details__order-info-text">
						ZIP:{" "}
						<span className="order-details__order-info-text-value">
							{orderData?.shipping_address?.postal_code}
						</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default OrderCustomerShippingInfoBlock;
