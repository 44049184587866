import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "./apiConfig";

import { TNotification } from "./api.types";

export const notificationsApi = createApi({
	reducerPath: "notificationsApi",
	baseQuery: baseQueryWithReAuth,
	tagTypes: ["TenantNotifications", "PublicNotifications"],
	endpoints: (builder) => ({
		getTenantNotifications: builder.query<TNotification[], void>({
			query: () => {
				return {
					url: `notifications/tenant/`,
					method: "GET",
				};
			},
			providesTags: ["TenantNotifications"],
		}),
		tenantNotificationAction: builder.mutation<
			void,
			{ ids_list: number[]; action: string }
		>({
			query: (data) => {
				return {
					url: `notifications/tenant/`,
					method: "PATCH",
					body: data,
				};
			},
			invalidatesTags: ["TenantNotifications"],
		}),
		getPublicNotifications: builder.query<TNotification[], void>({
			query: () => {
				return {
					url: `notifications/public/`,
					method: "GET",
				};
			},
			providesTags: ["PublicNotifications"],
		}),
		publicNotificationAction: builder.mutation<
			void,
			{ ids_list: number[]; action: string }
		>({
			query: (data) => {
				return {
					url: `notifications/public/`,
					method: "PATCH",
					body: data,
				};
			},
			invalidatesTags: ["PublicNotifications"],
		}),
	}),
});

export const {
	useGetTenantNotificationsQuery,
	useTenantNotificationActionMutation,
	useGetPublicNotificationsQuery,
	usePublicNotificationActionMutation,
} = notificationsApi;
