import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { FC } from "react";

import { TBulkUploadError } from "../../../api/api.types";

type TBulkUploadErrors = {
	show: boolean;
	onHide: () => void;
	errors: TBulkUploadError[];
};

const BulkUploadErrors: FC<TBulkUploadErrors> = ({ show, onHide, errors }) => {
	return (
		<Modal className="modal-600" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="bulkProductsUploadErrors" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{ gap: "12px" }}
				className="pt-0 d-flex flex-column"
			>
				<p className="upload-orders__description">
					<FormattedMessage id="theFollowingErrorsOccurredDuringTheBulkUploadProcess" />
				</p>
				<div className="bulk-upload-errors-list">
					{Array.isArray(errors) ? (
						errors.map((error, index) => (
							<div
								key={index}
								className="bulk-upload-errors-item"
							>
								<p>
									<FormattedMessage id="issueInColumn" /> "
									{error.column}",{" "}
									<FormattedMessage id="row" /> "{error.row}":
								</p>
								<span className="bulk-upload-description">
									{error.message}
								</span>
							</div>
						))
					) : (
						<div className="bulk-upload-errors-item">
							<p>
								<FormattedMessage id="issueInDocument" />
							</p>
							<span className="bulk-upload-description">
								{errors}
							</span>
						</div>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default BulkUploadErrors;
