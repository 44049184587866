// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container * {
	font-family: "Poppins", sans-serif !important;
}

.dashboard-container {
	width: 100%;
	height: 100vh;
	display: grid;
	grid-template-rows: 1fr;
}

.dashboard-content {
	width: 100%;
	overflow-y: auto;
}

@media (max-width: 992px) {
	.overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 99;
	}
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;CACC,6CAA6C;AAC9C;;AAEA;CACC,WAAW;CACX,aAAa;CACb,aAAa;CACb,uBAAuB;AACxB;;AAEA;CACC,WAAW;CACX,gBAAgB;AACjB;;AAEA;CACC;EACC,eAAe;EACf,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,WAAW;CACZ;AACD","sourcesContent":[".dashboard-container * {\n\tfont-family: \"Poppins\", sans-serif !important;\n}\n\n.dashboard-container {\n\twidth: 100%;\n\theight: 100vh;\n\tdisplay: grid;\n\tgrid-template-rows: 1fr;\n}\n\n.dashboard-content {\n\twidth: 100%;\n\toverflow-y: auto;\n}\n\n@media (max-width: 992px) {\n\t.overlay {\n\t\tposition: fixed;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tbackground-color: rgba(0, 0, 0, 0.5);\n\t\tz-index: 99;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
