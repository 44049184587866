// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container * {
    font-family: 'PT Sans', sans-serif !important;
}

.dashboard-container {
    display: flex;
    width: 100%;
    height: calc(100vh - 50px);
}

.dashboard-content {
    width: 100%;
    overflow-y: auto;
}

@media (max-width: 992px) {
    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;AACjD;;AAEA;IACI,aAAa;IACb,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;QACf,WAAW;QACX,YAAY;QACZ,oCAAoC;QACpC,WAAW;IACf;AACJ","sourcesContent":[".dashboard-container * {\n    font-family: 'PT Sans', sans-serif !important;\n}\n\n.dashboard-container {\n    display: flex;\n    width: 100%;\n    height: calc(100vh - 50px);\n}\n\n.dashboard-content {\n    width: 100%;\n    overflow-y: auto;\n}\n\n@media (max-width: 992px) {\n    .overlay {\n        position: fixed;\n        width: 100%;\n        height: 100%;\n        background-color: rgba(0, 0, 0, 0.5);\n        z-index: 99;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
