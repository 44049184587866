import { KeyboardEvent, useState } from "react";

type TReturnFilters = {
	handleDefaultFilter: (
		value: string | null,
		handlePage?: (value: string) => void
	) => void;
	onKeyDownSearch: (
		e: KeyboardEvent<HTMLInputElement>,
		handlePage?: (value: string) => void
	) => void;
	handleSearch: (value: string) => void;
	handlePageSize: (
		value: string | null,
		handlePage?: (value: string) => void
	) => void;
	handleProductsStatusFilter: (
		value: string | null,
		handlePage?: (value: string) => void
	) => void;
	handleSearchFilter: (handlePage?: (value: string) => void) => void;
	handleCategoryFilter: (
		value: string | null,
		handlePage?: (value: string) => void
	) => void;
	productStatusFilter: { is_public: string; in_review: string };
	clearFilters: (handlePage?: (value: string) => void) => void;
	categoryFilter: string;
	defaultFilter: string;
	searchFilter: string;
	pageSize: string;
	search: string;
};

const useFilters = (): TReturnFilters => {
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [search, setSearch] = useState<string>("");
	const [pageSize, setPageSize] = useState("10");
	const [defaultFilter, setDefaultFilter] = useState("");
	const [categoryFilter, setCategoryFilter] = useState("");
	const [productStatusFilter, setProductStatusFilter] = useState({
		in_review: "",
		is_public: "",
	});

	const handleSearch = (value: string) => {
		setSearch(value);
	};

	const handleProductsStatusFilter = (
		value: string | null,
		handlePage?: (value: string) => void
	) => {
		switch (value) {
			case "public": {
				if (handlePage) {
					handlePage("1");
				}

				setProductStatusFilter({
					in_review: "false",
					is_public: "true",
				});
				break;
			}
			case "private": {
				if (handlePage) {
					handlePage("1");
				}

				setProductStatusFilter({
					in_review: "false",
					is_public: "false",
				});
				break;
			}
			case "in_review": {
				if (handlePage) {
					handlePage("1");
				}

				setProductStatusFilter({
					in_review: "true",
					is_public: "false",
				});
				break;
			}
			default: {
				setProductStatusFilter({
					in_review: "",
					is_public: "",
				});
				break;
			}
		}
	};

	const handleSearchFilter = (handlePage?: (value: string) => void) => {
		if (handlePage) {
			handlePage("1");
		}

		setSearchFilter(search);
	};

	const handleDefaultFilter = (
		value: string | null,
		handlePage?: (value: string) => void
	) => {
		if (value) {
			if (handlePage) {
				handlePage("1");
			}

			setDefaultFilter(value);
		} else {
			setDefaultFilter("");
		}
	};

	const handlePageSize = (
		value: string | null,
		handlePage?: (value: string) => void
	) => {
		if (value) {
			if (handlePage) {
				handlePage("1");
			}

			setPageSize(value);
		}
	};

	const onKeyDownSearch = (
		e: KeyboardEvent<HTMLInputElement>,
		handlePage?: (value: string) => void
	) => {
		if (e.code === "Enter") {
			handleSearchFilter(handlePage);
		}
	};

	const handleCategoryFilter = (
		value: string | null,
		handlePage?: (value: string) => void
	) => {
		if (handlePage) {
			handlePage("1");
		}

		if (value) {
			setCategoryFilter(value);
		} else {
			setCategoryFilter("");
		}
	};

	const clearFilters = (handlePage?: (value: string) => void) => {
		setSearchFilter("");
		setSearch("");
		setPageSize("10");
		setDefaultFilter("");
		setCategoryFilter("");
		setProductStatusFilter({
			is_public: "",
			in_review: "",
		});

		if (handlePage) {
			handlePage("1");
		}
	};

	return {
		search,
		handleSearch,
		handleSearchFilter,
		searchFilter,
		pageSize,
		handlePageSize,
		clearFilters,
		productStatusFilter,
		handleProductsStatusFilter,
		onKeyDownSearch,
		handleDefaultFilter,
		defaultFilter,
		categoryFilter,
		handleCategoryFilter,
	};
};

export default useFilters;
