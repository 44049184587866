import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoClose, IoWarning } from "react-icons/io5";
import { MdArrowForwardIos } from "react-icons/md";
import { IoIosArrowRoundUp } from "react-icons/io";
import { FormattedMessage } from "react-intl";
import StarRatings from "react-star-ratings";
import { FaSyncAlt } from "react-icons/fa";
import { FC, Fragment } from "react";

import { ProductDataUpdated } from "./MyProducts";

import MyProductsVariantRow from "./MyProductsVariantRow";

type MyProductsTableProps = {
	productData: ProductDataUpdated[];
	selectAll: boolean;
	selectedAll: boolean;
	handleSelectPageAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleSelectProduct: (
		e: React.ChangeEvent<HTMLInputElement>,
		id: number
	) => void;
	selectedProducts: number[];
	handleExpandData: (id: number) => void;
	sortOption: string;
	handleSetSortFormat: (newFormat: string) => void;
};

const MyProductsTable: FC<MyProductsTableProps> = ({
	productData,
	selectAll,
	selectedAll,
	handleSelectPageAll,
	handleSelectProduct,
	selectedProducts,
	handleExpandData,
	sortOption,
	handleSetSortFormat,
}) => {
	return (
		<table className="table">
			<thead className="thead-light">
				<tr>
					<th className="text-center vertical-align-middle">
						<Form.Check
							checked={selectAll || selectedAll ? true : false}
							onChange={(e) => handleSelectPageAll(e)}
						/>
					</th>
					<th></th>
					<th className="text-center vertical-align-middle">
						<FormattedMessage id="productName" />
					</th>
					<th className="text-center vertical-align-middle">
						<FormattedMessage id="public" />
					</th>
					<th className="text-center vertical-align-middle">
						<div
							onClick={() =>
								handleSetSortFormat(
									sortOption === "created_at"
										? "-created_at"
										: "created_at"
								)
							}
							className="orders__table-header-with-sort centered"
						>
							<FormattedMessage id="createdTime" />
							<IoIosArrowRoundUp
								style={{
									transform: `rotate(${sortOption === "created_at" ? "180deg" : "0deg"})`,
								}}
								size={24}
							/>
						</div>
					</th>
					<th className="text-center vertical-align-middle">
						<div
							onClick={() =>
								handleSetSortFormat(
									sortOption === "updated_at"
										? "-updated_at"
										: "updated_at"
								)
							}
							className="orders__table-header-with-sort centered"
						>
							<FormattedMessage id="lastUpdateTime" />
							<IoIosArrowRoundUp
								style={{
									transform: `rotate(${sortOption === "updated_at" ? "180deg" : "0deg"})`,
								}}
								size={24}
							/>
						</div>
					</th>
					<th className="text-center vertical-align-middle">
						<FormattedMessage id="productRating" />
					</th>
				</tr>
			</thead>
			<tbody>
				{productData?.length > 0 &&
					productData.map((el) => (
						<Fragment key={el.id}>
							<tr data-testid="product-row">
								<td width="4%" className="text-center">
									<Form.Check
										data-testid="product-checkbox"
										onChange={(e) =>
											handleSelectProduct(e, el.id)
										}
										checked={
											selectedProducts.includes(el.id) ||
											selectedAll
												? true
												: false
										}
									/>
								</td>
								<td
									data-testid="expand-button"
									className="cursor-pointer"
									onClick={() => handleExpandData(el.id)}
									width="2%"
								>
									<MdArrowForwardIos
										className={
											el.expand
												? "rotate-expand-button"
												: ""
										}
									/>
								</td>
								<td className="text-center max-width-td">
									{el.variants?.every(
										(variant) =>
											variant?.status !== "available" &&
											variant?.status !==
												"pending_synchronization"
									) ? (
										<OverlayTrigger
											overlay={
												<Tooltip id="hi">
													<FormattedMessage id="actionRequired" />
												</Tooltip>
											}
											placement="bottom"
										>
											<span
												style={{
													gap: "8px",
												}}
												className="wrapped-table-row"
											>
												<IoClose
													style={{
														marginRight: "5px",
														marginBottom: "2px",
													}}
													size={23}
													color="red"
												/>
												{el.title}
											</span>
										</OverlayTrigger>
									) : el.variants?.some(
											(variant) =>
												variant?.status ===
												"pending_synchronization"
									  ) ? (
										<OverlayTrigger
											overlay={
												<Tooltip id="hi">
													<FormattedMessage id="pendingSynchronization" />
												</Tooltip>
											}
											placement="bottom"
										>
											<span
												style={{
													gap: "8px",
												}}
												className="wrapped-table-row"
											>
												<FaSyncAlt
													style={{
														marginRight: "5px",
														marginBottom: "3px",
													}}
													size={20}
													color="#4e61f4"
												/>
												{el.title}
											</span>
										</OverlayTrigger>
									) : el.variants?.some(
											(variant) =>
												variant?.images?.length === 0
									  ) ? (
										<OverlayTrigger
											overlay={
												<Tooltip id="hi">
													<FormattedMessage id="actionRequired" />
												</Tooltip>
											}
											placement="bottom"
										>
											<span
												style={{
													gap: "8px",
												}}
												className="wrapped-table-row"
											>
												<IoWarning
													style={{
														marginRight: "5px",
														marginBottom: "3px",
													}}
													size={20}
													color="#ffcc00"
												/>
												{el.title}
											</span>
										</OverlayTrigger>
									) : (
										<span
											data-testid="product-title"
											style={{
												gap: "8px",
											}}
											className="wrapped-table-row"
										>
											{el.title}
										</span>
									)}
								</td>
								<td className="text-center">
									{el.in_review ? (
										<div className="my-products__status-wrapper">
											<div className="my-products__status-circle blue"></div>
											<span
												data-testid="product-status"
												className="my-products__status-text"
											>
												<FormattedMessage id="inReview" />
											</span>
										</div>
									) : (
										<>
											{el.is_public ? (
												<div className="my-products__status-wrapper">
													<div className="my-products__status-circle green"></div>
													<span
														data-testid="product-status"
														className="my-products__status-text"
													>
														<FormattedMessage id="public" />
													</span>
												</div>
											) : (
												<div className="my-products__status-wrapper">
													<div className="my-products__status-circle red"></div>
													<span
														data-testid="product-status"
														className="my-products__status-text"
													>
														<FormattedMessage id="private" />
													</span>
												</div>
											)}
										</>
									)}
								</td>
								<td className="text-center">
									{el.created_at.split("T")[0]}
								</td>
								<td className="text-center">
									{el.updated_at.split("T")[0]}
								</td>
								<td className="text-center">
									<StarRatings
										rating={parseFloat(
											el.rank ? el.rank?.rating : "0"
										)}
										numberOfStars={5}
										starRatedColor="yellow"
										starDimension="24px"
										starSpacing="1px"
										svgIconPath="M11.4396 2.87017L12.9061 5.82742C13.1061 6.23908 13.6394 6.63394 14.0894 6.70954L16.7474 7.15481C18.4472 7.44046 18.8472 8.68385 17.6223 9.91044L15.5559 11.9939C15.2059 12.3468 15.0143 13.0273 15.1225 13.5146L15.7142 16.0938C16.1808 18.1353 15.1059 18.925 13.3145 17.858L10.823 16.371C10.3731 16.1022 9.63154 16.1022 9.17321 16.371L6.68185 17.858C4.89871 18.925 3.8155 18.1269 4.28212 16.0938L4.87372 13.5146C4.98203 13.0273 4.79039 12.3468 4.44043 11.9939L2.37399 9.91044C1.15746 8.68385 1.54908 7.44046 3.24889 7.15481L5.90693 6.70954C6.34855 6.63394 6.88183 6.23908 7.0818 5.82742L8.54829 2.87017C9.34821 1.26553 10.648 1.26553 11.4396 2.87017Z"
										svgIconViewBox="0 0 20 20"
										starEmptyColor="#666666"
									/>
								</td>
							</tr>
							{el.expand && (
								<tr data-testid="expanded-row">
									<td className="p-0" colSpan={7}>
										<table className="table mb-0 my-products__variants-table">
											<thead className="thead-light">
												<tr className="border-0">
													<th
														style={{
															paddingLeft: "25px",
														}}
														className="text-center vertical-align-middle"
													>
														<FormattedMessage id="variantName" />
													</th>
													<th className="text-center vertical-align-middle">
														<FormattedMessage id="inStock" />
													</th>
													<th className="text-center vertical-align-middle">
														<FormattedMessage id="inOrder" />
													</th>
													<th className="text-center vertical-align-middle">
														SKU
													</th>
													<th className="text-center vertical-align-middle">
														<FormattedMessage id="cost" />
													</th>
													<th className="text-center vertical-align-middle">
														MSRP
													</th>
												</tr>
											</thead>
											<tbody>
												{el.variants.map((variant) => (
													<MyProductsVariantRow
														variant={variant}
														key={variant.id}
													/>
												))}
											</tbody>
										</table>
									</td>
								</tr>
							)}
						</Fragment>
					))}
			</tbody>
		</table>
	);
};

export default MyProductsTable;
