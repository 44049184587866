import {
    AiOutlineClose,
    AiOutlineCloseCircle,
    AiOutlineInfoCircle,
    AiOutlineMenu,
    AiOutlineWarning,
} from "react-icons/ai";
import { Dropdown, Nav, Navbar, NavItem } from "react-bootstrap";
import { FC, useCallback, useEffect, useState } from "react";
import { FaBell, FaUserAlt } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import useWebSocket from "react-use-websocket";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

import {
    notificationsApi,
    useGetPublicNotificationsQuery,
    useGetTenantNotificationsQuery,
    usePublicNotificationActionMutation,
    useTenantNotificationActionMutation,
} from "../../../../../api/notificationsAPI";
import {
    selectAccessToken,
    signOut,
} from "../../../../../redux/reducers/auth/authSlice";
import useDetectSubdomainOrPublic from "../../../../../hooks/useDetectSubdomainOrPublic";
import { useTranslationsContext } from "../../../../../contexts/useTranslationsContext";
import {
    settingsApi,
    useGetTenantDataQuery,
} from "../../../../../api/settingsAPI";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { authApi, useLogoutMutation } from "../../../../../api/authAPI";
import { integrationsApi } from "../../../../../api/integrationsAPI";
import { categoriesApi } from "../../../../../api/categoriesAPI";
import { affiliateApi } from "../../../../../api/affiliateAPI";
import { TNotification } from "../../../../../api/api.types";
import { paymentsApi } from "../../../../../api/paymentsAPI";
import { productsApi } from "../../../../../api/productsAPI";
import { ordersApi } from "../../../../../api/ordersAPI";
import { statsApi } from "../../../../../api/statsAPI";
import { jobsApi } from "../../../../../api/jobsAPI";

import LogoIconBlue from "../../../../assets/images/logo-icon-blue.png";
import ChinaFlag from "../../../../assets/images/china-flag.png";
import UsFlag from "../../../../assets/images/us-flag.png";

type TDashboardHeaderProps = {
    expanded: boolean;
    showSidebar?: boolean;
    setShowSidebar?: (value: boolean) => void;
};

const DashboardHeader: FC<TDashboardHeaderProps> = ({
    expanded,
    showSidebar,
    setShowSidebar,
}) => {
    const [notifications, setNotifications] = useState<TNotification[]>([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [unreadNotifications, setUnreadNotifications] = useState(0);

    const isStaff = useAppSelector((state) => state.auth.isStaff);
    const accessToken = useAppSelector(selectAccessToken);

    const dispatch = useAppDispatch();

    const { userLocale, updateUserLocale } = useTranslationsContext();
    const [isPublic, subdomain] = useDetectSubdomainOrPublic();

    const [tenantNotificationAction] = useTenantNotificationActionMutation();
    const [publicNotificationAction] = usePublicNotificationActionMutation();
    const [logout] = useLogoutMutation();

    const socketUrl = `wss://${window.location.hostname}/wss/notifications/${
        isPublic ? "public/" : "tenant/"
    }?token=${accessToken}`;
    const { data } = useGetTenantDataQuery(subdomain, {
        skip: isPublic || !subdomain,
    });
    const { data: tenantNotifications } = useGetTenantNotificationsQuery(
        undefined,
        { skip: isPublic || isStaff }
    );
    const { data: publicNotifications } = useGetPublicNotificationsQuery(
        undefined,
        { skip: isPublic ? false : true }
    );

    const handleReadNotifications = useCallback(
        (list: number[]) => {
            if (isPublic) {
                publicNotificationAction({ ids_list: list, action: "READ" });
            } else {
                tenantNotificationAction({ ids_list: list, action: "READ" });
            }
        },
        [tenantNotificationAction, isPublic, publicNotificationAction]
    );

    const handleDeleteNotification = (id: number) => {
        if (isPublic) {
            publicNotificationAction({ ids_list: [id], action: "DELETE" });
        } else {
            tenantNotificationAction({ ids_list: [id], action: "DELETE" });
        }
    };

    const hideNotifications = () => {
        setShowNotifications(false);
    };

    const handleShowNotifications = () => {
        setShowNotifications((prev) => {
            return !prev;
        });
    };

    const handleLogoutUser = () => {
        const baseUrl = process.env.REACT_APP_PUBLIC_TENANT_URL;
        logout();
        dispatch(signOut());
        dispatch(authApi.util.resetApiState());
        dispatch(affiliateApi.util.resetApiState());
        dispatch(categoriesApi.util.resetApiState());
        dispatch(integrationsApi.util.resetApiState());
        dispatch(notificationsApi.util.resetApiState());
        dispatch(jobsApi.util.resetApiState());
        dispatch(ordersApi.util.resetApiState());
        dispatch(paymentsApi.util.resetApiState());
        dispatch(productsApi.util.resetApiState());
        dispatch(settingsApi.util.resetApiState());
        dispatch(statsApi.util.resetApiState());

        if (!isPublic && baseUrl) {
            window.location.href = baseUrl;
        }
    };

    const { sendJsonMessage } = useWebSocket(socketUrl, {
        onOpen: () => {
            if (isPublic) {
                sendJsonMessage({
                    action: "subscribe_to_notification_activity",
                    request_id: new Date().getTime(),
                    tenant_id: "None",
                });
            }
        },
        onMessage: (e) => {
            const message = JSON.parse(e.data);
            setNotifications((prev) => {
                return [...prev, message];
            });
        },
    });

    useEffect(() => {
        if (showNotifications) {
            let list: number[] = [];

            notifications.forEach((el) => {
                if (el.checked === false) {
                    list.push(el.id);
                }
            });

            if (list.length > 0) {
                setTimeout(() => {
                    handleReadNotifications(list);
                }, 1500);
            }
        }
    }, [showNotifications, notifications, handleReadNotifications]);

    useEffect(() => {
        if (notifications.length > 0) {
            let count = 0;

            notifications.forEach((el) => {
                if (el.checked === false) {
                    count = count + 1;
                }
            });

            setUnreadNotifications(count);
        } else {
            setUnreadNotifications(0);
        }
    }, [notifications]);

    useEffect(() => {
        if (tenantNotifications) {
            setNotifications(tenantNotifications);
        } else if (publicNotifications) {
            setNotifications(publicNotifications);
        }
    }, [tenantNotifications, publicNotifications]);

    useEffect(() => {
        if (data) {
            sendJsonMessage({
                action: "subscribe_to_notification_activity",
                request_id: new Date().getTime(),
                tenant_id: data.id,
            });
        }
    }, [data, sendJsonMessage, isPublic]);

    return (
        <Navbar expanded={expanded} className="user-header px-2 py-0">
            <Navbar.Brand className="col-xl-3 col-lg-3 m-0 d-flex align-items-center">
                {showSidebar ? (
                    <AiOutlineClose
                        onClick={() =>
                            setShowSidebar && setShowSidebar(!showSidebar)
                        }
                        className="sidebar-img ms-3"
                        fill="white"
                        size={25}
                    />
                ) : (
                    <AiOutlineMenu
                        onClick={() =>
                            setShowSidebar && setShowSidebar(!showSidebar)
                        }
                        className="sidebar-img ms-3"
                        fill="white"
                        size={25}
                    />
                )}
                <div className="user-header-logo">
                    <img width={80} src={LogoIconBlue} alt="logo" />
                    <span className="user-header-logo-text">Cloud Order</span>
                </div>
            </Navbar.Brand>
            <div className="col-xl-9 col-lg-9 justify-content-between">
                <Nav className="col-xl-7 col-lg-7 w-100 d-flex align-items-center justify-content-end">
                    <NavItem className="me-3 cursor-pointer">
                        <div
                            onClick={() =>
                                updateUserLocale(
                                    userLocale === "en" ? "zh" : "en"
                                )
                            }
                        >
                            {userLocale === "en" ? (
                                <img width={27} src={UsFlag} alt="" />
                            ) : (
                                <img width={27} src={ChinaFlag} alt="" />
                            )}
                        </div>
                    </NavItem>
                    <NavItem className="me-1">
                        <div
                            className="cursor-pointer position-relative"
                            onClick={handleShowNotifications}
                        >
                            <FaBell fill="white" size={25} />
                            {unreadNotifications > 0 && (
                                <div className="notifications-count-block">
                                    {unreadNotifications > 9
                                        ? "9+"
                                        : unreadNotifications}
                                </div>
                            )}
                        </div>
                        {showNotifications && (
                            <div className="notifications-container">
                                <div className="notifications-header">
                                    <span className="notifications-title">
                                        <FormattedMessage id="notifications" />
                                    </span>
                                    <IoClose
                                        className="cursor-pointer"
                                        size={22}
                                        onClick={hideNotifications}
                                    />
                                </div>
                                <div className="notification-body">
                                    {notifications &&
                                    notifications.length > 0 ? (
                                        notifications
                                            .slice(0)
                                            .reverse()
                                            .map((el) => (
                                                <div
                                                    id={`notification-${el.id}`}
                                                    key={el.id}
                                                    className={
                                                        el.checked
                                                            ? "notification-block"
                                                            : "notification-block notification-unchecked"
                                                    }
                                                >
                                                    <div className="notification-info">
                                                        <div>
                                                            {el.kind ===
                                                            "Info" ? (
                                                                <AiOutlineInfoCircle
                                                                    fill="rgba(66, 147, 208)"
                                                                    className="notification-icon"
                                                                    size={21}
                                                                />
                                                            ) : el.kind ===
                                                              "Warning" ? (
                                                                <AiOutlineWarning
                                                                    fill="#ffcc00"
                                                                    className="notification-icon"
                                                                    size={21}
                                                                />
                                                            ) : (
                                                                <AiOutlineCloseCircle
                                                                    fill="#ff3333"
                                                                    className="notification-icon"
                                                                    size={21}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="notification-text">
                                                            {el.text}
                                                        </div>
                                                    </div>
                                                    <MdDeleteForever
                                                        onClick={() =>
                                                            handleDeleteNotification(
                                                                el.id
                                                            )
                                                        }
                                                        className="cursor-pointer"
                                                        fill="#333333"
                                                        size={23}
                                                    />
                                                    <div className="notification-created-at">
                                                        {new Date(
                                                            el.created_at
                                                        ).toLocaleDateString(
                                                            "en-Us",
                                                            {
                                                                day: "2-digit",
                                                                month: "short",
                                                                year: "numeric",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        <div className="d-flex h-100 justify-content-center align-items-center">
                                            <FormattedMessage id="thereAreNoNotificationsNow" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </NavItem>
                    <Dropdown>
                        <Dropdown.Toggle
                            className="user-header-dropdown"
                            id="dropdown-basic"
                        >
                            <div className="navigation-user-menu">
                                <FaUserAlt fill="white" size={20} />
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="user-header-dropdown-menu">
                            <Dropdown.Item className="p-0" as="div">
                                <Link
                                    className="d-block w-100 px-3 py-1"
                                    to="/dashboard/settings-users/"
                                >
                                    <FormattedMessage id="settings" />
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleLogoutUser}>
                                <FormattedMessage id="logout" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </div>
        </Navbar>
    );
};

export default DashboardHeader;
