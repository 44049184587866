import {
	useStripe,
	useElements,
	PaymentElement,
} from "@stripe/react-stripe-js";
import { FormattedMessage, useIntl } from "react-intl";
import { ChangeEvent, useState } from "react";

import "./StripeForm.css";

const StripeForm = () => {
	const intl = useIntl();
	const stripe = useStripe();
	const elements = useElements();

	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/dashboard/billing-receipts/`,
			},
		});

		if (error) {
			if (!error.message) return;

			setErrorMessage(error.message);
		} else {
			setErrorMessage(
				intl.formatMessage({ id: "anUnexpectedErrorOccurred" })
			);
		}
	};

	return (
		<form onSubmit={handleSubmit} className="w-100">
			{errorMessage && (
				<div className="text-center mb-2 red-color">{errorMessage}</div>
			)}
			<div className="payment-element-wrapper">
				<PaymentElement />
			</div>
			<div className="d-flex justify-content-end mt-3">
				<button className="ice-button w-100" disabled={!stripe}>
					<FormattedMessage id="submit" />
				</button>
			</div>
		</form>
	);
};

export default StripeForm;
