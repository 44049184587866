import { FC, RefObject, useCallback, useEffect, useState } from "react";

type TOfferAnimationProps = {
    blockRef: RefObject<HTMLDivElement>;
    windowHeight: number;
    windowWidth: number;
};

const OffersAnimation: FC<TOfferAnimationProps> = ({
    blockRef,
    windowHeight,
    windowWidth,
}) => {
    const [currentWidth, setCurrentWidth] = useState<number>(1300);
    const [sidePadding, setSidePadding] = useState<number>(0);

    const handleResize = useCallback(() => {
        const width = blockRef.current?.offsetWidth;

        if (width) {
            const padding = (windowWidth - width) / 2;

            setCurrentWidth(width);
            setSidePadding(padding);
        }
    }, [blockRef, windowWidth]);

    useEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [blockRef, handleResize]);

    useEffect(() => {
        const targetElement = document.querySelector("#offers-element") as
            | HTMLElement
            | undefined;

        if (!targetElement) {
            return;
        }

        const animatedLine = document.querySelector(
            "#offers-animated-line"
        ) as SVGPathElement;
        const circle1 = document.querySelector(
            "#animated-circle-1"
        ) as SVGElement;
        const circle2 = document.querySelector(
            "#animated-circle-2"
        ) as SVGElement;
        const circle3 = document.querySelector(
            "#animated-circle-3"
        ) as SVGElement;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        animatedLine.style.animationPlayState = "running";
                        circle1.style.animationPlayState = "running";
                        circle2.style.animationPlayState = "running";
                        circle3.style.animationPlayState = "running";
                    } else {
                        animatedLine.style.animationPlayState = "paused";
                        circle1.style.animationPlayState = "paused";
                        circle2.style.animationPlayState = "paused";
                        circle3.style.animationPlayState = "paused";
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        observer.observe(targetElement);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className="home__animation-wrapper">
            <svg width="100%" height="100%">
                <defs>
                    <linearGradient
                        id="lineGradient"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                    >
                        <stop
                            offset="0%"
                            style={{ stopColor: "#e7f4fb", stopOpacity: "1" }}
                        />
                        <stop
                            offset="30%"
                            style={{ stopColor: "#4DB8FF", stopOpacity: "1" }}
                        />
                        <stop
                            offset="50%"
                            style={{ stopColor: "#4DB8FF", stopOpacity: "1" }}
                        />
                        <stop
                            offset="80%"
                            style={{ stopColor: "#4DB8FF", stopOpacity: "1" }}
                        />
                        <stop
                            offset="100%"
                            style={{ stopColor: "#e7f4fb", stopOpacity: "1" }}
                        />
                    </linearGradient>
                </defs>
                <path
                    id="offers-animated-line"
                    d={`M0 350 L${220 + sidePadding} 350 L${
                        sidePadding + 220
                    } 620 L${sidePadding + currentWidth / 2} 620 L${
                        sidePadding + currentWidth / 2
                    } 860 L${sidePadding * 2 + 1340} 860`}
                    stroke="url(#lineGradient)"
                    fill="none"
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
                <path
                    id="offers-flying-line-1"
                    d={`M${windowWidth} ${windowHeight / 1.2} C${
                        windowWidth / 1.5
                    } ${windowHeight / 1.5}, ${windowWidth / 1.1} ${
                        windowHeight / 2.5
                    }, ${windowWidth} 0`}
                    stroke="url(#lineGradient)"
                    fill="none"
                    strokeWidth="2"
                />
                <path
                    id="offers-flying-line-2"
                    d={`M${windowWidth} ${windowHeight / 1.5} C${
                        windowWidth / 1.8
                    } ${windowHeight / 2.6}, ${windowWidth / 2.4} ${
                        windowHeight / 3.2
                    }, ${windowWidth / 4} 0`}
                    stroke="url(#lineGradient)"
                    fill="none"
                    strokeWidth="2"
                />
                <circle
                    id="animated-circle-1"
                    cx={sidePadding + 220}
                    cy="350"
                    r="6"
                    fill="#96d5ff"
                    opacity="0"
                />
                <circle
                    id="animated-circle-2"
                    cx={sidePadding + currentWidth / 2}
                    cy="620"
                    r="6"
                    fill="#4DB8FF"
                    opacity="0"
                />
                <circle
                    id="animated-circle-3"
                    cx={sidePadding + currentWidth - 230}
                    cy="860"
                    r="6"
                    fill="#96d5ff"
                    opacity="0"
                />
            </svg>
        </div>
    );
};

export default OffersAnimation;
