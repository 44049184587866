import { FaLayerGroup, FaListUl } from "react-icons/fa6";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";

import { useGetIntegrationsQuery } from "../../../api/integrationsAPI";
import { TGetIntegrationsResults } from "../../../api/api.types";

type FulfillmentSelectModalProps = {
    type: string;
    onHide: () => void;
    setSelectedFulfillmentMethod: (value: number | null) => void;
    handleOpenOrderCreationModal: (value: boolean) => void;
};

const FulfillmentSelectModal: FC<FulfillmentSelectModalProps> = ({
    type,
    onHide,
    setSelectedFulfillmentMethod,
    handleOpenOrderCreationModal,
}) => {
    const [availableIntegrations, setAvailableIntegrations] = useState<
        TGetIntegrationsResults[]
    >([]);

    const { data } = useGetIntegrationsQuery();

    const handleSelectFulfillmentMethodAndOpenOrderCreation = (
        integration: TGetIntegrationsResults
    ) => {
        setSelectedFulfillmentMethod(integration.id);
        handleOpenOrderCreationModal(true);
        onHide();
    };

    useEffect(() => {
        if (data) {
            const filteredIntegrations = data.results.filter(
                (integration) =>
                    integration.partner === "Off Amazon Integration"
            );

            setAvailableIntegrations(filteredIntegrations);
        }
    }, [data]);

    return (
        <Modal centered show={type ? true : false} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title className="d-flex align-items-center gap-1">
                    <FaListUl size={24} />
                    <FormattedMessage id="selectFulfillmentMethod" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ gap: "12px" }}
                className="pt-0 d-flex flex-column"
            >
                <p className="bulk-upload-description">
                    <FormattedMessage id="hereIsAListOfFulfillmentMethodsYouCanUseToFulfill" />
                </p>
                {availableIntegrations && availableIntegrations.length > 0 ? (
                    availableIntegrations.map((integration) => (
                        <div
                            className="d-flex align-items-center justify-content-between gap-2"
                            key={integration.id}
                        >
                            <div className="d-flex align-items-center gap-2">
                                <FaLayerGroup size={24} />
                                <span className="bulk-origination-integration">
                                    {integration.name} ({integration.partner})
                                </span>
                            </div>
                            <button
                                onClick={() =>
                                    handleSelectFulfillmentMethodAndOpenOrderCreation(
                                        integration
                                    )
                                }
                                className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0 w-fit"
                            >
                                <FormattedMessage id="select" />
                            </button>
                        </div>
                    ))
                ) : (
                    <div className="text-center d-flex flex-column gap-2 align-items-center">
                        <FaLayerGroup size={32} />
                        <FormattedMessage id="youHaveNoOffAmazonIntegrations" />
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default FulfillmentSelectModal;
