import { TfiCommentsSmiley } from "react-icons/tfi";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { Carousel } from "react-bootstrap";
import { useState } from "react";

import { useGetPublicProductByIdQuery } from "../../../../../api/productsAPI";

import "./CatalogProduct.css";

const CatalogProduct = () => {
    const [activeVariant, setActiveVariant] = useState(0);

    const { id } = useParams();

    const { data } = useGetPublicProductByIdQuery(id ? Number(id) : 0);

    const handleChangeVariant = (variant: number) => {
        setActiveVariant(variant);
    };

    return (
        <div className="catalog-product__wrapper">
            <div className="catalog-product__content">
                <div className="catalog-product__information-block">
                    <div
                        className="catalog-product__images-wrapper"
                        style={{ maxWidth: "382px" }}
                    >
                        <Carousel className="product-details-carousel">
                            {data?.variants &&
                            data?.variants[activeVariant].images.length > 0 ? (
                                data?.variants[activeVariant].images.map(
                                    (el) => (
                                        <Carousel.Item key={el.id}>
                                            <img
                                                className="d-block catalog-product__carousel-image"
                                                src={el.url ? el.url : el.image}
                                                alt=""
                                            />
                                        </Carousel.Item>
                                    )
                                )
                            ) : (
                                <Carousel.Item>
                                    <div
                                        className="d-block catalog-product__carousel-image empty"
                                        style={{
                                            backgroundColor: "#ECECEC",
                                        }}
                                    />
                                </Carousel.Item>
                            )}
                        </Carousel>
                        <div className="catalog-product__variants-list">
                            {data?.variants &&
                                data.variants.length > 0 &&
                                data.variants.map((variant, idx) => (
                                    <div
                                        key={idx}
                                        onClick={() => handleChangeVariant(idx)}
                                        className={
                                            activeVariant === idx
                                                ? "catalog-product__variant-image-active"
                                                : "catalog-product__variant-image"
                                        }
                                    >
                                        <img
                                            src={
                                                variant.images[0]?.url
                                                    ? variant.images[0]?.url
                                                    : variant.images[0]?.image
                                            }
                                            alt=""
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="catalog-product__details-block">
                        <h5 className="catalog-product__details-title">
                            {data?.title}
                        </h5>
                        <div className="catalog-product__details-price-block">
                            <span className="catalog-product__details-price">
                                {
                                    data?.variants[activeVariant].prices[0]
                                        .currency.sign
                                }
                                {data?.variants[activeVariant]?.prices[0]
                                    ?.amount &&
                                    data?.variants[activeVariant]?.prices[0]
                                        ?.amount / 100}
                            </span>
                            <span className="catalog-product__details-sku">
                                {data?.variants[activeVariant].sku}
                            </span>
                        </div>
                        <div className="catalog-product__description-block">
                            <p className="catalog-product__description">
                                {data?.variants[activeVariant].description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="catalog-product__customer-reviews-block">
                {data?.variants &&
                    data.variants[activeVariant].reviews.length > 0 && (
                        <h6 className="catalog-product__reviews-title">
                            <FormattedMessage id="customerReviewsWithDots" />
                        </h6>
                    )}
                {data?.variants &&
                data.variants[activeVariant].reviews.length > 0 ? (
                    data?.variants[activeVariant].reviews.map((review, idx) => (
                        <div className="catalog-product__review-item" key={idx}>
                            <div className="catalog-product__review-author-block">
                                <span className="catalog-product__review-author">
                                    {review.review_author}
                                </span>
                                <span className="catalog-product__review-date">
                                    {review.review_date}
                                </span>
                            </div>
                            <div className="catalog-product__review-rating-block">
                                <StarRatings
                                    rating={parseFloat(
                                        review.review_rating
                                            ? String(review.review_rating)
                                            : "0"
                                    )}
                                    numberOfStars={5}
                                    starRatedColor="var(--button)"
                                    starDimension="20px"
                                    starSpacing="1px"
                                />
                            </div>
                            <span className="catalog-product__review-title">
                                {review.review_title}
                            </span>
                            <p className="catalog-product__review-content">
                                {review.review_content}
                            </p>
                        </div>
                    ))
                ) : (
                    <div className="catalog-product__no-reviews-text">
                        <TfiCommentsSmiley color="var(--text)" size={60} />
                        <FormattedMessage id="thisProductDoNotHaveAnyReviewsYet" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CatalogProduct;
