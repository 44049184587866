import { useIntl } from 'react-intl'
import * as yup from 'yup'

const affiliateCodeRegex = new RegExp('^[A-Za-z0-9_]*$')

export const useAddAffiliateValidationSchema = () => {
    const intl = useIntl()

    return yup.object().shape({
        first_name: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        last_name: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        email: yup
            .string()
            .email(intl.formatMessage({ id: "emailAddressIsInvalid" }))
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        community_name: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        code: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" }))
            .matches(affiliateCodeRegex, intl.formatMessage({ id: "codeMustContainOnlyLettersNumbersAndSymbol" })),
        revenue_per_order: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        benefits_discount_period: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        benefits_discount: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
    })
}