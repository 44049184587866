import { HiOutlineMinusSm } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { GoPlus } from 'react-icons/go';

import "./FAQ.css"

const FAQ = () => {
    const [expandedItem, setExpandedItem] = useState<string | null>(null);

    const handleExpandItem = (item: string) => {
        if (expandedItem === item) {
            setExpandedItem(null);
        } else {
            setExpandedItem(item);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='min-block-height d-flex flex-column justify-content-center align-items-center bg-white px-4'>
            <div className='faq-content-wrapper'>
                <div className='faq-title-block'>
                    <h5 className='faq-title'>
                        <FormattedMessage id="frequentlyAskedQuestions" />
                    </h5>
                    <p className='faq-description'>
                        <FormattedMessage id="hereAreSomeOfTheMostFrequentlyAskedQuestions" />
                    </p>
                </div>
                <div className='faq-questions-list'>
                    <div className={`faq-question-item ${expandedItem === '1' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howDoIGetPaidAsAnFBASeller" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="whenTheOrderIsFullyExecutedThenTheMoneyWillBeTransferred" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('1')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '2' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howAmIGetChargedToDropshipProductsFromCloudOrder" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="whenYouSetUpYourAccountWithCloudOrder" />
                                <FormattedMessage id="changesWillBeAppliedUsingTheCreditCardFromTheDefaultPaymentMethod" />
                                <FormattedMessage id="theDefaultPaymentMethodCanBeSetOnTheFinancialSettings" />
                                <FormattedMessage id="whenYouClickThePaymentButtonYouWillBeAskedToTypeYourCreditCardData" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="youMayAlsoChooseNotToUseAutomaticPayment" />
                                <br />
                                - <FormattedMessage id="goToOrdersSectionInTheMainMenu" />
                                <br />
                                - <FormattedMessage id="selectTheTargetOrderYouWishToMakePayment" />
                                <br />
                                - <FormattedMessage id="pressTheSelectButtonOnTheLeftAndChoosePayButton" />
                                <br />
                                - <FormattedMessage id="afterThatYouWillBeAbleToChooseOneOfYourCards" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('2')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '3' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="ifIAnAnFBASellerAndHaveShopifyStore" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="ifYouAreAnFBASellerAndAShopifyStoreOwnerYouMaySetUpYourCloudOrderAccount" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('3')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '4' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howDoIConnectCloudOrderToMyAmazonStore" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                - <FormattedMessage id="loginIntoYourCloudOrderTenant" />
                                <br />
                                - <FormattedMessage id="inTheLowerLeftCornerSelectTheSettings" />
                                <br />
                                - <FormattedMessage id="whenYouOpenTheSettingsClickTheButtonAddNewIntegration" />
                                <br />
                                - <FormattedMessage id="fromTheListOfProposedIntegrationsSelectTheAmazonFBA" />
                                <br />
                                - <FormattedMessage id="followTheInstructionsInTheFirmFillTheFormAndClickConnect" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('4')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '5' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howDoIConnectCloudOrderToMyShopifyStore" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="thereAreTwoWaysYouCanConnectYourShopifyStoreToCloudOrder" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="aInYourShopifyStoreYouCanInstallCloudOrderApplication" />
                                <br />
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    className="faq-link"
                                    href="https://apps.shopify.com/multi-channel-drop-shipping"
                                >
                                    https://apps.shopify.com/multi-channel-drop-shipping
                                </a>
                                <br />
                                <p></p>
                                <br />
                                <FormattedMessage id="bYouCanAlsoConnectYourShopifyStoreByAddingAShopifyIntegration" />
                                <br />
                                - <FormattedMessage id="loginIntoYourCloudOrderAccount" />
                                <br />
                                - <FormattedMessage id="inTheLowerLeftCornerSelectTheSettings" />
                                <br />
                                - <FormattedMessage id="onTheFollowingPageClickTheAddNewIntegrationButton" />
                                <br />
                                - <FormattedMessage id="fromTheListOfProposedIntegrationsSelectTheShopify" />
                                <br />
                                - <FormattedMessage id="fillInYourShopifyStoreNameAndClickConnect" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('5')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '6' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howDoIImportProductsFromMyAmazonStoreToCloudOrder" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                - <FormattedMessage id="loginIntoYourCloudOrderTenant" />
                                <br />
                                - <FormattedMessage id="inTheLowerLeftCornerSelectTheSettings" />
                                <br />
                                - <FormattedMessage id="onTheFollowingPageClickSelectOnTheRightSideOfTheRequiredIntegration" />
                                <br />
                                - <FormattedMessage id="fromTheListOfProposedActionsSelectTheImportAndConfirmTheAction" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('6')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '7' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howDoIChangeTheProductCostPricingForMyProductsListedOnCloudOrder" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="aToManuallyChangeTheCostForASingleProduct" />
                                <br />
                                - <FormattedMessage id="loginIntoYourCloudOrderAccount" />
                                <br />
                                - <FormattedMessage id="inTheLeftMenuSelectTheInventory" />
                                <br />
                                - <FormattedMessage id="onTheFollowingPageClickSelectOnTheRightSideOfTheProduct" />
                                <br />
                                - <FormattedMessage id="fromTheListOfProposedActionsSelectTheChangeCost" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="bToChangeThePricingForBulkProductList" />
                                <br />
                                - <FormattedMessage id="loginIntoYourCloudOrderAccount" />
                                <br />
                                - <FormattedMessage id="inTheLeftMenuSelectTheInventory" />
                                <br />
                                - <FormattedMessage id="onTheFollowingPageClickOnCheckboxOnTheLeftSideOfRequiredProductRows" />
                                <br />
                                - <FormattedMessage id="onceYouSelectProductsClickTheExportButtonOnTheTopRightCorner" />
                                <br />
                                - <FormattedMessage id="makeYourChangesToCSVFileDownloadedYourDesktop" />
                                <br />
                                - <FormattedMessage id="saveTheChangesPleaseMakeSureYourFileIsSavedAsCSV" />
                                <br />
                                - <FormattedMessage id="importTheUpdatedFileByClickingOnTheImportButton" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('7')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '8' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="whyAreNotMyProductsShowingUpInCloudOrderCatalog" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="byDefaultAllProductsYouImportedFromAmazonArePrivateToYourOwnAccount" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="requestToMakeSingleProductIntoCloudOrderPublicCatalog" />
                                <br />
                                - <FormattedMessage id="loginIntoYourCloudOrderAccount" />
                                <br />
                                - <FormattedMessage id="inTheLeftMenuSelectTheInventory" />
                                <br />
                                - <FormattedMessage id="onTheFollowingPageClickSelectOnTheRightSideOfTheRequiredProduct" />
                                <br />
                                - <FormattedMessage id="fromTheListOfProposedActionsSelectTheMakePublicAndConfirm" />
                                <br />
                                - <FormattedMessage id="allow24HoursForCloudOrderAdminToReviewAndApproveTheRequest" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="requestToMakeMultipleProductsPublic" />
                                <br />
                                - <FormattedMessage id="loginIntoYourCloudOrderTenant" />
                                <br />
                                - <FormattedMessage id="inTheLeftMenuSelectTheInventory" />
                                <br />
                                - <FormattedMessage id="onTheFollowingPageClickOnCheckboxOnTheLeftSideOfRequiredProductRows" />
                                <br />
                                - <FormattedMessage id="onceYouSelectProductsClickTheExportButtonOnTheTopRightCorner" />
                                <br />
                                - <FormattedMessage id="makeChangesToCSVFileByUpdatingTheProductsToPublic" />
                                <br />
                                - <FormattedMessage id="saveTheChangesPleaseMakeSureYourFileIsSavedAsCSV" />
                                <br />
                                - <FormattedMessage id="importTheUpdatedFileByClickingOnTheImportButton" />
                                <br />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('8')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '9' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="iHaveALotOfSKUsHowDoIBulkEditTheCostPriceOfMyProducts" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                - <FormattedMessage id="loginIntoYourCloudOrderAccount" />
                                <br />
                                - <FormattedMessage id="inTheLeftMenuSelectTheInventory" />
                                <br />
                                - <FormattedMessage id="onTheFollowingPageClickOnTheCheckboxOnTheLeftSideOfDesiredProductRows" />
                                <br />
                                - <FormattedMessage id="onceYouSelectProductsClickTheExportButtonOnTheTopRightCorner" />
                                <br />
                                - <FormattedMessage id="makeYourChangesToTheDownloadedCSVFileSaveTheFile" />
                                <br />
                                - <FormattedMessage id="importTheUpdatedFileByClickingOnTheImportButton" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('9')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '10' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="whatAreTheDifferentShippingTiersAndShippingRates" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="platformsSuchAsShopifyByDefaultUseThreeDifferentDeliveryMethods" />
                                <br />
                                - <FormattedMessage id="standard" />
                                <br />
                                - <FormattedMessage id="economy" />
                                <br />
                                - <FormattedMessage id="freeShipping" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="onCloudOrderAllTheseMethodsByDefaultAreMappedToStandardDelivery" />
                                <p>&nbsp;</p>
                                <FormattedMessage id="cloudOrderUsesStandardDeliveryMethodByDefaultWhichMapsToAmazonFBAMethod" />
                                <p>&nbsp;</p>
                                <FormattedMessage id="theDeliveryFeesAreCalculatedByAmazonFulfillmentServiceAndPassedOnToTheOrderAsTheStandard" />
                                <p>&nbsp;</p>
                                <FormattedMessage id="inAdditionShopifyStoreOwnersCanAlsoAddTwoMoreDeliveryMethods" />
                                <FormattedMessage id="deliveryFeeWillBeUpdatedAfterOrderIsFulfilledByAmazon" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('10')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '11' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howOftenAreMyProductsSyncedFromAmazon" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="yourProductsWillBeSyncedAutomaticallyOncePerDay" />
                                <br />
                                <FormattedMessage id="ifYouRequireAnUpdateOfExistingProductGoTo" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="integrations" /> -&gt; <FormattedMessage id="select" /> -&gt; <FormattedMessage id="importAndUpdateProducts" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('11')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '12' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howAreReviewsImportedFromAmazonToCloudOrder" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="cloudOrderAutomaticallyImportsProductReviewsEveryWeek" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="toInitiateImportProcedureManually" />
                                <br />
                                - <FormattedMessage id="loginIntoYourCloudOrderAccount" />
                                <br />
                                - <FormattedMessage id="inTheLowerLeftCornerSelectTheSettings" />
                                <br />
                                - <FormattedMessage id="onTheFollowingPageClickSelectOnTheRightSideOfTheRequiredIntegration" />
                                <br />
                                - <FormattedMessage id="fromTheListOfProposedActionSelectImportProductReviews" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="theProcessOfImportReviewsWillTakeSomeTime" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('12')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '13' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howToExportReviewsFromCloudOrderToShopifyStore" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <br />
                                - <FormattedMessage id="loginIntoYourCloudOrderAccount" />
                                <br />
                                - <FormattedMessage id="inTheLeftMenuSelectTheCatalog" />
                                <br />
                                - <FormattedMessage id="selectTheProductYouNeedToExportReviewsFor" />
                                <br />
                                - <FormattedMessage id="clickTheActionButtonOnTheTopRightCornerOfThePage" />
                                <br />
                                - <FormattedMessage id="fromTheListOfProposedActionsSelectTheExportToStore" />
                                <br />
                                - <FormattedMessage id="clickOnTheCheckboxOnTheLeftSideOfOverrideExistingProducts" />
                                <br />
                                <p>&nbsp;</p>
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('13')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '14' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howDoICheckTheStatusOfMyProductsImportsFromAmazon" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="initialProductImportTakesSomeTimeDependingOnTheNumber" />
                                <br />
                                <FormattedMessage id="pleaseAllow24HoursForTheProcessToFinish" />
                                <br />
                                <FormattedMessage id="youMayAlsoRequestInformationFromCloudOrderSupportAt" />
                                <br />
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    className='faq-link'
                                    href="mailto:info@cloudorder.us"
                                >
                                    info@cloudorder.us
                                </a>
                                <br />
                                <FormattedMessage id="cloudOrderWillReplyWithRelatedInformation" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('14')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                    <div className={`faq-question-item ${expandedItem === '15' ? 'expanded' : ''}`}>
                        <div className='faq-question-circle-wrapper'>
                            <div className='faq-question-circle'></div>
                        </div>
                        <div className='faq-question-content'>
                            <h5 className='faq-question-title'>
                                <FormattedMessage id="howDoIFindASpecificCategoryOfProductsToDropshipForMyShopify" />
                            </h5>
                            <p className='faq-question-description'>
                                <div className='faq-question-description-line'></div>
                                <FormattedMessage id="allProductsCanBeFilteredByCategorySearchByNameOrSearchBySKU" />
                                <br />
                                - <FormattedMessage id="loginIntoYourCloudOrderAccount" />
                                <br />
                                - <FormattedMessage id="inTheLeftMenuSelectTheCatalog" />
                                <br />
                                - <FormattedMessage id="onTheFollowingPageSelectTheRequiredCategoryByClickingTheCategoryButton" />
                                <br />
                                <p>&nbsp;</p>
                                <FormattedMessage id="ifYouDoNotHaveACloudOrderAccountYouMayUseThePublicCatalogToSearchForProducts" />
                            </p>
                        </div>
                        <div className='faq-question-icon' onClick={() => handleExpandItem('15')}>
                            <GoPlus className='faq-question-plus-icon' size={35} color="#000000" />
                            <HiOutlineMinusSm className='faq-question-minus-icon' size={35} color="#000000" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default FAQ;