import { createIntl, createIntlCache } from "react-intl";

import enTranslations from "./translations/en.json";
import zhTranslations from "./translations/zh.json";
import { RootState } from "../redux/store";

export const translations = {
	en: enTranslations,
	zh: zhTranslations,
};

const cache = createIntlCache();

export const getIntl = (state: RootState) => {
	const locale = state.auth.user?.language || "en";
	return createIntl(
		{ locale, messages: translations[locale] || translations["en"] },
		cache
	);
};
