import { FormattedMessage } from "react-intl"
import { FC, useState } from "react"

import { TGetTenantDetailResponse } from "../../../../redux/reducers/auth/authTypes"

import EditCompanyDetails from "../../../modals/EditCompanyDetails/EditCompanyDetails"

type TSettingsCompanyBlock = {
    tenantDetails: TGetTenantDetailResponse | undefined,
    subdomain: string,
}

const SettingsCompanyBlock: FC<TSettingsCompanyBlock> = ({ tenantDetails, subdomain }) => {
    const [editCompanyDetailsModal, setEditCompanyDetailsModal] = useState(false)

    return (
        <div className="col-6">
            <div className="settings-card px-3 card-height">
                <ul className="dashboard-menu-ul">
                    <li className="dashboard-menu-li">
                        <span className="dashboard-menu-a">
                            <FormattedMessage id="company" />
                        </span>
                    </li>
                </ul>
                <div className="pb-3">
                    <ul className="dashboard-body-settings ps-0 row">
                        <div className="col-6">
                            <li className="dashboard-body-li d-flex justify-content-start mb-3 text-muted">
                                <FormattedMessage id="nameWithDots" />
                                <span className="active-text ms-3">{tenantDetails?.name}</span>
                            </li>
                            <li className="dashboard-body-li d-flex justify-content-start my-3 text-muted">
                                <FormattedMessage id="addressLine1WithDots" />
                                <span className="active-text ms-3">{tenantDetails?.address_line_1}</span>
                            </li>
                            <li className="dashboard-body-li d-flex justify-content-start my-3 text-muted">
                                <FormattedMessage id="addressLine2WithDots" />
                                <span className="active-text ms-3">{tenantDetails?.address_line_2}</span>
                            </li>
                            <li className="dashboard-body-li d-flex justify-content-start mt-3 mb-2 text-muted">
                                <FormattedMessage id="phoneWithDots" />
                                <span className="active-text ms-3">{tenantDetails?.phone}</span>
                            </li>
                        </div>
                    </ul>
                    <div className="d-flex justify-content-end">
                        <button onClick={() => setEditCompanyDetailsModal(true)} type='button' className="dashboard-menu-button">
                            <FormattedMessage id="editCompanyDetails" />
                        </button>
                    </div>
                </div>
            </div>
            {editCompanyDetailsModal && (
                <EditCompanyDetails
                    subdomain={subdomain}
                    tenantDetails={tenantDetails}
                    show={editCompanyDetailsModal}
                    onHide={() => setEditCompanyDetailsModal(false)}
                />
            )}
        </div>
    )
}

export default SettingsCompanyBlock