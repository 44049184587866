import { MdDataUsage, MdOutlineSupervisorAccount } from 'react-icons/md'
import { AiOutlineBarcode, AiOutlineProfile } from 'react-icons/ai'
import { FaFingerprint, FaUserCircle } from 'react-icons/fa'
import { FormattedMessage, useIntl } from 'react-intl'
import { RiCommunityLine } from 'react-icons/ri'
import { HiOutlineCog } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import { BsPercent } from 'react-icons/bs'
import { toast } from 'react-toastify'

import {
    useDeleteAffiliateCodeMutation,
    useGetAffiliateCodesQuery,
    usePayAffiliateCodeMutation
} from '../../../../api/affiliateAPI'
import useHandleCustomRtkQueryError from '../../../../hooks/useHandleCustomRtkQueryError'
import AffiliateCode from '../../../modals/AffiliateCode/AffiliateCode'
import usePagination from '../../../../hooks/usePagination'
import { TAffiliateCode } from '../../../../api/api.types'

import PagePagination from '../../../components/common/Pagination/PagePagination'

import './AffiliateManagement.css'

const AffiliateManagement = () => {
    const [showModal, setShowModal] = useState(false)
    const [affiliate, setAffiliate] = useState<null | TAffiliateCode>(null)

    const intl = useIntl()

    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', 10)

    const [deleteAffiliateCode] = useDeleteAffiliateCodeMutation()
    const [payAffiliateCode] = usePayAffiliateCodeMutation()

    const { data: affiliateCodes } = useGetAffiliateCodesQuery(activePage)

    const handleShowAffiliateCode = (affiliate?: TAffiliateCode) => {
        if (affiliate) {
            setAffiliate(affiliate)
        } else {
            setAffiliate(null)
        }
        setShowModal(true)
    }

    const handleHideAffiliateCode = () => {
        setAffiliate(null)
        setShowModal(false)
    }

    const handleDeleteCode = (id: number) => {
        if (affiliateCodes && affiliateCodes.results?.length === 1 && activePage !== '1') {
            handlePage(String(Number(activePage) - 1))
        }
        deleteAffiliateCode(id)
    }

    const handlePayCode = (id: number) => {
        payAffiliateCode({ id: id }).then((res) => {
            if ("error" in res) {
                handleCustomError(res.error)
            } else {
                setCustomError(null)
                toast.success(intl.formatMessage({ id: "theAffiliatePayoutCompleted" }))
            }
        })
    }

    useEffect(() => {
        toast.error(customError)
    }, [customError])

    useEffect(() => {
        if (affiliateCodes) {
            handlePagesCount(affiliateCodes?.count)
        }
    }, [affiliateCodes, handlePagesCount])

    return (
        <section className='py-3'>
            <div className="affiliate-header-block">
                <h5 className="affiliate-header-title">
                    <FormattedMessage id="manageAffiliates" />
                </h5>
                <button onClick={() => handleShowAffiliateCode()} className="affiliate-button">
                    <FormattedMessage id="createNewAffiliate" />
                </button>
            </div>
            <div className="container-fluid dashboard-body">
                {affiliateCodes?.results.length === 0 ? (
                    <div className="no-affiliates-block">
                        <i className="fas fa-user-circle no-affiliates-icon fa-5x"></i>
                        <FaUserCircle size={100} fill="#144074" />
                        <p className="no-affiliates-text mt-2">
                            <FormattedMessage id="thereAreNoAffiliates" />
                        </p>
                        <button onClick={() => handleShowAffiliateCode()} className="affiliate-button mt-2">
                            <FormattedMessage id="createNewAffiliate" />
                        </button>
                    </div>
                ) : (
                    <>
                        <table className="table mb-2">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FaFingerprint className='me-1' size={16} /> <FormattedMessage id="name" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <RiCommunityLine className='me-1' size={18} /> <FormattedMessage id="community" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AiOutlineBarcode className='me-1' size={17} /> <FormattedMessage id="code" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <BsPercent className='me-1' size={17} /> <FormattedMessage id="codeAttributesDefault" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <MdDataUsage className='me-1' size={18} /> <FormattedMessage id="codeUsage" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <MdOutlineSupervisorAccount className='me-1' size={18} /> <FormattedMessage id="totalOrders" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AiOutlineProfile className='me-1' size={18} /> <FormattedMessage id="currentOrders" />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <HiOutlineCog className='me-1' size={18} /> <FormattedMessage id="actions" />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {affiliateCodes && affiliateCodes.results.length > 0 && affiliateCodes.results.map((affiliate) => (
                                    <tr key={affiliate.id}>
                                        <td className="align-middle text-center">{affiliate.first_name} {affiliate.last_name}</td>
                                        <td className="align-middle text-center">{affiliate.community_name}</td>
                                        <td className="align-middle text-center">{affiliate.code}</td>
                                        <td className="align-middle text-center">
                                            ${affiliate.revenue_per_order} / <FormattedMessage id="order" />
                                            <br />
                                            {affiliate.benefits_discount_period}, {affiliate.benefits_discount}%
                                        </td>
                                        <td className="align-middle text-center">
                                            {affiliate.code_usage}
                                        </td>
                                        <td className="align-middle text-center">
                                            {affiliate.total_orders}
                                        </td>
                                        <td className="align-middle text-center">
                                            {affiliate.total_unpaid_orders}
                                        </td>
                                        <td className="align-middle text-center">
                                            <button onClick={() => handleShowAffiliateCode(affiliate)} type="button" className="affiliate-button">
                                                <FormattedMessage id="addCode" />
                                            </button>
                                            <button
                                                onClick={() => handlePayCode(affiliate.id)}
                                                type="button"
                                                className="affiliate-button ms-2"
                                            >
                                                <FormattedMessage id="pay" />
                                            </button>
                                            <button
                                                onClick={() => handleDeleteCode(affiliate.id)}
                                                type="button"
                                                className="affiliate-button ms-2"
                                            >
                                                <FormattedMessage id="delete" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {pages.length > 1 && (
                            <PagePagination
                                pages={pages}
                                activePage={activePage}
                                handlePage={handlePage}
                            />
                        )}
                    </>
                )}
            </div>
            {showModal && (
                <AffiliateCode
                    show={showModal}
                    onHide={handleHideAffiliateCode}
                    affiliate={affiliate}
                />
            )}
        </section>
    )
}

export default AffiliateManagement