import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { FC } from "react";

import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { useAddOrderNoteMutation } from "../../../../api/ordersAPI";
import {
	TGetOrderResult,
	TOrderNoteSubmitData,
	TTenant,
} from "../../../../api/api.types";

import Divider from "./Divider";

import DetailsInfo from "../../../assets/images/details-info.svg";

type TOrderPricingDetailsProps = {
	orderData: TGetOrderResult | undefined;
	tenantData: TTenant | undefined;
};

const OrderPricingDetails: FC<TOrderPricingDetailsProps> = ({
	orderData,
	tenantData,
}) => {
	const [isPublic] = useDetectSubdomainOrPublic();

	const intl = useIntl();
	const { id } = useParams();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<TOrderNoteSubmitData>();

	const [addOrderNote] = useAddOrderNoteMutation();

	const isSeller = () => {
		if (orderData && tenantData) {
			if (tenantData.id === orderData.seller_tenant) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const onSubmit = (data: { text: string }) => {
		addOrderNote({
			order: Number(id),
			...data,
		}).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({ id: "somethingWentWrong" })
					);
				}
			} else {
				reset();
			}
		});
	};

	return (
		<div
			data-testid="order-pricing-block"
			className="order-details__shipment-details"
		>
			<div className="order-details__shipment-details-header">
				<div className="order-details__shipment-details-icon">
					<img src={DetailsInfo} alt="" />
				</div>
				<p className="order-details__shipment-details-title">
					<FormattedMessage id="pricingDetails" />
				</p>
			</div>
			{isSeller() || isPublic ? (
				<>
					<Divider />
					<div className="order-details__shipment-details-block">
						<h5 className="order-details__shipment-details-block-title">
							<FormattedMessage id="buyersCharge" />
						</h5>
						<table className="order-details__shipment-details-table">
							<tbody className="order-details__shipment-details-table-body">
								<tr>
									<td className="order-details__shipment-details-table-label">
										<FormattedMessage id="productCostWithDots" />
									</td>
									<td className="order-details__shipment-details-table-value">
										$
										{orderData?.raw_product_cost &&
											orderData?.raw_product_cost / 100}
									</td>
								</tr>
								<tr>
									<td className="order-details__shipment-details-table-label">
										<FormattedMessage id="buyerServiceFeeWithDots" />
									</td>
									<td className="order-details__shipment-details-table-value">
										$
										{orderData?.seller_margin &&
											orderData?.seller_margin / 100}
									</td>
								</tr>
								<tr>
									<td className="order-details__shipment-details-table-label">
										<FormattedMessage id="shippingCostWithDots" />
									</td>
									<td className="order-details__shipment-details-table-value">
										$
										{orderData?.shipping_cost &&
											orderData?.shipping_cost / 100}
									</td>
								</tr>
								<tr>
									<td className="order-details__shipment-details-table-label">
										<FormattedMessage id="customerRetailPriceWithDots" />
									</td>
									<td className="order-details__shipment-details-table-value">
										$
										{orderData?.end_customer_cost &&
											orderData?.end_customer_cost / 100}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</>
			) : null}
			{!isSeller() || isPublic ? (
				<>
					<Divider />
					<div className="order-details__shipment-details-block">
						<h5 className="order-details__shipment-details-block-title">
							<FormattedMessage id="vendorsPayout" />
						</h5>
						<table className="order-details__shipment-details-table">
							<tbody className="order-details__shipment-details-table-body">
								<tr>
									<td className="order-details__shipment-details-table-label">
										<FormattedMessage id="productCostWithDots" />
									</td>
									<td className="order-details__shipment-details-table-value">
										$
										{orderData?.raw_product_cost &&
											orderData?.raw_product_cost / 100}
									</td>
								</tr>
								<tr>
									<td className="order-details__shipment-details-table-label">
										<FormattedMessage id="sellerServiceFeeWithDots" />
									</td>
									<td className="order-details__shipment-details-table-value">
										$
										{orderData?.get_total_vendor_margin &&
											orderData?.get_total_vendor_margin /
												100}
									</td>
								</tr>
								<tr>
									<td className="order-details__shipment-details-table-label">
										<FormattedMessage id="shippingCostWithDots" />
									</td>
									<td className="order-details__shipment-details-table-value">
										$
										{orderData?.shipping_cost &&
											orderData?.shipping_cost / 100}
									</td>
								</tr>
								<tr>
									<td className="order-details__shipment-details-table-label">
										<FormattedMessage id="payoutToSellerWithDots" />
									</td>
									<td className="order-details__shipment-details-table-value">
										$
										{orderData?.get_total_vendor_payout &&
											orderData?.get_total_vendor_payout /
												100}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</>
			) : null}
			{isPublic && (
				<>
					<Divider />
					<div className="order-details__shipment-details-block">
						<h5 className="order-details__shipment-details-block-title">
							<FormattedMessage id="totalMargin" />
						</h5>
						<table className="order-details__shipment-details-table">
							<tbody className="order-details__shipment-details-table-body">
								<tr>
									<td className="order-details__shipment-details-table-label">
										<FormattedMessage id="totalMarginWithDots" />
									</td>
									<td className="order-details__shipment-details-table-value">
										$
										{(Number(
											orderData?.get_total_vendor_margin
										) +
											Number(orderData?.seller_margin)) /
											100}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</>
			)}
			{orderData?.order_notes_by_tenant &&
				orderData?.order_notes_by_tenant.length > 0 && (
					<>
						<Divider />
						<div className="order-details__shipment-details-block">
							<h5 className="order-details__shipment-details-block-title">
								<FormattedMessage id="notesUppercase" />
							</h5>
							<table className="order-details__shipment-details-table">
								<tbody className="order-details__shipment-details-table-body">
									{orderData.order_notes_by_tenant.map(
										(note, idx) => (
											<tr key={idx}>
												<td className="order-details__shipment-details-table-label pe-0">
													<div
														data-testid="order-note"
														className="order-details__shipment-details-note"
													>
														{note.text}
													</div>
												</td>
											</tr>
										)
									)}
								</tbody>
							</table>
						</div>
					</>
				)}
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Control
						data-testid="order-note-input"
						placeholder={`${intl.formatMessage({
							id: "enterANote",
						})}`}
						className="order-details__shipment-details-textarea"
						as="textarea"
						type="text"
						{...register("text", {
							required: true,
						})}
					/>
					{errors.text && (
						<p className="mb-0 form-field-error">
							{errors.text.message}
						</p>
					)}
				</Form.Group>
				<div className="d-flex justify-content-end mt-2">
					<button
						data-testid="add-note"
						className="ice-button w-100"
						type="submit"
					>
						<FormattedMessage id="addANote" />
					</button>
				</div>
			</Form>
		</div>
	);
};

export default OrderPricingDetails;
