import { useIntl } from "react-intl";
import * as yup from "yup";

import { passwordRegex } from "../../../../../helpers/constants";

export const useNewPasswordValidationSchema = () => {
    const intl = useIntl()

    return yup.object().shape({
        new_password1: yup
            .string()
            .matches(passwordRegex, intl.formatMessage({ id: "passwordMustContainMinimumEightCharactersUpperCaseAndNumber" }))
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        new_password2: yup
            .string()
            .oneOf([yup.ref("new_password1"), ""], intl.formatMessage({ id: "passwordsDoNotMatch" })).
            required(intl.formatMessage({ id: "thisFieldIsRequired" })),
    });
}

export const useResetPasswordValidationSchema = () => {
    const intl = useIntl()

    return yup.object().shape({
        email: yup
            .string()
            .email(intl.formatMessage({ id: "emailAddressIsInvalid" }))
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
    });
}