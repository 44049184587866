import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { FC } from "react";

import { TTenantDetail } from "../../../../../redux/reducers/auth/authTypes";

import "./TenantsModal.css";

type TListTenantsModalProps = {
	show: boolean;
	tenants: null | TTenantDetail[];
};

const TenantsModal: FC<TListTenantsModalProps> = ({ show, tenants }) => {
	const handleVisitTenant = (url: string) => {
		window.location.href = url;
	};

	return (
		<Modal className="modal-500" centered show={show}>
			<Modal.Header className="modal__header">
				<Modal.Title className="modal__title">
					<FormattedMessage id="tenantsList" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{tenants &&
					tenants.map((tenant) => (
						<div className="tenant-block" key={tenant.id}>
							<span className="tenant-domain">
								{tenant.subdomain}
							</span>
							<button
								data-testid="visit-tenant"
								style={{ minWidth: "100px" }}
								onClick={() =>
									handleVisitTenant(tenant.full_url)
								}
								className="ice-button"
							>
								<FormattedMessage id="visit" />
							</button>
						</div>
					))}
			</Modal.Body>
		</Modal>
	);
};

export default TenantsModal;
