import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC } from "react";

import { useGetIntegrationsQuery } from "../../../api/integrationsAPI";
import { useExportProductsMutation } from "../../../api/productsAPI";

export type TSelectIntegrationData = {
	integration_id: number;
	override_existing_status: boolean;
};

type TShowIntegrationProps = {
	show: boolean;
	onHide: () => void;
	selectedProducts: number[];
	setSelectedProducts: (value: number[]) => void;
};

const SelectIntegration: FC<TShowIntegrationProps> = ({
	show,
	onHide,
	selectedProducts,
	setSelectedProducts,
}) => {
	const intl = useIntl();

	const [exportProducts] = useExportProductsMutation();
	const { data: integrations } = useGetIntegrationsQuery();

	const { register, handleSubmit } = useForm<TSelectIntegrationData>();

	const onSubmit = (data: TSelectIntegrationData) => {
		const object = {
			ids_list: selectedProducts,
			integration_id: data.integration_id,
			override_existing_status: data.override_existing_status,
		};
		exportProducts(object).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({ id: "somethingWentWrong" })
					);
				}
			} else {
				setSelectedProducts([]);
				toast.success(
					intl.formatMessage({ id: "productsSuccessfullyExported" })
				);
				onHide();
			}
		});
	};

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="selectIntegration" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Select
						defaultValue=""
						className="default-input mt-2"
						{...register("integration_id")}
					>
						<option value="">
							<FormattedMessage id="selectIntegration" />
						</option>
						{integrations &&
							integrations.results.map((integration) => {
								if (
									integration.partner ===
									"Shopify Integration"
								) {
									return (
										<option
											key={integration.id}
											value={integration.id}
										>
											{integration.name}
										</option>
									);
								} else {
									return null;
								}
							})}
					</Form.Select>
					<Form.Group className="d-flex align-items-center mt-1">
						<Form.Check
							className="me-2"
							{...register("override_existing_status")}
						/>
						<label className="default-input-label">
							<FormattedMessage id="overrideExistingProduct" />
						</label>
					</Form.Group>
					<div className="d-flex justify-content-end">
						<button type="submit" className="ice-button w-100 mt-3">
							<FormattedMessage id="exportProductsToStore" />
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default SelectIntegration;
