import { useIntl } from "react-intl";
import * as yup from "yup";

export const useUpdateCompanyDetailsValidationSchema = () => {
	const intl = useIntl();

	return yup.object().shape({
		name: yup.string().default("").nullable(),
		country: yup.string().default("").nullable(),
		state: yup.string().default("").nullable(),
		city: yup.string().default("").nullable(),
		address_line_1: yup.string().default("").nullable(),
		address_line_2: yup.string().default("").nullable(),
		zip_code: yup.string().default("").nullable(),
		phone: yup.string().default("").nullable(),
		website: yup
			.string()
			.default("")
			.nullable()
			.url(intl.formatMessage({ id: "pleaseEnterAValidUrl" })),
	});
};
