import { HTML5Backend } from "react-dnd-html5-backend";
import { FormattedMessage, useIntl } from "react-intl";
import { MdDeleteOutline } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { toast } from "react-toastify";
import { FC, useState } from "react";

import { TBulkUploadError } from "../../../api/api.types";
import {
	useGetTemplateForUploadMutation,
	useUploadOrdersMutation,
} from "../../../api/ordersAPI";

import TargetBox from "../BulkUpload/TargetBox";

import File from "../../assets/images/file.svg";

import "../BulkUpload/BulkUpload.css";

type TUploadError = {
	data: {
		error: TBulkUploadError[];
	};
};

type TUploadOrders = {
	show: boolean;
	onHide: () => void;
	setBulkUploadErrors: (value: TBulkUploadError[]) => void;
};

const UploadOrders: FC<TUploadOrders> = ({
	show,
	onHide,
	setBulkUploadErrors,
}) => {
	const [file, setFile] = useState<File | null>(null);

	const intl = useIntl();

	const [getTemplateFile] = useGetTemplateForUploadMutation();
	const [uploadOrders] = useUploadOrdersMutation();

	const handleGetTemplateFile = () => {
		getTemplateFile().then((response) => {
			if ("error" in response) return;

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "orders_template.xlsx");
			document.body.appendChild(link);
			link.click();
		});
	};

	const handleUploadOrders = () => {
		if (!file) return;

		const formData = new FormData();
		formData.append("xlsx_file", file);

		uploadOrders(formData).then((response) => {
			if ("error" in response) {
				const errors = response.error as TUploadError;
				setBulkUploadErrors(errors.data.error as TBulkUploadError[]);
				onHide();
			} else {
				toast.success(
					intl.formatMessage({ id: "ordersUploadedSuccessfully" })
				);
				setFile(null);
				onHide();
			}
		});
	};

	return (
		<Modal className="modal-600" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="ordersUpload" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{ gap: "12px" }}
				className="pt-0 d-flex flex-column"
			>
				<p className="upload-orders__description">
					<FormattedMessage id="bulkOrdersUploadFromAnExcelFile" />
				</p>
				<button
					onClick={handleGetTemplateFile}
					className="light-grey-button"
				>
					<FormattedMessage id="downloadTemplate" />
				</button>
				<p className="upload-orders__description">
					<FormattedMessage id="afterYouHavePreparedTheFileDragAndDropOrClick" />
				</p>
				{file ? (
					<div className="bulk-upload-file-block">
						<div className="bulk-upload-file">
							<img src={File} alt="" />
							<div className="bulk-upload-file-info">
								<span className="bulk-upload-file-info-title">
									{file.name}
								</span>
								<span className="bulk-upload-file-size">
									{Math.round(file.size / 1024)} kb
								</span>
							</div>
						</div>
						<MdDeleteOutline
							onClick={() => setFile(null)}
							className="bulk-upload-file-delete"
							color="#666666"
							size={24}
						/>
					</div>
				) : (
					<DndProvider backend={HTML5Backend}>
						<TargetBox setFile={setFile} />
					</DndProvider>
				)}
				<div className="d-flex align-items-center justify-content-end mt-2">
					<button
						disabled={!file}
						onClick={handleUploadOrders}
						className="ice-button w-100"
					>
						<FormattedMessage id="upload" />
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default UploadOrders;
