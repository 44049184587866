import { FC } from "react"

type TUsageAnimationsProps = {
    windowWidth: number,
    windowHeight: number
}

const UsageAnimations: FC<TUsageAnimationsProps> = ({ windowWidth, windowHeight }) => {
    return (
        <div className="home__animation-wrapper">
            <svg width="100%" height="100%">
                <defs>
                    <linearGradient id="flyingGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style={{ stopColor: "#e7f4fb", stopOpacity: "1" }} />
                        <stop offset="30%" style={{ stopColor: "#4DB8FF", stopOpacity: "1" }} />
                        <stop offset="50%" style={{ stopColor: "#4DB8FF", stopOpacity: "1" }} />
                        <stop offset="80%" style={{ stopColor: "#4DB8FF", stopOpacity: "1" }} />
                        <stop offset="100%" style={{ stopColor: "#e7f4fb", stopOpacity: "1" }} />
                    </linearGradient>
                </defs>
                <path
                    id="usage-flying-line-1"
                    d={`M0 400 C${windowWidth / 4} -300, ${windowWidth / 2.5} ${windowHeight / 1}, ${windowWidth} 400`}
                    stroke="url(#flyingGradient)"
                    fill="none"
                    strokeWidth="2"
                />
                <path
                    id="usage-flying-line-2"
                    d={`M0 0 C${windowWidth / 4} 200, ${windowWidth / 4} ${windowHeight / 5}, ${windowWidth} ${windowHeight / 5}`}
                    stroke="url(#flyingGradient)"
                    fill="none"
                    strokeWidth="2"
                />
                <path
                    id="usage-flying-line-3"
                    d={`M0 ${windowHeight / 4} C0 ${windowHeight / 1.2}, ${windowWidth / 4} ${windowHeight / 1.5}, 0 0`}
                    stroke="url(#flyingGradient)"
                    fill="none"
                    strokeWidth="2"
                />
            </svg>
        </div>
    )
}

export default UsageAnimations