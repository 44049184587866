import { BsArrowUpRightSquare } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { FC, useEffect, useState } from "react";
import { IoCheckmark } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { FaCcStripe } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";

import usePagination from "../../../../hooks/usePagination";
import {
	useDeleteStripePaymentMethodMutation,
	useDeleteWiseReceiptMutation,
	useGetStripePaymentMethodsQuery,
	useGetWiseReceiptsQuery,
} from "../../../../api/paymentsAPI";
import {
	useGetTenantUsersQuery,
	useUpdateTenantUserMutation,
} from "../../../../api/settingsAPI";
import { useAppSelector } from "../../../../redux/hooks";

import StripePaymentMethod from "../../../modals/StripePaymentMethod/StripePaymentMethod";
import AddPaymentMethod from "../../../modals/AddPaymentMethod/AddPaymentMethod";
import PagePagination from "../../common/Pagination/PagePagination";
import WiseReceipt from "../../../modals/WiseReceipt/WiseReceipt";
import InviteUser from "../../../modals/InviteUser/InviteUser";
import AddNewUser from "../../../modals/AddNewUser/AddNewUser";
import EmptyState from "../../common/EmptyState/EmptyState";

import Arrow from "../../../assets/images/arrow.svg";

type TSettingsUsersFinancialsBlock = {
	isPublic: boolean;
};

const SettingsUsersFinancialsBlock: FC<TSettingsUsersFinancialsBlock> = ({
	isPublic,
}) => {
	const [showUsers, setShowUsers] = useState(true);
	const [addPayment, setAddPayment] = useState(false);
	const [addNewUserModal, setAddNewUserModal] = useState(false);
	const [inviteUserModal, setInviteUserModal] = useState(false);
	const [viewPaymentMethodModal, setViewPaymentMethodModal] = useState(false);
	const [paymentMethodViewId, setPaymentMethodViewId] = useState(0);
	const [viewWiseReceiptModal, setViewWiseReceiptModal] = useState(false);
	const [wiseReceiptViewId, setWiseReceiptViewId] = useState(0);

	const isStaff = useAppSelector((state) => state.auth.isStaff);

	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		10
	);

	const [deletePaymentMethod] = useDeleteStripePaymentMethodMutation();
	const [deleteWiseReceipt] = useDeleteWiseReceiptMutation();
	const [updateTenantUser] = useUpdateTenantUserMutation();
	const { data: tenantUsers } = useGetTenantUsersQuery(activePage, {
		skip: isPublic,
	});
	const { data: stripePaymentsMethods, refetch: refetchStripePayments } =
		useGetStripePaymentMethodsQuery(undefined, {
			skip: isPublic,
		});
	const { data: wiseReceipts, refetch: refetchWiseReceipts } =
		useGetWiseReceiptsQuery(undefined, {
			skip: isPublic || isStaff,
		});

	const refetchPaymentMethodsAfterUpdate = () => {
		refetchStripePayments();
	};

	const refetchWiseReceiptsAfterUpdate = () => {
		refetchWiseReceipts();
	};

	const handleCloseViewPaymentModal = () => {
		setViewPaymentMethodModal(false);
		setPaymentMethodViewId(0);
	};

	const handleDeletePaymentMethod = (id: number) => {
		deletePaymentMethod(id);
	};

	const handleViewPaymentMethod = (id: number) => {
		setPaymentMethodViewId(id);
		setViewPaymentMethodModal(true);
	};

	const handleCloseWiseReceiptModal = () => {
		setViewWiseReceiptModal(false);
		setWiseReceiptViewId(0);
	};

	const handleViewWiseReceipt = (id: number) => {
		setWiseReceiptViewId(id);
		setViewWiseReceiptModal(true);
	};

	const handleDeleteWiseReceipt = (id: number) => {
		deleteWiseReceipt(id);
	};

	const handleChangeUserRole = (
		id: number,
		role: string,
		status: boolean
	) => {
		updateTenantUser({
			data: { role: { role, is_active: status } },
			id,
		});
	};

	useEffect(() => {
		if (tenantUsers) {
			handlePagesCount(tenantUsers?.count);
		}
	}, [tenantUsers, handlePagesCount]);

	return (
		<div className="settings-users__block">
			<div className="settings-users__block-body">
				<div className="settings-users__block-body-header">
					<div className="settings-users__block-body-tabs">
						<h5
							className={`settings-users__block-title ${showUsers ? "active-tab" : ""}`}
							onClick={() => setShowUsers(true)}
						>
							<FormattedMessage id="users" />
						</h5>
						<h5
							data-testid="payment-deposit-tab"
							className={`settings-users__block-title ${!showUsers ? "active-tab" : ""}`}
							onClick={() => setShowUsers(false)}
						>
							<FormattedMessage id="paymentOrDeposit" />
						</h5>
					</div>
					<div className="settings-users__block-body-header-buttons">
						{showUsers ? (
							<>
								<PagePagination
									pages={pages}
									activePage={activePage}
									handlePage={handlePage}
								/>
								<button
									data-testid="add-new-user"
									onClick={() => setAddNewUserModal(true)}
									className="secondary-button"
								>
									<FormattedMessage id="addNewUser" />
								</button>
								<button
									data-testid="invite-existing-user"
									onClick={() => setInviteUserModal(true)}
									className="ice-button"
								>
									<FormattedMessage id="inviteExistingUser" />
								</button>
							</>
						) : (
							<button
								data-testid="add-payment-deposit-method"
								onClick={() => setAddPayment(true)}
								className="ice-button"
							>
								<FormattedMessage id="addPaymentDepositMethod" />
							</button>
						)}
					</div>
				</div>
				<div className="default-table-no-wrapper">
					{showUsers ? (
						<table className="table">
							<thead className="thead-light">
								<tr>
									<th>
										<FormattedMessage id="name" />
									</th>
									<th>
										<FormattedMessage id="email" />
									</th>
									<th>
										<FormattedMessage id="active" />
									</th>
									<th>
										<FormattedMessage id="role" />
									</th>
									<th>
										<FormattedMessage id="action" />
									</th>
								</tr>
							</thead>
							<tbody>
								{tenantUsers &&
									tenantUsers.results.map((user) => (
										<tr
											data-testid="user-row"
											key={user.id}
										>
											<td className="text-center">
												{user.first_name}{" "}
												{user.last_name}
											</td>
											<td
												data-testid="user-email"
												className="text-center"
											>
												{user.email}
											</td>
											<td
												data-testid="user-status"
												className="text-center"
											>
												{user.role.is_active ? (
													<FormattedMessage id="yes" />
												) : (
													<FormattedMessage id="no" />
												)}
											</td>
											<td className="text-center">
												<Dropdown className="d-flex justify-content-center">
													<Dropdown.Toggle
														data-testid="user-role-toggle"
														className="user-header-dropdown default-dropdown-toggle p-0"
													>
														<div className="default-dropdown-button">
															{user.role.role ===
															"Owner" ? (
																<FormattedMessage id="owner" />
															) : (
																<FormattedMessage id="staff" />
															)}
															<img
																className="arrow-icon"
																src={Arrow}
																alt=""
															/>
														</div>
													</Dropdown.Toggle>
													<Dropdown.Menu className="default-dropdown-menu-wrapper">
														<div
															data-testid="role-menu"
															className="default-dropdown-menu"
														>
															<Dropdown.Item
																data-testid="owner-role"
																className="default-dropdown-item"
																onClick={() =>
																	handleChangeUserRole(
																		user.id,
																		"OWNER",
																		user
																			.role
																			.is_active
																	)
																}
															>
																<FormattedMessage id="owner" />
															</Dropdown.Item>
															<Dropdown.Item
																data-testid="staff-role"
																className="default-dropdown-item"
																onClick={() =>
																	handleChangeUserRole(
																		user.id,
																		"STAFF",
																		user
																			.role
																			.is_active
																	)
																}
															>
																<FormattedMessage id="staff" />
															</Dropdown.Item>
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</td>
											<td className="text-center">
												<Dropdown className="d-flex justify-content-center">
													<Dropdown.Toggle
														data-testid="user-action-toggle"
														className="user-header-dropdown default-dropdown-toggle p-0"
													>
														<div className="default-dropdown-button">
															<FormattedMessage id="action" />
															<img
																className="arrow-icon"
																src={Arrow}
																alt=""
															/>
														</div>
													</Dropdown.Toggle>
													<Dropdown.Menu className="default-dropdown-menu-wrapper">
														<div
															data-testid="user-actions-menu"
															className="default-dropdown-menu"
														>
															<Dropdown.Item
																data-testid="deactivate-activate-user"
																className="default-dropdown-item"
																onClick={() =>
																	handleChangeUserRole(
																		user.id,
																		user.role.role.toUpperCase(),
																		!user
																			.role
																			.is_active
																	)
																}
															>
																{user.role
																	.is_active ? (
																	<FormattedMessage id="deactivate" />
																) : (
																	<FormattedMessage id="activate" />
																)}
															</Dropdown.Item>
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					) : (
						<>
							{stripePaymentsMethods &&
							stripePaymentsMethods.results.length === 0 &&
							wiseReceipts &&
							wiseReceipts.results.length === 0 ? (
								<EmptyState message="noPaymentsOrDepositsFound" />
							) : (
								<table className="table">
									<thead className="thead-light">
										<tr>
											<th
												className="text-center"
												scope="col"
											>
												<FormattedMessage id="method" />
											</th>
											<th
												className="text-center"
												scope="col"
											>
												<FormattedMessage id="actions" />
											</th>
											<th
												className="text-center"
												scope="col"
												style={{ width: "40px" }}
											>
												<FormattedMessage id="default" />
											</th>
										</tr>
									</thead>
									<tbody>
										{stripePaymentsMethods &&
											stripePaymentsMethods.results
												.length > 0 &&
											stripePaymentsMethods.results.map(
												(method) => (
													<tr
														data-testid="stripe-card-row"
														key={method.id}
													>
														<td
															data-testid="card-info"
															className="text-center"
														>
															<FaCcStripe
																size={22}
																className="me-1"
															/>{" "}
															<FormattedMessage id="stripeCard" />{" "}
															**** **** ****{" "}
															{method.last4} (
															{method.brand})
														</td>
														<td className="text-center">
															<div className="d-flex justify-content-center gap-2">
																<button
																	data-testid="view-payment-method"
																	onClick={() =>
																		handleViewPaymentMethod(
																			method.id
																		)
																	}
																	className="square-white-button"
																>
																	<BsArrowUpRightSquare
																		size={
																			16
																		}
																		fill="green"
																	/>
																</button>
																<button
																	data-testid="delete-payment-method"
																	onClick={() =>
																		handleDeletePaymentMethod(
																			method.id
																		)
																	}
																	className="square-white-button"
																>
																	<AiOutlineDelete
																		size={
																			20
																		}
																		fill="red"
																	/>
																</button>
															</div>
														</td>
														<td className="text-center">
															{method.default && (
																<span
																	data-testid="default-payment-method"
																	className="default-marker"
																>
																	<IoCheckmark
																		size={
																			20
																		}
																		style={{
																			color: "green",
																		}}
																	/>
																</span>
															)}
														</td>
													</tr>
												)
											)}
										{wiseReceipts &&
											wiseReceipts.results.length > 0 &&
											wiseReceipts.results.map((wise) => (
												<tr
													data-testid="bank-account-row"
													key={wise.id}
												>
													<td
														data-testid="bank-info"
														className="text-center"
													>
														<FormattedMessage id="bankAccountWithDots" />{" "}
														{wise.full_name}
													</td>
													<td className="text-center">
														<div className="d-flex justify-content-center gap-2">
															<button
																data-testid="view-wise-account"
																onClick={() =>
																	handleViewWiseReceipt(
																		wise.id
																	)
																}
																className="square-white-button"
															>
																<BsArrowUpRightSquare
																	size={16}
																	fill="green"
																/>
															</button>
															<button
																data-testid="delete-wise-account"
																onClick={() =>
																	handleDeleteWiseReceipt(
																		wise.id
																	)
																}
																className="square-white-button"
															>
																<AiOutlineDelete
																	size={20}
																	fill="red"
																/>
															</button>
														</div>
													</td>
													<td className="text-center">
														{wise.default && (
															<span
																data-testid="default-payment-method"
																className="default-marker"
															>
																<IoCheckmark
																	size={20}
																	style={{
																		color: "green",
																	}}
																/>
															</span>
														)}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							)}
						</>
					)}
				</div>
			</div>
			{addNewUserModal && (
				<AddNewUser
					show={addNewUserModal}
					onHide={() => setAddNewUserModal(false)}
				/>
			)}
			{inviteUserModal && (
				<InviteUser
					show={inviteUserModal}
					onHide={() => setInviteUserModal(false)}
				/>
			)}
			{addPayment && (
				<AddPaymentMethod
					show={addPayment}
					onHide={() => setAddPayment(false)}
				/>
			)}
			{viewPaymentMethodModal && (
				<StripePaymentMethod
					refetchPaymentMethodsAfterUpdate={
						refetchPaymentMethodsAfterUpdate
					}
					show={viewPaymentMethodModal}
					onHide={handleCloseViewPaymentModal}
					id={paymentMethodViewId}
				/>
			)}
			{viewWiseReceiptModal && (
				<WiseReceipt
					refetchWiseReceiptsAfterUpdate={
						refetchWiseReceiptsAfterUpdate
					}
					show={viewWiseReceiptModal}
					onHide={handleCloseWiseReceiptModal}
					id={wiseReceiptViewId}
				/>
			)}
		</div>
	);
};

export default SettingsUsersFinancialsBlock;
