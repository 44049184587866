import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { useState } from "react";

import { useJoinWaitlistMutation } from "../../../../api/authAPI";

import "./ComingSoon.css";

const ComingSoon = () => {
	const [email, setEmail] = useState("");

	const intl = useIntl();

	const [joinWaitlist] = useJoinWaitlistMutation();

	const onSubscribe = () => {
		joinWaitlist({
			email: email,
			event_name: "coming_soon_2025",
		}).then((res) => {
			if ("error" in res) {
				toast.error(intl.formatMessage({ id: "somethingWentWrong" }));
			} else {
				toast.success(
					intl.formatMessage({
						id: "thanksForSubscribingWeWillKeepYouUpdatedOnAllUpcomingFeatures",
					})
				);
				setEmail("");
			}
		});
	};

	return (
		<div className="coming-soon__wrapper">
			<Helmet prioritizeSeoTags>
				<title>Cloud Order - Coming Soon</title>
				<meta
					name="description"
					content="Discover the best dropshipping app of 2025! A powerful Shopify dropshipping tool and TikTok dropshipping tool to grow your online business."
				></meta>
			</Helmet>
			<div className="coming-soon__content">
				<h1 className="coming-soon__title">
					<FormattedMessage id="somethingBigIsOnTheWay" />
				</h1>
				<p className="coming-soon__description">
					<FormattedMessage id="weAreWorkingOnSomethingSpecialAndWillBeAbleToSeeItVerySoon" />
				</p>
				<div className="coming-soon__form">
					<div className="coming-soon__form-group">
						<label className="default-input-label">
							<FormattedMessage id="email" />
						</label>
						<input
							onChange={(e) => setEmail(e.target.value)}
							style={{ height: "56px" }}
							className="default-input"
							value={email}
							type="text"
						/>
					</div>
					<button
						onClick={onSubscribe}
						style={{
							height: "52px",
							borderRadius: "50px",
							width: "fit-content",
							padding: "0 20px",
						}}
						className="ice-button"
					>
						<FormattedMessage id="subscribeToBeTheFirstToKnow" />
					</button>
				</div>
			</div>
			<div className="coming-soon__default-sliding-text-block">
				<div className="coming-soon__default-sliding-text-marquee">
					<div className="marquee__inner">
						{[...Array(10)].map((_, index) => (
							<span key={index}>
								<FormattedMessage id="comingSoonUppercase" />
								&nbsp;
							</span>
						))}
					</div>
				</div>
			</div>
			<div className="coming-soon__features-sliding-text-block">
				<div className="coming-soon__default-sliding-text-marquee">
					<div className="marquee__inner white">
						<span>
							<FormattedMessage id="productAndOrderBulkUploads" />
							&nbsp;
						</span>
						<span>
							<FormattedMessage id="shopifyAndTikTokApp" />
							&nbsp;
						</span>
						<span>
							<FormattedMessage id="amazonMcfSupport" />
							&nbsp;
						</span>
						<span>
							<FormattedMessage id="manualOrderProcessing" />
							&nbsp;
						</span>
						<span>
							<FormattedMessage id="productAndOrderBulkUploads" />
							&nbsp;
						</span>
						<span>
							<FormattedMessage id="shopifyAndTikTokApp" />
							&nbsp;
						</span>
						<span>
							<FormattedMessage id="amazonMcfSupport" />
							&nbsp;
						</span>
						<span>
							<FormattedMessage id="manualOrderProcessing" />
							&nbsp;
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ComingSoon;
