import { FC } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCardForm from "../StripeCardForm/StripeCardForm";

type TCreditCardProps = {
	onHide: () => void;
};

const stripePromise = loadStripe(
	process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
		? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
		: ""
);

const CreditCard: FC<TCreditCardProps> = ({ onHide }) => {
	return (
		<Elements stripe={stripePromise}>
			<StripeCardForm onHide={onHide} />
		</Elements>
	);
};

export default CreditCard;
