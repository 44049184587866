import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { FC } from "react"

import { TSetNewPasswordForm } from '../../pages/LendingPage/Auth/ResetPassword/SetNewPassword/SetNewPassword'
import { useNewPasswordValidationSchema } from '../../pages/LendingPage/Auth/ResetPassword/validator'
import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";
import { useChangePasswordMutation } from "../../../api/settingsAPI";

import UserAlert from "../../components/common/UserAlert/UserAlert";

type TChangePasswordProps = {
    show: boolean,
    onHide: () => void,
}

const ChangePassword: FC<TChangePasswordProps> = ({ show, onHide }) => {
    const newPasswordValidationSchema = useNewPasswordValidationSchema()
    const intl = useIntl()

    const [customError, setCustomError] = useHandleCustomRtkQueryError()
    const [changePassword, { isSuccess }] = useChangePasswordMutation()

    const { register, handleSubmit, formState: { errors } } = useForm<TSetNewPasswordForm>({
        resolver: yupResolver(newPasswordValidationSchema),
    });

    const onSubmit = (data: TSetNewPasswordForm) => {
        changePassword(data).then((response) => {
            if ('error' in response) {
                setCustomError(intl.formatMessage({ id: "somethingWentWrongPleaseTryAgainLater" }))
            } else {
                setCustomError(null)
            }
        })
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="changePassword" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                {isSuccess ? (
                    <UserAlert text1={`${intl.formatMessage({ id: "passwordUpdatedSuccessfully" })}`} />
                ) : (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {customError && <p className="form-field-error">{customError}</p>}
                        <Form.Group>
                            <Form.Label className='form-input-label'>
                                <FormattedMessage id="newPassword" />
                            </Form.Label>
                            <Form.Control
                                placeholder={intl.formatMessage({ id: "newPassword" })}
                                className="form-input-field"
                                type="password"
                                {...register("new_password1")}
                            />
                            {errors.new_password1 && <p className="mb-0 form-field-error">
                                {errors.new_password1.message}
                            </p>}
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label className='form-input-label'>
                                <FormattedMessage id="confirmNewPassword" />
                            </Form.Label>
                            <Form.Control
                                placeholder={`${intl.formatMessage({ id: "confirmPassword" })}`}
                                className="form-input-field"
                                type="password"
                                {...register("new_password2")}
                            />
                            {errors.new_password2 && <p className="mb-0 form-field-error">{errors.new_password2.message}</p>}
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-3">
                            <button type='submit' className="dashboard-menu-button margin-top">
                                <FormattedMessage id="updatePassword" />
                            </button>
                        </div>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default ChangePassword