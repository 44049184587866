import {
    FaBarcode,
    FaCheck,
    FaDollarSign,
    FaFingerprint,
    FaWarehouse,
} from "react-icons/fa6";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsSortNumericUpAlt, BsStar } from "react-icons/bs";
import { IoClose, IoWarning } from "react-icons/io5";
import { MdArrowForwardIos } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import StarRatings from "react-star-ratings";
import { TfiReload } from "react-icons/tfi";
import { FaSyncAlt } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { FC, Fragment } from "react";

import { ProductDataUpdated } from "./MyProducts";

import MyProductsVariantRow from "./MyProductsVariantRow";

type MyProductsTableProps = {
    productData: ProductDataUpdated[];
    selectAll: boolean;
    selectedAll: boolean;
    handleSelectPageAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectProduct: (
        e: React.ChangeEvent<HTMLInputElement>,
        id: number
    ) => void;
    selectedProducts: number[];
    handleExpandData: (id: number) => void;
};

const MyProductsTable: FC<MyProductsTableProps> = ({
    productData,
    selectAll,
    selectedAll,
    handleSelectPageAll,
    handleSelectProduct,
    selectedProducts,
    handleExpandData,
}) => {
    return (
        <table className="table">
            <thead className="thead-light">
                <tr>
                    <th className="text-center" scope="col">
                        <Form.Check
                            checked={selectAll || selectedAll ? true : false}
                            onChange={(e) => handleSelectPageAll(e)}
                        />
                    </th>
                    <th></th>
                    <th className="text-center" scope="col">
                        <FaFingerprint size={17} />{" "}
                        <FormattedMessage id="productName" />
                    </th>
                    <th className="text-center" scope="col">
                        <FaCheck size={17} /> <FormattedMessage id="public" />
                    </th>
                    <th className="text-center" scope="col">
                        <FiClock size={19} />{" "}
                        <FormattedMessage id="createdTime" />
                    </th>
                    <th className="text-center" scope="col">
                        <FiClock size={19} />{" "}
                        <FormattedMessage id="lastUpdateTime" />
                    </th>
                    <th className="text-center" scope="col">
                        <BsStar size={19} />{" "}
                        <FormattedMessage id="productRating" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {productData?.length > 0 &&
                    productData.map((el) => (
                        <Fragment key={el.id}>
                            <tr className="table-row-body">
                                <td width="4%" className="text-center">
                                    <Form.Check
                                        onChange={(e) =>
                                            handleSelectProduct(e, el.id)
                                        }
                                        checked={
                                            selectedProducts.includes(el.id) ||
                                            selectedAll
                                                ? true
                                                : false
                                        }
                                    />
                                </td>
                                <td
                                    className="cursor-pointer"
                                    onClick={() => handleExpandData(el.id)}
                                    width="2%"
                                >
                                    <MdArrowForwardIos
                                        className={
                                            el.expand
                                                ? "rotate-expand-button"
                                                : ""
                                        }
                                    />
                                </td>
                                <td className="text-center max-width-td">
                                    {el.variants?.every(
                                        (variant) =>
                                            variant?.status !== "available" &&
                                            variant?.status !==
                                                "pending_synchronization"
                                    ) ? (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="hi">
                                                    <FormattedMessage id="actionRequired" />
                                                </Tooltip>
                                            }
                                            placement="bottom"
                                        >
                                            <span
                                                style={{
                                                    gap: "8px",
                                                }}
                                                className="wrapped-table-row"
                                            >
                                                <IoClose
                                                    style={{
                                                        marginRight: "5px",
                                                        marginBottom: "2px",
                                                    }}
                                                    size={23}
                                                    color="red"
                                                />
                                                {el.title}
                                            </span>
                                        </OverlayTrigger>
                                    ) : el.variants?.some(
                                          (variant) =>
                                              variant?.status ===
                                              "pending_synchronization"
                                      ) ? (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="hi">
                                                    <FormattedMessage id="pendingSynchronization" />
                                                </Tooltip>
                                            }
                                            placement="bottom"
                                        >
                                            <span
                                                style={{
                                                    gap: "8px",
                                                }}
                                                className="wrapped-table-row"
                                            >
                                                <FaSyncAlt
                                                    style={{
                                                        marginRight: "5px",
                                                        marginBottom: "3px",
                                                    }}
                                                    size={20}
                                                    color="#4e61f4"
                                                />
                                                {el.title}
                                            </span>
                                        </OverlayTrigger>
                                    ) : el.variants?.some(
                                          (variant) =>
                                              variant?.images?.length === 0
                                      ) ? (
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="hi">
                                                    <FormattedMessage id="actionRequired" />
                                                </Tooltip>
                                            }
                                            placement="bottom"
                                        >
                                            <span
                                                style={{
                                                    gap: "8px",
                                                }}
                                                className="wrapped-table-row"
                                            >
                                                <IoWarning
                                                    style={{
                                                        marginRight: "5px",
                                                        marginBottom: "3px",
                                                    }}
                                                    size={20}
                                                    color="#ffcc00"
                                                />
                                                {el.title}
                                            </span>
                                        </OverlayTrigger>
                                    ) : (
                                        <span
                                            style={{
                                                gap: "8px",
                                            }}
                                            className="wrapped-table-row"
                                        >
                                            {el.title}
                                        </span>
                                    )}
                                </td>
                                <td className="text-center">
                                    {el.in_review ? (
                                        <TfiReload size={18} />
                                    ) : (
                                        <>
                                            {el.is_public ? (
                                                <FaCheck size={18} />
                                            ) : (
                                                <GrClose size={18} />
                                            )}
                                        </>
                                    )}
                                </td>
                                <td className="text-center">
                                    {el.created_at.split("T")[0]}
                                </td>
                                <td className="text-center">
                                    {el.updated_at.split("T")[0]}
                                </td>
                                <td className="text-center">
                                    <StarRatings
                                        rating={parseFloat(
                                            el.rank ? el.rank?.rating : "0"
                                        )}
                                        numberOfStars={5}
                                        starRatedColor="yellow"
                                        starDimension="24px"
                                        starSpacing="1px"
                                    />
                                </td>
                            </tr>
                            {el.expand && (
                                <tr>
                                    <td className="p-0" colSpan={7}>
                                        <table className="table mb-0">
                                            <thead
                                                style={{
                                                    backgroundColor: "#d2ebf1",
                                                }}
                                                className="thead-light"
                                            >
                                                <tr className="border-0">
                                                    <th
                                                        style={{
                                                            paddingLeft: "25px",
                                                        }}
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <FaFingerprint
                                                            size={18}
                                                        />{" "}
                                                        <FormattedMessage id="variantName" />
                                                    </th>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <FaWarehouse
                                                            size={20}
                                                        />{" "}
                                                        <FormattedMessage id="inStock" />
                                                    </th>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <BsSortNumericUpAlt
                                                            size={20}
                                                        />{" "}
                                                        <FormattedMessage id="inOrder" />
                                                    </th>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <FaBarcode size={20} />{" "}
                                                        SKU
                                                    </th>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <FaDollarSign
                                                            size={19}
                                                        />{" "}
                                                        <FormattedMessage id="cost" />
                                                    </th>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <FaDollarSign
                                                            size={19}
                                                        />
                                                        MSRP
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {el.variants.map((variant) => (
                                                    <MyProductsVariantRow
                                                        variant={variant}
                                                        key={variant.id}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    ))}
            </tbody>
        </table>
    );
};

export default MyProductsTable;
