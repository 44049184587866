import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi2";
import { FormattedMessage, useIntl } from "react-intl";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FC, useEffect, useState } from "react";
import { GoPlusCircle } from "react-icons/go";
import { useForm } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { useGetOffAmazonProductsQuery } from "../../../api/productsAPI";
import { useCreateManualOrderMutation } from "../../../api/ordersAPI";
import { TProductVariants } from "../../../api/api.types";

import "./CreateOrder.css";

type TCreateOrder = {
	show: boolean;
	onHide: () => void;
};

type TProductVariantWithQuantity = TProductVariants & {
	quantity: number;
};

type TCreateManualOrderForm = {
	name: string;
	address_line_1: string;
	address_line_2: string;
	city: string;
	state: string;
	postal_code: string;
	country_code: string;
	phone: string;
	email: string;
};

const CreateOrder: FC<TCreateOrder> = ({ show, onHide }) => {
	const [selectedVariants, setSelectedVariants] = useState<
		TProductVariantWithQuantity[]
	>([]);
	const [variantsToDisplay, setVariantsToDisplay] = useState<
		TProductVariants[]
	>([]);
	const [noSelectedProductsIssue, setNoSelectedProductsIssue] =
		useState(false);
	const [showProductsList, setShowProductsList] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [search, setSearch] = useState("");

	const intl = useIntl();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<TCreateManualOrderForm>();

	const { data: variants } = useGetOffAmazonProductsQuery(search, {
		skip: !search,
	});
	const [createManualOrder] = useCreateManualOrderMutation();

	const handleFocusSearch = () => {
		setShowProductsList(true);
	};

	const handleAddVariantToSelected = (variant: TProductVariants) => {
		setSelectedVariants([...selectedVariants, { ...variant, quantity: 1 }]);
		setNoSelectedProductsIssue(false);
	};

	const handleRemoveSelectedVariant = (id: number) => {
		setSelectedVariants(
			selectedVariants.filter((variant) => variant.id !== id)
		);
	};

	const handleBlurSearch = () => {
		setTimeout(() => {
			setShowProductsList(false);
			setSearch("");
			setSearchValue("");
		}, 200);
	};

	const handleDecreaseQuantity = (id: number) => {
		setSelectedVariants(
			selectedVariants.map((variant) => {
				if (variant.id === id) {
					return {
						...variant,
						quantity:
							variant.quantity > 1 ? variant.quantity - 1 : 1,
					};
				}

				return variant;
			})
		);
	};

	const handleIncreaseQuantity = (id: number) => {
		setSelectedVariants(
			selectedVariants.map((variant) => {
				if (variant.id === id) {
					return { ...variant, quantity: variant.quantity + 1 };
				}

				return variant;
			})
		);
	};

	const handleChangeVariantQuantity = (id: number, quantity: string) => {
		if (!Number(quantity)) {
			setSelectedVariants(
				selectedVariants.map((variant) => {
					if (variant.id === id) {
						return { ...variant, quantity: 0 };
					}

					return variant;
				})
			);
		} else {
			setSelectedVariants(
				selectedVariants.map((variant) => {
					if (variant.id === id) {
						return { ...variant, quantity: Number(quantity) };
					}

					return variant;
				})
			);
		}
	};

	const saveOrder = (data: TCreateManualOrderForm) => {
		if (selectedVariants.length === 0) {
			setNoSelectedProductsIssue(true);
			return;
		}

		const lineItems = selectedVariants.map((variant) => ({
			sku: variant.sku,
			quantity: variant.quantity,
		}));

		createManualOrder({
			line_items: lineItems,
			shipping_address: {
				name: data.name,
				address_line1: data.address_line_1,
				address_line2: data.address_line_2,
				city: data.city,
				state: data.state,
				postal_code: data.postal_code,
				country_code: data.country_code,
				phone: data.phone,
				email: data.email,
			},
		}).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({ id: "failedToCreateOrder" })
					);
				}
			} else {
				onHide();
				toast.success(
					intl.formatMessage({ id: "orderCreatedSuccessfully" })
				);
			}
		});
	};

	useEffect(() => {
		const interval = setTimeout(() => {
			setSearch(searchValue);
		}, 700);

		return () => {
			clearTimeout(interval);
		};
	}, [searchValue]);

	useEffect(() => {
		if (!variants) {
			setVariantsToDisplay([]);
			return;
		}

		const variantsToDisplay: TProductVariants[] = [];

		variants.results.forEach((variant) => {
			if (
				!selectedVariants.some(
					(selectedVariant) => selectedVariant.id === variant.id
				)
			) {
				variantsToDisplay.push(variant);
			}
		});

		setVariantsToDisplay(variantsToDisplay);
	}, [variants, selectedVariants]);

	return (
		<Modal className="modal-600" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="orderCreate" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="fulfillment-select-modal__body">
				<form
					onSubmit={handleSubmit(saveOrder)}
					style={{ gap: "12px" }}
					className="d-flex flex-column"
				>
					<p className="fulfillment-select-modal__description">
						<FormattedMessage id="createAnOrderByFillingInTheFormBelow" />
					</p>
					<div
						className={`create-order__selected-products ${noSelectedProductsIssue ? "create-order__selected-products--error" : ""}`}
					>
						<div className="create-order__search-wrapper">
							<FiSearch
								className="create-order__search-icon"
								size={24}
								strokeWidth={1.2}
							/>
							<input
								placeholder={intl.formatMessage({
									id: "searchForProducts",
								})}
								onFocus={handleFocusSearch}
								onBlur={handleBlurSearch}
								onChange={(e) => setSearchValue(e.target.value)}
								className="create-order__search products"
								value={searchValue}
								type="text"
							/>
						</div>
						{showProductsList && search && (
							<div className="create-orders__variants-list">
								{variantsToDisplay?.length === 0 ? (
									<p className="create-order__no-selected-label">
										<FormattedMessage id="noProductsFound" />
									</p>
								) : (
									<>
										{variantsToDisplay.map((variant) => (
											<div
												onClick={() =>
													handleAddVariantToSelected(
														variant
													)
												}
												key={variant.id}
												className="create-order__variant"
											>
												<div className="create-order__variant-info-wrapper">
													{variant?.images[0]
														?.image ||
													variant?.images[0]?.url ? (
														<img
															src={
																variant
																	?.images[0]
																	?.image ||
																variant
																	?.images[0]
																	?.url
															}
															alt=""
														/>
													) : (
														<div className="create-order__variant-empty-image"></div>
													)}
													<div className="create-order__variant-info">
														<p className="create-order__variant-name">
															{variant.title}
														</p>
														<p className="create-order__variant-sku">
															{variant.sku}
														</p>
													</div>
												</div>
												<button
													onClick={() =>
														handleAddVariantToSelected(
															variant
														)
													}
													className="create-order__add-button"
													type="button"
												>
													<GoPlusCircle
														size={20}
														color="#666666"
													/>
												</button>
											</div>
										))}
									</>
								)}
							</div>
						)}
						{selectedVariants.length > 0 && (
							<div className="create-order__selected-variants-list">
								{selectedVariants.map((variant) => (
									<div
										key={variant.id}
										className="create-order__variant-selected"
									>
										<div className="create-order__variant-info-wrapper">
											{variant?.images[0]?.image ||
											variant?.images[0]?.url ? (
												<img
													src={
														variant?.images[0]
															?.image ||
														variant?.images[0]?.url
													}
													alt=""
												/>
											) : (
												<div className="create-order__variant-empty-image"></div>
											)}
											<div className="create-order__variant-info">
												<p className="create-order__variant-name">
													{variant.title}
												</p>
												<p className="create-order__variant-sku">
													{variant.sku}
												</p>
											</div>
										</div>
										<div className="create-order__selected-variant-options">
											<div className="create-order__quantity-input-wrapper">
												<div
													onClick={() =>
														handleDecreaseQuantity(
															variant.id
														)
													}
													className="create-order__quantity-input-decrease"
												>
													<HiOutlineMinus size={18} />
												</div>
												<input
													onChange={(e) =>
														handleChangeVariantQuantity(
															variant.id,
															e.target.value
														)
													}
													className="create-order__quantity-input"
													value={variant.quantity}
													type="text"
												/>
												<div
													onClick={() =>
														handleIncreaseQuantity(
															variant.id
														)
													}
													className="create-order__quantity-input-increase"
												>
													<HiOutlinePlus size={18} />
												</div>
											</div>
											<button
												onClick={() =>
													handleRemoveSelectedVariant(
														variant.id
													)
												}
												className="create-order__add-button"
												type="button"
											>
												<IoCloseCircleOutline
													size={20}
													color="#FFFFFF"
												/>
											</button>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
					<div className="create-order__input-wrapper">
						<input
							{...register("name", {
								required: `${intl.formatMessage({
									id: "customerNameIsRequired",
								})}`,
							})}
							placeholder={`${intl.formatMessage({
								id: "customerName",
							})} *`}
							className="create-order__search"
							type="text"
						/>
					</div>
					<div className="create-order__input-wrapper">
						<input
							{...register("address_line_1", {
								required: `${intl.formatMessage({
									id: "addressLine1IsRequired",
								})}`,
							})}
							className={`create-order__search ${errors.address_line_1 ? "create-order__search--error" : ""}`}
							placeholder={`${intl.formatMessage({
								id: "addressLine1",
							})} *`}
							type="text"
						/>
					</div>
					<div className="create-order__input-wrapper">
						<input
							placeholder={intl.formatMessage({
								id: "addressLine2",
							})}
							className="create-order__search"
							{...register("address_line_2")}
							type="text"
						/>
					</div>
					<div className="create-order__input-wrapper">
						<input
							{...register("city", {
								required: `${intl.formatMessage({
									id: "cityIsRequired",
								})}`,
							})}
							className={`create-order__search ${errors.city ? "create-order__search--error" : ""}`}
							placeholder={`${intl.formatMessage({
								id: "city",
							})} *`}
							type="text"
						/>
					</div>
					<div className="create-order__input-wrapper">
						<input
							{...register("state", {
								required: `${intl.formatMessage({
									id: "stateIsRequired",
								})}`,
							})}
							className={`create-order__search ${errors.state ? "create-order__search--error" : ""}`}
							placeholder={`${intl.formatMessage({
								id: "state",
							})} *`}
							type="text"
						/>
					</div>
					<div className="create-order__input-wrapper">
						<input
							{...register("postal_code", {
								required: `${intl.formatMessage({
									id: "postalCodeIsRequired",
								})}`,
							})}
							className={`create-order__search ${errors.postal_code ? "create-order__search--error" : ""}`}
							placeholder={`${intl.formatMessage({
								id: "postalCode",
							})} *`}
							type="text"
						/>
					</div>
					<div className="create-order__input-wrapper">
						<input
							{...register("country_code", {
								required: `${intl.formatMessage({
									id: "countryCodeIsRequired",
								})}`,
								maxLength: 2,
								minLength: 2,
							})}
							className={`create-order__search ${errors.country_code ? "create-order__search--error" : ""}`}
							placeholder={`${intl.formatMessage({
								id: "countryCode",
							})} *`}
							maxLength={2}
							type="text"
						/>
					</div>
					<div className="create-order__input-wrapper">
						<input
							{...register("phone", {
								required: `${intl.formatMessage({
									id: "phoneIsRequired",
								})}`,
							})}
							placeholder={`${intl.formatMessage({
								id: "phone",
							})} *`}
							className="create-order__search"
							type="text"
						/>
					</div>
					<div className="create-order__input-wrapper">
						<input
							{...register("email", {
								required: `${intl.formatMessage({
									id: "emailIsRequired",
								})}`,
							})}
							placeholder={`${intl.formatMessage({
								id: "email",
							})} *`}
							className="create-order__search"
							type="text"
						/>
					</div>
					<button className="ice-button w-100">
						<FormattedMessage id="createOrder" />
					</button>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default CreateOrder;
