import { AiOutlineFieldNumber, AiOutlineNumber } from 'react-icons/ai';
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import { BsReceiptCutoff } from "react-icons/bs";
import { FormattedMessage } from 'react-intl';
import { GoTrash } from 'react-icons/go';
import { useEffect } from "react";

import { useGetWiseTransfersQuery } from '../../../../../api/paymentsAPI';
import usePagination from '../../../../../hooks/usePagination';
import useFilters from '../../../../../hooks/useFilters';

import PagePagination from '../../../../components/common/Pagination/PagePagination';

const BillingPayouts = () => {
    const { pageSize, handlePageSize, clearFilters } = useFilters()
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', pageSize)

    const { data: wiseTransfers } = useGetWiseTransfersQuery({
        activePage,
        pageSize
    })

    useEffect(() => {
        if (wiseTransfers) {
            handlePagesCount(wiseTransfers.count)
        }
    }, [wiseTransfers, handlePagesCount])

    return (
        <section className='p-0'>
            <Container fluid className="header-dashboard">
                <Row className='ps-1'>
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center">
                        <ul className="dashboard-menu-ul d-flex align-items-start ps-0 m-0 ms-1">
                            <li className='max-height-38' >
                                <div className="inputBox dashboard-input-box max-height-38">
                                    <Dropdown onSelect={(e) => handlePageSize(e, handlePage)}>
                                        <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                            <FormattedMessage id="resultsPerPage" values={{ amount: pageSize }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={5}>
                                                <FormattedMessage id="resultsPerPage" values={{ amount: 5 }} />
                                            </Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={10}>
                                                <FormattedMessage id="resultsPerPage" values={{ amount: 10 }} />
                                            </Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={20}>
                                                <FormattedMessage id="resultsPerPage" values={{ amount: 20 }} />
                                            </Dropdown.Item>
                                            <Dropdown.Item className='dashboard-header-dwopdown-item' eventKey={50}>
                                                <FormattedMessage id="resultsPerPage" values={{ amount: 50 }} />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li>
                                <button
                                    onClick={() => clearFilters(handlePage)}
                                    className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38"
                                >
                                    <GoTrash size={18} className='me-2' /> <FormattedMessage id="clearFilter" />
                                </button>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className='page-wrapper'>
                <div className='dashboard-body'>
                    <Row className="billing-title-no-data">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AiOutlineNumber size={21} />
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AiOutlineFieldNumber className='me-1' size={24} /> Wise ID
                                        </div>
                                    </th>
                                    <th className='text-center' scope="col">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <BsReceiptCutoff size={16} className="me-1" /> <FormattedMessage id="receipt" />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {wiseTransfers && wiseTransfers.results.length > 0 && wiseTransfers.results.map((transfer) => (
                                    <tr key={transfer.id}>
                                        <td className='text-center'>{transfer.id}</td>
                                        <td className='text-center'>{transfer.wise_id}</td>
                                        <td className='text-center'>
                                            {transfer.receipt_url ? (
                                                <a target="_blank" rel="noreferrer" href={transfer.receipt_url}>
                                                    <button className="dashboard-menu-button" style={{ minWidth: '160px' }}>
                                                        <FormattedMessage id="viewReceipt" />
                                                    </button>
                                                </a>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Row>
                    {pages.length > 1 && (
                        <PagePagination
                            pages={pages}
                            activePage={activePage}
                            handlePage={handlePage}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

export default BillingPayouts