import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from 'react-router-dom'
import { FaArrowLeftLong } from "react-icons/fa6";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Cookies from 'universal-cookie';
import { Form } from 'react-bootstrap'

import { useLoginMutation, useGetTenantsListMutation, useGetUserMutation } from '../../../../../api/authAPI';
import useHandleCustomRtkQueryError from '../../../../../hooks/useHandleCustomRtkQueryError';
import { selectAccessToken } from '../../../../../redux/reducers/auth/authSlice';
import { useAppSelector } from '../../../../../redux/hooks';
import { useLoginValidationSchema } from './validator';

import Loader from '../../../../components/common/Loader/Loader';
import TenantsModal from '../TenantsModal/TenantsModal';

import SignIn from '../../../../assets/images/sing-in.png'

import './Login.css'

export type TLoginForm = {
    email: string,
    password: string,
}

const Login = () => {
    const [showModal, setShowModal] = useState(false)
    const accessToken = useAppSelector(selectAccessToken)

    const intl = useIntl()
    const navigate = useNavigate()

    const loginValidationSchema = useLoginValidationSchema()

    const [getTenants, { data: tenantsData }] = useGetTenantsListMutation()
    const [login, { data, isLoading, isSuccess }] = useLoginMutation()
    const [getUser] = useGetUserMutation()

    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()

    const { register, handleSubmit, formState: { errors } } = useForm<TLoginForm>({
        resolver: yupResolver(loginValidationSchema),
    });

    const onSubmit = (data: TLoginForm) => {
        login(data).then((response) => {
            if ('error' in response) {
                handleCustomError(response.error)
            } else {
                setCustomError(null)
            }
        })
    }

    useEffect(() => {
        const cookies = new Cookies()
        const invitationLink = cookies.get('invitationLink')

        if (invitationLink && accessToken) {
            navigate(`/invitations/accept_as_existing/${invitationLink}`)
        } else if (isSuccess && data && data.user.is_staff === true) {
            getUser()
        } else if (isSuccess && data && data.user.is_staff === false) {
            getTenants(data.access_token)
            setShowModal(true)
        }
    }, [data, isSuccess, getTenants, getUser, navigate, accessToken])

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="login-wrapper">
            <Form onSubmit={handleSubmit(onSubmit)} className="login__form">
                <Link to="/">
                    <div className='login__back'>
                        <FaArrowLeftLong size={20} color="#FFFFFF" />
                    </div>
                </Link>
                <div className='d-flex flex-column'>
                    <h3 className="login-title">
                        <FormattedMessage id="logInToYourAccount" />
                    </h3>
                    <div className="register-first-step__have-account">
                        <FormattedMessage id="firstTimeHere" />
                        <Link to='/register'>
                            &nbsp;<FormattedMessage id="signUp" />
                        </Link>
                    </div>
                </div>
                <div className="login-wrap d-flex justify-content-center flex-column gap-3">
                    <Form.Group>
                        <Form.Label className='auth-form-label'>
                            <FormattedMessage id="email" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({ id: 'email' })}`}
                            className="auth-form-input"
                            type="text"
                            {...register("email")}
                        />
                        {errors.email && <p className="mb-0 form-field-error">{errors.email.message}</p>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className='auth-form-label'>
                            <FormattedMessage id="password" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({ id: 'password' })}`}
                            className="auth-form-input"
                            type="password"
                            {...register("password")}
                        />
                        {errors.password && <p className="mb-0 form-field-error">{errors.password.message}</p>}
                    </Form.Group>
                    {customError && (<p className="mb-0 mt-1 form-field-error">{customError}</p>)}
                    <div className="d-flex flex-column justify-content-between mt-3 gap-3">
                        <button
                            className="auth-form-button dark-button"
                            type="submit"
                        >
                            <FormattedMessage id="logIn" />
                        </button>
                        <p className="login__reset-password">
                            <Link to='/reset-password' className="dark-text">
                                <FormattedMessage id="resetPassword" />
                            </Link>
                        </p>
                    </div>
                </div>
            </Form>
            <div className='register-right-side'>
                <img src={SignIn} alt="" />
            </div>
            <TenantsModal show={showModal} tenants={tenantsData ? tenantsData.results : null} />
        </div >
    )
}

export default Login