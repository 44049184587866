import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { selectUser, updateUser } from "../redux/reducers/auth/authSlice";
import { useUpdateUserMutation } from "../api/authAPI";
import { useAppSelector } from "../redux/hooks";

type TranslationsProviderProps = {
    children: ReactNode;
}

type TranslationsContextType = {
    userLocale: 'en' | 'zh';
    setUserLocale: (locale: 'en' | 'zh') => void;
    updateUserLocale: (locale: 'en' | 'zh') => void;
};

const TranslationsContext = createContext<TranslationsContextType>({
    userLocale: 'en',
    setUserLocale: () => { },
    updateUserLocale: () => { },
});

const useTranslationsContext = () => useContext(TranslationsContext);

const TranslationsProvider: FC<TranslationsProviderProps> = ({ children }) => {
    const [userLocale, setUserLocale] = useState<'en' | 'zh'>('en')
    const user = useAppSelector(selectUser)

    const dispatch = useDispatch()

    const [updateUserData] = useUpdateUserMutation()

    const updateUserLocale = (locale: 'en' | 'zh') => {
        updateUserData({
            language: locale
        })
        dispatch(updateUser({ ...user, language: locale }))
    }

    const value = {
        userLocale,
        setUserLocale,
        updateUserLocale
    }

    useEffect(() => {
        if (user) {
            setUserLocale(user.language)
        } else {
            const navigatorLanguage = navigator.language.split('-')[0]

            if (navigatorLanguage === 'zh') {
                setUserLocale('zh')
            } else {
                setUserLocale('en')
            }
        }
    }, [user])

    return (
        <TranslationsContext.Provider value={value}>
            {children}
        </TranslationsContext.Provider>
    )
};

export { TranslationsProvider, useTranslationsContext };