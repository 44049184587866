import { HiOutlineMinusSm } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";

import "./FAQ.css";

type QuestionType =
	| "fba"
	| "dropshipping"
	| "shopifyStore"
	| "amazonIntegration"
	| "shopifyIntegration"
	| "productImport"
	| "updatePricing"
	| "productVisibility"
	| "bulkEdit"
	| "pricingOptions"
	| "sync"
	| "amazonReviews"
	| "shopifyReviews"
	| "statusCheck"
	| "productSearch"
	| "expandMySalesChannels"
	| "orderOnPlatform"
	| "placeProducts";

const FAQ = () => {
	const [expandedItem, setExpandedItem] = useState<QuestionType | null>(null);

	const location = useLocation();

	const handleExpandItem = (item: QuestionType) => {
		if (expandedItem === item) {
			setExpandedItem(null);
		} else {
			setExpandedItem(item);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (location.hash) {
			const question = location.hash.replace("#", "") as QuestionType;

			setExpandedItem(question);

			setTimeout(() => {
				const element = document.getElementById(question);
				if (element) {
					element.scrollIntoView({
						behavior: "smooth",
						block: "start",
					});
				}
			}, 100);
		} else {
			setExpandedItem(null);
		}
	}, [location.hash]);

	return (
		<div className="min-block-height d-flex flex-column justify-content-center align-items-center bg-white px-4">
			<div data-testid="content" className="faq-content-wrapper">
				<div className="faq-title-block">
					<h5 className="faq-title">
						<FormattedMessage id="frequentlyAskedQuestions" />
					</h5>
					<p className="faq-description">
						<FormattedMessage id="hereAreSomeOfTheMostFrequentlyAskedQuestions" />
					</p>
				</div>
				<div className="faq-questions-list">
					<div
						id="fba"
						className={`faq-question-item ${expandedItem === "fba" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howDoIGetPaidAsAnFBASeller" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="whenTheOrderIsFullyExecutedThenTheMoneyWillBeTransferred" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("fba")}
						>
							<GoPlus
								data-testid="plus-icon"
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								data-testid="minus-icon"
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="dropshipping"
						className={`faq-question-item ${expandedItem === "dropshipping" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howAmIGetChargedToDropshipProductsFromCloudOrder" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="whenYouSetUpYourAccountWithCloudOrder" />
								<FormattedMessage id="changesWillBeAppliedUsingTheCreditCardFromTheDefaultPaymentMethod" />
								<FormattedMessage id="theDefaultPaymentMethodCanBeSetOnTheFinancialSettings" />
								<FormattedMessage id="whenYouClickThePaymentButtonYouWillBeAskedToTypeYourCreditCardData" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="youMayAlsoChooseNotToUseAutomaticPayment" />
								<br />-{" "}
								<FormattedMessage id="goToOrdersSectionInTheMainMenu" />
								<br />-{" "}
								<FormattedMessage id="selectTheTargetOrderYouWishToMakePayment" />
								<br />-{" "}
								<FormattedMessage id="pressTheSelectButtonOnTheLeftAndChoosePayButton" />
								<br />-{" "}
								<FormattedMessage id="afterThatYouWillBeAbleToChooseOneOfYourCards" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("dropshipping")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="shopifyStore"
						className={`faq-question-item ${expandedItem === "shopifyStore" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="ifIAnAnFBASellerAndHaveShopifyStore" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="ifYouAreAnFBASellerAndAShopifyStoreOwnerYouMaySetUpYourCloudOrderAccount" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("shopifyStore")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="amazonIntegration"
						className={`faq-question-item ${expandedItem === "amazonIntegration" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howDoIConnectCloudOrderToMyAmazonStore" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderTenant" />
								<br />-{" "}
								<FormattedMessage id="inTheLowerLeftCornerSelectTheSettings" />
								<br />-{" "}
								<FormattedMessage id="whenYouOpenTheSettingsClickTheButtonAddNewIntegration" />
								<br />-{" "}
								<FormattedMessage id="fromTheListOfProposedIntegrationsSelectTheAmazonFBA" />
								<br />-{" "}
								<FormattedMessage id="followTheInstructionsInTheFirmFillTheFormAndClickConnect" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() =>
								handleExpandItem("amazonIntegration")
							}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="shopifyIntegration"
						className={`faq-question-item ${expandedItem === "shopifyIntegration" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howDoIConnectCloudOrderToMyShopifyStore" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="thereAreTwoWaysYouCanConnectYourShopifyStoreToCloudOrder" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="aInYourShopifyStoreYouCanInstallCloudOrderApplication" />
								<br />
								<a
									target="_blank"
									rel="noreferrer"
									className="faq-link"
									href="https://apps.shopify.com/multi-channel-drop-shipping"
								>
									https://apps.shopify.com/multi-channel-drop-shipping
								</a>
								<br />
								<p></p>
								<br />
								<FormattedMessage id="bYouCanAlsoConnectYourShopifyStoreByAddingAShopifyIntegration" />
								<br />-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderAccount" />
								<br />-{" "}
								<FormattedMessage id="inTheLowerLeftCornerSelectTheSettings" />
								<br />-{" "}
								<FormattedMessage id="onTheFollowingPageClickTheAddNewIntegrationButton" />
								<br />-{" "}
								<FormattedMessage id="fromTheListOfProposedIntegrationsSelectTheShopify" />
								<br />-{" "}
								<FormattedMessage id="fillInYourShopifyStoreNameAndClickConnect" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() =>
								handleExpandItem("shopifyIntegration")
							}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="productImport"
						className={`faq-question-item ${expandedItem === "productImport" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howDoIImportProductsFromMyAmazonStoreToCloudOrder" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderTenant" />
								<br />-{" "}
								<FormattedMessage id="inTheLowerLeftCornerSelectTheSettings" />
								<br />-{" "}
								<FormattedMessage id="onTheFollowingPageClickSelectOnTheRightSideOfTheRequiredIntegration" />
								<br />-{" "}
								<FormattedMessage id="fromTheListOfProposedActionsSelectTheImportAndConfirmTheAction" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("productImport")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="updatePricing"
						className={`faq-question-item ${expandedItem === "updatePricing" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howDoIChangeTheProductCostPricingForMyProductsListedOnCloudOrder" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="aToManuallyChangeTheCostForASingleProduct" />
								<br />-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderAccount" />
								<br />-{" "}
								<FormattedMessage id="inTheLeftMenuSelectTheInventory" />
								<br />-{" "}
								<FormattedMessage id="onTheFollowingPageClickSelectOnTheRightSideOfTheProduct" />
								<br />-{" "}
								<FormattedMessage id="fromTheListOfProposedActionsSelectTheChangeCost" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="bToChangeThePricingForBulkProductList" />
								<br />-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderAccount" />
								<br />-{" "}
								<FormattedMessage id="inTheLeftMenuSelectTheInventory" />
								<br />-{" "}
								<FormattedMessage id="onTheFollowingPageClickOnCheckboxOnTheLeftSideOfRequiredProductRows" />
								<br />-{" "}
								<FormattedMessage id="onceYouSelectProductsClickTheExportButtonOnTheTopRightCorner" />
								<br />-{" "}
								<FormattedMessage id="makeYourChangesToCSVFileDownloadedYourDesktop" />
								<br />-{" "}
								<FormattedMessage id="saveTheChangesPleaseMakeSureYourFileIsSavedAsCSV" />
								<br />-{" "}
								<FormattedMessage id="importTheUpdatedFileByClickingOnTheImportButton" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("updatePricing")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="productVisibility"
						className={`faq-question-item ${expandedItem === "productVisibility" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="whyAreNotMyProductsShowingUpInCloudOrderCatalog" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="byDefaultAllProductsYouImportedFromAmazonArePrivateToYourOwnAccount" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="requestToMakeSingleProductIntoCloudOrderPublicCatalog" />
								<br />-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderAccount" />
								<br />-{" "}
								<FormattedMessage id="inTheLeftMenuSelectTheInventory" />
								<br />-{" "}
								<FormattedMessage id="onTheFollowingPageClickSelectOnTheRightSideOfTheRequiredProduct" />
								<br />-{" "}
								<FormattedMessage id="fromTheListOfProposedActionsSelectTheMakePublicAndConfirm" />
								<br />-{" "}
								<FormattedMessage id="allow24HoursForCloudOrderAdminToReviewAndApproveTheRequest" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="requestToMakeMultipleProductsPublic" />
								<br />-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderTenant" />
								<br />-{" "}
								<FormattedMessage id="inTheLeftMenuSelectTheInventory" />
								<br />-{" "}
								<FormattedMessage id="onTheFollowingPageClickOnCheckboxOnTheLeftSideOfRequiredProductRows" />
								<br />-{" "}
								<FormattedMessage id="onceYouSelectProductsClickTheExportButtonOnTheTopRightCorner" />
								<br />-{" "}
								<FormattedMessage id="makeChangesToCSVFileByUpdatingTheProductsToPublic" />
								<br />-{" "}
								<FormattedMessage id="saveTheChangesPleaseMakeSureYourFileIsSavedAsCSV" />
								<br />-{" "}
								<FormattedMessage id="importTheUpdatedFileByClickingOnTheImportButton" />
								<br />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() =>
								handleExpandItem("productVisibility")
							}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="bulkEdit"
						className={`faq-question-item ${expandedItem === "bulkEdit" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="iHaveALotOfSKUsHowDoIBulkEditTheCostPriceOfMyProducts" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderAccount" />
								<br />-{" "}
								<FormattedMessage id="inTheLeftMenuSelectTheInventory" />
								<br />-{" "}
								<FormattedMessage id="onTheFollowingPageClickOnTheCheckboxOnTheLeftSideOfDesiredProductRows" />
								<br />-{" "}
								<FormattedMessage id="onceYouSelectProductsClickTheExportButtonOnTheTopRightCorner" />
								<br />-{" "}
								<FormattedMessage id="makeYourChangesToTheDownloadedCSVFileSaveTheFile" />
								<br />-{" "}
								<FormattedMessage id="importTheUpdatedFileByClickingOnTheImportButton" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("bulkEdit")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="pricingOptions"
						className={`faq-question-item ${expandedItem === "pricingOptions" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="whatAreTheDifferentShippingTiersAndShippingRates" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="platformsSuchAsShopifyByDefaultUseThreeDifferentDeliveryMethods" />
								<br />
								- <FormattedMessage id="standard" />
								<br />
								- <FormattedMessage id="economy" />
								<br />
								- <FormattedMessage id="freeShipping" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="onCloudOrderAllTheseMethodsByDefaultAreMappedToStandardDelivery" />
								<p>&nbsp;</p>
								<FormattedMessage id="cloudOrderUsesStandardDeliveryMethodByDefaultWhichMapsToAmazonFBAMethod" />
								<p>&nbsp;</p>
								<FormattedMessage id="theDeliveryFeesAreCalculatedByAmazonFulfillmentServiceAndPassedOnToTheOrderAsTheStandard" />
								<p>&nbsp;</p>
								<FormattedMessage id="inAdditionShopifyStoreOwnersCanAlsoAddTwoMoreDeliveryMethods" />
								<FormattedMessage id="deliveryFeeWillBeUpdatedAfterOrderIsFulfilledByAmazon" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("pricingOptions")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="sync"
						className={`faq-question-item ${expandedItem === "sync" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howOftenAreMyProductsSyncedFromAmazon" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="yourProductsWillBeSyncedAutomaticallyOncePerDay" />
								<br />
								<FormattedMessage id="ifYouRequireAnUpdateOfExistingProductGoTo" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="integrations" /> -&gt;{" "}
								<FormattedMessage id="select" /> -&gt;{" "}
								<FormattedMessage id="importAndUpdateProducts" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("sync")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="amazonReviews"
						className={`faq-question-item ${expandedItem === "amazonReviews" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howAreReviewsImportedFromAmazonToCloudOrder" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="cloudOrderAutomaticallyImportsProductReviewsEveryWeek" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="toInitiateImportProcedureManually" />
								<br />-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderAccount" />
								<br />-{" "}
								<FormattedMessage id="inTheLowerLeftCornerSelectTheSettings" />
								<br />-{" "}
								<FormattedMessage id="onTheFollowingPageClickSelectOnTheRightSideOfTheRequiredIntegration" />
								<br />-{" "}
								<FormattedMessage id="fromTheListOfProposedActionSelectImportProductReviews" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="theProcessOfImportReviewsWillTakeSomeTime" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("amazonReviews")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="shopifyReviews"
						className={`faq-question-item ${expandedItem === "shopifyReviews" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howToExportReviewsFromCloudOrderToShopifyStore" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<br />-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderAccount" />
								<br />-{" "}
								<FormattedMessage id="inTheLeftMenuSelectTheCatalog" />
								<br />-{" "}
								<FormattedMessage id="selectTheProductYouNeedToExportReviewsFor" />
								<br />-{" "}
								<FormattedMessage id="clickTheActionButtonOnTheTopRightCornerOfThePage" />
								<br />-{" "}
								<FormattedMessage id="fromTheListOfProposedActionsSelectTheExportToStore" />
								<br />-{" "}
								<FormattedMessage id="clickOnTheCheckboxOnTheLeftSideOfOverrideExistingProducts" />
								<br />
								<p>&nbsp;</p>
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("shopifyReviews")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="statusCheck"
						className={`faq-question-item ${expandedItem === "statusCheck" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howDoICheckTheStatusOfMyProductsImportsFromAmazon" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="initialProductImportTakesSomeTimeDependingOnTheNumber" />
								<br />
								<FormattedMessage id="pleaseAllow24HoursForTheProcessToFinish" />
								<br />
								<FormattedMessage id="youMayAlsoRequestInformationFromCloudOrderSupportAt" />
								<br />
								<a
									target="_blank"
									rel="noreferrer"
									className="faq-link"
									href="mailto:info@cloudorder.us"
								>
									info@cloudorder.us
								</a>
								<br />
								<FormattedMessage id="cloudOrderWillReplyWithRelatedInformation" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("statusCheck")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						id="productSearch"
						className={`faq-question-item ${expandedItem === "productSearch" ? "expanded" : ""}`}
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howDoIFindASpecificCategoryOfProductsToDropshipForMyShopify" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="allProductsCanBeFilteredByCategorySearchByNameOrSearchBySKU" />
								<br />-{" "}
								<FormattedMessage id="loginIntoYourCloudOrderAccount" />
								<br />-{" "}
								<FormattedMessage id="inTheLeftMenuSelectTheCatalog" />
								<br />-{" "}
								<FormattedMessage id="onTheFollowingPageSelectTheRequiredCategoryByClickingTheCategoryButton" />
								<br />
								<p>&nbsp;</p>
								<FormattedMessage id="ifYouDoNotHaveACloudOrderAccountYouMayUseThePublicCatalogToSearchForProducts" />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("productSearch")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						className={`faq-question-item  ${
							expandedItem === "expandMySalesChannels"
								? "expanded"
								: ""
						}`}
						id="expandMySalesChannels"
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="asABrandOwnerOrSupplierHowDoIUseCloudOrderToExpandMySalesChannels" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="cloudOrderProvidesAPowerfulPlatformToGrowYourReach" />
								<br />
								<FormattedMessage id="here’sHowItWorks" />
								<br />
								<br />
								<FormattedMessage id="currentSalesChannelsAvailableOnCloudOrder" />
								<br />
								<br />-{" "}
								<FormattedMessage id="shopifyStoresAnyProductYouListOn" />
								<br />-{" "}
								<FormattedMessage id="tikTokShopsYourListedProducts" />
								<br />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() =>
								handleExpandItem("expandMySalesChannels")
							}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						className={`faq-question-item  ${
							expandedItem === "orderOnPlatform" ? "expanded" : ""
						}`}
						id="orderOnPlatform"
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="asABrandOwnerOrSupplierHowDoIFulfillTheOrdersReceivedOnThePlatform" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="cloudOrderPartnersWithFulfillmentServicesLikeKYT" />
								<br />
								<FormattedMessage id="onceLinkedYourOrdersWillBeFulfilledAutomatically" />
								<br />
								<FormattedMessage id="thisIntegrationEnsures" />
								<br />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("orderOnPlatform")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
					<div
						className={`faq-question-item  ${
							expandedItem === "placeProducts" ? "expanded" : ""
						}`}
						id="placeProducts"
					>
						<div className="faq-question-circle-wrapper">
							<div className="faq-question-circle"></div>
						</div>
						<div className="faq-question-content">
							<h5 className="faq-question-title">
								<FormattedMessage id="howDoIListMyProductsOnCloudOrder" />
							</h5>
							<p className="faq-question-description">
								<div className="faq-question-description-line"></div>
								<FormattedMessage id="listingYourProducts" />
								<br />
								<FormattedMessage id="youHaveTwoOptions" />
								<br />
								<br />
								1. <FormattedMessage id="manualBulkUpload" />
								<br />
								<br />
								2.{" "}
								<FormattedMessage id="amazonSellerCentralIntegration" />
								<br />
							</p>
						</div>
						<div
							className="faq-question-icon"
							onClick={() => handleExpandItem("placeProducts")}
						>
							<GoPlus
								className="faq-question-plus-icon"
								size={35}
								color="#000000"
							/>
							<HiOutlineMinusSm
								className="faq-question-minus-icon"
								size={35}
								color="#000000"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default FAQ;
