// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-task__switch-field {
	display: flex;
	align-items: center;
	gap: 24px;
}

.create-task__switch-field label {
	margin-bottom: 0;
	color: #191919;
	font-weight: 500;
}

.create-task__switches-wrapper {
	display: flex;
	align-items: center;
	gap: 24px;
	margin-top: 12px;
	justify-content: space-between;
}

.create-task__inputs-list-wrapper {
	display: flex;
	align-items: center;
	gap: 16px;
	margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modals/CreateTask/CreateTask.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;AACV;;AAEA;CACC,gBAAgB;CAChB,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;CACT,gBAAgB;CAChB,8BAA8B;AAC/B;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;CACT,gBAAgB;AACjB","sourcesContent":[".create-task__switch-field {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 24px;\n}\n\n.create-task__switch-field label {\n\tmargin-bottom: 0;\n\tcolor: #191919;\n\tfont-weight: 500;\n}\n\n.create-task__switches-wrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 24px;\n\tmargin-top: 12px;\n\tjustify-content: space-between;\n}\n\n.create-task__inputs-list-wrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 16px;\n\tmargin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
