import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "./apiConfig";

import { TGraphsData, TPieObject, TRetailStatsData } from "./api.types";

export const statsApi = createApi({
	reducerPath: "statsApi",
	baseQuery: baseQueryWithReAuth,
	tagTypes: [],
	endpoints: (builder) => ({
		getGraphsData: builder.query<
			TGraphsData,
			{
				type: string;
				filterDate: { start_date: string; end_date: string };
			}
		>({
			query: ({ type, filterDate }) => {
				return {
					url: `orders/graph/?type=${type}${filterDate.start_date ? `&start_date=${filterDate.start_date}&end_date=${filterDate.end_date}` : ""}`,
					method: "GET",
				};
			},
		}),
		getStatsData: builder.query<
			TRetailStatsData,
			{
				type: string;
				filterDate: { start_date: string; end_date: string };
			}
		>({
			query: ({ type, filterDate }) => {
				return {
					url: `orders/stats/?type=${type}${filterDate.start_date ? `&start_date=${filterDate.start_date}&end_date=${filterDate.end_date}` : ""}`,
					method: "GET",
				};
			},
		}),
		getPieData: builder.query<TPieObject, void>({
			query: () => {
				return {
					url: `orders/pie/`,
					method: "GET",
				};
			},
		}),
	}),
});

export const {
	useGetGraphsDataQuery,
	useGetStatsDataQuery,
	useGetPieDataQuery,
} = statsApi;
