import { FormattedMessage } from "react-intl";
import { FaSyncAlt } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { FC } from "react";

type ProductDetailsStatusBlockProps = {
    status: string;
};

const ProductDetailsStatusBlock: FC<ProductDetailsStatusBlockProps> = ({
    status,
}) => {
    return (
        <div
            className={`product-details-status-block ${
                status === "pending_synchronization"
                    ? "pending-synchronization"
                    : ""
            }`}
        >
            {status === "integration_missing" ? (
                <>
                    <MdError size={50} color="red" />
                    <div className="product-details-status-block-text">
                        <span>
                            <FormattedMessage id="thisProductVariantDoesNotHaveAnIntegrationToMakeProductAvailable" />
                        </span>
                        <span>
                            - <FormattedMessage id="goToMyProductTab" />
                        </span>
                        <span>
                            -{" "}
                            <FormattedMessage id="selectTheProductsYouWantToAddTheIntegrationTo" />
                        </span>
                        <span>
                            -{" "}
                            <FormattedMessage id="clickActionButtonAndSelectAddIntegration" />
                        </span>
                    </div>
                </>
            ) : status === "synchronization_failed" ? (
                <>
                    <MdError size={50} color="red" />
                    <div className="product-details-status-block-text">
                        <span>
                            <FormattedMessage id="synchronizationOfThisProductFailed" />
                        </span>
                        <span>
                            -{" "}
                            <FormattedMessage id="reviewTheProductInformationAndTryToReUploadAgain" />
                        </span>
                    </div>
                </>
            ) : status === "pending_synchronization" ? (
                <>
                    <FaSyncAlt size={50} color="#4e61f4" />
                    <div className="product-details-status-block-text">
                        <span>
                            <FormattedMessage id="weAreCurrentlySynchronizingThisProduct" />
                        </span>
                    </div>
                </>
            ) : status === "failed" ? (
                <>
                    <MdError size={50} color="red" />
                    <div className="product-details-status-block-text">
                        <span>
                            <FormattedMessage id="thisProductFailedPleaseReviewInformationOrTryToReUpload" />
                        </span>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default ProductDetailsStatusBlock;
