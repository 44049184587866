import { FormattedMessage, useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { FC, useEffect } from 'react'

import useHandleCustomRtkQueryError from '../../../hooks/useHandleCustomRtkQueryError'
import { useAddAffiliateValidationSchema } from './useAddAffiliateValidationSchema'
import { useCreateAffiliateCodeMutation } from '../../../api/affiliateAPI'
import { TAffiliateCode } from '../../../api/api.types'

export type TCreateAffiliateSubmitData = {
    first_name: string,
    last_name: string,
    email: string,
    community_name: string,
    code: string,
    revenue_per_order: string,
    benefits_discount_period: string,
    benefits_discount: string,
}

type TAffiliateCodeProps = {
    show: boolean,
    onHide: () => void,
    affiliate: null | TAffiliateCode,
}

const AffiliateCode: FC<TAffiliateCodeProps> = ({ show, onHide, affiliate }) => {
    const intl = useIntl()
    const addAffiliateValidationSchema = useAddAffiliateValidationSchema()

    const [customError, setCustomError, handleCustomError] = useHandleCustomRtkQueryError()

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<TCreateAffiliateSubmitData>({
        resolver: yupResolver(addAffiliateValidationSchema),
    });

    const [createAffiliateCode] = useCreateAffiliateCodeMutation()

    const onSubmit = (data: TCreateAffiliateSubmitData) => {
        createAffiliateCode(data).then((res) => {
            if ("error" in res) {
                handleCustomError(res.error)
            } else {
                toast.success(intl.formatMessage({ id: 'theAffiliateCodeHasBeenAddedSuccessfully' }))
                setCustomError('')
                onHide()
            }
        })
    }

    useEffect(() => {
        if (customError) {
            toast.error(customError)
        }
    }, [customError])

    useEffect(() => {
        if (affiliate) {
            setValue('first_name', affiliate.first_name)
            setValue('last_name', affiliate.last_name)
            setValue('email', affiliate.email)
            setValue('community_name', affiliate.community_name)
        }
    }, [affiliate, setValue])

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className='mb-2'>
                        <Form.Label className='affiliate-header-title mb-1'>
                            <FormattedMessage id="name" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({ id: 'firstName' })}`}
                            className="form-input-field"
                            type="text"
                            {...register("first_name")}
                        />
                        {errors.first_name && <p className="mb-0 form-field-error">{errors.first_name.message}</p>}
                        <Form.Control
                            placeholder={`${intl.formatMessage({ id: 'lastName' })}`}
                            className="form-input-field mt-2"
                            type="text"
                            {...register("last_name")}
                        />
                        {errors.last_name && <p className="mb-0 form-field-error">{errors.last_name.message}</p>}
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label className='affiliate-header-title mb-1'>
                            <FormattedMessage id="email" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({ id: 'email' })}`}
                            className="form-input-field"
                            type="text"
                            {...register("email")}
                        />
                        {errors.email && <p className="mb-0 form-field-error">{errors.email.message}</p>}
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label className='affiliate-header-title mb-1'>
                            <FormattedMessage id="communityName" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({ id: 'communityName' })}`}
                            className="form-input-field"
                            type="text"
                            {...register("community_name")}
                        />
                        {errors.community_name && <p className="mb-0 form-field-error">{errors.community_name.message}</p>}
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label className='affiliate-header-title mb-1'>
                            <FormattedMessage id="codeName" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({ id: 'codeName' })}`}
                            className="form-input-field"
                            type="text"
                            {...register("code")}
                        />
                        {errors.code && <p className="mb-0 form-field-error">{errors.code.message}</p>}
                    </Form.Group>
                    <Form.Group className='d-flex flex-column'>
                        <Form.Label className='affiliate-header-title mb-1'>
                            <FormattedMessage id="codeAttributes" />
                        </Form.Label>
                        <Form.Label className='affiliate-input-label mb-1'>
                            <FormattedMessage id="communityOwnerRevenuePerOrder" />
                        </Form.Label>
                        <Form.Select className="form-input-field" {...register("revenue_per_order")}>
                            <option value="0.05">$0.05</option>
                            <option value="0.10">$0.10</option>
                            <option value="0.15">$0.15</option>
                            <option value="0.20">$0.20</option>
                            <option value="0.25">$0.25</option>
                            <option value="0.30">$0.30</option>
                        </Form.Select>
                        {errors.revenue_per_order && <p className="mb-0 form-field-error">{errors.revenue_per_order.message}</p>}
                        <Form.Label className='affiliate-input-label mt-2 mb-1'>
                            <FormattedMessage id="communityMemberBenefitsPeriod" />
                        </Form.Label>
                        <Form.Select className="form-input-field" {...register("benefits_discount_period")}>
                            <option value="1">
                                <FormattedMessage id="countMonths" values={{ count: "1" }} />
                            </option>
                            <option value="2">
                                <FormattedMessage id="countMonths" values={{ count: "2" }} />
                            </option>
                            <option value="3">
                                <FormattedMessage id="countMonths" values={{ count: "3" }} />
                            </option>
                            <option value="4">
                                <FormattedMessage id="countMonths" values={{ count: "4" }} />
                            </option>
                            <option value="5">
                                <FormattedMessage id="countMonths" values={{ count: "5" }} />
                            </option>
                            <option value="6">
                                <FormattedMessage id="countMonths" values={{ count: "6" }} />
                            </option>
                            <option value="12">
                                <FormattedMessage id="countMonths" values={{ count: "12" }} />
                            </option>
                            <option value="FREE_FOREVER">
                                <FormattedMessage id="freeForLife" />
                            </option>
                        </Form.Select>
                        {errors.benefits_discount_period && <p className="mb-0 form-field-error">
                            {errors.benefits_discount_period.message}
                        </p>}
                        <Form.Label className='affiliate-input-label mt-2 mb-1'>
                            <FormattedMessage id="communityMemberBenefitsPeriod" />
                        </Form.Label>
                        <Form.Select className="form-input-field" {...register("benefits_discount")}>
                            <option value="0">
                                <FormattedMessage id="noDiscount" />
                            </option>
                            <option value="10">10%</option>
                            <option value="15">15%</option>
                            <option value="20">20%</option>
                            <option value="25">25%</option>
                            <option value="30">30%</option>
                        </Form.Select>
                        {errors.benefits_discount && <p className="mb-0 form-field-error">
                            {errors.benefits_discount.message}
                        </p>}
                    </Form.Group>
                    <button className="affiliate-button mt-4 w-100" type="submit">
                        <FormattedMessage id="saveAffiliateDetails" />
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AffiliateCode