// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--button-background: linear-gradient(180deg, #00bfeb 0%, #2392cb 100%);
}
`, "",{"version":3,"sources":["webpack://./src/app/assets/styles/_variables.css"],"names":[],"mappings":"AAAA;CACC,sEAAsE;AACvE","sourcesContent":[":root {\n\t--button-background: linear-gradient(180deg, #00bfeb 0%, #2392cb 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
