import { useIntl } from 'react-intl';
import * as yup from 'yup'

export const useUpdateProfileValidationSchema = () => {
    const intl = useIntl();

    return yup.object().shape({
        first_name: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        last_name: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        email: yup
            .string()
            .email(intl.formatMessage({ id: "emailAddressIsInvalid" }))
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
    });
}