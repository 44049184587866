import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { FC } from "react";

import { useGetCsvFileMutation } from "../../../api/productsAPI";

type TExportCsvProps = {
	show: boolean;
	onHide: () => void;
};

const ExportCsv: FC<TExportCsvProps> = ({ show, onHide }) => {
	const intl = useIntl();

	const [exportCsvFile] = useGetCsvFileMutation();

	const handleExportCsvFile = async () => {
		exportCsvFile().then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({ id: "somethingWentWrong" })
					);
				}
			} else {
				downloadCsv(res.data, "products.csv");
				onHide();
			}
		});
	};

	const downloadCsv = (data: string, filename: string) => {
		const blob = new Blob([data], { type: "text/csv" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = url;
		link.download = filename;
		link.click();

		URL.revokeObjectURL(url);
	};

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="exportProducts" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<div className="d-flex flex-column">
					<span className="upload-orders__description">
						<FormattedMessage id="whenSavingAFilePleaseUseACommaDelimiter" />{" "}
						<FormattedMessage id="forMacAndWindowsSaveAsType" />
					</span>
					<div className="d-flex justify-content-end mt-4">
						<button
							className="ice-button w-100"
							onClick={handleExportCsvFile}
						>
							<FormattedMessage id="export" />
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ExportCsv;
