import { FormattedMessage } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { TbFilter } from "react-icons/tb";
import { AiOutlineNumber } from "react-icons/ai";

import useClickOutsideOfBlock from "../../../../../hooks/useClickOutsideOfBlock";
import {
	BILLING_PAYPAL_FILTERS,
	BILLING_PAYPAL_KINDS_TRANSLATIONS,
} from "../../../../../helpers/constants";
import { useGetTransactionsQuery } from "../../../../../api/paymentsAPI";
import usePagination from "../../../../../hooks/usePagination";
import useFilters from "../../../../../hooks/useFilters";

import PagePagination from "../../../../components/common/Pagination/PagePagination";
import FilterBlock from "../../../../components/common/FilterBlock/FilterBlock";
import EmptyState from "../../../../components/common/EmptyState/EmptyState";
import Loading from "../../../../components/common/Loading/Loading";

const BillingPaypalReceipts = () => {
	const [expandFilters, setExpandFilters] = useState(false);
	const filterRef = useRef<HTMLDivElement>(null);

	const { pageSize, handleDefaultFilter, defaultFilter } = useFilters();

	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		pageSize
	);

	const {
		data: paypalReceiptsData,
		isLoading,
		isFetching,
	} = useGetTransactionsQuery({
		activePage,
		pageSize,
		defaultFilter,
	});

	useEffect(() => {
		if (paypalReceiptsData) {
			handlePagesCount(paypalReceiptsData.count);
		}
	}, [paypalReceiptsData, handlePagesCount]);

	useClickOutsideOfBlock({
		blockRef: filterRef,
		showValue: expandFilters,
		setShowValue: setExpandFilters,
	});

	return (
		<div className="billing-content-wrapper">
			<div className="billing_content" data-testid="receipts-pay-table">
				<div className="billing__page-filters">
					<h5 className="billing__page-title">
						<FormattedMessage id="paypalReceipts" />
					</h5>
					<div className="billing__page-filters-actions">
						{paypalReceiptsData?.results &&
							paypalReceiptsData?.results.length > 0 && (
								<PagePagination
									pages={pages}
									activePage={activePage}
									handlePage={handlePage}
								/>
							)}
						<div
							ref={filterRef}
							className="billing__page-filters-wrapper"
						>
							<div
								onClick={() => setExpandFilters(!expandFilters)}
								className={`billing__page-filters-button ${expandFilters ? "expanded" : ""}`}
								data-testid="filter-button-pay"
							>
								<TbFilter
									size={22}
									color={
										expandFilters ? "#FFFFFF" : "#666666"
									}
								/>
							</div>
							{expandFilters && (
								<FilterBlock
									selectedFilters={{
										kind: defaultFilter,
									}}
									filters={BILLING_PAYPAL_FILTERS}
									handlers={{
										kind: handleDefaultFilter,
									}}
									handlePage={handlePage}
									onClose={() => setExpandFilters(false)}
								/>
							)}
						</div>
					</div>
				</div>
				<Loading isLoading={isLoading || isFetching}>
					{paypalReceiptsData &&
					paypalReceiptsData.results.length === 0 ? (
						<EmptyState message="noPaypalReceiptsFound" />
					) : (
						<div className="default-table">
							<table className="table">
								<thead className="thead-light">
									<tr>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<AiOutlineNumber size={21} />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="orderID" />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="datePaid" />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="amount" />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="currency" />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="kind" />
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{paypalReceiptsData &&
										paypalReceiptsData.results.length > 0 &&
										paypalReceiptsData.results.map(
											(receipt) => (
												<tr
													key={receipt.id}
													data-testid="table-paypal-row"
												>
													<td className="text-center">
														{receipt.id}
													</td>
													<td className="text-center">
														{
															receipt.order
																.custom_order_id
														}
													</td>
													<td className="text-center">
														{
															receipt.created_at.split(
																"T"
															)[0]
														}
													</td>
													<td className="text-center">
														{receipt.amount / 100}
													</td>
													<td className="text-center">
														{receipt.currency}
													</td>
													<td className="text-center">
														<FormattedMessage
															id={
																BILLING_PAYPAL_KINDS_TRANSLATIONS[
																	receipt.kind as keyof typeof BILLING_PAYPAL_KINDS_TRANSLATIONS
																] || "payment"
															}
														/>
													</td>
												</tr>
											)
										)}
								</tbody>
							</table>
						</div>
					)}
				</Loading>
			</div>
		</div>
	);
};

export default BillingPaypalReceipts;
