import { useAppDispatch, useAppSelector } from './redux/hooks';
import { IntlProvider } from 'react-intl';
import { useEffect } from 'react';

import { selectAccessToken, selectIsAuth, selectUser, signIn } from './redux/reducers/auth/authSlice';
import { useCheckTenantAccessMutation, useGetUserMutation } from './api/authAPI';
import { translations } from './i18n/config';

import useDetectSubdomainOrPublic from './hooks/useDetectSubdomainOrPublic';
import { useTranslationsContext } from './contexts/useTranslationsContext';

import LendingPage from './app/pages/LendingPage/LendingPage';
import Dashboard from './app/pages/Dashboard/Dashboard';

import 'react-toastify/dist/ReactToastify.css';
import './app/assets/styles/globals.css';

function App() {
  const { userLocale } = useTranslationsContext()
  const [isPublic, subdomain] = useDetectSubdomainOrPublic()

  const accessToken = useAppSelector(selectAccessToken)
  const isAuth = useAppSelector(selectIsAuth)
  const user = useAppSelector(selectUser)

  const [checkTenantAccess, { data: tenantAccessData, isSuccess: isCheckTenantSuccess }] = useCheckTenantAccessMutation()
  const [getUser, { data, isSuccess, isError, isLoading }] = useGetUserMutation()

  const dispatch = useAppDispatch()

  useEffect(() => {
    getUser()
  }, [getUser])

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_PUBLIC_TENANT_URL

    if (!isPublic && isCheckTenantSuccess && tenantAccessData && tenantAccessData.results.some((el) => el.subdomain === subdomain)) {
      dispatch(signIn(data))
    } else if (isCheckTenantSuccess && !isPublic && baseUrl) {
      window.location.href = baseUrl
    }

  }, [tenantAccessData, dispatch, isCheckTenantSuccess, data, isPublic, subdomain])

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_PUBLIC_TENANT_URL

    if (isSuccess && data?.is_staff === true) {
      dispatch(signIn(data))
    } else if (isSuccess && data?.is_staff === false) {
      if (accessToken) {
        checkTenantAccess({ token: accessToken, subdomain })
      } else {
        if (baseUrl) {
          window.location.href = baseUrl
        }
      }
    } else if (isError && !isPublic && baseUrl) {
      window.location.href = baseUrl
    }

  }, [data, isSuccess, dispatch, isError, isPublic, accessToken, subdomain, checkTenantAccess])

  return (
    <div>
      <IntlProvider
        locale={userLocale}
        messages={translations[`${userLocale}`]}
      >
        {isAuth && user ? (
          <Dashboard />
        ) : (
          <LendingPage isLoading={isLoading} />
        )}
      </IntlProvider>
    </div>
  );
}

export default App;
