import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useConfirmAmazonIntegrationMutation } from "../../../../api/integrationsAPI";
import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { useGetTenantDetailsQuery } from "../../../../api/settingsAPI";
import { selectUser } from "../../../../redux/reducers/auth/authSlice";
import { useAppSelector } from "../../../../redux/hooks";

import SettingsUsersFinancialsBlock from "../../../components/layout/SettingsUsersFinancialsBlock/SettingsUsersFinancialsBlock";
import SettingsIntegrationsBlock from "../../../components/layout/SettingsIntegrationsBlock/SettingsIntegrationsBlock";
import SettingsAffiliateBlock from "../../../components/layout/SettingsAffiliateBlock/SettingsAffiliateBlock";
import SettingsCompanyBlock from "../../../components/layout/SettingsCompanyBlock/SettingsCompanyBlock";
import SettingsAccountBlock from "../../../components/layout/SettingsAccountBlock/SettingsAccountBlock";
import SettingsRates from "../../../components/layout/SettingsRates/SettingsRates";
import EditIntegration from "../../../modals/EditIntegration/EditIntegration";
import Loading from "../../../components/common/Loading/Loading";

import "./SettingsUsers.css";

const SettingsUsers = () => {
	const [editIntegration, setEditIntegration] = useState(false);
	const [editId, setEditId] = useState<null | number>(null);
	const [searchParams] = useSearchParams();
	const user = useAppSelector(selectUser);

	const navigate = useNavigate();

	const intl = useIntl();
	const [isPublic, subdomain] = useDetectSubdomainOrPublic();

	const [confirmAmazonIntegration, { isLoading }] =
		useConfirmAmazonIntegrationMutation();

	const { data: tenantDetails } = useGetTenantDetailsQuery(subdomain, {
		skip: isPublic || !subdomain,
	});

	const handleHideEditIntegration = () => {
		setEditId(null);
		setEditIntegration(false);
	};

	const handleEditIntegration = (id: number) => {
		setEditId(id);
		setEditIntegration(true);
	};

	useEffect(() => {
		const spapi_oauth_code = searchParams.get("spapi_oauth_code");
		const state = searchParams.get("state");
		const selling_partner_id = searchParams.get("selling_partner_id");

		if (spapi_oauth_code && state && selling_partner_id) {
			const object = {
				spapi_oauth_code,
				state,
				selling_partner_id,
			};
			confirmAmazonIntegration(object).then((res) => {
				if ("error" in res) {
					const status = (res.error as { status: number }).status;

					if (status !== 403) {
						toast.error(
							intl.formatMessage({
								id: "somethingWentWrongPleaseTryAgainLater",
							})
						);
					}
					navigate("/dashboard/settings-users/");
				} else {
					toast.success(
						intl.formatMessage({ id: "amazonIntegrationAdded" })
					);
					navigate("/dashboard/settings-users/");
					setEditId(res.data.id);
					setEditIntegration(true);
				}
			});
		}
	}, [searchParams, confirmAmazonIntegration, navigate, intl]);

	return (
		<Loading isLoading={isLoading}>
			<div
				data-testid="settings-page"
				className="settings-users__content-wrapper"
			>
				<div className="total-orders__content">
					<div className="total-orders__tab-selector">
						<div className="total-orders__tab-item active-tab">
							<FormattedMessage id="settings" />
						</div>
					</div>
				</div>
				<div className="settings-users__content">
					<div className="settings-users__two-blocks-in-row">
						<SettingsAccountBlock user={user} />
						{!isPublic && (
							<SettingsCompanyBlock
								tenantDetails={tenantDetails}
								subdomain={subdomain}
							/>
						)}
					</div>
					{!isPublic && (
						<>
							<SettingsUsersFinancialsBlock isPublic={isPublic} />
							<SettingsIntegrationsBlock
								isPublic={isPublic}
								handleEditIntegration={handleEditIntegration}
							/>
							<SettingsAffiliateBlock />
							<SettingsRates />
						</>
					)}
					{editIntegration && editId && (
						<EditIntegration
							show={editIntegration}
							onHide={() => handleHideEditIntegration()}
							id={editId}
						/>
					)}
				</div>
			</div>
		</Loading>
	);
};

export default SettingsUsers;
