import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import { BiSearch } from 'react-icons/bi'
import { useEffect } from 'react'

import { useGetPublicProductsQuery } from '../../../../api/productsAPI'
import { useGetCategoriesQuery } from '../../../../api/categoriesAPI'
import usePagination from '../../../../hooks/usePagination'
import useFilters from '../../../../hooks/useFilters'

import PagePagination from '../../../components/common/Pagination/PagePagination'

import './Catalog.css'

const Catalog = () => {
    const intl = useIntl()

    const {
        search,
        handleSearch,
        handleSearchFilter,
        searchFilter,
        onKeyDownSearch,
        pageSize,
        categoryFilter,
        handleCategoryFilter,
        clearFilters
    } = useFilters()
    const [activePage, pages, handlePage, handlePagesCount] = usePagination('page', pageSize)

    const navigate = useNavigate()

    const { data: categories } = useGetCategoriesQuery()
    const { data } = useGetPublicProductsQuery({
        activePage,
        searchFilter,
        pageSize,
        categoryFilter
    })

    const handleViewProduct = (id: number) => {
        navigate(`/catalog/product/${id}`)
    }

    useEffect(() => {
        if (data) {
            handlePagesCount(data.count)
        }
    }, [data, handlePagesCount])

    return (
        <section className='catalog-wrapper'>
            <div className='catalog__header'>
                <h2 className='catalog__title'>
                    <FormattedMessage id="catalog" />
                </h2>
                <div className='catalog__search-wrapper'>
                    <input
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        onKeyDown={(e) => onKeyDownSearch(e, handlePage)}
                        className="catalog__search-input"
                        placeholder={intl.formatMessage({ id: 'search' })}
                    />
                    <BiSearch
                        onClick={() => handleSearchFilter(handlePage)}
                        className='catalog__search-icon'
                        size={20}
                    />
                </div>
            </div>
            <div className="catalog__content-wrapper">
                <div className='catalog__filters'>
                    <div className='catalog__filter-block'>
                        <h6 className='catalog__filter-block-title'>
                            <FormattedMessage id="categories" />
                        </h6>
                        <div className='catalog__filter-line'></div>
                        <div className='catalog__filter-list-items'>
                            {categories && categories.results.map((category) => (
                                <div
                                    onClick={() => handleCategoryFilter(String(category.id), handlePage)}
                                    className={`catalog__filter-item ${categoryFilter === String(category.id) ? 'selected' : ''}`}
                                    key={category.id}
                                >
                                    {category.name}
                                </div>
                            ))}
                        </div>
                    </div>
                    <button onClick={() => clearFilters(handlePage)} className='catalog__clear-filters-button'>
                        <FormattedMessage id="clearFilters" />
                    </button>
                </div>
                <div className='catalog__products-wrapper'>
                    {data && data.results.length > 0 ? (
                        <div className="catalog__products-list">
                            {data.results.map((product) => (
                                <div key={product.id} className="catalog__product-block">
                                    <img
                                        className="catalog__product-image"
                                        src={
                                            product?.variants[0]?.images[0]?.url
                                                ? product?.variants[0]?.images[0]?.url
                                                : product?.variants[0]?.images[0]?.image
                                        }
                                        alt=''
                                    />
                                    <div className="catalog__product-details">
                                        <p className="catalog__product-title">
                                            {product.title}
                                        </p>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='me-1 mb-1'>
                                                <StarRatings
                                                    rating={parseFloat(product.rank ? product.rank.rating : '0')}
                                                    numberOfStars={5}
                                                    starRatedColor="yellow"
                                                    starDimension="20px"
                                                    starSpacing="1px"
                                                />
                                            </div>
                                            <span style={{ marginTop: '3px' }} className="number-rating">
                                                {product.rank ? product.rank.rating : '0'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='catalog__hover-wrapper'>
                                        <button
                                            onClick={() => handleViewProduct(product.id)}
                                            className="catalog__view-button"
                                        >
                                            <FormattedMessage id="viewProduct" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='catalog__empty-products'>
                            <FormattedMessage id="thereAreNoProducts" />
                        </div>
                    )}
                    {pages.length > 1 && (
                        <PagePagination
                            activePage={activePage}
                            pages={pages}
                            handlePage={handlePage}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

export default Catalog