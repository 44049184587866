import { FormattedMessage } from "react-intl";
import { FC, useState } from "react";

import { TGetTenantDetailResponse } from "../../../../redux/reducers/auth/authTypes";

import EditCompanyDetails from "../../../modals/EditCompanyDetails/EditCompanyDetails";

type TSettingsCompanyBlock = {
	tenantDetails: TGetTenantDetailResponse | undefined;
	subdomain: string;
};

const SettingsCompanyBlock: FC<TSettingsCompanyBlock> = ({
	tenantDetails,
	subdomain,
}) => {
	const [editCompanyDetailsModal, setEditCompanyDetailsModal] =
		useState(false);

	return (
		<div className="settings-users__block">
			<h5 className="settings-users__block-title">
				<FormattedMessage id="company" />
			</h5>
			<div className="settings-users__block-body">
				<div className="setting-users__block-info-wrapper">
					<div className="settings-users__block-info-row">
						<FormattedMessage id="nameWithDots" />{" "}
						<span
							data-testid="company-name"
							className="settings-users__block-info-row-value"
						>
							{tenantDetails?.name}
						</span>
					</div>
					<div className="settings-users__block-info-row">
						<FormattedMessage id="addressLine1WithDots" />{" "}
						<span
							data-testid="address-line-1"
							className="settings-users__block-info-row-value"
						>
							{tenantDetails?.address_line_1}
						</span>
					</div>
					<div className="settings-users__block-info-row">
						<FormattedMessage id="addressLine2WithDots" />{" "}
						<span
							data-testid="address-line-2"
							className="settings-users__block-info-row-value"
						>
							{tenantDetails?.address_line_2}
						</span>
					</div>
					<div className="settings-users__block-info-row">
						<FormattedMessage id="phoneWithDots" />{" "}
						<span
							data-testid="company-phone"
							className="settings-users__block-info-row-value"
						>
							{tenantDetails?.phone}
						</span>
					</div>
				</div>
				<div className="d-flex justify-content-end">
					<button
						data-testid="edit-company-details-button"
						onClick={() => setEditCompanyDetailsModal(true)}
						type="button"
						className="ice-button"
					>
						<FormattedMessage id="editCompanyDetails" />
					</button>
				</div>
			</div>
			{editCompanyDetailsModal && (
				<EditCompanyDetails
					subdomain={subdomain}
					tenantDetails={tenantDetails}
					show={editCompanyDetailsModal}
					onHide={() => setEditCompanyDetailsModal(false)}
				/>
			)}
		</div>
	);
};

export default SettingsCompanyBlock;
