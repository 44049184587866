import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import FooterArrow from "../../../assets/images/footer-arrow.svg";
import FooterLogo from "../../../assets/images/footer-logo.png";

import "./Footer.css";

const Footer = () => {
	return (
		<footer className="footer-wrapper">
			<div className="footer">
				<img className="footer__logo" src={FooterLogo} alt="" />
				<div className="footer__links-block">
					<div className="footer__main-links-block">
						<div className="footer__links-column">
							<h6 className="footer__links-column-title">
								<FormattedMessage id="resources" />
							</h6>
							<div className="footer__links-list">
								<Link to="/catalog">
									<span className="footer__link-item">
										<img src={FooterArrow} alt="" />
										<FormattedMessage id="catalog" />
									</span>
								</Link>
								<Link to="/">
									<span className="footer__link-item">
										<img src={FooterArrow} alt="" />
										<FormattedMessage id="home" />
									</span>
								</Link>
								<Link to="/FAQ">
									<span className="footer__link-item">
										<img src={FooterArrow} alt="" />
										<FormattedMessage id="faq" />
									</span>
								</Link>
							</div>
						</div>
						<div className="footer__links-column">
							<h6 className="footer__links-column-title">
								<FormattedMessage id="contacts" />
							</h6>
							<div className="footer__links-list">
								<Link
									to="https://www.facebook.com/cloudorderus"
									target="_blank"
								>
									<span className="footer__link-item">
										<img src={FooterArrow} alt="" />
										Facebook
									</span>
								</Link>
								<Link
									to="https://www.linkedin.com/company/cloudorderus/"
									target="_blank"
								>
									<span className="footer__link-item">
										<img src={FooterArrow} alt="" />
										LinkedIn
									</span>
								</Link>
								<Link to="mailto:info@cloudorder.us">
									<span className="footer__link-item">
										<img src={FooterArrow} alt="" />
										info@cloudorder.us
									</span>
								</Link>
							</div>
						</div>
					</div>
					<div className="footer__privacy-block">
						<span className="footer__project-name">
							© CloudOrder.us <FormattedMessage id="system" />.
						</span>
						<Link to="/privacy-policy">
							<span className="footer__privacy-link">
								<FormattedMessage id="privacyPolicy" />
							</span>
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
};
export default Footer;
