import { FormattedMessage, useIntl } from "react-intl";
import { FC, useEffect, useState } from "react";
import { FaLayerGroup } from "react-icons/fa6";
import { Modal } from "react-bootstrap";

import { useAssignIntegrationMutation } from "../../../api/productsAPI";
import { useGetIntegrationsQuery } from "../../../api/integrationsAPI";
import { TGetIntegrationsResults } from "../../../api/api.types";
import { toast } from "react-toastify";

type AttachIntegrationModalProps = {
    show: boolean;
    onHide: () => void;
    selectedProducts: number[];
    setSelectedProducts: (value: number[]) => void;
};

const AttachIntegrationModal: FC<AttachIntegrationModalProps> = ({
    show,
    onHide,
    selectedProducts,
    setSelectedProducts,
}) => {
    const [integrationsList, setIntegrationsList] = useState<
        TGetIntegrationsResults[]
    >([]);

    const intl = useIntl();

    const { data } = useGetIntegrationsQuery();

    const [attachIntegration] = useAssignIntegrationMutation();

    const handleAttachIntegration = (integrationId: number) => {
        attachIntegration({
            ids: selectedProducts,
            integration_id: integrationId,
        }).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({
                            id: "somethingWentWrongDuringIntegrationAttach",
                        })
                    );
                }
            } else {
                onHide();
                setSelectedProducts([]);
                toast.success(
                    intl.formatMessage({
                        id: "integrationAttachedSuccessfully",
                    })
                );
            }
        });
    };

    useEffect(() => {
        if (data) {
            const filteredIntegrations = data.results.filter(
                (integration) =>
                    integration.partner === "Off Amazon Integration"
            );

            setIntegrationsList(filteredIntegrations);
        }
    }, [data]);

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="attachIntegrationCamelCase" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                {integrationsList && integrationsList.length > 0 ? (
                    <div className="d-flex flex-column gap-2 w-100">
                        {integrationsList.map((integration) => (
                            <div
                                className="d-flex justify-content-between align-items-center w-100"
                                key={integration.id}
                            >
                                <div className="d-flex align-items-center gap-2">
                                    <FaLayerGroup size={24} />
                                    <span className="bulk-origination-integration">
                                        {integration.name} (
                                        {integration.partner})
                                    </span>
                                </div>
                                <button
                                    onClick={() =>
                                        handleAttachIntegration(integration.id)
                                    }
                                    className="bulk-upload-choose-files"
                                >
                                    <FormattedMessage id="attach" />
                                </button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center py-4">
                        <FormattedMessage id="youHaveNoOffAmazonIntegrationsPleaseAddOneToAttachProducts" />
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AttachIntegrationModal;
