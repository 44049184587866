import { FiSearch } from "react-icons/fi";
import { useIntl } from "react-intl";
import { FC } from "react";

import "./Search.css";

type SearchProps = {
	search: string;
	handleSearchFilter: (handlePage: (page: string) => void) => void;
	handleSearch: (search: string) => void;
	onKeyDownSearch: (e: any, handlePage: (page: string) => void) => void;
	handlePage: (page: string) => void;
};

const Search: FC<SearchProps> = ({
	search,
	handleSearchFilter,
	handleSearch,
	onKeyDownSearch,
	handlePage,
}) => {
	const intl = useIntl();

	return (
		<div className="search__wrapper">
			<button
				onClick={() => handleSearchFilter(handlePage)}
				className="search__input-button"
			>
				<FiSearch size={24} strokeWidth={1.2} />
			</button>
			<input
				data-testid="search"
				value={search}
				onChange={(e) => handleSearch(e.target.value)}
				onKeyDown={(e) => onKeyDownSearch(e, handlePage)}
				className="search__input"
				placeholder={`${intl.formatMessage({
					id: "search",
				})}`}
			/>
		</div>
	);
};

export default Search;
