// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tenant-block {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.tenant-domain {
	font-weight: 500;
}

.tenant-visit-button {
	background-color: var(--button) !important;
}

.tenant-visit-button:hover {
	background-color: var(--button-hover) !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/LendingPage/Auth/TenantsModal/TenantsModal.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,0CAA0C;AAC3C;;AAEA;CACC,gDAAgD;AACjD","sourcesContent":[".tenant-block {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n\tmargin-bottom: 10px;\n}\n\n.tenant-domain {\n\tfont-weight: 500;\n}\n\n.tenant-visit-button {\n\tbackground-color: var(--button) !important;\n}\n\n.tenant-visit-button:hover {\n\tbackground-color: var(--button-hover) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
