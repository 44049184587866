import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

const usePagination = (
	param: string,
	itemsPerPage: number | string
): [string, string[], (page: string) => void, (count: number) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [activePage, setActivePage] = useState<string>(
		searchParams.get(param) ? (searchParams.get(param) as string) : "1"
	);
	const [pages, setPages] = useState<string[]>([]);

	const handlePage = useCallback(
		(page: string) => {
			setSearchParams((prev) => {
				prev.set(`${[param]}`, page);
				return prev;
			});
			setActivePage(page);
		},
		[setSearchParams, param]
	);

	const handlePagesCount = useCallback(
		(count: number) => {
			if (count) {
				const pages = Math.ceil(count / Number(itemsPerPage));
				let arrayPages = [];

				for (let i = 1; i <= pages; i++) {
					arrayPages.push(String(i));
				}
				setPages(arrayPages);
			} else {
				setPages([]);
			}
		},
		[itemsPerPage]
	);

	return [activePage, pages, handlePage, handlePagesCount];
};

export default usePagination;
