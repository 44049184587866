import { FormattedMessage, useIntl } from "react-intl";
import { FC, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IoWarning } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { useUploadProductImagesMutation } from "../../../../api/productsAPI";
import { TProductVariants } from "../../../../api/api.types";

type ProductDetailsImagesProps = {
	data: TProductVariants | undefined;
	isLoadingDetails: boolean;
};

const ProductDetailsImages: FC<ProductDetailsImagesProps> = ({
	data,
	isLoadingDetails,
}) => {
	const [attachedImages, setAttachedImages] = useState<File[] | null>(null);

	const inputRef = useRef<HTMLInputElement>(null);

	const intl = useIntl();
	const { id } = useParams();
	const [isPublic] = useDetectSubdomainOrPublic();

	const [uploadProductImages, { isLoading }] =
		useUploadProductImagesMutation();

	const handleRemoveImage = (index: number) => {
		if (attachedImages) {
			setAttachedImages(attachedImages.filter((_, i) => i !== index));
		}
	};

	const handleSaveImages = () => {
		if (!attachedImages) return;

		const formData = new FormData();

		attachedImages.forEach((image) => {
			formData.append("images", image);
		});

		uploadProductImages({
			id: Number(id),
			data: formData,
		}).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({ id: "somethingWentWrong" })
					);
				}
			} else {
				setAttachedImages(null);
			}
		});
	};

	const handleUploadImages = (files: FileList | null) => {
		const filesList = Array.from(files || []);

		if (filesList.length === 0) return;

		setAttachedImages(filesList);

		if (inputRef.current) {
			inputRef.current.value = "";
		}
	};

	return (
		<div className="product-details__images-list">
			{data?.images && data?.images.length > 0 ? (
				<>
					{data?.images.map((el) => (
						<img
							key={el.id}
							className="d-block"
							src={el.url ? el.url : el.image}
							style={{
								aspectRatio: "1 / 1",
								objectFit: "contain",
							}}
							alt=""
						/>
					))}
				</>
			) : (
				<>
					{!isPublic ? (
						<div className="no-product-images-block">
							{attachedImages && attachedImages.length > 0 ? (
								<>
									<div className="no-product-images-attached-list">
										{attachedImages.map((image, idx) => (
											<div
												className="bulk-uploaded-product-attached-image-block"
												key={idx}
											>
												<img
													src={URL.createObjectURL(
														image
													)}
													alt=""
												/>
												<div
													onClick={() =>
														handleRemoveImage(idx)
													}
													className="bulk-uploaded-product-attached-image-remove"
												>
													<MdDelete
														size={26}
														color="#FFFFFF"
													/>
												</div>
											</div>
										))}
									</div>
									<button
										className="ice-button"
										onClick={handleSaveImages}
										disabled={isLoading}
									>
										<FormattedMessage id="saveImages" />
									</button>
								</>
							) : (!attachedImages ||
									attachedImages?.length === 0) &&
							  !isLoadingDetails ? (
								<>
									<IoWarning size={80} color="#ffcc00" />
									<span className="no-product-images-text">
										<FormattedMessage id="thisProductHasNoUploadedImagesPleaseUpload" />
									</span>
									<button
										className="ice-button"
										onClick={() =>
											inputRef.current?.click()
										}
									>
										<FormattedMessage id="uploadImages" />
									</button>
									<input
										onChange={(e) =>
											handleUploadImages(e.target.files)
										}
										className="d-none"
										accept="image/*"
										ref={inputRef}
										type="file"
										multiple
									/>
								</>
							) : null}
						</div>
					) : (
						<div>
							<FormattedMessage id="noImages" />
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default ProductDetailsImages;
