import { selectVerifyEmail } from "../../../../../redux/reducers/auth/authSlice"
import { useAppSelector } from "../../../../../redux/hooks"

import RegisterFirstStep from "./RegisterFirstStep/RegisterFirstStep"
import RegisterPending from "./RegisterPending/RegisterPending"

const Register = () => {
    const verifyEmail = useAppSelector(selectVerifyEmail)

    return (
        <div className="register-wrapper">
            {verifyEmail ? (
                <RegisterPending />
            ) : (
                <RegisterFirstStep />
            )}
        </div>
    )
}

export default Register