import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { FC } from "react";

import StripeForm from "../../components/common/StripeForm/StripeForm";

import "./StripePayment.css";

type TStripePaymentProps = {
    show: boolean;
    onHide: () => void;
    clientSecret: string;
};

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        : ""
);

const StripePayment: FC<TStripePaymentProps> = ({
    show,
    onHide,
    clientSecret,
}) => {
    const appearance: { theme: "stripe" | "night" | "flat" } = {
        theme: "stripe",
    };

    const options: StripeElementsOptions = {
        clientSecret,
        appearance,
    };

    return (
        <Modal
            className="stripe-payment-modal"
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="requiresConfirmation" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center align-items-center w-100 pt-0">
                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <StripeForm />
                    </Elements>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default StripePayment;
