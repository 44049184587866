import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { FC, useEffect, useState } from "react";
import "react-bootstrap/dist/react-bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

import AcceptInvitedExistingUser from "./Auth/AcceptInvitedExistingUser/AcceptInvitedExistingUser";
import SetNewPassword from "./Auth/ResetPassword/SetNewPassword/SetNewPassword";
import AcceptInvitedUser from "./Auth/AcceptInvitedUser/AcceptInvitedUser";
import CatalogProduct from "./Catalog/CatalogProduct/CatalogProduct";
import VerifyEmail from "./Auth/Register/VerifyEmail/VerifyEmail";
import ResetPassword from "./Auth/ResetPassword/ResetPassword";
import Loading from "../../components/common/Loading/Loading";
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import ShopifyHome from "./ShopifyHome/ShopifyHome";
import AmazonHome from "./AmazonHome/AmazonHome";
import ComingSoon from "./ComingSoon/ComingSoon";
import Register from "./Auth/Register/Register";
import Catalog from "./Catalog/Catalog";
import Login from "./Auth/Login/Login";
import Terms from "./Terms/Terms";
import Home from "./Home/Home";
import FAQ from "./FAQ/FAQ";

type TLandingPageProps = {
	isLoading: boolean;
};

const LIST_PAGES_WITHOUT_HEADER_FOOTER = [
	"/login",
	"/register",
	"/reset-password",
];

const LendingPage: FC<TLandingPageProps> = ({ isLoading }) => {
	const [hiddenHeaderFooter, setHiddenHeaderFooter] = useState(false);

	const location = useLocation();

	useEffect(() => {
		if (LIST_PAGES_WITHOUT_HEADER_FOOTER.includes(location.pathname)) {
			setHiddenHeaderFooter(true);
		} else {
			setHiddenHeaderFooter(false);
		}
	}, [location]);

	return (
		<Loading
			customClass="loading-container__full-page"
			isLoading={isLoading}
		>
			<div>
				{!hiddenHeaderFooter && <Header />}
				<Routes>
					<Route path="/" element={<Home />}></Route>
					<Route path="*" element={<Home />}></Route>
					<Route path="/catalog" element={<Catalog />}></Route>
					<Route path="/FAQ" element={<FAQ />}></Route>
					<Route
						path="/privacy-policy"
						element={<PrivacyPolicy />}
					></Route>
					<Route path="/login" element={<Login />}></Route>
					<Route path="/register" element={<Register />}></Route>
					<Route path="/terms" element={<Terms />}></Route>
					<Route
						path="/verify_email/:id"
						element={<VerifyEmail />}
					></Route>
					<Route
						path="/reset-password"
						element={<ResetPassword />}
					></Route>
					<Route
						path="/reset_password/:id/:code"
						element={<SetNewPassword />}
					></Route>
					<Route
						path="/invitations/accept_as_new/:code"
						element={<AcceptInvitedUser />}
					></Route>
					<Route
						path="/invitations/accept_as_existing/:code"
						element={<AcceptInvitedExistingUser />}
					></Route>
					<Route
						path="/catalog/product/:id"
						element={<CatalogProduct />}
					></Route>
					<Route
						path="/integrations/redirect-amazon-login/"
						element={<AmazonHome />}
					></Route>
					<Route
						path="/coherence/integrations/shopify/oauth/redirect/"
						element={<ShopifyHome />}
					></Route>
					<Route path="/coming-soon" element={<ComingSoon />}></Route>
				</Routes>
				{!hiddenHeaderFooter && <Footer />}
				<ToastContainer position="bottom-right" />
			</div>
		</Loading>
	);
};

export default LendingPage;
