// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-element-form {
    display: flex;
    flex-direction: column;
}

.card-element-form-text {
    font-size: 13px;
    text-align: center;
    margin: 5px 0 15px 0;
}

.card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/StripeCardForm/StripeCardForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;IAC1B,aAAa;IACb,uCAAuC;IACvC,gBAAgB;IAChB,WAAW;IACX,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":[".card-element-form {\n    display: flex;\n    flex-direction: column;\n}\n\n.card-element-form-text {\n    font-size: 13px;\n    text-align: center;\n    margin: 5px 0 15px 0;\n}\n\n.card-element {\n    border-radius: 4px 4px 0 0;\n    padding: 12px;\n    border: 1px solid rgba(50, 50, 93, 0.1);\n    max-height: 44px;\n    width: 100%;\n    background: white;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
