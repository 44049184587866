// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-wrapper {
	height: 100vh;
	display: grid;
	grid-template-columns: 1fr 600px;
	width: 100%;
	background-color: var(--background);
}

.reset-password-content {
	display: flex;
	flex-direction: column;
	padding: 80px;
	height: 100vh;
	overflow-y: auto;
	position: relative;
	justify-content: center;
	gap: 24px;
}

@media (max-width: 1150px) {
	.reset-password-wrapper {
		height: 100vh;
		display: grid;
		grid-template-columns: 1fr 450px;
		width: 100%;
		background-color: var(--background);
	}

	.reset-password-content {
		display: flex;
		flex-direction: column;
		padding: 40px;
		height: 100vh;
		overflow-y: auto;
		position: relative;
		justify-content: center;
		gap: 24px;
	}
}

@media (max-width: 950px) {
	.reset-password-wrapper {
		height: 100vh;
		display: grid;
		grid-template-columns: 1fr;
		width: 100%;
		background-color: var(--background);
	}

	.reset-password-content {
		display: flex;
		flex-direction: column;
		padding: 50px 20px;
		height: 100vh;
		overflow-y: auto;
		position: relative;
		justify-content: center;
		gap: 24px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/LendingPage/Auth/ResetPassword/ResetPassword.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,aAAa;CACb,gCAAgC;CAChC,WAAW;CACX,mCAAmC;AACpC;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,aAAa;CACb,aAAa;CACb,gBAAgB;CAChB,kBAAkB;CAClB,uBAAuB;CACvB,SAAS;AACV;;AAEA;CACC;EACC,aAAa;EACb,aAAa;EACb,gCAAgC;EAChC,WAAW;EACX,mCAAmC;CACpC;;CAEA;EACC,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,SAAS;CACV;AACD;;AAEA;CACC;EACC,aAAa;EACb,aAAa;EACb,0BAA0B;EAC1B,WAAW;EACX,mCAAmC;CACpC;;CAEA;EACC,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,SAAS;CACV;AACD","sourcesContent":[".reset-password-wrapper {\n\theight: 100vh;\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 600px;\n\twidth: 100%;\n\tbackground-color: var(--background);\n}\n\n.reset-password-content {\n\tdisplay: flex;\n\tflex-direction: column;\n\tpadding: 80px;\n\theight: 100vh;\n\toverflow-y: auto;\n\tposition: relative;\n\tjustify-content: center;\n\tgap: 24px;\n}\n\n@media (max-width: 1150px) {\n\t.reset-password-wrapper {\n\t\theight: 100vh;\n\t\tdisplay: grid;\n\t\tgrid-template-columns: 1fr 450px;\n\t\twidth: 100%;\n\t\tbackground-color: var(--background);\n\t}\n\n\t.reset-password-content {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tpadding: 40px;\n\t\theight: 100vh;\n\t\toverflow-y: auto;\n\t\tposition: relative;\n\t\tjustify-content: center;\n\t\tgap: 24px;\n\t}\n}\n\n@media (max-width: 950px) {\n\t.reset-password-wrapper {\n\t\theight: 100vh;\n\t\tdisplay: grid;\n\t\tgrid-template-columns: 1fr;\n\t\twidth: 100%;\n\t\tbackground-color: var(--background);\n\t}\n\n\t.reset-password-content {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tpadding: 50px 20px;\n\t\theight: 100vh;\n\t\toverflow-y: auto;\n\t\tposition: relative;\n\t\tjustify-content: center;\n\t\tgap: 24px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
