import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FC } from "react";

import { useCreateTaskValidationSchema } from "./validator";
import { useCreateJobMutation } from "../../../api/jobsAPI";

import Switch from "../../components/common/Switch/Switch";

import "./CreateTask.css";

export type TCreateTaskSubmitData = {
	function_name: string;
	function_arguments: string | null;
	periodic_schedule: string;
	allow_retries: string | null;
	queue: string | null;
	is_scheduled_for_retry: string | null;
	retry_times: number | null;
	max_retries: number | null;
	retry_seconds_interval: number | null;
	disabled: boolean | null;
	is_one_off: boolean | null;
};

type TCreateTaskProps = {
	show: boolean;
	onHide: () => void;
};

const CreateTask: FC<TCreateTaskProps> = ({ show, onHide }) => {
	const intl = useIntl();
	const createTaskValidationSchema = useCreateTaskValidationSchema();

	const [createTask] = useCreateJobMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<TCreateTaskSubmitData>({
		resolver: yupResolver(createTaskValidationSchema),
	});

	const onSubmit = (data: TCreateTaskSubmitData) => {
		const object = {
			...data,
			function_arguments: data.function_arguments
				? JSON.parse(data.function_arguments)
				: data.function_arguments,
		};
		createTask(object);
		onHide();
	};

	return (
		<Modal
			className="modal-700"
			centered
			show={show}
			onHide={onHide}
			data-testid="create-task-modal"
		>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="createTask" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Control
							data-testid="create-task-function-name"
							placeholder={`${intl.formatMessage({
								id: "functionName",
							})} *`}
							className="default-input"
							type="string"
							{...register("function_name")}
						/>
						{errors.function_name && (
							<p
								className="mb-0 form-field-error"
								data-testid="create-task-error"
							>
								{errors.function_name.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mt-3">
						<Form.Control
							data-testid="create-task-function-arguments"
							style={{ height: "120px", padding: "10px 16px" }}
							placeholder={`${intl.formatMessage({
								id: "functionArguments",
							})}`}
							className="default-input"
							as="textarea"
							defaultValue=""
							{...register("function_arguments")}
						/>
						{errors.function_arguments && (
							<p className="mb-0 form-field-error">
								{errors.function_arguments.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mt-3">
						<Form.Control
							data-testid="create-task-queue"
							className="default-input"
							type="string"
							placeholder={`${intl.formatMessage({
								id: "queue",
							})}`}
							{...register("queue")}
						/>
						{errors.queue && (
							<p className="mb-0 form-field-error">
								{errors.queue.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mt-3">
						<Form.Select
							className="default-input"
							{...register("periodic_schedule")}
						>
							<option value="* * * * *">
								<FormattedMessage id="everyMinute" />
							</option>
							<option value="0 * * * *">
								<FormattedMessage id="everyHour" />
							</option>
							<option value="0 */6 * * *">
								<FormattedMessage id="every6Hours" />
							</option>
							<option value="0 1 * * *">
								<FormattedMessage id="everyDay" />
							</option>
							<option value="0 0 */3 * *">
								<FormattedMessage id="every3days" />
							</option>
							<option value="0 0 * * 0">
								<FormattedMessage id="everyWeek" />
							</option>
							<option value="0 0 1 * *">
								<FormattedMessage id="everyMonth" />
							</option>
							<option value="0 0 1 1 *">
								<FormattedMessage id="everyYear" />
							</option>
						</Form.Select>
						{errors.periodic_schedule && (
							<p className="mb-0 form-field-error">
								{errors.periodic_schedule.message}
							</p>
						)}
					</Form.Group>
					<div className="create-task__switches-wrapper">
						<div className="create-task__switch-field">
							<Form.Label className="default-input-label">
								<FormattedMessage id="allowRetries" />
							</Form.Label>
							<Switch
								register={register}
								registerField="allow_retries"
								leftLabel="no"
								rightLabel="yes"
							/>
						</div>
						<div className="create-task__switch-field">
							<Form.Label className="default-input-label">
								<FormattedMessage id="scheduledForRetry" />
							</Form.Label>
							<Switch
								register={register}
								registerField="is_scheduled_for_retry"
								leftLabel="no"
								rightLabel="yes"
							/>
						</div>
					</div>
					<div className="create-task__inputs-list-wrapper w-100">
						<Form.Group className="w-100">
							<Form.Control
								placeholder={`${intl.formatMessage({
									id: "retryTimes",
								})}`}
								className="default-input"
								type="number"
								{...register("retry_times")}
								data-testid="create-task-retry-times"
							/>
							{errors.retry_times && (
								<p className="mb-0 form-field-error">
									{errors.retry_times.message}
								</p>
							)}
						</Form.Group>
						<Form.Group className="w-100">
							<Form.Control
								data-testid="create-task-max-retries"
								placeholder={`${intl.formatMessage({
									id: "maximumRetries",
								})}`}
								className="default-input"
								type="number"
								{...register("max_retries")}
							/>
							{errors.max_retries && (
								<p className="mb-0 form-field-error">
									{errors.max_retries.message}
								</p>
							)}
						</Form.Group>
						<Form.Group className="w-100">
							<Form.Control
								data-testid="create-task-retry-seconds-interval"
								placeholder={`${intl.formatMessage({
									id: "retrySecondsInterval",
								})}`}
								className="default-input"
								type="number"
								{...register("retry_seconds_interval")}
							/>
							{errors.retry_seconds_interval && (
								<p className="mb-0 form-field-error">
									{errors.retry_seconds_interval.message}
								</p>
							)}
						</Form.Group>
					</div>
					<div className="create-task__switches-wrapper">
						<div className="create-task__switch-field">
							<Form.Label className="default-input-label">
								<FormattedMessage id="disabled" />
							</Form.Label>
							<Switch
								register={register}
								registerField="disabled"
								leftLabel="no"
								rightLabel="yes"
							/>
						</div>
						<div className="create-task__switch-field">
							<Form.Label className="default-input-label">
								<FormattedMessage id="runOnlyOnce" />{" "}
								<span className="red-color">*</span>
							</Form.Label>
							<Switch
								data-testid="create-task-is_one_off"
								register={register}
								registerField="is_one_off"
								leftLabel="no"
								rightLabel="yes"
							/>
						</div>
					</div>
					<div className="d-flex justify-content-end mt-4">
						<button
							type="submit"
							className="ice-button w-100"
							data-testid="create-task-submit"
						>
							<FormattedMessage id="createTask" />
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default CreateTask;
