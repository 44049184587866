import { FormattedMessage } from "react-intl";
import { FC, useState } from "react";

import AddTrackingInformation from "../../../modals/AddTrackingInformation/AddTrackingInformation";
import { useGetTrackingByIdQuery } from "../../../../api/ordersAPI";
import { TOrderFulfillment } from "../../../../api/api.types";

type TOrderDetails = {
	fulfillment: TOrderFulfillment;
};

const OrderTracking: FC<TOrderDetails> = ({ fulfillment }) => {
	const [addTrackingDataModal, setAddTrackingDataModal] = useState(false);

	const { data: trackingData } = useGetTrackingByIdQuery(fulfillment.id, {
		skip: !fulfillment.id,
	});

	return (
		<div className="order-details__fulfillment-details-block">
			<p className="order-details__fulfillment-details-title">
				<FormattedMessage id="trackingInformationWithDots" />
			</p>
			<div className="order-details__fulfillment-details-table-wrapper">
				<table className="table mb-0">
					<thead className="thead-light">
						<tr>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="trackingNumberDefault" />
							</th>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="carrierCode" />
							</th>
							<th className="text-center vertical-align-middle">
								<div className="d-flex align-items-center justify-content-center">
									<button
										style={{ height: "34px" }}
										type="button"
										className="ice-button"
										disabled={
											!trackingData?.results ||
											trackingData.results.length === 0
												? false
												: true
										}
										onClick={() =>
											setAddTrackingDataModal(true)
										}
									>
										<FormattedMessage id="add" />
									</button>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{trackingData?.results &&
							trackingData?.results.map((trackingItem) => (
								<tr key={fulfillment.id}>
									<td className="text-center max-width-td">
										{trackingItem.tracking_number}
									</td>
									<td className="text-center max-width-td">
										{trackingItem.carrier_code}
									</td>
									<td className="text-center max-width-td"></td>
								</tr>
							))}
					</tbody>
					{addTrackingDataModal && (
						<AddTrackingInformation
							show={addTrackingDataModal}
							onHide={() => setAddTrackingDataModal(false)}
							fulfillment={fulfillment.id}
						/>
					)}
				</table>
			</div>
		</div>
	);
};

export default OrderTracking;
