import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import ReCAPTCHA from "react-google-recaptcha";
import { MdOutlineDone } from "react-icons/md";
import { BiLoaderAlt } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useState } from "react";

import useHandleCustomRtkQueryError from "../../../../../../hooks/useHandleCustomRtkQueryError";
import { useCheckAffiliateCodeMutation } from "../../../../../../api/affiliateAPI";
import { useRegisterUserMutation } from "../../../../../../api/authAPI";
import { useRegisterFirstStepValidationSchema } from "./validator";

import Loading from "../../../../../components/common/Loading/Loading";

import SignIn from "../../../../../assets/images/sing-in.png";

import "./RegisterFirstStep.css";

export type TRegisterFirstStepForm = {
	first_name: string;
	last_name: string;
	subdomain: string;
	email: string;
	password1: string;
	password2: string;
	affiliate_code?: string;
};

const RegisterFirstStep = () => {
	const [captcha, setCaptcha] = useState(false);

	let timeoutId: NodeJS.Timeout;

	console.log(process.env.REACT_APP_DISABLE_CAPTCHA);

	const intl = useIntl();
	const registerFirstStepValidationSchema =
		useRegisterFirstStepValidationSchema();

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<TRegisterFirstStepForm>({
		resolver: yupResolver(registerFirstStepValidationSchema),
	});

	const [customError, setCustomError, handleCustomError] =
		useHandleCustomRtkQueryError();

	const [checkAffiliateCode, { data, isLoading: isLoadingCheckCode }] =
		useCheckAffiliateCodeMutation();
	const [registerUser, { isLoading }] = useRegisterUserMutation();

	const handleCaptcha = (value: string | null) => {
		setCaptcha(() => {
			if (value) {
				return true;
			} else {
				return false;
			}
		});
	};

	const handleExpired = () => {
		setCaptcha(false);
	};

	const handleChangeAffiliateCode = (value: string) => {
		clearTimeout(timeoutId);

		timeoutId = setTimeout(() => {
			checkAffiliateCode(value);
		}, 500);
	};

	const onSubmit = (formData: TRegisterFirstStepForm) => {
		const object: TRegisterFirstStepForm = {
			first_name: formData.first_name,
			last_name: formData.last_name,
			subdomain: formData.subdomain,
			email: formData.email,
			password1: formData.password1,
			password2: formData.password2,
		};

		if (data && formData.affiliate_code) {
			object.affiliate_code = formData.affiliate_code;
		}

		registerUser(object).then((response) => {
			if ("error" in response) {
				handleCustomError(response.error);
			} else {
				setCustomError(null);
			}
		});
	};

	const domainPopover = (
		<Popover id="popover-basic">
			<Popover.Header as="h3">
				<FormattedMessage id="subdomainName" />
			</Popover.Header>
			<Popover.Body>
				<FormattedMessage id="whatIsASubdomainName" />
				<FormattedMessage id="subdomainNameIsAWebsiteAddressUsedToAccess" />
				<FormattedMessage id="whatIsACloudOrderDomain" />
				<FormattedMessage id="yourCloudOrderAccountNameWillLookLikeThis" />
				<FormattedMessage id="yourCloudOrderIsUsedWithDots" />
				<FormattedMessage id="soYouAndYourStaffCanAccessYourStoresAUniqueWay" />
			</Popover.Body>
		</Popover>
	);

	return (
		<Loading isLoading={isLoading}>
			<div className="register-first-step-wrapper">
				<Form
					className="register-first-step__form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Link to="/">
						<div className="register-first-step__back">
							<FaArrowLeftLong size={20} color="#FFFFFF" />
						</div>
					</Link>
					<div className="d-flex flex-column">
						<h3 className="auth-title">
							<FormattedMessage id="welcomeToCloudOrder" />
						</h3>
						<div className="register-first-step__have-account">
							<FormattedMessage id="alreadyHaveAnAccount" />
							<Link to="/login">
								&nbsp;
								<FormattedMessage id="logIn" />
							</Link>
						</div>
					</div>
					{customError && (
						<p className="form-field-error mb-1">{customError}</p>
					)}
					<div className="register-first-step__fields">
						<Form.Group>
							<Form.Control
								className="auth-form-input"
								type="text"
								placeholder={intl.formatMessage({
									id: "firstName",
								})}
								{...register("first_name")}
							/>
							{errors.first_name && (
								<p className="mb-0 form-field-error">
									{errors.first_name.message}
								</p>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Control
								className="auth-form-input"
								type="text"
								placeholder={intl.formatMessage({
									id: "lastName",
								})}
								{...register("last_name")}
							/>
							{errors.last_name && (
								<p className="mb-0 form-field-error">
									{errors.last_name.message}
								</p>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Control
								className="auth-form-input"
								type="password"
								placeholder={intl.formatMessage({
									id: "password",
								})}
								{...register("password1")}
							/>
							{errors.password1 && (
								<p
									className="mb-0 form-field-error"
									style={{ maxWidth: "400px" }}
								>
									{errors.password1.message}
								</p>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Control
								className="auth-form-input"
								type="password"
								placeholder={intl.formatMessage({
									id: "confirmPassword",
								})}
								{...register("password2")}
							/>
							{errors.password2 && (
								<p className="mb-0 form-field-error">
									{errors.password2.message}
								</p>
							)}
						</Form.Group>
					</div>
					<Form.Group>
						<Form.Control
							className="auth-form-input"
							type="text"
							placeholder={intl.formatMessage({ id: "email" })}
							{...register("email")}
						/>
						{errors.email && (
							<p className="mb-0 form-field-error">
								{errors.email.message}
							</p>
						)}
					</Form.Group>
					<div>
						<Form.Group className="register-input-relative-group">
							<Form.Control
								className="register-input-width-60 auth-form-input"
								type="text"
								placeholder={intl.formatMessage({
									id: "subdomain",
								})}
								{...register("subdomain")}
							/>
							<div className="register-input-group-domain">
								<span className="w-100 h-100 domain-style d-flex align-items-center justify-content-between">
									.cloudorder.us
									<OverlayTrigger
										trigger="click"
										placement="right"
										overlay={domainPopover}
									>
										<svg
											type="button"
											xmlns="http://www.w3.org/2000/svg"
											width="13"
											height="13"
											fill="currentColor"
											className="bi bi-info-circle"
											viewBox="0 0 16 16"
										>
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
											<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
										</svg>
									</OverlayTrigger>
								</span>
							</div>
						</Form.Group>
						{errors.subdomain && (
							<p className="mb-0 form-field-error">
								{errors.subdomain.message}
							</p>
						)}
					</div>
					<Form.Group>
						<div className="block-affiliate-code">
							<Form.Control
								className="auth-form-input"
								type="text"
								placeholder={intl.formatMessage({
									id: "affiliateCode",
								})}
								{...register("affiliate_code", {
									onChange: (e) =>
										handleChangeAffiliateCode(
											e.target.value
										),
								})}
							/>
							{isLoadingCheckCode && (
								<BiLoaderAlt
									className="affiliate-code-check-loading"
									size={22}
								/>
							)}
							{!isLoadingCheckCode && data && (
								<MdOutlineDone
									className="affiliate-code-check-done"
									size={22}
								/>
							)}
							{!isLoadingCheckCode &&
								getValues("affiliate_code") &&
								!data && (
									<IoCloseOutline
										className="affiliate-code-check-error"
										size={22}
									/>
								)}
						</div>
						{errors.affiliate_code && (
							<p className="mb-0 form-field-error">
								{errors.affiliate_code.message}
							</p>
						)}
						{data && (
							<div className="affiliate-code-info-block mt-1">
								<span className="me-2">
									{data.benefits_discount_period + ","}
								</span>
								<span>
									{data.benefits_discount +
										`% ${intl.formatMessage({ id: "discountUppercase" })}`}
								</span>
							</div>
						)}
					</Form.Group>
					<div className="col-20 my-2">
						{process.env.REACT_APP_DISABLE_CAPTCHA !== "true" && (
							<ReCAPTCHA
								sitekey="6LescSEkAAAAAPCbn7XK7VJBhkw2gzWPXIcAE8c0"
								onChange={handleCaptcha}
								onExpired={handleExpired}
							/>
						)}
						<div
							className={`d-flex align-items-center ${process.env.REACT_APP_DISABLE_CAPTCHA === "true" ? "mt-0" : "mt-3"}`}
						>
							<label className="register-first-step__agree-to-terms">
								<FormattedMessage id="byCreatingAnAccountYouAgreeToThe" />{" "}
								<Link to="/terms">
									<FormattedMessage id="termsOfUse" />
								</Link>{" "}
								<FormattedMessage id="and" />{" "}
								<Link to="/privacy-policy">
									<FormattedMessage id="privacyPolicy" />
								</Link>
								.
							</label>
						</div>
					</div>
					<div className="register-first-step__button-wrapper">
						<button
							disabled={
								process.env.REACT_APP_DISABLE_CAPTCHA === "true"
									? false
									: !captcha
							}
							className="dark-button"
							type="submit"
						>
							<FormattedMessage id="createAnAccount" />
						</button>
					</div>
				</Form>
				<div className="register-right-side">
					<img src={SignIn} alt="" />
				</div>
			</div>
		</Loading>
	);
};

export default RegisterFirstStep;
