const Divider = () => {
	return (
		<div className="order-details__shipment-details-divider">
			<div className="order-details__divider-circle"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-dash"></div>
			<div className="order-details__divider-circle"></div>
		</div>
	);
};

export default Divider;
