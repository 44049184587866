import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { AiOutlineNumber } from "react-icons/ai";
import { TbFilter } from "react-icons/tb";
import { toast } from "react-toastify";

import {
	useConfirmStripePaymentMutation,
	useGetPaymentIntentReceiptLinkMutation,
	useGetStripeReceiptsQuery,
} from "../../../../../api/paymentsAPI";

import {
	BILLING_FILTERS,
	BILLING_RECEIPTS_STATUS_TRANSLATIONS,
} from "../../../../../helpers/constants";

import useClickOutsideOfBlock from "../../../../../hooks/useClickOutsideOfBlock";
import usePagination from "../../../../../hooks/usePagination";
import { TStripeReceipt } from "../../../../../api/api.types";
import useFilters from "../../../../../hooks/useFilters";

import StripePayment from "../../../../modals/StripePayment/StripePayment";
import PagePagination from "../../../../components/common/Pagination/PagePagination";
import FilterBlock from "../../../../components/common/FilterBlock/FilterBlock";
import EmptyState from "../../../../components/common/EmptyState/EmptyState";
import Loading from "../../../../components/common/Loading/Loading";

import "../Billing.css";

const BillingReceipts = () => {
	const [clientSecret, setClientSecret] = useState<string | null>(null);
	const [confirmPayment, setConfirmPayment] = useState(false);
	const [expandFilters, setExpandFilters] = useState(false);

	const filterRef = useRef<HTMLDivElement>(null);

	const intl = useIntl();

	const { pageSize, handleDefaultFilter, defaultFilter } = useFilters();
	const [searchParams] = useSearchParams();

	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		pageSize
	);

	const navigate = useNavigate();

	const [getReceiptLink] = useGetPaymentIntentReceiptLinkMutation();
	const [confirmStripe] = useConfirmStripePaymentMutation();

	const {
		data: stripeReceiptsData,
		isLoading,
		isFetching,
	} = useGetStripeReceiptsQuery({
		activePage,
		pageSize,
		defaultFilter,
	});

	const handleConfirmPayment = (secret: string) => {
		setConfirmPayment(true);
		setClientSecret(secret);
	};

	const handleViewReceipt = (receipt: TStripeReceipt) => {
		getReceiptLink(receipt.id).then((res) => {
			if ("error" in res) {
				toast.error(intl.formatMessage({ id: "receiptNotFound" }));
			} else {
				window.open(res.data.receipt_url, "_blank");
			}
		});
	};

	useEffect(() => {
		if (stripeReceiptsData) {
			handlePagesCount(stripeReceiptsData.count);
		}
	}, [stripeReceiptsData, handlePagesCount]);

	useEffect(() => {
		const payment_intent = searchParams.get("payment_intent");
		const payment_intent_client_secret = searchParams.get(
			"payment_intent_client_secret"
		);
		const redirect_status = searchParams.get("redirect_status");

		if (
			payment_intent &&
			payment_intent_client_secret &&
			redirect_status &&
			redirect_status === "succeeded"
		) {
			const orderId = stripeReceiptsData?.results.find(
				(el) => el.client_secret === payment_intent_client_secret
			)?.id;

			if (orderId) {
				confirmStripe(orderId);
				navigate("/dashboard/billing-receipts/");
			}
		}
	}, [searchParams, confirmStripe, stripeReceiptsData, navigate]);

	useClickOutsideOfBlock({
		blockRef: filterRef,
		showValue: expandFilters,
		setShowValue: setExpandFilters,
	});

	return (
		<div className="billing-content-wrapper">
			<div className="billing_content" data-testid="receipts-table">
				<div className="billing__page-filters">
					<h5 className="billing__page-title">
						<FormattedMessage id="receipts" />
					</h5>
					<div className="billing__page-filters-actions">
						{stripeReceiptsData?.results &&
							stripeReceiptsData?.results.length > 0 && (
								<PagePagination
									pages={pages}
									activePage={activePage}
									handlePage={handlePage}
								/>
							)}
						<div
							ref={filterRef}
							className="billing__page-filters-wrapper"
						>
							<div
								onClick={() => setExpandFilters(!expandFilters)}
								className={`billing__page-filters-button ${expandFilters ? "expanded" : ""}`}
								data-testid="filter-button"
							>
								<TbFilter
									size={22}
									color={
										expandFilters ? "#FFFFFF" : "#666666"
									}
								/>
							</div>
							{expandFilters && (
								<FilterBlock
									selectedFilters={{
										status: defaultFilter,
									}}
									filters={BILLING_FILTERS}
									handlers={{
										status: handleDefaultFilter,
									}}
									handlePage={handlePage}
									onClose={() => setExpandFilters(false)}
								/>
							)}
						</div>
					</div>
				</div>
				<Loading isLoading={isLoading || isFetching}>
					{stripeReceiptsData &&
					stripeReceiptsData.results.length === 0 ? (
						<EmptyState message="noBillingReceiptsFound" />
					) : (
						<div className="default-table">
							<table className="table">
								<thead className="thead-light">
									<tr>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<AiOutlineNumber size={21} />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="processedDate" />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="status" />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="amount" />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="currency" />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="description" />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="action" />
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{stripeReceiptsData &&
										stripeReceiptsData.results.length > 0 &&
										stripeReceiptsData.results.map(
											(receipt) => (
												<tr
													key={receipt.id}
													data-testid="table-receipt-row"
												>
													<td className="text-center">
														{receipt.id}
													</td>
													<td className="text-center">
														{
															receipt.created_at.split(
																"T"
															)[0]
														}
													</td>
													<td
														data-testid="receipt-status"
														className="text-center"
													>
														<FormattedMessage
															id={
																BILLING_RECEIPTS_STATUS_TRANSLATIONS[
																	receipt.status as keyof typeof BILLING_RECEIPTS_STATUS_TRANSLATIONS
																] || "succeeded"
															}
														/>
													</td>
													<td className="text-center">
														{receipt.amount}
													</td>
													<td className="text-center">
														{receipt.currency}
													</td>
													<td className="text-center max-width-td">
														<span className="wrapped-table-row">
															{
																receipt.description
															}
														</span>
													</td>
													<td className="text-center">
														{receipt.status ===
														"requires_action" ? (
															<button
																onClick={() =>
																	handleConfirmPayment(
																		receipt.client_secret
																	)
																}
																className="ice-button"
																style={{
																	minWidth:
																		"160px",
																}}
																data-testid="confirm-payment-button"
															>
																<FormattedMessage id="confirmPayment" />
															</button>
														) : (
															<button
																onClick={() =>
																	handleViewReceipt(
																		receipt
																	)
																}
																className="ice-button"
																style={{
																	minWidth:
																		"160px",
																}}
															>
																<FormattedMessage id="viewReceipt" />
															</button>
														)}
													</td>
												</tr>
											)
										)}
								</tbody>
							</table>
						</div>
					)}
				</Loading>
			</div>
			{confirmPayment && clientSecret && (
				<StripePayment
					show={confirmPayment}
					onHide={() => setConfirmPayment(false)}
					clientSecret={clientSecret}
				/>
			)}
		</div>
	);
};

export default BillingReceipts;
