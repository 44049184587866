import { FC, useCallback, useEffect, useRef, useState } from "react";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import IntroductionAnimation from "../../../components/layout/Home/IntroductionAnimation";
import OffersAnimation from "../../../components/layout/Home/OffersAnimation";
import UsageAnimations from "../../../components/layout/Home/UsageAnimations";
import StoryAnimations from "../../../components/layout/Home/StoryAnimations";

import MarketplaceGif from "../../../assets/images/marketplace.gif";
import JoinUsArrow from "../../../assets/images/join-us-arrow.svg";
import RetailGif from "../../../assets/images/retail.gif";
import SalesGif from "../../../assets/images/sales.gif";
import Zhen from "../../../assets/images/zhen.png";

import "./Animations.css";
import "./Home.css";

type THomeProps = {
    isLoading?: boolean;
};

const Home: FC<THomeProps> = ({ isLoading }) => {
    const [windowHeight, setWindowHeight] = useState<number>(
        window.innerHeight
    );
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [currentVideo, setCurrentVideo] = useState<number>(1);

    const offersBlockRef = useRef<HTMLDivElement>(null);

    const intl = useIntl();

    const handleResize = useCallback(() => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        setWindowWidth(windowWidth);
        setWindowHeight(windowHeight);
    }, []);

    const handleSwipe = useCallback(
        (swipeArea: HTMLElement) => {
            let startX: number;
            let startY: number;

            swipeArea.addEventListener(
                "touchstart",
                function (e) {
                    const touch = e.touches[0];
                    startX = touch.clientX;
                    startY = touch.clientY;
                },
                false
            );

            swipeArea.addEventListener(
                "touchend",
                function (e) {
                    const touch = e.changedTouches[0];
                    const diffX = touch.clientX - startX;
                    const diffY = touch.clientY - startY;

                    if (Math.abs(diffX) > Math.abs(diffY)) {
                        if (diffX > 30) {
                            setCurrentVideo(currentVideo === 1 ? 2 : 1);
                        } else if (diffX < -30) {
                            setCurrentVideo(currentVideo === 1 ? 2 : 1);
                        }
                    }
                },
                false
            );
        },
        [currentVideo]
    );

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        const swipeArea = document.getElementById("swipeArea");

        if (!swipeArea) return;

        handleSwipe(swipeArea);

        return () => {
            swipeArea.removeEventListener("touchstart", () => {});
            swipeArea.removeEventListener("touchend", () => {});
        };
    }, [handleSwipe]);

    return (
        <main className="home-page-wrapper">
            <div className="home-content-wrapper">
                <div className="home-section-wrapper">
                    <div className="home-introduction-block">
                        <div className="home-introduction__title-wrapper">
                            <div className="home-introduction__title-block">
                                <div className="home-introduction__title-wrapper">
                                    <h1 className="home-introduction__title">
                                        <FormattedMessage id="dropshipping" />
                                    </h1>
                                </div>
                                <div className="home-introduction__title-wrapper">
                                    <h1 className="home-introduction__title">
                                        <FormattedMessage id="marketplace" />
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="home-introduction__join-us-block">
                            <Link to="/register">
                                <button className="home-introduction__join-us-button">
                                    <FormattedMessage id="joinUsNow" />
                                </button>
                            </Link>
                            <img src={JoinUsArrow} alt="" />
                        </div>
                    </div>
                    <IntroductionAnimation
                        windowHeight={windowHeight}
                        windowWidth={windowWidth}
                    />
                </div>
                <div className="home-section-wrapper position-relative">
                    <div ref={offersBlockRef} className="home-about-block">
                        <div className="home-about__main-intro">
                            <h5 className="home-about__what-we-do">
                                <FormattedMessage id="whatWeDo" />
                            </h5>
                            <p className="home-about__what-we-do-description">
                                <FormattedMessage id="weAreAnECommerceMarketplaceThatConnectsREtailersWithBrand" />
                            </p>
                        </div>
                        <div className="home-about__offers-wrapper">
                            <div className="home-about__offer-block">
                                <img
                                    width={60}
                                    className="home-about__offer-block-gif"
                                    src={SalesGif}
                                    alt=""
                                />
                                <div className="home-about__offer-text-block">
                                    <h6 className="home-about__offer-block-title">
                                        <FormattedMessage id="expandSalesNetworkForBrands" />
                                    </h6>
                                    <span className="home-about__offer-block-description">
                                        <FormattedMessage id="brandsHaveAccessToThousandsOfRetailsStoresAsPartOfTheirSales" />
                                    </span>
                                </div>
                            </div>
                            <div
                                id="offers-element"
                                className="home-about__offer-block center"
                            >
                                <img
                                    width={70}
                                    className="home-about__offer-block-gif"
                                    src={RetailGif}
                                    alt=""
                                />
                                <div className="home-about__offer-text-block">
                                    <h6 className="home-about__offer-block-title">
                                        <FormattedMessage id="eCommerceWholesaleMarketplace" />
                                    </h6>
                                    <span className="home-about__offer-block-description">
                                        <FormattedMessage id="weAutomateTheProcessToConnectRetailersWithBrands" />
                                    </span>
                                </div>
                            </div>
                            <div className="home-about__offer-block end">
                                <img
                                    width={80}
                                    className="home-about__offer-block-gif"
                                    src={MarketplaceGif}
                                    alt=""
                                />
                                <div className="home-about__offer-text-block">
                                    <h6 className="home-about__offer-block-title">
                                        <FormattedMessage id="dropshippingSourceForRetailers" />
                                    </h6>
                                    <span className="home-about__offer-block-description">
                                        <FormattedMessage id="shopifySellersImportProductsFromThousandsOfSmallToMediumFBASellers" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OffersAnimation
                        blockRef={offersBlockRef}
                        windowHeight={windowHeight}
                        windowWidth={windowWidth}
                    />
                </div>
                <div
                    id="swipeArea"
                    className="home-section-wrapper position-relative"
                >
                    <div className="home-usage-block">
                        <div className="home-usage__information-block">
                            <h3 className="home-usage__title">
                                <FormattedMessage id="whoCanUseCloudOrder" />
                            </h3>
                            <div className="home-usage__description-block">
                                <h6 className="home-usage__subtitle">
                                    {currentVideo === 1 ? (
                                        <FormattedMessage id="forShopifyStores" />
                                    ) : (
                                        <FormattedMessage id="forAmazonSellers" />
                                    )}
                                </h6>
                                <p className="home-usage__description">
                                    {currentVideo === 1 ? (
                                        <FormattedMessage id="webStoresToDropshipProductsFromSmallBrands" />
                                    ) : (
                                        <FormattedMessage id="amazonSellersExpandTheirSalesChannelsByMakingTheirProducts" />
                                    )}
                                </p>
                                <Link to="/register">
                                    <button className="home-usage__join-button">
                                        <FormattedMessage id="joinUsNow" />
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="home-usage__videos-block">
                            <div
                                className={`home-usage__video-wrapper ${
                                    currentVideo === 1 ? "current first" : ""
                                }`}
                            >
                                <iframe
                                    title={intl.formatMessage({
                                        id: "whyCloudOrderForStore",
                                    })}
                                    className="home-usage__video"
                                    src="https://www.youtube.com/embed/C4UsYfLj8rc?autoplay=0&enablejsapi=1&origin=https%3A%2F%2Foms.com&widgetid=7"
                                ></iframe>
                                {currentVideo !== 1 && (
                                    <div
                                        onClick={() => setCurrentVideo(1)}
                                        className="home-usage__video-dark-block"
                                    ></div>
                                )}
                            </div>
                            <div
                                className={`home-usage__video-wrapper ${
                                    currentVideo === 2 ? "current second" : ""
                                }`}
                            >
                                <iframe
                                    title={intl.formatMessage({
                                        id: "whyCloudOrderForStore",
                                    })}
                                    className="home-usage__video"
                                    src="https://www.youtube.com/embed/yCSR0adT11k?autoplay=0&enablejsapi=1&origin=https%3A%2F%2Foms.com&widgetid=7"
                                ></iframe>
                                {currentVideo !== 2 && (
                                    <div
                                        onClick={() => setCurrentVideo(2)}
                                        className="home-usage__video-dark-block"
                                    ></div>
                                )}
                            </div>
                            <div className="home-usage__mobile-video-dots">
                                <div
                                    onClick={() => setCurrentVideo(1)}
                                    className={`home-usage__mobile-video-dot ${
                                        currentVideo === 1 ? "current" : ""
                                    }`}
                                ></div>
                                <div
                                    onClick={() => setCurrentVideo(2)}
                                    className={`home-usage__mobile-video-dot ${
                                        currentVideo === 2 ? "current" : ""
                                    }`}
                                ></div>
                            </div>
                        </div>
                        <div className="home-usage__description-block-mobile">
                            <h6 className="home-usage__subtitle">
                                {currentVideo === 1 ? (
                                    <FormattedMessage id="forShopifyStores" />
                                ) : (
                                    <FormattedMessage id="forAmazonSellers" />
                                )}
                            </h6>
                            <p className="home-usage__description">
                                {currentVideo === 1 ? (
                                    <FormattedMessage id="webStoresToDropshipProductsFromSmallBrands" />
                                ) : (
                                    <FormattedMessage id="amazonSellersExpandTheirSalesChannelsByMakingTheirProducts" />
                                )}
                            </p>
                            <Link to="/register">
                                <button className="home-usage__join-button">
                                    <FormattedMessage id="joinUsNow" />
                                    <HiOutlineArrowLongRight size={22} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <UsageAnimations
                        windowHeight={windowHeight}
                        windowWidth={windowWidth}
                    />
                </div>
                <div className="home-section-wrapper position-relative">
                    <div className="home-story-block">
                        <h3 className="home-story__title-mobile">
                            <FormattedMessage id="ourStory" />
                        </h3>
                        <div className="home-story__image-block">
                            <img src={Zhen} alt="" />
                            <div className="home-story__points">
                                <div className="home-story__point">
                                    <FormattedMessage id="eCommerceEntrepreneur" />
                                </div>
                                <div className="home-story__point">
                                    <FormattedMessage id="logistics" />
                                </div>
                                <div className="home-story__point">
                                    <FormattedMessage id="chainManagement" />
                                </div>
                            </div>
                        </div>
                        <div className="home-story__text-block">
                            <h3 className="home-story__title">
                                <FormattedMessage id="ourStory" />
                            </h3>
                            <p className="home-story__text">
                                <FormattedMessage id="asASuccessfulAmazonSellerCloudOrderFounder" />
                            </p>
                            <p className="home-story__text">
                                <FormattedMessage id="beingASmallBrandOwnerWeDidNotHaveABigMarketingBudget" />
                            </p>
                            <p className="home-story__conclusion">
                                <FormattedMessage id="asAResultCloudOrderWasBorn" />
                            </p>
                        </div>
                    </div>
                    <StoryAnimations
                        windowHeight={windowHeight}
                        windowWidth={windowWidth}
                    />
                </div>
            </div>
        </main>
    );
};

export default Home;
