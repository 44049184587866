// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-name-title {
    color: #1a4074;
}

.product-details-carousel .active::before {
    background: transparent !important;
}

.product-details-textarea {
    background-color: white !important;
    resize: none;
    height: 150px;
}

.product-details-status-block {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 4px;
    background-color: #f8e1d3;
    margin-bottom: 24px;
    align-items: center;
}

.product-details-status-block-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.product-details-status-block.pending-synchronization {
    background-color: #e6f0f7;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/ProductDetails/ProductDetails.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;IAClC,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".product-name-title {\n    color: #1a4074;\n}\n\n.product-details-carousel .active::before {\n    background: transparent !important;\n}\n\n.product-details-textarea {\n    background-color: white !important;\n    resize: none;\n    height: 150px;\n}\n\n.product-details-status-block {\n    display: flex;\n    gap: 16px;\n    padding: 16px;\n    border-radius: 4px;\n    background-color: #f8e1d3;\n    margin-bottom: 24px;\n    align-items: center;\n}\n\n.product-details-status-block-text {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n\n.product-details-status-block.pending-synchronization {\n    background-color: #e6f0f7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
