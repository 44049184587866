import { IoCloseOutline } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { FormattedMessage } from "react-intl";
import { Nav } from "react-bootstrap";
import { FC, useState } from "react";

import { selectIsAuth } from "../../../../redux/reducers/auth/authSlice";
import { useAppSelector } from "../../../../redux/hooks";

import DashboardHeader from "./DashboardHeader/DashboardHeader";

import RegisterArrow from "../../../assets/images/register-arrow.svg";
import Logo from "../../../assets/images/logo.svg";

import "./Header.css";

type THeaderProps = {
    setShowSidebar?: (value: boolean) => void;
    showSidebar?: boolean;
};

const Header: FC<THeaderProps> = ({ setShowSidebar, showSidebar }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const isAuth = useAppSelector(selectIsAuth);

    return (
        <>
            {isAuth ? (
                <DashboardHeader
                    expanded={expanded}
                    showSidebar={showSidebar}
                    setShowSidebar={setShowSidebar}
                />
            ) : (
                <div className="header-wrapper">
                    <div className="header">
                        <div className="header__logo-block">
                            <img src={Logo} alt="" />
                        </div>
                        <div
                            onClick={() => setExpanded(!expanded)}
                            className="header__mobile-expand"
                        >
                            {expanded ? (
                                <IoCloseOutline size={38} />
                            ) : (
                                <RxHamburgerMenu size={28} />
                            )}
                        </div>
                        <Nav
                            className={`header__nav ${
                                expanded ? "expanded" : ""
                            }`}
                        >
                            <NavLink
                                className="header__nav-item"
                                to="/"
                                onClick={() => setExpanded(false)}
                            >
                                <FormattedMessage id="home" />
                            </NavLink>
                            <NavLink
                                className="header__nav-item"
                                to="/catalog"
                                onClick={() => setExpanded(false)}
                            >
                                <FormattedMessage id="catalog" />
                            </NavLink>
                            <NavLink
                                className="header__nav-item"
                                to="/FAQ"
                                onClick={() => setExpanded(false)}
                            >
                                <FormattedMessage id="faq" />
                            </NavLink>
                            <NavLink
                                className="header__nav-item mobile-only"
                                to="/register"
                                onClick={() => setExpanded(false)}
                            >
                                <FormattedMessage id="register" />
                            </NavLink>
                            <NavLink
                                className="header__nav-item mobile-only"
                                to="/login"
                                onClick={() => setExpanded(false)}
                            >
                                <FormattedMessage id="login" />
                            </NavLink>
                        </Nav>
                        <div className="header__register-block">
                            <Link to="/login">
                                <button className="header__login-button">
                                    <FormattedMessage id="login" />
                                </button>
                            </Link>
                            <Link to="/register">
                                <button className="header__register-button">
                                    <div className="register-button__main">
                                        <FormattedMessage id="register" />
                                    </div>
                                    <div className="register-button__middle">
                                        <div className="register-button-middle__top-circle"></div>
                                        <div className="register-button-middle__bottom-circle"></div>
                                    </div>
                                    <div className="register-button__arrow">
                                        <img src={RegisterArrow} alt="" />
                                    </div>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default Header;
