import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { FC } from "react";

import { useCompleteOrderTransactionMutation } from "../../../../api/paymentsAPI";
import { TGetOrderResult } from "../../../../api/api.types";

export type TCompletePaymentObject = {
	tenant: number;
	amount: number;
	currency: string;
	order: number;
	kind: string;
	method: string;
	transaction_extra_data: any;
};

type TPayPalButton = {
	orderPay: TGetOrderResult;
	handleCloseManuallyPayWithRefetch: () => void;
};

const PayPalButton: FC<TPayPalButton> = ({
	orderPay,
	handleCloseManuallyPayWithRefetch,
}) => {
	const [completeOrderTransaction] = useCompleteOrderTransactionMutation();

	const intl = useIntl();

	const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID
		? process.env.REACT_APP_PAYPAL_CLIENT_ID
		: "";

	const createOrder = (data: any, actions: any) => {
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						value: orderPay.end_customer_cost / 100,
						currency_code: orderPay.currency,
					},
					custom_id: orderPay.id,
				},
			],
		});
	};

	const onApprove = (data: any, actions: any) => {
		return actions.order.capture().then(function (details: any) {
			const object = {
				tenant: orderPay.seller_tenant,
				amount: orderPay.end_customer_cost,
				currency: orderPay.currency,
				order: orderPay.id,
				kind: "payment",
				method: "paypal",
				transaction_extra_data: details,
			};

			completeOrderTransaction(object).then((res) => {
				if ("error" in res) {
					const status = (res.error as { status: number }).status;

					if (status !== 403) {
						toast.error(
							intl.formatMessage({ id: "somethingWentWrong" })
						);
					}
				} else {
					handleCloseManuallyPayWithRefetch();
					toast.success(
						intl.formatMessage({
							id: "theOrderHasBeenPaidSuccessfully",
						})
					);
				}
			});
		});
	};

	const onError = (err: any) => {
		toast.error(
			intl.formatMessage(
				{ id: "paymentFailed" },
				{ error: JSON.stringify(err) }
			)
		);
	};

	return (
		<PayPalScriptProvider options={{ clientId: CLIENT_ID }}>
			<PayPalButtons
				className="w-100"
				createOrder={createOrder}
				onApprove={onApprove}
				onError={onError}
			/>
		</PayPalScriptProvider>
	);
};

export default PayPalButton;
