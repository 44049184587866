import { FormattedMessage, useIntl } from "react-intl";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC } from "react";

import { useAddCreditsMutation } from "../../../api/paymentsAPI";

export type TAddCreditsSubmitData = {
	amount: number;
	description: string;
	tenant?: number;
};

type TAddCreditsProps = {
	show: boolean;
	onHide: () => void;
	tenantId: number | null;
};

const AddCredits: FC<TAddCreditsProps> = ({ show, onHide, tenantId }) => {
	const intl = useIntl();

	const [addCredits] = useAddCreditsMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<TAddCreditsSubmitData>();

	const onSubmit = (data: TAddCreditsSubmitData) => {
		if (tenantId) {
			const object = {
				...data,
				tenant: tenantId,
				amount: data.amount * 100,
			};
			addCredits(object).then((res) => {
				if ("error" in res) {
					const status = (res.error as { status: number }).status;

					if (status !== 403) {
						toast.error(
							intl.formatMessage({
								id: "somethingWentWrongPleaseTryAgainLater",
							})
						);
					}
				} else {
					toast.success(
						intl.formatMessage({
							id: "theCreditsHasBeenAddedSuccessfully",
						})
					);
					onHide();
				}
			});
		}
	};

	return (
		<Modal
			className="modal-500"
			centered
			show={show}
			onHide={onHide}
			data-testid="add-credits-modal"
		>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="addCredits" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Label className="default-input-label">
							<FormattedMessage id="credits" />
						</Form.Label>
						<Form.Control
							className="default-input"
							type="number"
							placeholder={`${intl.formatMessage({
								id: "credits",
							})}`}
							{...register("amount", {
								required: true,
							})}
						/>
						{errors.amount && (
							<p className="mb-0 form-field-error">
								{errors.amount.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mt-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="description" />
						</Form.Label>
						<Form.Control
							className="default-input"
							type="text"
							placeholder={`${intl.formatMessage({
								id: "description",
							})}`}
							{...register("description")}
						/>
						{errors.description && (
							<p className="mb-0 form-field-error">
								{errors.description.message}
							</p>
						)}
					</Form.Group>
					<div className="d-flex justify-content-end mt-3">
						<button
							data-testid="add-credits"
							type="submit"
							className="ice-button w-100"
						>
							<FormattedMessage id="addCredits" />
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default AddCredits;
