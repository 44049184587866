// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.total-section {
    background-color: #F3FAFF;
    min-height: 80px !important;
    min-width: 170px;
    box-shadow: 0px 0px 25px 5px rgb(66 147 208 / 25%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #144074 !important;
}

.total-charts-block {
    padding: 0px 20px 20px;
    background: #F3FAFF;
    box-shadow: 0px 0px 25px 5px rgb(66 147 208 / 25%);
}

@media (max-width: 640px) {
    .page-wrapper > .row > .col {
        margin-top: 5px;
        margin-bottom: 0px !important;
        width: 100% !important;
    }

    .total-section {
        min-height: 50px !important;
        min-width: 120px;
    }

    .h6, h6 {
        font-size: 0.8rem;
    }

    .h3, h3 {
        font-size: 1.2rem;
    }
}

@media (max-width: 992px) {
    .col-lg-8, .col-lg-4{
        padding-right: 10px !important;
        padding-left: 10px !important;
        margin-bottom: 20px !important;
    } 

    .total-charts-block {
        justify-content: center;
    }

    .page-wrapper-graphs {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/TotalOrders/TotalOrders.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,gBAAgB;IAChB,kDAAkD;IAClD,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,kDAAkD;AACtD;;AAEA;IACI;QACI,eAAe;QACf,6BAA6B;QAC7B,sBAAsB;IAC1B;;IAEA;QACI,2BAA2B;QAC3B,gBAAgB;IACpB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,8BAA8B;QAC9B,6BAA6B;QAC7B,8BAA8B;IAClC;;IAEA;QACI,uBAAuB;IAC3B;;IAEA;QACI,6BAA6B;QAC7B,8BAA8B;IAClC;AACJ","sourcesContent":[".total-section {\n    background-color: #F3FAFF;\n    min-height: 80px !important;\n    min-width: 170px;\n    box-shadow: 0px 0px 25px 5px rgb(66 147 208 / 25%);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    color: #144074 !important;\n}\n\n.total-charts-block {\n    padding: 0px 20px 20px;\n    background: #F3FAFF;\n    box-shadow: 0px 0px 25px 5px rgb(66 147 208 / 25%);\n}\n\n@media (max-width: 640px) {\n    .page-wrapper > .row > .col {\n        margin-top: 5px;\n        margin-bottom: 0px !important;\n        width: 100% !important;\n    }\n\n    .total-section {\n        min-height: 50px !important;\n        min-width: 120px;\n    }\n\n    .h6, h6 {\n        font-size: 0.8rem;\n    }\n\n    .h3, h3 {\n        font-size: 1.2rem;\n    }\n}\n\n@media (max-width: 992px) {\n    .col-lg-8, .col-lg-4{\n        padding-right: 10px !important;\n        padding-left: 10px !important;\n        margin-bottom: 20px !important;\n    } \n\n    .total-charts-block {\n        justify-content: center;\n    }\n\n    .page-wrapper-graphs {\n        padding-left: 10px !important;\n        padding-right: 10px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
