import { OverlayTrigger } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FC } from "react";

import { ORDER_SYNCHRONIZATION_STATUS_TO_TEXT } from "../../../../helpers/constants";

type OrderStatusProps = {
    status:
        | "success"
        | "pending_synchronization"
        | "synchronization_failed"
        | "status_update_failed"
        | "failed";
};

const OrderStatus: FC<OrderStatusProps> = ({ status }) => {
    const intl = useIntl();

    return (
        <>
            <OverlayTrigger
                placement="right"
                overlay={
                    <div className="order-status-overlay">
                        {intl.formatMessage({
                            id: ORDER_SYNCHRONIZATION_STATUS_TO_TEXT[status],
                        })}
                    </div>
                }
            >
                <button className="no-styles-button">
                    <div className={`order-status-circle ${status}`}></div>
                </button>
            </OverlayTrigger>
        </>
    );
};

export default OrderStatus;
