export const TOTAL_ORDERS_CHART_OPTIONS = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false,
			position: "top" as const,
		},
		title: {
			display: false,
			text: "Chart.js Line Chart",
		},
	},
	interaction: {
		intersect: false,
		mode: "index" as "index",
	},
	scales: {
		y: {
			min: 0,
			ticks: {
				display: false,
				beginAtZero: true,
			},
			grid: {
				drawBorder: false,
				display: false,
			},
			border: {
				display: false,
			},
		},
		x: {
			grid: {
				drawBorder: false,
				display: false,
			},
			border: {
				display: false,
			},
			ticks: {
				fontSize: 14,
				font: {
					weight: "bold" as "bold",
					color: "#191919",
				},
				maxRotation: 0,
				autoSkip: true,
				autoSkipPadding: 10,
			},
		},
	},
};
