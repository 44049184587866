import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
	useGetStripePaymentMethodQuery,
	useUpdateStripePaymentMethodMutation,
} from "../../../api/paymentsAPI";

export type TEditStripePaymentMethod = {
	default: string;
};

type TStripePaymentMethodProps = {
	show: boolean;
	onHide: () => void;
	id: number;
	refetchPaymentMethodsAfterUpdate: () => void;
};

const StripePaymentMethod: FC<TStripePaymentMethodProps> = ({
	show,
	onHide,
	id,
	refetchPaymentMethodsAfterUpdate,
}) => {
	const intl = useIntl();

	const [updateStripeMethod] = useUpdateStripePaymentMethodMutation();
	const { data } = useGetStripePaymentMethodQuery(id);

	const { register, handleSubmit, setValue } =
		useForm<TEditStripePaymentMethod>();

	const onSubmit = (data: TEditStripePaymentMethod) => {
		updateStripeMethod({
			data: { default: data.default === "true" ? true : false },
			id,
		}).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({ id: "somethingWentWrong" })
					);
				}
			} else {
				onHide();
				setTimeout(() => {
					refetchPaymentMethodsAfterUpdate();
				}, 3000);
			}
		});
	};

	useEffect(() => {
		if (data) {
			setValue("default", data.default ? "true" : "false");
		}
	}, [data, setValue]);

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="stripePaymentMethod" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex flex-column w-100 pt-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<div className="setting-users__block-info-wrapper">
						<div className="settings-users__block-info-row">
							<FormattedMessage id="emailWithDots" />{" "}
							<span className="settings-users__block-info-row-value">
								**** **** **** {data?.last4}
							</span>
						</div>
						<div className="settings-users__block-info-row">
							<FormattedMessage id="brandWithDots" />{" "}
							<span className="settings-users__block-info-row-value">
								{data?.brand}
							</span>
						</div>
						<div className="settings-users__block-info-row">
							<FormattedMessage id="expirationMonthWithDots" />{" "}
							<span className="settings-users__block-info-row-value">
								{data?.exp_month}
							</span>
						</div>
						<div className="settings-users__block-info-row">
							<FormattedMessage id="expirationYearWithDots" />{" "}
							<span className="settings-users__block-info-row-value">
								{data?.exp_year}
							</span>
						</div>
						<div className="settings-users__block-info-row d-flex align-items-center gap-2">
							<FormattedMessage id="defaultCardWithDots" />{" "}
							<Form.Select
								className="default-input"
								style={{ width: "110px" }}
								{...register("default")}
							>
								<option value="true">
									<FormattedMessage id="yes" />
								</option>
								<option value="false">
									<FormattedMessage id="no" />
								</option>
							</Form.Select>
						</div>
					</div>
					<div className="d-flex justify-content-end mt-3">
						<button className="ice-button w-100">
							<FormattedMessage id="save" />
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default StripePaymentMethod;
