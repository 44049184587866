import { FaLayerGroup, FaListUl } from "react-icons/fa6";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";

import { useGetIntegrationsQuery } from "../../../api/integrationsAPI";
import { TGetIntegrationsResults } from "../../../api/api.types";

type TBulkOriginationProps = {
    show: boolean;
    onHide: () => void;
    setOpenBulk: (value: boolean) => void;
    setBulkUploadIntegration: (value: number | null) => void;
};

const BulkOrigination: FC<TBulkOriginationProps> = ({
    show,
    onHide,
    setOpenBulk,
    setBulkUploadIntegration,
}) => {
    const [availableIntegrations, setAvailableIntegrations] = useState<
        TGetIntegrationsResults[]
    >([]);

    const { data } = useGetIntegrationsQuery();

    const handleSkipAndOpenBulkUpload = () => {
        setBulkUploadIntegration(null);
        setOpenBulk(true);
        onHide();
    };

    const handleSelectIntegrationAndOpenBulkUpload = (
        integration: TGetIntegrationsResults
    ) => {
        setBulkUploadIntegration(integration.id);
        setOpenBulk(true);
        onHide();
    };

    useEffect(() => {
        if (data) {
            const filteredIntegrations = data.results.filter(
                (integration) =>
                    integration.partner === "Off Amazon Integration"
            );

            setAvailableIntegrations(filteredIntegrations);
        }
    }, [data]);

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title className="d-flex align-items-center gap-2">
                    <FaListUl size={24} />
                    <FormattedMessage id="selectProductsOrigination" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ gap: "12px" }}
                className="pt-0 d-flex flex-column"
            >
                <p className="bulk-upload-description">
                    <FormattedMessage id="hereIsAListOfIntegrationsYouCanUseToUpload" />
                </p>
                {availableIntegrations && availableIntegrations.length > 0 ? (
                    availableIntegrations.map((integration) => (
                        <div
                            className="d-flex align-items-center justify-content-between gap-2"
                            key={integration.id}
                        >
                            <div className="d-flex align-items-center gap-2">
                                <FaLayerGroup size={24} />
                                <span className="bulk-origination-integration">
                                    {integration.name} ({integration.partner})
                                </span>
                            </div>
                            <button
                                onClick={() =>
                                    handleSelectIntegrationAndOpenBulkUpload(
                                        integration
                                    )
                                }
                                className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0 w-fit"
                            >
                                <FormattedMessage id="select" />
                            </button>
                        </div>
                    ))
                ) : (
                    <div className="text-center d-flex flex-column gap-2 align-items-center">
                        <FaLayerGroup size={32} />
                        <FormattedMessage id="youHaveNoOffAmazonIntegrations" />
                    </div>
                )}
                <div className="d-flex align-items-center justify-content-end mt-2 flex-column gap-4">
                    <div className="bulk-origination-divider">
                        <span>
                            <FormattedMessage id="or" />
                        </span>
                    </div>
                    <button
                        onClick={handleSkipAndOpenBulkUpload}
                        className="bulk-upload-choose-files w-100"
                    >
                        <FormattedMessage id="skipAndSelectIntegrationLater" />
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BulkOrigination;
