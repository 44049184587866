import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC } from "react";

import { useAddKytIntegrationMutation } from "../../../api/integrationsAPI";

import "./AddKytIntegration.css";

export type TAddKytIntegrationFormData = {
    name: string;
    access_token: string;
    access_key: string;
};

type TAddKytIntegrationProps = {
    show: boolean;
    onHide: () => void;
};

const AddKytIntegration: FC<TAddKytIntegrationProps> = ({ show, onHide }) => {
    const intl = useIntl();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TAddKytIntegrationFormData>();

    const [addKytIntegration] = useAddKytIntegrationMutation();

    const handleAddIntegration = (data: TAddKytIntegrationFormData) => {
        addKytIntegration(data).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({
                            id: "somethingWentWrongPleaseTryAgainLater",
                        })
                    );
                }
            } else {
                toast.success(
                    intl.formatMessage({ id: "integrationAddedSuccessfully" })
                );
            }
        });
        onHide();
    };

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="addNewKytIntegration" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0 d-flex flex-column align-items-center">
                <Form
                    onSubmit={handleSubmit(handleAddIntegration)}
                    className="d-flex flex-column align-items-center w-100"
                >
                    <Form.Group className="w-100">
                        <Form.Label className="form-input-label ps-1">
                            <FormattedMessage id="integrationName" />
                            <span className="red-color"> *</span>
                        </Form.Label>
                        <Form.Control
                            className="w-100 form-input-field"
                            placeholder={intl.formatMessage({
                                id: "integrationName",
                            })}
                            {...register("name", {
                                required: intl.formatMessage({
                                    id: "thisFieldIsRequired",
                                }),
                            })}
                            type="text"
                        />
                        {errors.name && (
                            <p className="mb-0 form-field-error">
                                {errors.name.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="w-100 mt-2">
                        <Form.Label className="form-input-label ps-1">
                            <FormattedMessage id="accessToken" />
                            <span className="red-color"> *</span>
                        </Form.Label>
                        <Form.Control
                            className="w-100 form-input-field"
                            placeholder={intl.formatMessage({
                                id: "accessToken",
                            })}
                            {...register("access_token", {
                                required: intl.formatMessage({
                                    id: "thisFieldIsRequired",
                                }),
                            })}
                            type="text"
                        />
                        {errors.access_token && (
                            <p className="mb-0 form-field-error">
                                {errors.access_token.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="w-100 mt-2">
                        <Form.Label className="form-input-label ps-1">
                            <FormattedMessage id="accessKey" />
                            <span className="red-color"> *</span>
                        </Form.Label>
                        <Form.Control
                            className="w-100 form-input-field"
                            placeholder={intl.formatMessage({
                                id: "accessKey",
                            })}
                            {...register("access_key", {
                                required: intl.formatMessage({
                                    id: "thisFieldIsRequired",
                                }),
                            })}
                            type="text"
                        />
                        {errors.access_key && (
                            <p className="mb-0 form-field-error">
                                {errors.access_key.message}
                            </p>
                        )}
                    </Form.Group>
                    <div className="d-flex align-items-center mt-3 justify-content-end w-100">
                        <button className="dashboard-menu-button" type="submit">
                            <FormattedMessage id="connect" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddKytIntegration;
