import { FormattedMessage } from "react-intl";
import { useState } from "react";

import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { useGetTenantAffiliateQuery } from "../../../../api/affiliateAPI";
import { useAppSelector } from "../../../../redux/hooks";

import ChangeAffiliateCode from "../../../modals/ChangeAffiliateCode/ChangeAffiliateCode";

const SettingsAffiliateBlock = () => {
	const [isPublic, subdomain] = useDetectSubdomainOrPublic();
	const [changeCode, setChangeCode] = useState(false);

	const isStaff = useAppSelector((state) => state.auth.isStaff);

	const { data: affiliateData } = useGetTenantAffiliateQuery(subdomain, {
		skip: isPublic || !subdomain || isStaff,
	});

	const handleChangeCode = () => {
		setChangeCode(true);
	};

	return (
		<div className="settings-users__block">
			<div className="settings-users__block-body">
				<div className="settings-users__block-body-header">
					<h5 className="settings-users__block-title">
						<FormattedMessage id="affiliateCode" />
					</h5>
					<div className="settings-users__block-body-header-buttons">
						<button
							data-testid="change-affiliate-code"
							onClick={handleChangeCode}
							className="ice-button"
						>
							{affiliateData ? (
								<FormattedMessage id="changeCode" />
							) : (
								<FormattedMessage id="addCode" />
							)}
						</button>
					</div>
				</div>
				<div className="default-table-no-wrapper">
					{!affiliateData ? (
						<div
							className="not-have-affiliate"
							id="no-active-affiliate"
						>
							<FormattedMessage id="yourDoNotHaveActiveAffiliateCode" />
							<button
								data-testid="add-affiliate-code"
								onClick={handleChangeCode}
								className="ice-button mt-2"
							>
								<FormattedMessage id="addCode" />
							</button>
						</div>
					) : (
						<table className="table">
							<thead className="thead-light">
								<tr>
									<th>
										<FormattedMessage id="activeCode" />
									</th>
									<th>
										<FormattedMessage id="benefitsDiscount" />
									</th>
									<th>
										<FormattedMessage id="expirationDate" />
									</th>
									<th>
										<FormattedMessage id="active" />
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									data-testid="affiliate-row"
									key={affiliateData?.id}
								>
									<td className="text-center">
										{affiliateData?.program.code}
									</td>
									<td className="text-center">
										{
											affiliateData?.program
												.benefits_discount
										}{" "}
										%
									</td>
									<td className="text-center">
										{affiliateData?.get_period}
									</td>
									<td className="text-center">
										{affiliateData?.is_active ? (
											<FormattedMessage id="yes" />
										) : (
											<FormattedMessage id="no" />
										)}
									</td>
								</tr>
							</tbody>
						</table>
					)}
				</div>
			</div>
			{changeCode && (
				<ChangeAffiliateCode
					show={changeCode}
					onHide={() => setChangeCode(false)}
				/>
			)}
		</div>
	);
};

export default SettingsAffiliateBlock;
