import { FormattedMessage } from "react-intl";
import { FC } from "react";

import { TCategory } from "../../../../api/api.types";

type CatalogFilterProps = {
	categories: TCategory[] | undefined;
	handlePage: (page: string) => void;
	handleCategoryFilter: (
		value: string,
		handlePage?: (value: string) => void
	) => void;
	categoryFilter: string;
	clearFilters: (handlePage: (page: string) => void) => void;
	background?: string;
};

const CatalogFilter: FC<CatalogFilterProps> = ({
	categories,
	handlePage,
	handleCategoryFilter,
	categoryFilter,
	clearFilters,
	background,
}) => {
	return (
		<div
			style={{ backgroundColor: background }}
			className="catalog__filters"
		>
			<div className="catalog__filter-block">
				<h6 className="catalog__filter-block-title">
					<FormattedMessage id="categories" />
				</h6>
				<div className="catalog__filter-line"></div>
				<div className="catalog__filter-list-items">
					{categories &&
						categories.map((category) => (
							<div
								data-testid="filter-item"
								onClick={() =>
									handleCategoryFilter(
										String(category.id),
										handlePage
									)
								}
								className={`catalog__filter-item ${categoryFilter === String(category.id) ? "selected" : ""}`}
								key={category.id}
							>
								{category.name}
							</div>
						))}
				</div>
			</div>
			<button
				data-testid="clear-filters"
				onClick={() => clearFilters(handlePage)}
				className="catalog__clear-filters-button"
			>
				<FormattedMessage id="clearFilters" />
			</button>
		</div>
	);
};

export default CatalogFilter;
