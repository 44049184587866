import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { TbFilter } from "react-icons/tb";
import { toast } from "react-toastify";

import {
	useDeleteJobMutation,
	useGetJobsQuery,
	useRunJobManuallyMutation,
	useUpdateJobMutation,
} from "../../../../../api/jobsAPI";
import {
	SYSTEM_FILTERS,
	SYSTEM_SCHEDULE_STATUS_TO_INFO,
} from "../../../../../helpers/constants";
import useClickOutsideOfBlock from "../../../../../hooks/useClickOutsideOfBlock";
import usePagination from "../../../../../hooks/usePagination";
import useFilters from "../../../../../hooks/useFilters";
import { TJob } from "../../../../../api/api.types";

import PagePagination from "../../../../components/common/Pagination/PagePagination";
import FilterBlock from "../../../../components/common/FilterBlock/FilterBlock";
import EmptyState from "../../../../components/common/EmptyState/EmptyState";
import EditTaskArgs from "../../../../modals/EditTaskArgs/EditTaskArgs";
import Loading from "../../../../components/common/Loading/Loading";
import CreateTask from "../../../../modals/CreateTask/CreateTask";
import Search from "../../../../components/common/Search/Search";

import Arrow from "../../../../assets/images/arrow.svg";

import "../System.css";

const SystemSchedule = () => {
	const [expandFilters, setExpandFilters] = useState(false);
	const [createTask, setCreateTask] = useState(false);
	const [job, setJob] = useState<null | TJob>(null);
	const [editArgs, setEditArgs] = useState(false);

	const filterRef = useRef<HTMLDivElement>(null);

	const navigate = useNavigate();

	const {
		search,
		handleSearch,
		handleSearchFilter,
		searchFilter,
		onKeyDownSearch,
		pageSize,
		handleDefaultFilter,
		defaultFilter,
	} = useFilters();
	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		pageSize
	);
	useClickOutsideOfBlock({
		blockRef: filterRef,
		showValue: expandFilters,
		setShowValue: setExpandFilters,
	});

	const [deleteJob] = useDeleteJobMutation();
	const [runJob] = useRunJobManuallyMutation();
	const [updateJob] = useUpdateJobMutation();

	const {
		data: jobsData,
		isLoading,
		isFetching,
	} = useGetJobsQuery({
		activePage,
		searchFilter,
		pageSize,
		defaultFilter,
	});

	const handleNavigateToDetails = (id: string) => {
		navigate(`/dashboard/system-schedule/${id}`);
	};

	const handleChangePeriod = (
		e: ChangeEvent<HTMLSelectElement>,
		id: string
	) => {
		updateJob({
			data: {
				periodic_schedule: e.target.value,
			},
			id,
		}).then((res) => {
			if ("error" in res) {
				toast.error(<FormattedMessage id="somethingWentWrong" />);
			} else {
				toast.success(<FormattedMessage id="intervalUpdated" />);
			}
		});
	};

	const handleRunOnceJob = (e: ChangeEvent<HTMLInputElement>, id: string) => {
		if (e.target.checked) {
			updateJob({
				data: {
					is_one_off: true,
				},
				id,
			});
		} else {
			updateJob({
				data: {
					is_one_off: false,
				},
				id,
			});
		}
	};

	const handleDisableJob = (e: ChangeEvent<HTMLInputElement>, id: string) => {
		if (e.target.checked) {
			updateJob({
				data: {
					disabled: true,
				},
				id,
			});
		} else {
			updateJob({
				data: {
					disabled: false,
				},
				id,
			});
		}
	};

	const handleOpenEditModal = (job: TJob) => {
		setJob(job);
		setEditArgs(true);
	};

	const handleRunManually = (id: string) => {
		runJob(id).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;
				if (status !== 403) {
					toast.error(<FormattedMessage id="somethingWentWrong" />);
				}
			} else {
				toast.success(<FormattedMessage id="jobStarted" />);
			}
		});
	};

	const handleDeleteJob = (id: string) => {
		deleteJob(id);
		if (jobsData && jobsData.results?.length === 1 && activePage !== "1") {
			handlePage(String(Number(activePage) - 1));
		}
	};

	useEffect(() => {
		if (jobsData) {
			handlePagesCount(jobsData.count);
		}
	}, [jobsData, handlePagesCount]);

	return (
		<div className="system-schedule__content-wrapper">
			<div className="system-schedule__header">
				<Search
					search={search}
					handleSearchFilter={handleSearchFilter}
					handleSearch={handleSearch}
					onKeyDownSearch={onKeyDownSearch}
					handlePage={handlePage}
				/>
				<button
					onClick={() => setCreateTask(true)}
					className="ice-button"
					data-testid="create-task-button"
				>
					<FormattedMessage id="createTask" />
				</button>
			</div>
			<div
				className="system-schedule__content"
				data-testid="system-schedule-table"
			>
				<div className="system-schedule__filters">
					<h5 className="system-schedule__page-title">
						<FormattedMessage id="scheduleManagement" />
					</h5>
					<div className="system-schedule__filters-buttons">
						{jobsData && jobsData.results.length > 0 && (
							<PagePagination
								pages={pages}
								activePage={activePage}
								handlePage={handlePage}
							/>
						)}
						<div
							ref={filterRef}
							className="orders__page-filters-wrapper"
						>
							<div
								onClick={() => setExpandFilters(!expandFilters)}
								className={`orders__page-filters-button ${expandFilters ? "expanded" : ""}`}
								data-testid="filter-button-system"
							>
								<TbFilter
									size={22}
									color={
										expandFilters ? "#FFFFFF" : "#666666"
									}
								/>
							</div>
							{expandFilters && (
								<FilterBlock
									selectedFilters={{
										status: defaultFilter,
									}}
									filters={SYSTEM_FILTERS}
									handlers={{
										status: handleDefaultFilter,
									}}
									handlePage={handlePage}
									onClose={() => setExpandFilters(false)}
								/>
							)}
						</div>
					</div>
				</div>
				<Loading isLoading={isLoading || isFetching}>
					{jobsData && jobsData.results.length === 0 ? (
						<EmptyState message="noSchedulesFound" />
					) : (
						<div className="default-table">
							<table className="table">
								<thead className="thead-light">
									<tr>
										<th>
											<FormattedMessage id="name" />
										</th>
										<th>
											<FormattedMessage id="interval" />
										</th>
										<th>
											<FormattedMessage id="lastRun" />
										</th>
										<th>
											<FormattedMessage id="status" />
										</th>
										<th>
											<FormattedMessage id="disabled" />
										</th>
										<th>
											<FormattedMessage id="runOnce" />
										</th>
										<th>
											<FormattedMessage id="action" />
										</th>
									</tr>
								</thead>
								<tbody>
									{jobsData &&
										jobsData.results.length > 0 &&
										jobsData.results.map((job) => (
											<tr
												key={job.id}
												data-testid="table-system-row"
											>
												<td className="text-center max-width-td">
													<span
														data-testid="function-name"
														className="wrapped-table-row"
													>
														{job.function_name}
													</span>
												</td>
												<td className="text-center">
													<Form.Select
														onChange={(e) =>
															handleChangePeriod(
																e,
																job.id
															)
														}
														defaultValue={
															job.periodic_schedule
														}
														className="default-input"
														data-testid="dropdown-interval"
													>
														<option value="* * * * *">
															<FormattedMessage id="everyMinute" />
														</option>
														<option value="0 * * * *">
															<FormattedMessage id="everyHour" />
														</option>
														<option value="0 */6 * * *">
															<FormattedMessage id="every6Hours" />
														</option>
														<option value="0 1 * * *">
															<FormattedMessage id="everyDay" />
														</option>
														<option value="0 0 */3 * *">
															<FormattedMessage id="every3days" />
														</option>
														<option value="0 0 * * 0">
															<FormattedMessage id="everyWeek" />
														</option>
														<option value="0 0 1 * *">
															<FormattedMessage id="everyMonth" />
														</option>
														<option value="0 0 1 1 *">
															<FormattedMessage id="everyYear" />
														</option>
													</Form.Select>
												</td>
												<td className="text-center">
													{
														job.last_run_at?.split(
															"T"
														)[0]
													}
												</td>
												<td
													className="text-center"
													data-testid="table-status-row"
												>
													<div className="system-schedule__status">
														<div
															style={{
																backgroundColor: `${SYSTEM_SCHEDULE_STATUS_TO_INFO[job.status as keyof typeof SYSTEM_SCHEDULE_STATUS_TO_INFO]?.color || "#D24023"}`,
															}}
															className="system-schedule__status-circle"
														></div>
														<FormattedMessage
															id={
																SYSTEM_SCHEDULE_STATUS_TO_INFO[
																	job.status as keyof typeof SYSTEM_SCHEDULE_STATUS_TO_INFO
																]?.label ||
																"failed"
															}
														/>
													</div>
												</td>
												<td className="text-center">
													<Form.Check
														data-testid="disable-checkbox"
														className="system-schedule__checkbox"
														onChange={(e) =>
															handleDisableJob(
																e,
																job.id
															)
														}
														checked={job.disabled}
													/>
												</td>
												<td className="text-center">
													<Form.Check
														data-testid="run-checkbox"
														className="system-schedule__checkbox"
														onChange={(e) =>
															handleRunOnceJob(
																e,
																job.id
															)
														}
														checked={job.is_one_off}
													/>
												</td>
												<td className="text-center">
													<Dropdown
														className="d-flex justify-content-center"
														data-testid="dropdown"
													>
														<Dropdown.Toggle className="user-header-dropdown default-dropdown-toggle p-0">
															<div className="default-dropdown-button">
																<FormattedMessage id="select" />
																<img
																	className="arrow-icon"
																	src={Arrow}
																	alt=""
																/>
															</div>
														</Dropdown.Toggle>
														<Dropdown.Menu className="default-dropdown-menu-wrapper">
															<div
																className="default-dropdown-menu"
																data-testid="dropdown-menu"
															>
																<Dropdown.Item
																	onClick={() =>
																		handleNavigateToDetails(
																			job.id
																		)
																	}
																	className="default-dropdown-item"
																	data-testid="detail"
																>
																	<FormattedMessage id="details" />
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={() =>
																		handleOpenEditModal(
																			job
																		)
																	}
																	className="default-dropdown-item"
																	data-testid="edit-arguments"
																>
																	<FormattedMessage id="argsThreeDots" />
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={() =>
																		handleRunManually(
																			job.id
																		)
																	}
																	className="default-dropdown-item"
																	data-testid="run-manually"
																>
																	<FormattedMessage id="runManually" />
																</Dropdown.Item>
																<Dropdown.Item
																	onClick={() =>
																		handleDeleteJob(
																			job.id
																		)
																	}
																	className="default-dropdown-item"
																	data-testid="remove-job"
																>
																	<FormattedMessage id="remove" />
																</Dropdown.Item>
															</div>
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					)}
				</Loading>
			</div>
			{createTask && (
				<CreateTask
					show={createTask}
					onHide={() => setCreateTask(false)}
				/>
			)}
			{editArgs && job && (
				<EditTaskArgs
					job={job}
					show={editArgs}
					onHide={() => setEditArgs(false)}
				/>
			)}
		</div>
	);
};

export default SystemSchedule;
