// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.custom-pagination {
	display: flex;
	align-items: center;
	gap: 10px;
}

.page-pagination-button {
	color: #666666;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	padding: 0;
}

.page-pagination-button.disabled {
	color: #e6e6e6;
	cursor: default;
	padding: 0;
}

.active-page {
	color: #0085ff;
}

.total-pages {
	color: #191919;
}

.pagination-info {
	display: flex;
	gap: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/Pagination/PagePagination.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,yBAAyB;CACzB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;AACV;;AAEA;CACC,cAAc;CACd,eAAe;CACf,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,6BAA6B;CAC7B,UAAU;AACX;;AAEA;CACC,cAAc;CACd,eAAe;CACf,UAAU;AACX;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,aAAa;CACb,QAAQ;AACT","sourcesContent":[".pagination-container {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\talign-items: center;\n}\n\n.custom-pagination {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 10px;\n}\n\n.page-pagination-button {\n\tcolor: #666666;\n\tcursor: pointer;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tbackground-color: transparent;\n\tpadding: 0;\n}\n\n.page-pagination-button.disabled {\n\tcolor: #e6e6e6;\n\tcursor: default;\n\tpadding: 0;\n}\n\n.active-page {\n\tcolor: #0085ff;\n}\n\n.total-pages {\n\tcolor: #191919;\n}\n\n.pagination-info {\n\tdisplay: flex;\n\tgap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
