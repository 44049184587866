import { BiErrorCircle } from "react-icons/bi";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { FC } from "react";

import { TBulkUploadError } from "../../../api/api.types";

type TUploadOrdersErrors = {
	show: boolean;
	onHide: () => void;
	errors: TBulkUploadError[];
};

const UploadOrdersErrors: FC<TUploadOrdersErrors> = ({
	show,
	onHide,
	errors,
}) => {
	return (
		<Modal className="modal-600" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="ordersUploadErrors" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{ gap: "12px" }}
				className="pt-0 d-flex flex-column"
			>
				<p className="upload-orders__description">
					<FormattedMessage id="theFollowingErrorsOccurredDuringTheOrdersUpload" />
				</p>
				<div className="bulk-upload-errors-list">
					{errors.map((error, index) => (
						<div key={index} className="bulk-upload-errors-item">
							<p className="bulk-upload-errors-title">
								<FormattedMessage id="issueInColumn" /> "
								{error.column}", <FormattedMessage id="row" /> "
								{error.row}":
							</p>
							<span className="bulk-upload-errors-description">
								{error.message}
							</span>
						</div>
					))}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default UploadOrdersErrors;
