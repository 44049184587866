import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowRoundUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { TbFilter } from "react-icons/tb";
import { toast } from "react-toastify";

import {
	useCancelOrderMutation,
	useFulfillOrderMutation,
	useGetOrdersQuery,
} from "../../../../api/ordersAPI";
import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { TBulkUploadError, TGetOrderResult } from "../../../../api/api.types";
import useClickOutsideOfBlock from "../../../../hooks/useClickOutsideOfBlock";
import {
	ORDER_STATUS_TRANSLATIONS,
	ORDERS_FILTERS,
} from "../../../../helpers/constants";
import usePagination from "../../../../hooks/usePagination";
import useFilters from "../../../../hooks/useFilters";

import PagePagination from "../../../components/common/Pagination/PagePagination";
import UploadOrdersErrors from "../../../modals/UploadOrders/UploadOrdersErrors";
import FilterBlock from "../../../components/common/FilterBlock/FilterBlock";
import EmptyState from "../../../components/common/EmptyState/EmptyState";
import PayPalPayment from "../../../modals/PayPalPayment/PayPalPayment";
import OrderStatus from "../../../components/layout/Orders/OrderStatus";
import UploadOrders from "../../../modals/UploadOrders/UploadOrders";
import CreateOrder from "../../../modals/CreateOrder/CreateOrder";
import Loading from "../../../components/common/Loading/Loading";
import Search from "../../../components/common/Search/Search";

import Arrow from "../../../assets/images/arrow.svg";

import "./Orders.css";

const Orders = () => {
	const [bulkUploadErrors, setBulkUploadErrors] = useState<
		TBulkUploadError[] | null
	>(null);
	const [orderPay, setOrderPay] = useState<TGetOrderResult | null>(null);
	const [showUploadOrders, setShowUploadOrders] = useState(false);
	const [showCreateOrder, setShowCreateOrder] = useState(false);
	const [paypalPayment, setPaypalPayment] = useState(false);
	const [expandFilters, setExpandFilters] = useState(false);
	const [createdAtSortOption, setCreatedAtSortOption] =
		useState<string>("-imported_date");

	const filterRef = useRef<HTMLDivElement>(null);

	const {
		pageSize,
		defaultFilter,
		handleDefaultFilter,
		search,
		handleSearch,
		handleSearchFilter,
		onKeyDownSearch,
		searchFilter,
	} = useFilters();
	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		pageSize
	);
	const [isPublic] = useDetectSubdomainOrPublic();
	useClickOutsideOfBlock({
		blockRef: filterRef,
		showValue: expandFilters,
		setShowValue: setExpandFilters,
	});

	const intl = useIntl();
	const navigate = useNavigate();

	const [cancelOrder, { isLoading: isLoadingCancel }] =
		useCancelOrderMutation();
	const [fulfillOrder, { isLoading: isLoadingFulfill }] =
		useFulfillOrderMutation();

	const isCancellable = (status: String) => {
		if (
			status === "New" ||
			status === "In Progress" ||
			status === "Return In Progress"
		) {
			return true;
		} else {
			return false;
		}
	};

	const isShippable = (status: String) => {
		if (status === "New") {
			return true;
		} else {
			return false;
		}
	};

	const {
		data: ordersData,
		refetch,
		isLoading,
		isFetching,
	} = useGetOrdersQuery({
		activePage,
		pageSize,
		defaultFilter,
		searchFilter,
		dateSort: createdAtSortOption,
	});

	const handleCloseManuallyPayWithRefetch = () => {
		setPaypalPayment(false);
		setOrderPay(null);
		refetch();
	};

	const handleClosePayManually = () => {
		setPaypalPayment(false);
		setOrderPay(null);
	};

	const handlePayManually = (order: TGetOrderResult) => {
		setOrderPay(order);
		setPaypalPayment(true);
	};

	const handleChangeSortFormat = () => {
		if (createdAtSortOption === "-imported_date") {
			setCreatedAtSortOption("imported_date");
		} else {
			setCreatedAtSortOption("-imported_date");
		}

		if (activePage !== "1") {
			handlePage("1");
		}
	};

	const handleFulfillOrder = (id: number) => {
		fulfillOrder(id).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({
							id: "somethingWentWrongPleaseTryAgainLater",
						})
					);
				}
			}
		});
	};

	const handleCancelOrder = (id: number) => {
		cancelOrder(id).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({
							id: "somethingWentWrongPleaseTryAgainLater",
						})
					);
				}
			}
		});
	};

	const navigateToOrderDetails = (id: number) => {
		navigate(`/dashboard/order-details/${id}`);
	};

	const handleOpenFulfillmentSelectModal = (type: string) => {
		if (type === "manual") {
			setShowCreateOrder(true);
		} else {
			setShowUploadOrders(true);
		}
	};

	useEffect(() => {
		if (ordersData) {
			handlePagesCount(ordersData.count);
		}
	}, [ordersData, handlePagesCount]);

	return (
		<div data-testid="content" className="orders_content-wrapper">
			<div className="orders_header">
				<Search
					search={search}
					handleSearchFilter={handleSearchFilter}
					handleSearch={handleSearch}
					onKeyDownSearch={onKeyDownSearch}
					handlePage={handlePage}
				/>
				{!isPublic && (
					<div className="orders__buttons-wrapper">
						<button
							data-testid="create-order-button"
							onClick={() =>
								handleOpenFulfillmentSelectModal("manual")
							}
							className="ice-button"
						>
							<FormattedMessage id="createOrder" />
						</button>
						<button
							data-testid="upload-orders-button"
							onClick={() =>
								handleOpenFulfillmentSelectModal("bulk")
							}
							className="ice-button"
						>
							<FormattedMessage id="uploadOrders" />
						</button>
					</div>
				)}
			</div>
			<div className="orders_content">
				<div className="orders__page-filters">
					<h5 className="orders__page-title">
						<FormattedMessage id="orders" />
					</h5>
					<div className="orders__page-filters-actions">
						{ordersData?.results &&
							ordersData?.results.length > 0 && (
								<PagePagination
									pages={pages}
									activePage={activePage}
									handlePage={handlePage}
								/>
							)}
						<div
							ref={filterRef}
							className="orders__page-filters-wrapper"
						>
							<div
								data-testid="filter-button"
								onClick={() => setExpandFilters(!expandFilters)}
								className={`orders__page-filters-button ${expandFilters ? "expanded" : ""}`}
							>
								<TbFilter
									size={22}
									color={
										expandFilters ? "#FFFFFF" : "#666666"
									}
								/>
							</div>
							{expandFilters && (
								<FilterBlock
									selectedFilters={{
										fulfillments__fulfillment_line_items__status:
											defaultFilter,
									}}
									filters={ORDERS_FILTERS}
									handlers={{
										fulfillments__fulfillment_line_items__status:
											handleDefaultFilter,
									}}
									handlePage={handlePage}
									onClose={() => setExpandFilters(false)}
								/>
							)}
						</div>
					</div>
				</div>
				<Loading
					isLoading={
						isLoading ||
						isLoadingCancel ||
						isLoadingFulfill ||
						isFetching
					}
				>
					{ordersData && ordersData.results.length === 0 ? (
						<EmptyState message="noOrdersFound" />
					) : (
						<div className="orders__table-wrapper">
							<table className="table">
								<thead className="thead-light">
									<tr>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="orderID" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="orderStore" />
										</th>
										<th className="text-center vertical-align-middle">
											<div
												onClick={handleChangeSortFormat}
												className="orders__table-header-with-sort centered"
											>
												<FormattedMessage id="creationDate" />
												<IoIosArrowRoundUp
													style={{
														transform: `rotate(${createdAtSortOption === "-imported_date" ? "0deg" : "180deg"})`,
													}}
													size={24}
												/>
											</div>
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="retailPrice" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="orderIsPaid" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="status" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="action" />
										</th>
									</tr>
								</thead>
								<tbody>
									{ordersData &&
										ordersData.results.length > 0 &&
										ordersData.results.map((order) => (
											<tr
												data-testid="order-row"
												key={order.id}
											>
												<td className="text-center">
													<div
														data-testid="order-id"
														className="d-flex align-items-center justify-content-center gap-1"
													>
														<OrderStatus
															status={
																order.sync_status
															}
														/>
														{order.custom_order_id}
													</div>
												</td>
												<td className="text-center">
													{order?.seller_integration
														?.name
														? order
																?.seller_integration
																?.name
														: "-"}
												</td>
												<td className="text-center">
													{
														order.imported_date.split(
															"T"
														)[0]
													}
												</td>
												<td className="text-center">
													$
													{order.end_customer_cost /
														100}
												</td>
												<td className="text-center">
													<span className="d-flex align-items-center justify-content-center gap-2">
														<div
															className={`orders__order-status-color ${order.is_paid ? "paid" : "not-paid"}`}
														></div>
														{order.is_paid ? (
															<FormattedMessage id="paid" />
														) : (
															<FormattedMessage id="notPaid" />
														)}
													</span>
												</td>
												<td className="text-center">
													<FormattedMessage
														id={`${ORDER_STATUS_TRANSLATIONS[order.status as keyof typeof ORDER_STATUS_TRANSLATIONS] || order.status}`}
													/>
												</td>
												<td className="text-center">
													<Dropdown className="d-flex justify-content-center">
														<Dropdown.Toggle className="user-header-dropdown orders__dropdown-toggle p-0">
															<div className="orders__dropdown-button">
																<FormattedMessage id="select" />
																<img
																	className="arrow-icon"
																	src={Arrow}
																	alt=""
																/>
															</div>
														</Dropdown.Toggle>
														<Dropdown.Menu
															data-testid="dropdown-menu"
															className="orders__dropdown-menu-wrapper"
														>
															<div className="orders__dropdown-menu">
																<Dropdown.Item
																	data-testid="dropdown-details-option"
																	onClick={() =>
																		navigateToOrderDetails(
																			order.id
																		)
																	}
																	className="orders__dropdown-item"
																>
																	<FormattedMessage id="details" />
																</Dropdown.Item>
																{!isPublic ? (
																	<>
																		{!order.is_paid ? (
																			<Dropdown.Item
																				onClick={() =>
																					handlePayManually(
																						order
																					)
																				}
																				className="orders__dropdown-item"
																			>
																				<FormattedMessage id="payManually" />
																			</Dropdown.Item>
																		) : null}

																		{isShippable(
																			order.status
																		) ? (
																			<Dropdown.Item
																				disabled={
																					order.is_paid
																						? false
																						: true
																				}
																				className={`orders__dropdown-item ${order.is_paid ? "" : "disabled-dropdown-item"}`}
																				onClick={() =>
																					handleFulfillOrder(
																						order.id
																					)
																				}
																			>
																				<FormattedMessage id="ship" />
																			</Dropdown.Item>
																		) : null}

																		{isCancellable(
																			order.status
																		) ? (
																			<Dropdown.Item
																				onClick={() =>
																					handleCancelOrder(
																						order.id
																					)
																				}
																				className="orders__dropdown-item"
																			>
																				<FormattedMessage id="cancel" />
																			</Dropdown.Item>
																		) : null}
																	</>
																) : null}
															</div>
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					)}
				</Loading>
			</div>
			{paypalPayment && orderPay && (
				<PayPalPayment
					show={paypalPayment}
					onHide={handleClosePayManually}
					orderPay={orderPay}
					handleCloseManuallyPayWithRefetch={
						handleCloseManuallyPayWithRefetch
					}
				/>
			)}
			{showUploadOrders && (
				<UploadOrders
					show={showUploadOrders}
					onHide={() => setShowUploadOrders(false)}
					setBulkUploadErrors={setBulkUploadErrors}
				/>
			)}
			{showCreateOrder && (
				<CreateOrder
					show={showCreateOrder}
					onHide={() => setShowCreateOrder(false)}
				/>
			)}
			{bulkUploadErrors && bulkUploadErrors?.length > 0 && (
				<UploadOrdersErrors
					show={bulkUploadErrors && bulkUploadErrors?.length > 0}
					onHide={() => setBulkUploadErrors(null)}
					errors={bulkUploadErrors}
				/>
			)}
		</div>
	);
};

export default Orders;
