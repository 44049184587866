// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopify-logo-title {
    font-size: 18px;
    font-style: italic;
}

.red-color {
    color: red;
}

.list-item-disc {
    display: list-item;
    list-style: disc;
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/AddShopifyIntegration/AddShopifyIntegration.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".shopify-logo-title {\n    font-size: 18px;\n    font-style: italic;\n}\n\n.red-color {\n    color: red;\n}\n\n.list-item-disc {\n    display: list-item;\n    list-style: disc;\n    font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
