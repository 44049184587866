import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FC, useEffect } from "react";

import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";
import { selectUser, updateUser } from "../../../redux/reducers/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useUpdateProfileMutation } from "../../../api/settingsAPI";
import { useUpdateProfileValidationSchema } from "./validator";

type TUpdateProfileProps = {
	show: boolean;
	onHide: () => void;
};

export type UpdateProfileSubmitData = {
	first_name: string;
	last_name: string;
	email: string;
};

const UpdateProfile: FC<TUpdateProfileProps> = ({ show, onHide }) => {
	const user = useAppSelector(selectUser);

	const intl = useIntl();
	const dispatch = useAppDispatch();
	const updateProfileValidationSchema = useUpdateProfileValidationSchema();

	const [customError, setCustomError, handleCustomError] =
		useHandleCustomRtkQueryError();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<UpdateProfileSubmitData>({
		resolver: yupResolver(updateProfileValidationSchema),
	});

	const [updateProfile] = useUpdateProfileMutation();

	const onSubmit = (data: UpdateProfileSubmitData) => {
		updateProfile(data).then((response) => {
			if ("error" in response) {
				handleCustomError(response.error);
			} else {
				setCustomError(null);
				dispatch(updateUser(response.data));
				onHide();
			}
		});
	};

	useEffect(() => {
		if (user) {
			setValue("first_name", user.first_name);
			setValue("last_name", user.last_name);
			setValue("email", user.email);
		}
	}, [user, setValue]);

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="updateProfile" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Label className="default-input-label">
							<FormattedMessage id="firstName" />
						</Form.Label>
						<Form.Control
							className="default-input"
							type="string"
							placeholder={`${intl.formatMessage({ id: "firstName" })}`}
							{...register("first_name")}
						/>
						{errors.first_name && (
							<p
								data-testid="first-name-error"
								className="mb-0 form-field-error"
							>
								{errors.first_name.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mt-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="lastName" />
						</Form.Label>
						<Form.Control
							className="default-input"
							type="string"
							placeholder={`${intl.formatMessage({ id: "lastName" })}`}
							{...register("last_name")}
						/>
						{errors.last_name && (
							<p
								data-testid="last-name-error"
								className="mb-0 form-field-error"
							>
								{errors.last_name.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mt-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="email" />
						</Form.Label>
						<Form.Control
							data-testid="profile-email"
							className="default-input"
							type="string"
							placeholder={`${intl.formatMessage({ id: "email" })}`}
							{...register("email")}
						/>
						{(errors.email ||
							customError ===
								"A user with that email already exists.") && (
							<p className="mb-0 form-field-error">
								{errors.email ? (
									errors.email.message
								) : (
									<FormattedMessage id="aUserWithThatEmailAlreadyExists" />
								)}
							</p>
						)}
					</Form.Group>
					<div className="d-flex justify-content-end mt-3">
						<button
							data-testid="confirm-profile-update"
							type="submit"
							className="ice-button w-100"
						>
							<FormattedMessage id="updateProfile" />
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default UpdateProfile;
