import { DropTargetMonitor, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { FormattedMessage } from "react-intl";
import { FC, useRef } from "react";

import Upload from "../../assets/images/upload.svg";

type TTargetBox = {
	setFile: (files: File) => void;
	acceptFiles?: string;
};

const TargetBox: FC<TTargetBox> = ({ setFile, acceptFiles = ".xlsx" }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const [{ canDrop, isOver }, drop] = useDrop(
		() => ({
			accept: [NativeTypes.FILE],
			drop(item: { files: File[] }) {
				if (handleFileDrop) {
					handleFileDrop(item);
				}
			},
			canDrop() {
				return true;
			},
			collect: (monitor: DropTargetMonitor) => {
				return {
					isOver: monitor.isOver(),
					canDrop: monitor.canDrop(),
				};
			},
		}),
		[]
	);

	const isActive = canDrop && isOver;

	const handleChooseFile = () => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	};

	const handleFileUploaded = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setFile(e.target.files[0]);
		}
	};

	const handleFileDrop = (item: { files: File[] }) => {
		if (item.files.length === 0 || item.files.length > 1) {
			return;
		}

		const file = item.files[0];

		if (
			file.type !==
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		) {
			return;
		}

		setFile(file);
	};

	return (
		<div
			className={
				isActive
					? "bulk-upload-dnd-wrapper-active"
					: "bulk-upload-dnd-wrapper"
			}
			ref={drop}
		>
			<img src={Upload} alt="" />
			<h5 className="bulk-upload-dnd-title">
				<FormattedMessage id="dropToUpload" />
			</h5>
			<button onClick={handleChooseFile} className="light-grey-button">
				<FormattedMessage id="chooseFile" />
			</button>
			<input
				onChange={handleFileUploaded}
				accept={acceptFiles}
				className="d-none"
				ref={inputRef}
				type="file"
			/>
		</div>
	);
};

export default TargetBox;
