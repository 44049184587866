import { ChangeEvent, FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";

import { useGetAmazonLinksQuery } from "../../../api/integrationsAPI";

import amazon from "../../assets/images/amazon.png";

import "./AddAmazonIntegration.css";

type TAddAmazonIntegrationProps = {
	show: boolean;
	onHide: () => void;
};

const AddAmazonIntegration: FC<TAddAmazonIntegrationProps> = ({
	show,
	onHide,
}) => {
	const [loginStep, setLoginStep] = useState(false);
	const [selectedUrl, setSelectedUrl] = useState("");

	const { data } = useGetAmazonLinksQuery();

	const nextStep = () => {
		if (selectedUrl) {
			setLoginStep(true);
		}
	};

	const handleChangeCountry = (e: ChangeEvent<HTMLSelectElement>) => {
		setSelectedUrl(e.target.value);
	};

	const handleLoginAmazon = () => {
		window.location.href = selectedUrl;
	};

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="addNewAmazonFBAIntegration" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0 d-flex flex-column">
				{loginStep ? (
					<>
						<span className="mt-2 default-input-label">
							<FormattedMessage id="pleaseLoginInAmazonForContinue" />
						</span>
						<button
							onClick={handleLoginAmazon}
							className="secondary-button mt-3"
						>
							<img
								className="add-integration-amazon-image"
								src={amazon}
								alt=""
							/>
							<span>
								<FormattedMessage id="loginWithAmazon" />
							</span>
						</button>
					</>
				) : (
					<>
						<span className="mt-2 default-input-label">
							<FormattedMessage id="pleaseSelectACountryToContinue" />
						</span>
						<Form.Select
							onChange={handleChangeCountry}
							className="mt-2 default-input"
						>
							<option value="">
								<FormattedMessage id="selectCountry" />
							</option>
							{data &&
								Object.keys(data).map((key) => (
									<option key={key} value={data[key]}>
										{key}
									</option>
								))}
						</Form.Select>
						<button
							className="ice-button mt-3 w-100"
							onClick={nextStep}
						>
							<FormattedMessage id="continue" />
						</button>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default AddAmazonIntegration;
