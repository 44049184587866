import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { BiSearch } from "react-icons/bi";
import { useEffect } from "react";

import { useGetPublicProductsQuery } from "../../../../api/productsAPI";
import { useGetCategoriesQuery } from "../../../../api/categoriesAPI";
import usePagination from "../../../../hooks/usePagination";
import useFilters from "../../../../hooks/useFilters";

import PagePagination from "../../../components/common/Pagination/PagePagination";

import "./Catalog.css";
import CatalogFilter from "../../../components/layout/CatalogFilter/CatalogFilter";

const Catalog = () => {
	const intl = useIntl();

	const {
		search,
		handleSearch,
		handleSearchFilter,
		searchFilter,
		onKeyDownSearch,
		pageSize,
		categoryFilter,
		handleCategoryFilter,
		clearFilters,
	} = useFilters();
	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		pageSize
	);

	const navigate = useNavigate();

	const { data: categories } = useGetCategoriesQuery();
	const { data } = useGetPublicProductsQuery({
		activePage,
		searchFilter,
		pageSize,
		categoryFilter,
	});

	const handleViewProduct = (id: number) => {
		navigate(`/catalog/product/${id}`);
	};

	useEffect(() => {
		if (data) {
			handlePagesCount(data.count);
		}
	}, [data, handlePagesCount]);

	return (
		<section className="catalog-wrapper">
			<div className="catalog__header">
				<h2 className="catalog__title">
					<FormattedMessage id="catalog" />
				</h2>
				<div className="d-flex align-items-center gap-4">
					{pages.length > 1 && (
						<PagePagination
							activePage={activePage}
							pages={pages}
							handlePage={handlePage}
						/>
					)}
					<div className="catalog__search-wrapper">
						<input
							data-testid="search-input"
							value={search}
							onChange={(e) => handleSearch(e.target.value)}
							onKeyDown={(e) => onKeyDownSearch(e, handlePage)}
							className="catalog__search-input"
							placeholder={intl.formatMessage({ id: "search" })}
						/>
						<BiSearch
							onClick={() => handleSearchFilter(handlePage)}
							className="catalog__search-icon"
							size={20}
						/>
					</div>
				</div>
			</div>
			<div className="catalog__content-wrapper">
				<CatalogFilter
					categories={categories?.results}
					handlePage={handlePage}
					handleCategoryFilter={handleCategoryFilter}
					categoryFilter={categoryFilter}
					clearFilters={clearFilters}
				/>
				<div className="catalog__products-wrapper">
					{data && data.results.length > 0 ? (
						<div
							data-testid="product-list"
							className="catalog__products-list"
						>
							{data.results.map((product) => (
								<div
									data-testid="product-block"
									key={product.id}
									className="catalog__product-block"
								>
									<img
										className="catalog__product-image"
										src={
											product?.variants[0]?.images[0]?.url
												? product?.variants[0]
														?.images[0]?.url
												: product?.variants[0]
														?.images[0]?.image
										}
										alt=""
									/>
									<div className="catalog__product-details">
										<p
											data-testid="product-title"
											className="catalog__product-title"
										>
											{product.title}
										</p>
										<div className="d-flex align-items-center justify-content-between">
											<div className="me-1 mb-1">
												<StarRatings
													rating={parseFloat(
														product.rank
															? product.rank
																	.rating
															: "0"
													)}
													numberOfStars={5}
													starRatedColor="yellow"
													starDimension="20px"
													starSpacing="1px"
													svgIconPath="M11.4396 2.87017L12.9061 5.82742C13.1061 6.23908 13.6394 6.63394 14.0894 6.70954L16.7474 7.15481C18.4472 7.44046 18.8472 8.68385 17.6223 9.91044L15.5559 11.9939C15.2059 12.3468 15.0143 13.0273 15.1225 13.5146L15.7142 16.0938C16.1808 18.1353 15.1059 18.925 13.3145 17.858L10.823 16.371C10.3731 16.1022 9.63154 16.1022 9.17321 16.371L6.68185 17.858C4.89871 18.925 3.8155 18.1269 4.28212 16.0938L4.87372 13.5146C4.98203 13.0273 4.79039 12.3468 4.44043 11.9939L2.37399 9.91044C1.15746 8.68385 1.54908 7.44046 3.24889 7.15481L5.90693 6.70954C6.34855 6.63394 6.88183 6.23908 7.0818 5.82742L8.54829 2.87017C9.34821 1.26553 10.648 1.26553 11.4396 2.87017Z"
													svgIconViewBox="0 0 20 20"
													starEmptyColor="#666666"
												/>
											</div>
											<span
												style={{ marginTop: "3px" }}
												className="number-rating"
											>
												{product.rank
													? product.rank.rating
													: "0"}
											</span>
										</div>
									</div>
									<div className="catalog__hover-wrapper">
										<button
											data-testid="view-button"
											onClick={() =>
												handleViewProduct(product.id)
											}
											className="catalog__view-button"
										>
											<FormattedMessage id="viewProduct" />
										</button>
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="catalog__empty-products">
							<FormattedMessage id="thereAreNoProducts" />
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default Catalog;
