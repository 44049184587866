// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-pending-wrapper {
	max-width: 800px;
	margin-top: 80px;
	margin-bottom: 80px;
}

.register-pending-img {
	max-width: 100%;
	height: auto;
	border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/LendingPage/Auth/Register/RegisterPending/RegisterPending.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,YAAY;CACZ,kBAAkB;AACnB","sourcesContent":[".register-pending-wrapper {\n\tmax-width: 800px;\n\tmargin-top: 80px;\n\tmargin-bottom: 80px;\n}\n\n.register-pending-img {\n\tmax-width: 100%;\n\theight: auto;\n\tborder-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
