import { useIntl } from "react-intl";
import * as yup from "yup";

export const useInviteUserValidationSchema = () => {
	const intl = useIntl();

	return yup.object().shape({
		email: yup
			.string()
			.email(intl.formatMessage({ id: "emailAddressIsInvalid" }))
			.required(intl.formatMessage({ id: "thisFieldIsRequired" })),
		role: yup
			.string()
			.oneOf(["OWNER", "STAFF"])
			.required(intl.formatMessage({ id: "thisFieldIsRequired" })),
	});
};
