import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

import "./Terms.css";

const Terms = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="terms-wrapper">
			<div className="terms-content">
				<h3 className="terms__title">
					CloudOrder.US
					<br />
					<FormattedMessage id="termsAndConditions" />
				</h3>
				<div className="terms-text-block">
					<p>
						<FormattedMessage id="welcomeToOurWebsiteWeProvideItsServicesToYouSubjectToTHeNoticesTerms" />
					</p>
					<p>
						<FormattedMessage id="accessingBrowsingOrOtherwiseUsingTheSite" />
					</p>
					<p>
						<FormattedMessage id="youRepresentAndWarrantThatYouAreAtLeast18YearsOld" />
					</p>
					<p>
						<FormattedMessage id="exceptAsPermittedInTheParagraphAboveYouMayNotReproduce" />
					</p>
					<p>
						<FormattedMessage id="youShallNotUploadToDistributeOrOtherwisePublishThroughThisSiteAnyContent" />
					</p>
					<p>
						<FormattedMessage id="youWillBeSolelyResponsibleForAllAccessToAndUseOfThisSite" />
					</p>
					<h3>
						<FormattedMessage id="reviewsAndComments" />
					</h3>
					<p>
						<FormattedMessage id="exceptAsOtherwiseProvidedElsewhereInThisAgreementOrOnTheSite" />
					</p>
					<p>
						<FormattedMessage id="youWarrantThatYourSubmissionsInWholeOrInPartAreClear" />
					</p>
					<p>
						<FormattedMessage id="inAdditionToTheRightsApplicableToAnySubmission" />
					</p>
					<h3>
						<FormattedMessage id="copyright" />
					</h3>
					<p>
						<FormattedMessage id="allTextGraphicsPhotographsOrOtherImagesButtonIconsAudio" />
					</p>
					<p>
						<FormattedMessage id="ourWebsiteRecognizesAndRespectsAllCopyrightsAndTrademarks" />
					</p>
					<h3>
						<FormattedMessage id="intellectualPropertyInfringementPolicy" />
					</h3>
					<p>
						<FormattedMessage id="itIsThePolicyOfOurWebsiteToTakeAppropriate" />
					</p>
					<h3>
						<FormattedMessage id="informationRequired" />
					</h3>
					<p>
						<FormattedMessage id="anElectronicOrPhysicalSignatureOfThePerson" />
					</p>
					<p>
						<FormattedMessage id="aDescriptionOfTheAllegedlyInfringingWork" />
					</p>
					<p>
						<FormattedMessage id="aDescriptionOfWhereTheAllegedlyInfringingMaterial" />
					</p>
					<p>
						<FormattedMessage id="informationReasonablySufficientToAllowUs" />
					</p>
					<p>
						<FormattedMessage id="aStatementByYouThatYouHaveAGoodFaithBelief" />
					</p>
					<p>
						<FormattedMessage id="identificationOfTheIntellectualPropertyRights" />
					</p>
					<p>
						<FormattedMessage id="identificationOfTheIntellectualPropertyRights" />
						&lt;
					</p>
					<h3>
						<FormattedMessage id="terminationAndEffectOfTermination" />
					</h3>
					<p>
						<FormattedMessage id="inAdditionToAnyOtherLegalOrEquitableRemedies" />
					</p>
					<h3>
						<FormattedMessage id="orderAcceptance" />
					</h3>
					<p>
						<FormattedMessage id="pleaseNoteThatThereMayBeCertainOrdersThatWeAreUnableToAcceptAndMustCancel" />
					</p>
					<h3>
						<FormattedMessage id="typographicalErrors" />
					</h3>
					<p>
						<FormattedMessage id="whileOurWebsiteStrivesToProvideAccurateProductAndPricing" />
					</p>
					<h3>
						<FormattedMessage id="pricingInDifferentCurrencies" />
					</h3>
					<p>
						<FormattedMessage id="pricingOfProductsSoldByOurWebsiteIsBasedUponFigures" />
					</p>
					<h3>
						<FormattedMessage id="links" />
					</h3>
					<p>
						<FormattedMessage id="thisSiteMayContainLinksToOtherSitesOnTheInternet" />
					</p>
					<h3>
						<FormattedMessage id="remedies" />
					</h3>
					<p>
						<FormattedMessage id="youAgreeThatOurWebsiteRemedyAtLawForAnyActual" />
					</p>
					<p>
						<FormattedMessage id="noRightOrRemedyOfOurWebsiteShallBeExclusive" />
					</p>
					<p>
						<FormattedMessage id="noInstanceOfWaiverByOurWebsiteOfItsRights" />
					</p>
					<h3>
						<FormattedMessage id="governingLaw" />
					</h3>
					<p>
						<FormattedMessage id="theseTermsAndConditionsAreGovernedByAndAreToBeConstructed" />
					</p>
					<p>
						<FormattedMessage id="companyNameIchinInc" />
					</p>
					<p>&nbsp;</p>
				</div>
			</div>
		</div>
	);
};
export default Terms;
