import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC } from "react";

import { useUpdateJobMutation } from "../../../api/jobsAPI";
import { TJob } from "../../../api/api.types";

export type TEditTaskArgsSubmitData = {
	function_arguments: string;
};

type TEditTaskArgsProps = {
	job: TJob;
	show: boolean;
	onHide: () => void;
};

const EditTaskArgs: FC<TEditTaskArgsProps> = ({ job, show, onHide }) => {
	const intl = useIntl();

	const [updateJob] = useUpdateJobMutation();

	const { register, handleSubmit } = useForm<TEditTaskArgsSubmitData>();

	const onSubmit = (data: TEditTaskArgsSubmitData) => {
		let functionArguments: string;
		try {
			functionArguments = JSON.parse(data.function_arguments);
		} catch (e) {
			functionArguments = "{}";
		}

		updateJob({
			data: {
				function_arguments: functionArguments,
			},
			id: job.id,
		}).then((res) => {
			if ("error" in res) {
				toast.error(<FormattedMessage id="somethingWentWrong" />);
			} else {
				toast.success(
					<FormattedMessage id="functionArgumentsUpdated" />
				);
				onHide();
			}
		});
	};

	return (
		<Modal
			className="modal-650"
			centered
			show={show}
			onHide={onHide}
			data-testid="edit-task-modal"
		>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="editFunctionArguments" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group className="mt-1">
						<Form.Label className="default-input-label">
							<FormattedMessage id="functionArguments" />
						</Form.Label>
						<Form.Control
							data-testid="function-arguments"
							style={{ height: "120px", padding: "10px 16px" }}
							defaultValue={JSON.stringify(
								job.function_arguments
							)}
							as="textarea"
							className="default-input"
							type="string"
							placeholder={`${intl.formatMessage({ id: "functionArguments" })}`}
							{...register("function_arguments")}
						/>
					</Form.Group>
					<div className="d-flex justify-content-end mt-3">
						<button
							data-testid="change-function-arguments"
							type="submit"
							className="ice-button w-100"
						>
							<FormattedMessage id="change" />
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default EditTaskArgs;
