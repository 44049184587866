// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-verified-text {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
    color: rgb(18, 63, 115);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/LendingPage/Auth/Register/VerifyEmail/VerifyEmail.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".email-verified-text {\n    font-size: 1.3rem;\n    font-weight: 600;\n    text-align: center;\n    margin-bottom: 15px;\n    color: rgb(18, 63, 115);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
