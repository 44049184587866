export const THIS_FIELD_IS_REQUIRED = "This field is required";
export const LOGIN_PAGE_URL = "/login";

export const passwordRegex = new RegExp(
	"^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z.,@#!$%^&*()-_=+;'\"`\\d]{8,}$"
);

export const CRON_TIME = {
	"* * * * *": "Every 1 minute",
	"0 * * * *": "Every 1 hour",
	"0 */6 * * *": "Every 6 hour",
	"0 1 * * *": "Every 1 day",
	"0 0 */3 * *": "Every 3 day",
	"0 0 * * 0": "Every week",
	"0 0 1 * *": "Every month",
	"0 0 1 1 *": "Every year",
};

export const TOTAL_ORDERS_DATES_TRANSLATION_OPTIONS = {
	"This Week": "thisWeek",
	"This Month": "thisMonth",
	"This Year": "thisYear",
	"All Time": "allTime",
	"Last Week": "lastWeek",
	"Last 2 Weeks": "last2Weeks",
	"Last 30 Days": "last30Days",
	"Last 12 Months": "last12Months",
	"Custom Range": "customRange",
};

export const ORDER_SYNCHRONIZATION_STATUS_TO_TEXT = {
	success: "orderSynchronizedSuccessfully",
	pending_synchronization: "orderIsPendingSynchronization",
	synchronization_failed: "orderSynchronizationFailed",
	status_update_failed: "orderStatusUpdateFailed",
	failed: "orderSynchronizationFailed",
};

export const RATES_SHIPPING_METHODS_TRANSLATIONS = {
	Standard: "standard",
	Priority: "priority",
	Expedited: "expedited",
};

export const ORDER_STATUS_TRANSLATIONS = {
	New: "new",
	Completed: "completed",
	"Partially Completed": "partiallyCompleted",
	"In Progress": "inProgress",
	Canceled: "canceled",
	"Return in progress": "returnInProgress",
	Returned: "returned",
	Error: "error",
};

export const ORDERS_FILTERS = {
	filterByStatus: {
		field: "fulfillments__fulfillment_line_items__status",
		options: [
			{
				id: 1,
				name: "all",
				value: "All",
			},
			{
				id: 2,
				name: "inProgress",
				value: "IN_PROGRESS",
			},
			{
				id: 3,
				name: "partiallyCompleted",
				value: "PARTIALLY_COMPLETED",
			},
			{
				id: 4,
				name: "completed",
				value: "COMPLETED",
			},
			{
				id: 5,
				name: "returnInProgress",
				value: "RETURN_IN_PROGRESS",
			},
			{
				id: 6,
				name: "returned",
				value: "RETURNED",
			},
			{
				id: 7,
				name: "canceled",
				value: "CANCELED",
			},
			{
				id: 8,
				name: "error",
				value: "ERROR",
			},
			{
				id: 9,
				name: "new",
				value: "NEW",
			},
		],
	},
};

export const MY_PRODUCTS_FILTERS = {
	filterByStatus: {
		field: "status",
		options: [
			{
				id: 1,
				name: "public",
				value: "public",
			},
			{
				id: 2,
				name: "inReview",
				value: "in_review",
			},
			{
				id: 3,
				name: "private",
				value: "private",
			},
		],
	},
	filterByAction: {
		field: "action",
		options: [
			{
				id: 1,
				name: "all",
				value: "All",
			},
			{
				id: 2,
				name: "requiresAction",
				value: "Required Action",
			},
		],
	},
};

export const SYSTEM_FILTERS = {
	filterByStatus: {
		field: "status",
		options: [
			{
				id: 1,
				name: "success",
				value: "SUCCESS",
			},
			{
				id: 2,
				name: "pending",
				value: "PENDING",
			},
			{
				id: 3,
				name: "failed",
				value: "FAILED",
			},
		],
	},
};

export const BILLING_PAYPAL_FILTERS = {
	filterByKind: {
		field: "kind",
		options: [
			{
				id: 1,
				name: "payment",
				value: "payment",
			},
			{
				id: 2,
				name: "refund",
				value: "refund",
			},
		],
	},
};

export const SYSTEM_SCHEDULE_STATUS_TO_INFO = {
	SUCCESS: {
		label: "success",
		color: "#117627",
	},
	PENDING: {
		label: "pending",
		color: "#0085FF",
	},
	FAILED: {
		label: "failed",
		color: "#D24023",
	},
};

export const BILLING_FILTERS = {
	filterByStatus: {
		field: "status",
		options: [
			{
				id: 1,
				name: "requiresAction",
				value: "requires_action",
			},
			{
				id: 2,
				name: "succeeded",
				value: "succeeded",
			},
			{
				id: 3,
				name: "requiresPaymentMethod",
				value: "requires_payment_method",
			},
			{
				id: 4,
				name: "requiresConfirmationDefault",
				value: "requires_confirmation",
			},
		],
	},
};

export const BILLING_PAYPAL_KINDS_TRANSLATIONS = {
	Payment: "payment",
	Refund: "refund",
};

export const BILLING_RECEIPTS_STATUS_TRANSLATIONS = {
	requires_action: "requiresAction",
	succeeded: "succeeded",
	requires_payment_method: "requiresPaymentMethod",
	requires_confirmation: "requiresConfirmationDefault",
};
