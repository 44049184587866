import { FormattedMessage, useIntl } from "react-intl";
import { ChangeEvent, FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { useImportCsvFileMutation } from "../../../api/productsAPI";

type TImportCsvProps = {
    show: boolean;
    onHide: () => void;
};

const ImportCsv: FC<TImportCsvProps> = ({ show, onHide }) => {
    const [file, setFile] = useState<File | null>(null);

    const intl = useIntl();

    const [importFile] = useImportCsvFileMutation();

    const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const handleSubmitFile = () => {
        if (file) {
            const formData = new FormData();

            formData.append("csv_file", file);

            importFile(formData).then((res) => {
                if ("error" in res) {
                    toast.error(
                        intl.formatMessage({ id: "somethingWentWrong" })
                    );
                } else {
                    if (res.data.created) {
                        toast.success(
                            intl.formatMessage({
                                id: "updateOfTheProductListStartedYouWillReceiveANotification",
                            })
                        );
                        onHide();
                    } else {
                        toast.error(
                            intl.formatMessage({
                                id: "updateOfProductsListAlreadyStarted",
                            })
                        );
                        onHide();
                    }
                }
            });
        }
    };

    return (
        <Modal size="lg" centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="importProducts" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div className="d-flex flex-column">
                    <h5>
                        <FormattedMessage id="selectAFileWithDots" />
                    </h5>
                    <span className="mt-1">
                        <FormattedMessage id="pleaseMakeSureThatYouAreUsingCSVFileWithComma" />
                    </span>
                    <div
                        style={{ border: "1px solid #ccc" }}
                        className="p-2 mt-2"
                    >
                        <input
                            onChange={(e) => handleChangeFile(e)}
                            type="file"
                            accept="text/csv"
                        />
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <button
                            className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38"
                            onClick={handleSubmitFile}
                        >
                            <FormattedMessage id="import" />
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ImportCsv;
