import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect } from "react";

import {
	useGetFinancialRatesQuery,
	useUpdateFinancialRatesMutation,
} from "../../../../api/settingsAPI";
import { RATES_SHIPPING_METHODS_TRANSLATIONS } from "../../../../helpers/constants";
import { TRatesObject } from "../../../../api/api.types";

export type TRatesData = {
	[key: string]: number;
};

const SettingsRates = () => {
	const { data } = useGetFinancialRatesQuery();

	const intl = useIntl();

	const [updateFinancialRates] = useUpdateFinancialRatesMutation();

	const { register, handleSubmit, setValue } = useForm<TRatesData>();

	const onSubmit = (formData: TRatesData) => {
		const updatedData: TRatesObject[] = [];

		if (data && data.length > 0 && data[0].shipping_rates_json.length > 0) {
			data[0].shipping_rates_json.forEach((rate) => {
				updatedData.push({
					...rate,
					min_weight: formData[`min_weight_${rate.id}`],
					max_weight: formData[`max_weight_${rate.id}`],
					cost: formData[`cost_${rate.id}`] * 100,
				});
			});

			updateFinancialRates({ shipping_rates_json: updatedData }).then(
				(res) => {
					if ("error" in res) {
						const status = (res.error as { status: number }).status;

						if (status !== 403) {
							toast.error(
								intl.formatMessage({ id: "somethingWentWrong" })
							);
						}
					} else {
						toast.success(
							intl.formatMessage({
								id: "shippingRatesHaveBeenUpdated",
							})
						);
					}
				}
			);
		}
	};

	useEffect(() => {
		if (data && data.length > 0 && data[0].shipping_rates_json.length > 0) {
			data[0].shipping_rates_json.forEach((rate) => {
				setValue(`min_weight_${rate.id}`, rate.min_weight);
				setValue(`max_weight_${rate.id}`, rate.max_weight);
				setValue(`cost_${rate.id}`, rate.cost / 100);
			});
		}
	}, [data, setValue]);

	return (
		<div className="settings-users__block">
			<Form
				className="settings-users__block-body"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="settings-users__block-body-header">
					<h5 className="settings-users__block-title">
						<FormattedMessage id="shippingRates" />
					</h5>
					<div className="settings-users__block-body-header-buttons">
						<button
							data-testid="save-changes"
							type="submit"
							className="ice-button"
						>
							<FormattedMessage id="saveChanges" />
						</button>
					</div>
				</div>
				<div className="default-table-no-wrapper">
					<table className="table">
						<thead className="thead-light">
							<tr>
								<th>
									<FormattedMessage id="shippingMethod" />
								</th>
								<th>
									<FormattedMessage id="minWeight" />
								</th>
								<th>
									<FormattedMessage id="maxWeight" />
								</th>
								<th>
									<FormattedMessage id="cost" />
								</th>
								<th>
									<FormattedMessage id="dimension" />
								</th>
							</tr>
						</thead>
						<tbody className="rates-table-body">
							{data &&
								data.length > 0 &&
								data[0].shipping_rates_json.length > 0 &&
								data[0].shipping_rates_json.map((rate) => (
									<tr key={rate.id}>
										<td className="text-center">
											{intl.formatMessage({
												id: RATES_SHIPPING_METHODS_TRANSLATIONS[
													rate.shipping_method as keyof typeof RATES_SHIPPING_METHODS_TRANSLATIONS
												],
											})}
										</td>
										<td className="text-center">
											<Form.Group>
												<Form.Control
													className="default-input"
													type="number"
													data-testid="min-weight"
													placeholder={`${intl.formatMessage(
														{
															id: "minWeight",
														}
													)}`}
													{...register(
														`min_weight_${rate.id}`
													)}
												/>
											</Form.Group>
										</td>
										<td className="text-center">
											<Form.Group>
												<Form.Control
													className="default-input"
													type="number"
													data-testid="max-weight"
													placeholder={`${intl.formatMessage(
														{
															id: "maxWeight",
														}
													)}`}
													{...register(
														`max_weight_${rate.id}`
													)}
												/>
											</Form.Group>
										</td>
										<td className="text-center">
											<Form.Group>
												<Form.Control
													className="default-input"
													type="number"
													data-testid="cost"
													placeholder={`${intl.formatMessage(
														{
															id: "cost",
														}
													)}`}
													{...register(
														`cost_${rate.id}`
													)}
												/>
											</Form.Group>
										</td>
										<td className="text-center">
											{rate.weight_demension}
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</Form>
		</div>
	);
};

export default SettingsRates;
