import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Dropdown, Form } from "react-bootstrap";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
	useAddProductNoteMutation,
	useGetProductVariantQuery,
} from "../../../../api/productsAPI";
import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { TProductNoteSubmitData } from "../../../../api/api.types";

import ProductDetailsStatusBlock from "../../../components/layout/ProductDetails/ProductDetailsStatusBlock";
import ProductDetailsImages from "../../../components/layout/ProductDetails/ProductDetailsImages";
import EditProductModal from "../../../modals/UpdateProductAmount/EditProductModal";
import ModifyAutoUpdate from "../../../modals/ModifyAutoUpdate/ModifyAutoUpdate";
import Loading from "../../../components/common/Loading/Loading";

import Arrow from "../../../assets/images/arrow.svg";

import "./ProductDetails.css";

const ProductDetails = () => {
	const [editProduct, setEditProduct] = useState(false);
	const [modifyAuto, setModifyAuto] = useState(false);

	const intl = useIntl();
	const { id } = useParams();
	const navigate = useNavigate();
	const [isPublic] = useDetectSubdomainOrPublic();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<TProductNoteSubmitData>();

	const { data, isLoading: isLoadingDetails } = useGetProductVariantQuery(
		Number(id)
	);

	const [addProductNote] = useAddProductNoteMutation();

	const navigateToProducts = () => {
		navigate("/dashboard/my-products/");
	};

	const onSubmit = (data: TProductNoteSubmitData) => {
		addProductNote({
			product: Number(id),
			text: data.text,
		}).then((res) => {
			if ("error" in res) {
				const status = (res.error as { status: number }).status;

				if (status !== 403) {
					toast.error(
						intl.formatMessage({ id: "somethingWentWrong" })
					);
				}
			} else {
				reset();
			}
		});
	};

	return (
		<div className="product-details__content-wrapper">
			<div
				data-testid="back"
				onClick={navigateToProducts}
				className="product-details__back"
			>
				<IoIosArrowRoundBack color="#666666" size={28} />
			</div>
			<Loading isLoading={isLoadingDetails}>
				<div
					data-testid="product-content"
					className="product-details__content"
				>
					{data?.status && data.status !== "available" && (
						<ProductDetailsStatusBlock status={data.status} />
					)}
					<div className="product-details__content-header">
						<h4 className="product-details__content-title">
							{data?.title}
						</h4>
						{!isPublic && (
							<Dropdown className="d-flex justify-content-center">
								<Dropdown.Toggle
									data-testid="action"
									className="user-header-dropdown orders__dropdown-toggle p-0"
								>
									<div className="orders__dropdown-button">
										<FormattedMessage id="action" />
										<img
											className="arrow-icon"
											src={Arrow}
											alt=""
										/>
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu className="orders__dropdown-menu-wrapper">
									<div
										data-testid="action-menu"
										className="orders__dropdown-menu"
									>
										<Dropdown.Item
											data-testid="modify-auto-update"
											className="orders__dropdown-item"
											onClick={() => setModifyAuto(true)}
										>
											<FormattedMessage id="modifyAutoUpdateDefault" />
										</Dropdown.Item>
										<Dropdown.Item
											data-testid="update-product"
											className="orders__dropdown-item"
											onClick={() => setEditProduct(true)}
										>
											<FormattedMessage id="editProduct" />
										</Dropdown.Item>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						)}
					</div>
					<ProductDetailsImages
						data={data}
						isLoadingDetails={isLoadingDetails}
					/>
					{data?.properties && data?.properties?.length > 0 && (
						<div className="product-details__content-info">
							<h5 className="product-details__content-info-title">
								<FormattedMessage id="details" />
							</h5>
							<table className="table default-table-no-wrapper">
								<thead className="thead-light">
									<tr>
										{data?.properties.map((property) => (
											<th
												key={property.id}
												style={{
													textTransform: "capitalize",
												}}
												className="text-center"
												scope="col"
											>
												{property.name}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									<tr>
										{data?.properties.map((property) => (
											<td
												key={property.id}
												className="text-center"
											>
												{property.value}
											</td>
										))}
									</tr>
								</tbody>
							</table>
						</div>
					)}
					<div className="product-details__content-info">
						<h5 className="product-details__content-info-title">
							<FormattedMessage id="productInfo" />
						</h5>
						<table className="table default-table-no-wrapper">
							<thead className="thead-light">
								<tr>
									<th className="text-center" scope="col">
										<FormattedMessage id="productNameUppercase" />
									</th>
									<th className="text-center" scope="col">
										SKU
									</th>
									<th className="text-center" scope="col">
										ASIN
									</th>
									<th className="text-center" scope="col">
										<FormattedMessage id="totalUppercase" />
									</th>
									<th className="text-center" scope="col">
										<FormattedMessage id="inOrderUppercase" />
									</th>
									<th className="text-center" scope="col">
										<FormattedMessage id="availableUppercase" />
									</th>
									<th className="text-center" scope="col">
										<FormattedMessage id="productCostUppercase" />
									</th>
									<th className="text-center" scope="col">
										MSRP
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="text-center max-width-td">
										<span className="wrapped-table-row">
											{data?.title}
										</span>
									</td>
									<td className="text-center">{data?.sku}</td>
									<td className="text-center">
										{data?.amazon_product_variant?.asin}
									</td>
									<td className="text-center">
										{data?.inventories.map((el, idx) => (
											<Fragment key={idx}>
												<span>{el.amount_total}</span>
												<br />
											</Fragment>
										))}
									</td>
									<td className="text-center">
										{data?.inventories.map((el, idx) => (
											<Fragment key={idx}>
												<span>{el.amount_reserve}</span>
												<br />
											</Fragment>
										))}
									</td>
									<td className="text-center">
										{data?.inventories.map((el, idx) => (
											<Fragment key={idx}>
												<span>
													{el.amount_available}
												</span>
												<br />
											</Fragment>
										))}
									</td>
									<td className="text-center">
										{data?.prices.map((el, idx) => (
											<Fragment key={idx}>
												<span>
													{el.currency.sign}
													{el.amount / 100}
												</span>
												<br />
											</Fragment>
										))}
									</td>
									<td className="text-center">
										{data?.prices.map((el, idx) => (
											<Fragment key={idx}>
												<span>
													{el.currency.sign}
													{el.msrp_amount / 100}
												</span>
												<br />
											</Fragment>
										))}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="product-details__content-info">
						<h5 className="product-details__content-info-title">
							<FormattedMessage id="notesWithDots" />
						</h5>
						{data?.product_notes_by_tenant &&
							data?.product_notes_by_tenant.length > 0 &&
							data.product_notes_by_tenant.map((note, idx) => (
								<div data-testid="note-text" key={idx}>
									{note.text}
								</div>
							))}
						<Form
							className="mt-1"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Form.Control
								data-testid="product-note-input"
								placeholder={`${intl.formatMessage({
									id: "enterANote",
								})}`}
								className="default-input product-details-textarea p-2"
								type="text"
								as="textarea"
								style={{ height: "120px" }}
								{...register("text", {
									required: true,
								})}
							/>
							{errors.text && (
								<p className="mb-0 form-field-error">
									{errors.text.message}
								</p>
							)}
							<div className="d-flex justify-content-end mt-3">
								<button
									data-testid="add-note"
									className="ice-button"
								>
									<FormattedMessage id="addNote" />
								</button>
							</div>
						</Form>
					</div>
				</div>
			</Loading>
			{modifyAuto && (
				<ModifyAutoUpdate
					show={modifyAuto}
					onHide={() => setModifyAuto(false)}
					product={data}
				/>
			)}
			{editProduct && (
				<EditProductModal
					show={editProduct}
					onHide={() => setEditProduct(false)}
					product={data}
				/>
			)}
		</div>
	);
};

export default ProductDetails;
