import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { FC } from "react";

import { TGetOrderResult } from "../../../api/api.types";

import PayPalButton from "../../components/common/PayPalButton/PayPalButton";

type TStripePaymentProps = {
	show: boolean;
	onHide: () => void;
	orderPay: TGetOrderResult;
	handleCloseManuallyPayWithRefetch: () => void;
};

const PayPalPayment: FC<TStripePaymentProps> = ({
	show,
	onHide,
	orderPay,
	handleCloseManuallyPayWithRefetch,
}) => {
	return (
		<Modal centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="paypalPayment" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex justify-content-center align-items-center w-100">
				<PayPalButton
					orderPay={orderPay}
					handleCloseManuallyPayWithRefetch={
						handleCloseManuallyPayWithRefetch
					}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default PayPalPayment;
