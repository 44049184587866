// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-list ul {
    display: flex;
    flex-direction: column;
    padding-left: 28px;
}

.category-list li {
    color: #144074 !important;
    font-weight: 700;
    cursor: pointer;
    padding: 2px 0;
}

.categories-item-icon-active {
    transform: rotate(90deg);
}`, "",{"version":3,"sources":["webpack://./src/app/components/layout/CategoryTree/CategoryTree.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".category-list ul {\n    display: flex;\n    flex-direction: column;\n    padding-left: 28px;\n}\n\n.category-list li {\n    color: #144074 !important;\n    font-weight: 700;\n    cursor: pointer;\n    padding: 2px 0;\n}\n\n.categories-item-icon-active {\n    transform: rotate(90deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
