import { Form, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineDone } from "react-icons/md";
import { BiLoaderAlt } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC } from "react";

import {
    useCheckAffiliateCodeMutation,
    useUpdateTenantAffiliateCodeMutation,
} from "../../../api/affiliateAPI";

export type TChangeAffiliateCodeSubmitData = {
    affiliate_code: string;
};

type TChangeAffiliateCode = {
    show: boolean;
    onHide: () => void;
};

const ChangeAffiliateCode: FC<TChangeAffiliateCode> = ({ show, onHide }) => {
    let timeoutId: NodeJS.Timeout;

    const intl = useIntl();

    const [checkAffiliateCode, { data, isLoading: isLoadingCheckCode }] =
        useCheckAffiliateCodeMutation();
    const [updateAffiliateCode] = useUpdateTenantAffiliateCodeMutation();

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<TChangeAffiliateCodeSubmitData>();

    const handleChangeAffiliateCode = (value: string) => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            checkAffiliateCode(value);
        }, 500);
    };

    const onSubmit = (data: TChangeAffiliateCodeSubmitData) => {
        updateAffiliateCode(data).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    const status = (res.error as { status: number }).status;

                    if (status !== 403) {
                        toast.error(
                            intl.formatMessage({ id: "somethingWentWrong" })
                        );
                    }
                }
            } else {
                onHide();
            }
        });
    };

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="affiliateCode" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <div className="block-affiliate-code">
                            <Form.Control
                                className="form-input-field"
                                type="text"
                                placeholder={intl.formatMessage({
                                    id: "enterAffiliateCode",
                                })}
                                {...register("affiliate_code", {
                                    onChange: (e) =>
                                        handleChangeAffiliateCode(
                                            e.target.value
                                        ),
                                })}
                            />
                            {isLoadingCheckCode && (
                                <BiLoaderAlt
                                    className="affiliate-code-check-loading"
                                    size={18}
                                />
                            )}
                            {!isLoadingCheckCode && data && (
                                <MdOutlineDone
                                    className="affiliate-code-check-done"
                                    size={18}
                                />
                            )}
                            {!isLoadingCheckCode &&
                                getValues("affiliate_code") &&
                                !data && (
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 700, hide: 0 }}
                                        overlay={
                                            <Tooltip
                                                id="tooltip-error"
                                                className="affiliate-code-tooltip-error"
                                            >
                                                <FormattedMessage id="unableToFindAffiliateCode" />
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            <IoCloseOutline
                                                className="affiliate-code-check-error"
                                                size={18}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                )}
                        </div>
                        {errors.affiliate_code && (
                            <p className="mb-0 form-field-error">
                                {errors.affiliate_code.message}
                            </p>
                        )}
                        {data && (
                            <div className="affiliate-code-info-block mt-1">
                                <span className="me-2">
                                    {data.benefits_discount_period + ","}
                                </span>
                                <span>
                                    {data.benefits_discount +
                                        `% ${intl.formatMessage({
                                            id: "discountUppercase",
                                        })}`}
                                </span>
                            </div>
                        )}
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <button
                            disabled={data ? false : true}
                            type="submit"
                            className="dashboard-menu-button mt-2"
                        >
                            <FormattedMessage id="addCode" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ChangeAffiliateCode;
