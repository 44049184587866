import { FormattedMessage } from "react-intl";
import { FC } from "react";

import { TGetOrderResult } from "../../../../api/api.types";

type TLineItemsTableProps = {
	orderData: TGetOrderResult | undefined;
};

const LineItemsTable: FC<TLineItemsTableProps> = ({ orderData }) => {
	return (
		<div
			data-testid="line-items-block"
			className="order-details__fulfillment-details-block"
		>
			<p className="order-details__fulfillment-details-title">
				<FormattedMessage id="lineItems" />
			</p>
			<div className="order-details__fulfillment-details-table-wrapper">
				<table className="table mb-0">
					<thead className="thead-light">
						<tr>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="image" />
							</th>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="productName" />
							</th>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="quantity" />
							</th>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="price" />
							</th>
						</tr>
					</thead>
					<tbody>
						{orderData?.line_items &&
							orderData?.line_items.length > 0 &&
							orderData?.line_items.map((lineItem) => (
								<tr key={lineItem.id}>
									<td className="text-center">
										<img
											height={70}
											src={
												lineItem?.variant_product
													?.images[0]?.url
													? lineItem?.variant_product
															?.images[0]?.url
													: lineItem?.variant_product
															?.images[0]?.image
											}
											alt=""
										/>
									</td>
									<td
										width="30%"
										className="text-center max-width-td"
									>
										<div className="d-flex flex-column">
											<p className="wrapped-table-row">
												{
													lineItem?.variant_product
														?.title
												}
											</p>
											<p className="">
												SKU:{" "}
												{lineItem?.variant_product?.sku}
											</p>
										</div>
									</td>
									<td className="text-center order-details-info-text">
										{lineItem?.quantity}
									</td>
									<td className="text-center order-details-info-text">
										${lineItem?.price / 100}
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default LineItemsTable;
