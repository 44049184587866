import { useIntl } from "react-intl";
import * as yup from "yup";

import { passwordRegex } from "../../../../../../helpers/constants";

export const useRegisterFirstStepValidationSchema = () => {
    const intl = useIntl()

    return yup.object().shape({
        first_name: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        last_name: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        subdomain: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        email: yup
            .string()
            .email(intl.formatMessage({ id: "emailAddressIsInvalid" }))
            .required(intl.formatMessage({ id: "emailIsRequired" })),
        password1: yup
            .string()
            .matches(passwordRegex, intl.formatMessage({ id: "passwordMustContainMinimumEightCharactersUpperCaseAndNumber" }))
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        password2: yup
            .string()
            .oneOf([yup.ref("password1"), ""], intl.formatMessage({ id: "passwordsDoNotMatch" }))
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
    });
}