import { ChangeEvent, FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Modal } from "react-bootstrap";

import { useGetAmazonLinksQuery } from "../../../api/integrationsAPI";

import amazon from "../../assets/images/amazon.png";

import "./AddAmazonIntegration.css";

type TAddAmazonIntegrationProps = {
    show: boolean;
    onHide: () => void;
};

const AddAmazonIntegration: FC<TAddAmazonIntegrationProps> = ({
    show,
    onHide,
}) => {
    const [loginStep, setLoginStep] = useState(false);
    const [selectedUrl, setSelectedUrl] = useState("");

    const { data } = useGetAmazonLinksQuery();

    const nextStep = () => {
        if (selectedUrl) {
            setLoginStep(true);
        }
    };

    const handleChangeCountry = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedUrl(e.target.value);
    };

    const handleLoginAmazon = () => {
        window.location.href = selectedUrl;
    };

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="addNewAmazonFBAIntegration" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0 d-flex flex-column align-items-center">
                {loginStep ? (
                    <>
                        <span className="mt-2">
                            <FormattedMessage id="pleaseLoginInAmazonForContinue" />
                        </span>
                        <button
                            onClick={handleLoginAmazon}
                            className="amazon-login-button mt-2"
                        >
                            <img
                                className="add-integration-amazon-image"
                                src={amazon}
                                alt=""
                            />
                            <span>
                                <FormattedMessage id="loginWithAmazon" />
                            </span>
                        </button>
                    </>
                ) : (
                    <>
                        <span className="mt-2">
                            <FormattedMessage id="pleaseSelectACountryToContinue" />
                        </span>
                        <Form.Select
                            onChange={handleChangeCountry}
                            style={{ maxWidth: "49%" }}
                            className="mt-2"
                        >
                            <option value="">
                                <FormattedMessage id="selectCountry" />
                            </option>
                            {data &&
                                Object.keys(data).map((key) => (
                                    <option key={key} value={data[key]}>
                                        {key}
                                    </option>
                                ))}
                        </Form.Select>
                        <button className="dark-button mt-3" onClick={nextStep}>
                            <FormattedMessage id="continue" />
                        </button>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AddAmazonIntegration;
