import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useState } from "react"

import { useCheckAcceptAsNewCodeQuery } from "../../../../../api/authAPI"

import SetInvitedUserPassword from "../../../../modals/SetInvitedUserPassword/SetInvitedUserPassword"
import Loader from "../../../../components/common/Loader/Loader"

const AcceptInvatedUser = () => {
    const [invitedUserPasswordModal, setInvitedUserPasswordModal] = useState(false)

    const intl = useIntl()
    const { code } = useParams()

    const { data, isLoading, isSuccess } = useCheckAcceptAsNewCodeQuery(code ? code : '')

    const acceptInvitation = () => {
        setInvitedUserPasswordModal(true)
    }

    if (isLoading) {
        return <Loader text={`${intl.formatMessage({ id: "verifyingLinkThreeDots" })}`} />
    }

    if (isSuccess && data.expired === false) {
        return (
            <div className="min-block-height d-flex flex-column align-items-center justify-content-center">
                <span className="email-verified-text">
                    <FormattedMessage id="youHaveBeenInvitedToOneOfTheTenantsOfCloudOrder" />
                </span>
                <button className="dark-button" onClick={acceptInvitation}>
                    <FormattedMessage id="acceptInvitation" />
                </button>
                <SetInvitedUserPassword
                    show={invitedUserPasswordModal}
                    onHide={() => setInvitedUserPasswordModal(false)}
                    code={code ? code : ''}
                />
            </div>
        )
    }

    if (isSuccess && data.expired === true) {
        return (
            <div className="min-block-height d-flex flex-column align-items-center justify-content-center">
                <span className="email-verified-text">
                    <FormattedMessage id="yourLinkIsOutOfDate" />
                </span>
            </div>
        )
    }

    return <Loader />
}

export default AcceptInvatedUser