import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="privacy-policy-wrapper">
			<div className="privacy-policy">
				<h3 className="privacy-policy__title">
					<FormattedMessage id="privacyPolicyForCloudOrder" />
				</h3>
				<p className="privacy-policy__last-updated">
					<span className="privacy-policy__bold-text">
						<FormattedMessage id="lastUpdated" />
					</span>{" "}
					<FormattedMessage id="privacyPolicyLastUpdateDate" />
				</p>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="1Introduction" />
				</h4>
				<p>
					<FormattedMessage id="welcomeToCloudOrderWeAreCommittedToProtectingYourPrivacy" />
				</p>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="2InformationWeCollect" />
				</h4>
				<h5>
					<FormattedMessage id="2.1PersonalInformation" />
				</h5>
				<ul className="privacy-policy__list">
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="nameEmailAddressContactInformation" />
						</span>{" "}
						<FormattedMessage id="whenYouPlaceAnOrderSubscribeToOurNewsletterOrContactUs" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="paymentInformation" />
						</span>{" "}
						<FormattedMessage id="whenYouMakeAPurchaseOnOurSiteWeCollectPaymentData" />
					</li>
				</ul>
				<h5>
					<FormattedMessage id="2.2AutomaticallyCollectedInformation" />
				</h5>
				<ul className="privacy-policy__list">
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="cookiesAndSimilarTechnologies" />
						</span>{" "}
						<FormattedMessage id="weUseCookiesToTrackUserBehaviorForImprovingOurService" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="logData" />
						</span>{" "}
						<FormattedMessage id="includingIPAddressesBrowserTypeReferringExitPage" />
					</li>
				</ul>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="3HowWeUseYourInformation" />
				</h4>
				<ul className="privacy-policy__list">
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="toProcessYourOrders" />
						</span>{" "}
						<FormattedMessage id="weUseYourInformationToFulfillOrdersCommunicateWithYouAboutThem" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="toImproveOurService" />
						</span>{" "}
						<FormattedMessage id="analyzingUserBehaviorToEnhanceOurWebsiteFunctionality" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="forMarketing" />
						</span>{" "}
						<FormattedMessage id="withYourConsentWeMightSendMarketingCommunicationsAboutOurProducts" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="toComplyWithLegalObligations" />
						</span>{" "}
						<FormattedMessage id="suchAsTaxCalculationsComplianceWithLegalRequests" />
					</li>
				</ul>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="4SharingYourInformation" />
				</h4>
				<ul className="privacy-policy__list">
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="serviceProviders" />
						</span>{" "}
						<FormattedMessage id="weMayShareYourInformationWithThirdPartiesWhoAssistUsInDelivering" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="legalCompliance" />
						</span>{" "}
						<FormattedMessage id="weMayDiscloseInformationInResponseToLegalRequestsOrToProtect" />
					</li>
				</ul>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="5YourRights" />
				</h4>
				<ul className="privacy-policy__list">
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="access" />
						</span>{" "}
						<FormattedMessage id="youCanRequestAccessToThePersonalDataWeHoldAboutYou" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="correction" />
						</span>{" "}
						<FormattedMessage id="youHaveTheRightToCorrectInaccurateOrIncompleteData" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="deletion" />
						</span>{" "}
						<FormattedMessage id="youCanRequestTheDeletionOfYourPersonalDataWhereThereNoCompelling" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="object" />
						</span>{" "}
						<FormattedMessage id="youMayObjectToOurProcessingOfYourDataUnderCertain" />
					</li>
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="portability" />
						</span>{" "}
						<FormattedMessage id="youHaveTheRightToReceiveYourPersonalDataInAStructured" />
					</li>
				</ul>
				<p>
					<FormattedMessage id="toExerciseTheseRightsPleaseContactUsAt" />{" "}
					<a
						className="privacy-policy__link"
						href="mailto:contact@cloudorder.us"
					>
						contact@cloudorder.us
					</a>
					.
				</p>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="6DataSecurity" />
				</h4>
				<p>
					<FormattedMessage id="weProtectYourDataUsingIndustryStandardSecurityMeasures" />
				</p>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="7InternationalDataTransfers" />
				</h4>
				<p>
					<FormattedMessage id="yourDataMayBeProcessedInCountriesOutsideOfYourOwn" />
				</p>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="8RetentionOfData" />
				</h4>
				<p>
					<FormattedMessage id="weRetainYourPersonalInformationOnlyForAsLongAsNecessary" />
				</p>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="9ChildrenPrivacy" />
				</h4>
				<p>
					<FormattedMessage id="ourServiceAreNotDirectedToChildrenUnder13" />
				</p>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="10ChangesToThisPrivacyPolicy" />
				</h4>
				<p>
					<FormattedMessage id="weMayUpdateOurPrivacyPolicyFromTimeToTime" />
				</p>
				<h4 className="privacy-policy__section-title">
					<FormattedMessage id="11ContactInformation" />
				</h4>
				<p>
					<FormattedMessage id="forAnyQuestionsOrConcernsAboutThisPrivacyPolicyPleaseContactUsAt" />
				</p>
				<ul className="privacy-policy__list">
					<li>
						<span className="privacy-policy__bold-text">
							<FormattedMessage id="emailWithDots" />
						</span>{" "}
						<a
							className="privacy-policy__link"
							href="mailto:contact@cloudorder.us"
						>
							contact@cloudorder.us
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};
export default PrivacyPolicy;
