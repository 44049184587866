// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopify-logo-title {
	font-size: 18px;
	font-style: italic;
}

.red-color {
	color: red;
}

.list-item-disc {
	display: list-item;
	list-style: disc;
	font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modals/AddShopifyIntegration/AddShopifyIntegration.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,kBAAkB;CAClB,gBAAgB;CAChB,eAAe;AAChB","sourcesContent":[".shopify-logo-title {\n\tfont-size: 18px;\n\tfont-style: italic;\n}\n\n.red-color {\n\tcolor: red;\n}\n\n.list-item-disc {\n\tdisplay: list-item;\n\tlist-style: disc;\n\tfont-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
