// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	margin: 0;
	font-family:
		-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family:
		source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
	box-sizing: border-box;
}

:root {
	--background: #ffffff;
	--text: #202020;
	--button: #4db8ff;
	--dark: #000000;
	--gray: #d9d9d9;
	--button-hover: #1da1f2;
	--secondary-text: #2a2a2e;
	--background-light: #eff8ff;
	--border-transparent: #66666659;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT;;;YAGW;CACX,mCAAmC;CACnC,kCAAkC;AACnC;;AAEA;CACC;oEACmE;AACpE;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC,qBAAqB;CACrB,eAAe;CACf,iBAAiB;CACjB,eAAe;CACf,eAAe;CACf,uBAAuB;CACvB,yBAAyB;CACzB,2BAA2B;CAC3B,+BAA+B;AAChC","sourcesContent":["body {\n\tmargin: 0;\n\tfont-family:\n\t\t-apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n\t\t\"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n\t\tsans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n\tfont-family:\n\t\tsource-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n* {\n\tbox-sizing: border-box;\n}\n\n:root {\n\t--background: #ffffff;\n\t--text: #202020;\n\t--button: #4db8ff;\n\t--dark: #000000;\n\t--gray: #d9d9d9;\n\t--button-hover: #1da1f2;\n\t--secondary-text: #2a2a2e;\n\t--background-light: #eff8ff;\n\t--border-transparent: #66666659;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
