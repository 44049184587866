import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC, useEffect } from "react";

import { useModifyProductAutoUpdateMutation } from "../../../api/productsAPI";
import { TProductVariants } from "../../../api/api.types";

export type TModifyAutoUpdateSubmitData = {
	disallow_auto_update_price: boolean | string;
	disallow_auto_update_stock: boolean | string;
};

type TModifyAutoUpdate = {
	show: boolean;
	onHide: () => void;
	product: TProductVariants | undefined;
};

const ModifyAutoUpdate: FC<TModifyAutoUpdate> = ({ show, onHide, product }) => {
	const intl = useIntl();

	const [modifyAuto] = useModifyProductAutoUpdateMutation();

	const { register, handleSubmit, setValue } =
		useForm<TModifyAutoUpdateSubmitData>();

	const onSubmit = (data: TModifyAutoUpdateSubmitData) => {
		if (product) {
			const object = {
				data: {
					disallow_auto_update_price:
						data.disallow_auto_update_price === "true"
							? true
							: false,
					disallow_auto_update_stock:
						data.disallow_auto_update_stock === "true"
							? true
							: false,
				},
				id: product?.id,
			};

			modifyAuto(object).then((res) => {
				if ("error" in res) {
					const status = (res.error as { status: number }).status;

					if (status !== 403) {
						toast.error(
							intl.formatMessage({ id: "somethingWentWrong" })
						);
					}
				} else {
					onHide();
				}
			});
		}
	};

	useEffect(() => {
		if (product) {
			setValue(
				"disallow_auto_update_price",
				product?.disallow_auto_update_price ? "true" : "false"
			);
			setValue(
				"disallow_auto_update_stock",
				product?.disallow_auto_update_stock ? "true" : "false"
			);
		}
	}, [product, setValue]);

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="modifyAutoUpdate" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group className="mb-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="disallowAutoUpdatePrice" />
						</Form.Label>
						<Form.Select
							data-testid="disallow-auto-update-select"
							className="default-input"
							{...register("disallow_auto_update_price")}
						>
							<option data-testid="disallow-yes" value="true">
								<FormattedMessage id="yes" />
							</option>
							<option value="false">
								<FormattedMessage id="no" />
							</option>
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="disallowAutoUpdateStock" />
						</Form.Label>
						<Form.Select
							className="default-input"
							{...register("disallow_auto_update_stock")}
						>
							<option value="true">
								<FormattedMessage id="yes" />
							</option>
							<option value="false">
								<FormattedMessage id="no" />
							</option>
						</Form.Select>
					</Form.Group>
					<div className="d-flex justify-content-end mt-4">
						<button
							data-testid="modify-button"
							type="submit"
							className="ice-button w-100"
						>
							<FormattedMessage id="modifyAutoUpdate" />
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default ModifyAutoUpdate;
