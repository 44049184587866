import { FaShippingFast, FaWeightHanging } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { BsCurrencyDollar } from "react-icons/bs";
import { RxDimensions } from "react-icons/rx";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect } from "react";

import {
    useGetFinancialRatesQuery,
    useUpdateFinancialRatesMutation,
} from "../../../../api/settingsAPI";
import { TRatesObject } from "../../../../api/api.types";
import { RATES_SHIPPING_METHODS_TRANSLATIONS } from "../../../../helpers/constants";

export type TRatesData = {
    [key: string]: number;
};

const SettingsRates = () => {
    const { data } = useGetFinancialRatesQuery();

    const intl = useIntl();

    const [updateFinancialRates] = useUpdateFinancialRatesMutation();

    const { register, handleSubmit, setValue } = useForm<TRatesData>();

    const onSubmit = (formData: TRatesData) => {
        const updatedData: TRatesObject[] = [];

        if (data && data.length > 0 && data[0].shipping_rates_json.length > 0) {
            data[0].shipping_rates_json.forEach((rate) => {
                updatedData.push({
                    ...rate,
                    min_weight: formData[`min_weight_${rate.id}`],
                    max_weight: formData[`max_weight_${rate.id}`],
                    cost: formData[`cost_${rate.id}`] * 100,
                });
            });

            updateFinancialRates({ shipping_rates_json: updatedData }).then(
                (res) => {
                    if ("error" in res) {
                        const status = (res.error as { status: number }).status;

                        if (status !== 403) {
                            toast.error(
                                intl.formatMessage({ id: "somethingWentWrong" })
                            );
                        }
                    } else {
                        toast.success(
                            intl.formatMessage({
                                id: "shippingRatesHaveBeenUpdated",
                            })
                        );
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (data && data.length > 0 && data[0].shipping_rates_json.length > 0) {
            data[0].shipping_rates_json.forEach((rate) => {
                setValue(`min_weight_${rate.id}`, rate.min_weight);
                setValue(`max_weight_${rate.id}`, rate.max_weight);
                setValue(`cost_${rate.id}`, rate.cost / 100);
            });
        }
    }, [data, setValue]);

    return (
        <div className="row">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                    <div className="settings-card px-3 mt-4 pb-2">
                        <div className="row dashboard-menu-sub d-flex align-items-center">
                            <div className="col-lg-7">
                                <div className="left">
                                    <ul className="dashboard-menu-ul">
                                        <li className="dashboard-menu-li">
                                            <span className="dashboard-menu-a">
                                                <FormattedMessage id="shippingRates" />
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="right text-right d-flex justify-content-end">
                                    <button
                                        type="submit"
                                        className="catalog-button-blue dropdown-settings-button"
                                    >
                                        <FormattedMessage id="saveChanges" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="settingsUesrManagetable">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center" scope="col">
                                            <FaShippingFast size={20} />{" "}
                                            <FormattedMessage id="shippingMethod" />
                                        </th>
                                        <th className="text-center" scope="col">
                                            <FaWeightHanging size={17} />{" "}
                                            <FormattedMessage id="minWeight" />
                                        </th>
                                        <th className="text-center" scope="col">
                                            <FaWeightHanging size={17} />{" "}
                                            <FormattedMessage id="maxWeight" />
                                        </th>
                                        <th className="text-center" scope="col">
                                            <BsCurrencyDollar size={20} />{" "}
                                            <FormattedMessage id="cost" />
                                        </th>
                                        <th className="text-center" scope="col">
                                            <RxDimensions size={20} />{" "}
                                            <FormattedMessage id="dimension" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="rates-table-body">
                                    {data &&
                                        data.length > 0 &&
                                        data[0].shipping_rates_json.length >
                                            0 &&
                                        data[0].shipping_rates_json.map(
                                            (rate) => (
                                                <tr key={rate.id}>
                                                    <td className="text-center">
                                                        {intl.formatMessage({
                                                            id: RATES_SHIPPING_METHODS_TRANSLATIONS[
                                                                rate.shipping_method as keyof typeof RATES_SHIPPING_METHODS_TRANSLATIONS
                                                            ],
                                                        })}
                                                    </td>
                                                    <td className="text-center">
                                                        <Form.Group>
                                                            <Form.Control
                                                                className="form-input-field"
                                                                type="number"
                                                                placeholder={`${intl.formatMessage(
                                                                    {
                                                                        id: "minWeight",
                                                                    }
                                                                )}`}
                                                                {...register(
                                                                    `min_weight_${rate.id}`
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td className="text-center">
                                                        <Form.Group>
                                                            <Form.Control
                                                                className="form-input-field"
                                                                type="number"
                                                                placeholder={`${intl.formatMessage(
                                                                    {
                                                                        id: "maxWeight",
                                                                    }
                                                                )}`}
                                                                {...register(
                                                                    `max_weight_${rate.id}`
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td className="text-center">
                                                        <Form.Group>
                                                            <Form.Control
                                                                className="form-input-field"
                                                                type="number"
                                                                placeholder={`${intl.formatMessage(
                                                                    {
                                                                        id: "cost",
                                                                    }
                                                                )}`}
                                                                {...register(
                                                                    `cost_${rate.id}`
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td className="text-center">
                                                        {rate.weight_demension}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default SettingsRates;
