import { FC, useState } from "react";
import { SlArrowRight } from "react-icons/sl";

import { TCategory } from "../../../../api/api.types";

type TCategoryProps = {
    name: string,
    children: TCategory[],
    handleCategoryFilter: (value: string, handlePage?: (value: string) => void) => void,
    handlePage: (value: string) => void,
    id: number,
    setOpenCategories: (value: boolean) => void,
}

const Category: FC<TCategoryProps> = ({ name, children, handleCategoryFilter, id, handlePage, setOpenCategories }) => {
    const [show, setShow] = useState(false)

    const handleFilterProducts = (id: number) => {
        handleCategoryFilter(`${id}`)
        setOpenCategories(false)
    }

    return (
        <ul className="p-0">
            <li>
                <span onClick={() => handleFilterProducts(id)}>{name}</span>
                {children.length > 0 ? (
                    <SlArrowRight
                        size={15}
                        className={show ? 'categories-item-icon-active ms-2' : 'ms-2'}
                        onClick={() => setShow(!show)}
                    />
                ) : ''}
            </li>
            {children && show && (
                <ul>
                    {children.map((child) => (
                        <li key={child.id}>
                            <Category
                                name={child.name}
                                children={child.children}
                                id={child.id}
                                handleCategoryFilter={handleCategoryFilter}
                                handlePage={handlePage}
                                setOpenCategories={setOpenCategories}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </ul>
    );
}

export default Category