import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import { TranslationsProvider } from './contexts/useTranslationsContext';
import { store } from './redux/store';

import App from './App';

import './index.css';
import './app/assets/styles/_variables.css';
import './app/assets/styles/globals.css';

ReactGA.initialize('G-GPWDB298D5');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <TranslationsProvider>
        <App />
      </TranslationsProvider>
    </Provider>
  </BrowserRouter>
);
