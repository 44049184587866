import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC, useEffect } from "react";

import { TEditProduct, TProductVariants } from "../../../api/api.types";
import { useEditProductMutation } from "../../../api/productsAPI";

export type TUpdateProductAmountSubmitData = {
	amount_total: number;
	amount: string;
	title: string;
	description: string;
	sku: string;
	msrp_amount: string;
} & {
	[key: string]: string;
};

type TEditProductModalProps = {
	show: boolean;
	onHide: () => void;
	product: TProductVariants | undefined;
};

const EditProductModal: FC<TEditProductModalProps> = ({
	show,
	onHide,
	product,
}) => {
	const intl = useIntl();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<TUpdateProductAmountSubmitData>();

	const [updateProduct] = useEditProductMutation();

	const onSubmit = (data: TUpdateProductAmountSubmitData) => {
		if (product) {
			const finalData: TEditProduct = {};

			if (product.inventories[0].amount_total !== data.amount_total) {
				finalData["inventory"] = {
					id: product?.inventories[0].id,
					amount_total: data.amount_total,
				};
			}

			if (
				product.prices[0].amount / 100 !== Number(data.amount) ||
				product.prices[0].msrp_amount / 100 !== Number(data.msrp_amount)
			) {
				finalData["price"] = {
					id: product?.prices[0].id,
					amount: Math.round(Number(data.amount) * 100),
					msrp_amount: Math.round(Number(data.msrp_amount) * 100),
				};
			}

			if (product.title !== data.title) {
				finalData["title"] = data.title;
			}

			if (product.description !== data.description) {
				finalData["description"] = data.description;
			}

			if (product.sku !== data.sku) {
				finalData["sku"] = data.sku;
			}

			Object.entries(data).forEach(([key, value]) => {
				if (key.includes("property_")) {
					const propertyId = key.split("_")[1];
					const property = product.properties.find(
						(property) => property.id === Number(propertyId)
					);

					finalData["properties_list"] =
						finalData["properties_list"] || [];

					if (property) {
						if (property.value !== value) {
							finalData["properties_list"] = [
								...finalData["properties_list"],
								{
									id: property.id,
									value: String(value),
								},
							];
						}
					}
				}
			});

			updateProduct({
				id: product?.inventories[0].id,
				data: finalData,
			}).then((res) => {
				if ("error" in res) {
					console.log("OOOOO", res.error);
					const error = res.error as {
						status: number;
						data: { error?: string[] };
					};

					if (error.status !== 403) {
						if (error.data.error) {
							toast.error(error.data.error[0]);
						} else {
							toast.error(
								intl.formatMessage({ id: "somethingWentWrong" })
							);
						}
					}
				} else {
					onHide();
				}
			});
		}
	};

	useEffect(() => {
		if (product) {
			setValue("amount_total", product.inventories[0].amount_total);
			setValue("amount", String(product.prices[0].amount / 100));
			setValue("title", product.title);
			setValue("description", product.description);
			setValue("sku", product.sku);
			setValue(
				"msrp_amount",
				String(product.prices[0]?.msrp_amount / 100)
			);

			Object.entries(product?.properties || {}).forEach(([, value]) => {
				setValue(`property_${String(value.id)}`, value.value);
			});
		}
	}, [product, setValue]);

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="editProduct" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group className="mb-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="productTitle" />
						</Form.Label>
						<Form.Control
							className="default-input"
							type="string"
							{...register("title", {
								required: {
									value: true,
									message: intl.formatMessage({
										id: "thisFieldIsRequired",
									}),
								},
							})}
						/>
						{errors.title && (
							<p className="mb-0 form-field-error">
								{errors.title.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mb-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="productSKU" />
						</Form.Label>
						<Form.Control
							className="default-input"
							type="string"
							{...register("sku", {
								required: {
									value: true,
									message: intl.formatMessage({
										id: "thisFieldIsRequired",
									}),
								},
							})}
						/>
						{errors.sku && (
							<p className="mb-0 form-field-error">
								{errors.sku.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mb-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="productDescription" />
						</Form.Label>
						<Form.Control
							className="default-input"
							type="string"
							as="textarea"
							style={{ height: "120px" }}
							{...register("description", {
								required: {
									value: true,
									message: intl.formatMessage({
										id: "thisFieldIsRequired",
									}),
								},
							})}
						/>
						{errors.description && (
							<p className="mb-0 form-field-error">
								{errors.description.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mb-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="totalAmount" />
						</Form.Label>
						<Form.Control
							data-testid="amount"
							className="default-input"
							type="number"
							{...register("amount_total", {
								required: {
									value: true,
									message: intl.formatMessage({
										id: "thisFieldIsRequired",
									}),
								},
							})}
						/>
						{errors.amount_total && (
							<p className="mb-0 form-field-error">
								{errors.amount_total.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mb-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="totalPrice" />
						</Form.Label>
						<Form.Control
							data-testid="product-price-input"
							className="default-input"
							type="text"
							{...register("amount", {
								required: {
									value: true,
									message: intl.formatMessage({
										id: "thisFieldIsRequired",
									}),
								},
							})}
						/>
						{errors.amount && (
							<p className="mb-0 form-field-error">
								{errors.amount.message}
							</p>
						)}
					</Form.Group>
					<Form.Group className="mb-2">
						<Form.Label className="default-input-label">
							<FormattedMessage id="msrpPrice" />
						</Form.Label>
						<Form.Control
							data-testid="product-price-input"
							className="default-input"
							type="text"
							{...register("msrp_amount", {
								required: {
									value: true,
									message: intl.formatMessage({
										id: "thisFieldIsRequired",
									}),
								},
							})}
						/>
						{errors.msrp_amount && (
							<p className="mb-0 form-field-error">
								{errors.msrp_amount.message}
							</p>
						)}
					</Form.Group>
					{Object.entries(product?.properties || {}).map(
						([key, value]) => {
							return (
								<Form.Group key={key} className="mb-2">
									<Form.Label
										style={{ textTransform: "capitalize" }}
										className="default-input-label"
									>
										{value.name}
									</Form.Label>
									<Form.Control
										data-testid="product-price-input"
										className="default-input"
										type="text"
										{...register(`property_${value.id}`, {
											required: {
												value: true,
												message: intl.formatMessage({
													id: "thisFieldIsRequired",
												}),
											},
										})}
									/>
									{errors[`property_${value.id}`] && (
										<p className="mb-0 form-field-error">
											{
												errors[`property_${value.id}`]
													?.message
											}
										</p>
									)}
								</Form.Group>
							);
						}
					)}
					<div className="d-flex justify-content-end mt-4">
						<button
							data-testid="save-product-updates"
							type="submit"
							className="ice-button w-100"
						>
							<FormattedMessage id="save" />
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default EditProductModal;
