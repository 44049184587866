import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useUpdateFulfillmentMutation } from "../../../api/ordersAPI";
import { TOrderFulfillment } from "../../../api/api.types";

type EditFulfillmentStatusProps = {
	onHide: () => void;
	fulfillment: TOrderFulfillment;
};

const EditFulfillmentStatus: FC<EditFulfillmentStatusProps> = ({
	onHide,
	fulfillment,
}) => {
	const [status, setStatus] = useState<string>("NEW");

	const intl = useIntl();

	const [updateFulfillmentStatus] = useUpdateFulfillmentMutation();

	const handleSave = () => {
		updateFulfillmentStatus({
			id: fulfillment.id,
			data: {
				status: status,
			},
		}).then((res) => {
			if ("error" in res) {
				const error = (res.error as { data: { error: string[] } })?.data
					?.error[0];

				toast.error(error);
			} else {
				toast.success(intl.formatMessage({ id: "statusUpdated" }));
			}
			onHide();
		});
	};

	useEffect(() => {
		if (fulfillment) {
			const status = fulfillment.status;

			if (status.toLowerCase() === "new") {
				setStatus("NEW");
			} else if (status.toLowerCase() === "in progress") {
				setStatus("IN_PROGRESS");
			} else if (status.toLowerCase() === "completed") {
				setStatus("COMPLETED");
			}
		}
	}, [fulfillment]);
	return (
		<Modal className="modal-500" centered show onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="editFulfillmentStatus" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form.Group className="mb-2">
					<Form.Label className="default-input-label">
						<FormattedMessage id="fulfillmentStatus" />
					</Form.Label>
					<Form.Select
						value={status}
						onChange={(e) => setStatus(e.target.value)}
						className="default-input"
					>
						<option value="NEW">
							<FormattedMessage id="new" />
						</option>
						<option value="IN_PROGRESS">
							<FormattedMessage id="inProgress" />
						</option>
						<option value="COMPLETED">
							<FormattedMessage id="completed" />
						</option>
					</Form.Select>
				</Form.Group>
				<div className="d-flex justify-content-end mt-3">
					<button
						onClick={handleSave}
						type="button"
						className="ice-button w-100"
					>
						<FormattedMessage id="updateStatus" />
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default EditFulfillmentStatus;
