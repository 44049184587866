import { UseFormRegister, FieldValues, Path } from "react-hook-form";
import { Form } from "react-bootstrap";

import "./Switch.css";
import { FormattedMessage } from "react-intl";

type SwitchProps<T extends FieldValues> = {
	value?: boolean;
	setValue?: (value: boolean) => void;
	leftLabel?: string;
	rightLabel?: string;
	register?: UseFormRegister<T>;
	required?: boolean;
	registerField?: Path<T>;
};

const Switch = <T extends FieldValues>({
	value,
	setValue,
	leftLabel,
	rightLabel,
	register,
	required,
	registerField,
}: SwitchProps<T>) => {
	return (
		<div className="switch__wrapper">
			{leftLabel && (
				<span className="switch__label">
					<FormattedMessage id={leftLabel || "no"} />
				</span>
			)}
			{register && registerField ? (
				<Form.Check
					type="switch"
					{...register(registerField, {
						required: required ? true : false,
					})}
				/>
			) : value && setValue ? (
				<Form.Check
					checked={value}
					onChange={() => setValue(!value)}
					type="switch"
				/>
			) : null}
			{rightLabel && (
				<span className="switch__label">
					<FormattedMessage id={rightLabel || "yes"} />
				</span>
			)}
		</div>
	);
};

export default Switch;
