import { BiErrorCircle } from "react-icons/bi"
import { FormattedMessage } from "react-intl"
import { Modal } from "react-bootstrap"
import { FC } from "react"

import { TBulkUploadError } from "../../../api/api.types"

type TBulkUploadErrors = {
    show: boolean,
    onHide: () => void,
    errors: TBulkUploadError[],
}

const BulkUploadErrors: FC<TBulkUploadErrors> = ({ show, onHide, errors }) => {
    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title className="d-flex align-items-center gap-1">
                    <BiErrorCircle size={24} />
                    <FormattedMessage id="bulkProductsUploadErrors" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ gap: '12px' }}
                className="pt-0 d-flex flex-column"
            >
                <p className="bulk-upload-description">
                    <FormattedMessage id="theFollowingErrorsOccurredDuringTheBulkUploadProcess" />
                </p>
                <div className="bulk-upload-errors-list">
                    {errors.map((error, index) => (
                        <div key={index} className="bulk-upload-errors-item">
                            <p>
                                <FormattedMessage id="issueInColumn" /> "{error.column}", <FormattedMessage id="row" /> "{error.row}":
                            </p>
                            <span className="bulk-upload-description">
                                {error.message}
                            </span>
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default BulkUploadErrors