import { useIntl } from "react-intl";
import * as yup from "yup";

export const useLoginValidationSchema = () => {
    const intl = useIntl()

    return yup.object().shape({
        email: yup
            .string()
            .email(intl.formatMessage({ id: "emailAddressIsInvalid" }))
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        password: yup
            .string()
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
    });
}