import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "./apiConfig";

import { TGetJobsResponse, TJobDetails, TUpdateJobData } from "./api.types";
import { TCreateTaskSubmitData } from "../app/modals/CreateTask/CreateTask";

export const jobsApi = createApi({
	reducerPath: "jobsApi",
	baseQuery: baseQueryWithReAuth,
	tagTypes: ["Jobs"],
	endpoints: (builder) => ({
		getJobs: builder.query<
			TGetJobsResponse,
			{
				activePage: string;
				searchFilter: string;
				pageSize: string;
				defaultFilter: string;
			}
		>({
			query: ({ activePage, searchFilter, pageSize, defaultFilter }) => {
				return {
					url: `/management/jobs/?page=${activePage}${searchFilter ? `&search=${searchFilter}` : ""}${pageSize ? `&page_size=${pageSize}` : ""}${defaultFilter ? `&status=${defaultFilter}` : ""}`,
					method: "GET",
				};
			},
			providesTags: ["Jobs"],
		}),
		createJob: builder.mutation<void, TCreateTaskSubmitData>({
			query: (data) => {
				return {
					url: `/management/jobs/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["Jobs"],
		}),
		deleteJob: builder.mutation<void, string>({
			query: (id) => {
				return {
					url: `/management/jobs/${id}/`,
					method: "DELETE",
				};
			},
			invalidatesTags: ["Jobs"],
		}),
		runJobManually: builder.mutation<void, string>({
			query: (id) => {
				return {
					url: `/management/jobs/run/${id}/`,
					method: "PATCH",
				};
			},
			invalidatesTags: ["Jobs"],
		}),
		updateJob: builder.mutation<void, { data: TUpdateJobData; id: string }>(
			{
				query: ({ data, id }) => {
					return {
						url: `/management/jobs/${id}/`,
						method: "PATCH",
						body: data,
					};
				},
				invalidatesTags: ["Jobs"],
			}
		),
		getJobById: builder.query<TJobDetails, string>({
			query: (id) => {
				return {
					url: `/management/jobs/${id}/?expand=results`,
					method: "GET",
				};
			},
		}),
	}),
});

export const {
	useGetJobsQuery,
	useCreateJobMutation,
	useDeleteJobMutation,
	useRunJobManuallyMutation,
	useUpdateJobMutation,
	useGetJobByIdQuery,
} = jobsApi;
