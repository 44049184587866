import { FormattedMessage, useIntl } from "react-intl"
import { Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { FC } from "react"

import { useUpdateJobMutation } from "../../../api/jobsAPI"
import { TJob } from "../../../api/api.types"

export type TEditTaskArgsSubmitData = {
    function_arguments: string,
}

type TEditTaskArgsProps = {
    job: TJob,
    show: boolean,
    onHide: () => void,
}

const EditTaskArgs: FC<TEditTaskArgsProps> = ({ job, show, onHide }) => {
    const intl = useIntl()

    const [updateJob] = useUpdateJobMutation()

    const { register, handleSubmit } = useForm<TEditTaskArgsSubmitData>();

    const onSubmit = (data: TEditTaskArgsSubmitData) => {
        updateJob({
            data: {
                function_arguments: JSON.parse(data.function_arguments)
            }, id: job.id
        })
        onHide()
    }

    return (
        <Modal size="lg" centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="editFunctionArguments" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className='mt-1'>
                        <Form.Label className='form-input-label'>
                            <FormattedMessage id="functionArguments" />
                        </Form.Label>
                        <Form.Control
                            defaultValue={JSON.stringify(job.function_arguments)}
                            as="textarea"
                            className="form-input-field"
                            type="string"
                            placeholder={`${intl.formatMessage({ id: "functionArguments" })}`}
                            {...register("function_arguments")}
                        />
                    </Form.Group>
                    <div className='d-flex justify-content-end mt-3'>
                        <button type='submit' className="dashboard-menu-button">
                            <FormattedMessage id="change" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default EditTaskArgs