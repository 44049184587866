// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-nav {
    display: flex;
}
.settings-nav a {
    padding: 10px !important;
    display: flex !important;
    color: #6C6B79 !important; 
    text-decoration: none !important;
    position: relative !important;
    font-weight: 400 !important;
}
.settings-nav .active {
    transition: all 0.8s !important;
    border-bottom: 2px solid #1C6FD1;
}
.settings-nav .active::before {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/layout/SettingsUserNav/SettingsUserNav.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,wBAAwB;IACxB,wBAAwB;IACxB,yBAAyB;IACzB,gCAAgC;IAChC,6BAA6B;IAC7B,2BAA2B;AAC/B;AACA;IACI,+BAA+B;IAC/B,gCAAgC;AACpC;AACA;IACI,aAAa;AACjB","sourcesContent":[".settings-nav {\n    display: flex;\n}\n.settings-nav a {\n    padding: 10px !important;\n    display: flex !important;\n    color: #6C6B79 !important; \n    text-decoration: none !important;\n    position: relative !important;\n    font-weight: 400 !important;\n}\n.settings-nav .active {\n    transition: all 0.8s !important;\n    border-bottom: 2px solid #1C6FD1;\n}\n.settings-nav .active::before {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
