import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoClose, IoWarning } from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import { FaSyncAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FC } from "react";

import { TProductVariants } from "../../../../api/api.types";

type MyProductsVariantRowProps = {
	variant: TProductVariants;
};

const MyProductsVariantRow: FC<MyProductsVariantRowProps> = ({ variant }) => {
	return (
		<tr key={variant.id} className="nested-table-body">
			<td
				width="30%"
				className="text-center max-width-td padding-product-nested-table"
			>
				{variant.status !== "available" &&
				variant.status !== "pending_synchronization" ? (
					<OverlayTrigger
						overlay={
							<Tooltip id="hi">
								<FormattedMessage id="actionRequired" />
							</Tooltip>
						}
						placement="bottom"
					>
						<span className="wrapped-table-row">
							<Link
								data-testid="details-link"
								style={{
									gap: "8px",
								}}
								className="my-product-details-link"
								to={`/dashboard/my-products/${variant.id}`}
							>
								<IoClose
									style={{
										marginRight: "5px",
										marginBottom: "2px",
									}}
									size={23}
									color="red"
								/>
								{variant.title}
							</Link>
						</span>
					</OverlayTrigger>
				) : variant.status === "pending_synchronization" ? (
					<OverlayTrigger
						overlay={
							<Tooltip id="hi">
								<FormattedMessage id="pendingSynchronization" />
							</Tooltip>
						}
						placement="bottom"
					>
						<span className="wrapped-table-row">
							<Link
								style={{
									gap: "8px",
								}}
								className="my-product-details-link"
								to={`/dashboard/my-products/${variant.id}`}
							>
								<FaSyncAlt
									style={{
										marginRight: "5px",
										marginBottom: "2px",
									}}
									size={20}
									color="#4e61f4"
								/>
								{variant.title}
							</Link>
						</span>
					</OverlayTrigger>
				) : variant.images.length === 0 ? (
					<OverlayTrigger
						overlay={
							<Tooltip id="hi">
								<FormattedMessage id="actionRequired" />
							</Tooltip>
						}
						placement="bottom"
					>
						<span className="wrapped-table-row">
							<Link
								style={{
									gap: "8px",
								}}
								className="my-product-details-link"
								to={`/dashboard/my-products/${variant.id}`}
							>
								<IoWarning
									style={{
										marginRight: "5px",
										marginBottom: "2px",
									}}
									size={20}
									color="#ffcc00"
								/>
								{variant.title}
							</Link>
						</span>
					</OverlayTrigger>
				) : (
					<span className="wrapped-table-row">
						<Link
							style={{
								gap: "8px",
							}}
							className="my-product-details-link"
							to={`/dashboard/my-products/${variant.id}`}
						>
							{variant.title}
						</Link>
					</span>
				)}
			</td>
			<td className="text-center">
				{variant.inventories[0].amount_available}
			</td>
			<td className="text-center">
				{variant.inventories[0].amount_reserve}
			</td>
			<td className="text-center">{variant.sku}</td>
			<td className="text-center">
				{variant.prices[0].currency.sign}
				{variant.prices[0].amount / 100}
			</td>
			<td className="text-center">
				{variant.prices[0].currency.sign}
				{variant.prices[0].msrp_amount / 100}
			</td>
		</tr>
	);
};

export default MyProductsVariantRow;
