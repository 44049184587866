// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-element-form {
	display: flex;
	flex-direction: column;
}

.card-element-form-text {
	font-size: 13px;
	text-align: center;
	margin: 5px 0 15px 0;
}

.card-element {
	border-radius: 4px 4px 0 0;
	padding: 12px;
	border: 1px solid rgba(50, 50, 93, 0.1);
	max-height: 44px;
	width: 100%;
	background: white;
	box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/StripeCardForm/StripeCardForm.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,eAAe;CACf,kBAAkB;CAClB,oBAAoB;AACrB;;AAEA;CACC,0BAA0B;CAC1B,aAAa;CACb,uCAAuC;CACvC,gBAAgB;CAChB,WAAW;CACX,iBAAiB;CACjB,sBAAsB;AACvB","sourcesContent":[".card-element-form {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.card-element-form-text {\n\tfont-size: 13px;\n\ttext-align: center;\n\tmargin: 5px 0 15px 0;\n}\n\n.card-element {\n\tborder-radius: 4px 4px 0 0;\n\tpadding: 12px;\n\tborder: 1px solid rgba(50, 50, 93, 0.1);\n\tmax-height: 44px;\n\twidth: 100%;\n\tbackground: white;\n\tbox-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
