export const getSubdomain = (): string => {
	const url = window.location.hostname;
	const splittedUrl = url.split(".");

	if (splittedUrl.length === 3) {
		return splittedUrl[0];
	} else {
		return "";
	}
};

export const getDateByFilterType = (type: string) => {
	switch (type) {
		case "This Week": {
			const today = new Date();
			const dayOfWeek = today.getDay();

			const startDate = new Date(today);
			startDate.setDate(today.getDate() - dayOfWeek);

			const endDate = new Date(today);
			endDate.setDate(today.getDate() + (6 - dayOfWeek));

			const startDateString = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
			const endDateString = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;

			return { startDate: startDateString, endDate: endDateString };
		}
		case "This Month": {
			const today = new Date();

			const firstDayOfMonth = new Date(
				today.getFullYear(),
				today.getMonth(),
				1
			);
			const lastDayOfMonth = new Date(
				today.getFullYear(),
				today.getMonth() + 1,
				0
			);

			const startDateString = `${firstDayOfMonth.getFullYear()}-${firstDayOfMonth.getMonth() + 1}-${firstDayOfMonth.getDate()}`;
			const endDateString = `${lastDayOfMonth.getFullYear()}-${lastDayOfMonth.getMonth() + 1}-${lastDayOfMonth.getDate()}`;

			return { startDate: startDateString, endDate: endDateString };
		}
		case "Last Week": {
			const today = new Date();

			const firstDayOfLastWeek = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() - 7 - today.getDay() + 1
			);
			const lastDayOfLastWeek = new Date(
				firstDayOfLastWeek.getFullYear(),
				firstDayOfLastWeek.getMonth(),
				firstDayOfLastWeek.getDate() + 6
			);

			const startDateString = `${firstDayOfLastWeek.getFullYear()}-${firstDayOfLastWeek.getMonth() + 1}-${firstDayOfLastWeek.getDate()}`;
			const endDateString = `${lastDayOfLastWeek.getFullYear()}-${lastDayOfLastWeek.getMonth() + 1}-${lastDayOfLastWeek.getDate()}`;

			return { startDate: startDateString, endDate: endDateString };
		}
		case "Last 2 Weeks": {
			const today = new Date();

			const firstDayOfLastTwoWeeks = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() - 14 - today.getDay() + 1
			);
			const lastDayOfLastTwoWeeks = new Date(
				firstDayOfLastTwoWeeks.getFullYear(),
				firstDayOfLastTwoWeeks.getMonth(),
				firstDayOfLastTwoWeeks.getDate() + 13
			);

			const startDateString = `${firstDayOfLastTwoWeeks.getFullYear()}-${firstDayOfLastTwoWeeks.getMonth() + 1}-${firstDayOfLastTwoWeeks.getDate()}`;
			const endDateString = `${lastDayOfLastTwoWeeks.getFullYear()}-${lastDayOfLastTwoWeeks.getMonth() + 1}-${lastDayOfLastTwoWeeks.getDate()}`;

			return { startDate: startDateString, endDate: endDateString };
		}
		case "Last 30 Days": {
			const today = new Date();

			const firstDayOfLast30Days = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() - 29
			);
			const lastDayOfLast30Days = today;

			const startDateString = `${firstDayOfLast30Days.getFullYear()}-${firstDayOfLast30Days.getMonth() + 1}-${firstDayOfLast30Days.getDate()}`;
			const endDateString = `${lastDayOfLast30Days.getFullYear()}-${lastDayOfLast30Days.getMonth() + 1}-${lastDayOfLast30Days.getDate()}`;

			return { startDate: startDateString, endDate: endDateString };
		}
		case "Last 12 Months": {
			const today = new Date();

			const firstDayOfLast12Months = new Date(
				today.getFullYear() - 1,
				today.getMonth(),
				today.getDate()
			);
			const lastDayOfLast12Months = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate()
			);

			const startDateString = `${firstDayOfLast12Months.getFullYear()}-${firstDayOfLast12Months.getMonth() + 1}-${firstDayOfLast12Months.getDate()}`;
			const endDateString = `${lastDayOfLast12Months.getFullYear()}-${lastDayOfLast12Months.getMonth() + 1}-${lastDayOfLast12Months.getDate()}`;

			return { startDate: startDateString, endDate: endDateString };
		}
		default: {
			const today = new Date();
			const dayOfWeek = today.getDay();

			const startDate = new Date(today);
			startDate.setDate(today.getDate() - dayOfWeek);

			const endDate = new Date(today);
			endDate.setDate(today.getDate() + (6 - dayOfWeek));

			const startDateString = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
			const endDateString = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;

			return { startDate: startDateString, endDate: endDateString };
		}
	}
};

export const detectProductStatus = (statuses: {
	in_review: string;
	is_public: string;
}) => {
	let status: string | null = null;

	if (statuses) {
		if (statuses.in_review === "false" && statuses.is_public === "true") {
			status = "public";
		} else if (
			statuses.in_review === "false" &&
			statuses.is_public === "false"
		) {
			status = "private";
		} else if (
			statuses.in_review === "true" &&
			statuses.is_public === "false"
		) {
			status = "in_review";
		}
	} else {
		status = null;
	}

	return status;
};

export const calculatePercentageOfNumber = (total: number, number: number) => {
	return (number / total) * 100;
};
