import { FaFingerprint, FaLayerGroup } from "react-icons/fa";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BiDollar, BiLeftArrowAlt } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { RiBarcodeFill } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { BsImage } from "react-icons/bs";
import { toast } from "react-toastify";

import {
    useAddOrderNoteMutation,
    useGetOrderByIdQuery,
} from "../../../../api/ordersAPI";
import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { useGetTenantDataQuery } from "../../../../api/settingsAPI";
import { TOrderNoteSubmitData } from "../../../../api/api.types";

import OrderTracking from "./OrderTracking";

import "./OrderDetails.css";

const OrderDetails = () => {
    const intl = useIntl();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isPublic, Subdomain] = useDetectSubdomainOrPublic();

    const [addOrderNote] = useAddOrderNoteMutation();

    const { data: orderData } = useGetOrderByIdQuery(id ? Number(id) : 0, {
        skip: !id,
    });
    const { data: tenantData } = useGetTenantDataQuery(Subdomain, {
        skip: isPublic || !Subdomain,
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<TOrderNoteSubmitData>();

    const navigateToOrders = () => {
        navigate("/dashboard/orders/");
    };
    const isSeller = () => {
        if (orderData && tenantData) {
            if (tenantData.id === orderData.seller_tenant) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const onSubmit = (data: { text: string }) => {
        addOrderNote({
            order: Number(id),
            ...data,
        }).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({ id: "somethingWentWrong" })
                    );
                }
            } else {
                reset();
            }
        });
    };

    return (
        <section className="p-0">
            <Container fluid className="header-dashboard">
                <Row className="ps-1">
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center ms-1">
                        <button
                            onClick={navigateToOrders}
                            className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38"
                        >
                            <BiLeftArrowAlt size={20} />{" "}
                            <FormattedMessage id="back" />
                        </button>
                    </Col>
                </Row>
            </Container>
            <div className="page-wrapper">
                <div className="d-flex justify-content-between">
                    <div className="order-details-wrapper">
                        <div className="d-flex justify-content-between">
                            <div className="order-info-block dashboard-body">
                                <div className="d-flex align-items-center mb-1">
                                    <p className="order-info-title me-4">
                                        <FormattedMessage id="order" />
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <div className="order-status-block"></div>
                                        <span>
                                            {orderData?.status?.toLowerCase()}
                                        </span>
                                    </div>
                                </div>
                                <p className="order-details-info-text">
                                    <FormattedMessage id="orderCustomIdWithDots" />{" "}
                                    {orderData?.custom_order_id}
                                </p>
                                <p className="order-details-info-text">
                                    <FormattedMessage id="shopifyOrderId" />{" "}
                                    {orderData?.store_order_id}
                                </p>
                                <div className="order-details-info-text d-flex justify-content-end mt-3">
                                    {orderData?.imported_date.split(".")[0]}
                                </div>
                            </div>
                            <div className="order-info-block dashboard-body">
                                <p className="order-info-title mb-1">
                                    <FormattedMessage id="customer" />
                                </p>
                                <p className="order-details-info-text-between">
                                    <span>
                                        <FormattedMessage id="emailWithDots" />
                                    </span>
                                    <span>
                                        {orderData?.shipping_address?.email}
                                    </span>
                                </p>
                                <p className="order-details-info-text-between">
                                    <span>
                                        <FormattedMessage id="phoneWithDots" />
                                    </span>
                                    <span>
                                        {orderData?.shipping_address?.phone}
                                    </span>
                                </p>
                                <p className="order-info-title mt-1 mb-1">
                                    <FormattedMessage id="shippingAddress" />
                                </p>
                                <p className="order-details-address-text-between">
                                    <span>
                                        <FormattedMessage id="address1WithDots" />
                                    </span>
                                    <span>
                                        {
                                            orderData?.shipping_address
                                                ?.address_line1
                                        }
                                    </span>
                                </p>
                                <p className="order-details-address-text-between">
                                    <span>
                                        <FormattedMessage id="address2WithDots" />
                                    </span>
                                    <span>
                                        {
                                            orderData?.shipping_address
                                                ?.address_line2
                                        }
                                    </span>
                                </p>
                                <p className="order-details-address-text-between">
                                    <span>
                                        <FormattedMessage id="countryWithDots" />
                                    </span>
                                    <span>
                                        {
                                            orderData?.shipping_address
                                                ?.country_code
                                        }
                                    </span>
                                </p>
                                <p className="order-details-address-text-between">
                                    <span>
                                        <FormattedMessage id="cityWithDots" />
                                    </span>
                                    <span>
                                        {orderData?.shipping_address?.city}
                                    </span>
                                </p>
                                <p className="order-details-address-text-between">
                                    <span>ZIP:</span>
                                    <span>
                                        {
                                            orderData?.shipping_address
                                                ?.postal_code
                                        }
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="dashboard-body orders-product-block">
                            <div className="d-flex flex-column">
                                <p className="order-table-title">
                                    <FormattedMessage id="lineItems" />
                                </p>
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th
                                                className="text-center"
                                                scope="col"
                                            >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <BsImage
                                                        className="me-1"
                                                        size={18}
                                                    />{" "}
                                                    <FormattedMessage id="image" />
                                                </div>
                                            </th>
                                            <th
                                                className="text-center"
                                                scope="col"
                                            >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <FaFingerprint
                                                        className="me-1"
                                                        size={18}
                                                    />{" "}
                                                    <FormattedMessage id="productName" />
                                                </div>
                                            </th>
                                            <th
                                                className="text-center"
                                                scope="col"
                                            >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <FaLayerGroup
                                                        className="me-1"
                                                        size={18}
                                                    />{" "}
                                                    <FormattedMessage id="quantity" />
                                                </div>
                                            </th>
                                            <th
                                                className="text-center"
                                                scope="col"
                                            >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <BiDollar size={20} />{" "}
                                                    <FormattedMessage id="price" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderData?.line_items &&
                                            orderData?.line_items.length > 0 &&
                                            orderData?.line_items.map(
                                                (lineItem) => (
                                                    <tr
                                                        key={lineItem.id}
                                                        className="table-row-body"
                                                    >
                                                        <td className="text-center order-details-info-text">
                                                            <img
                                                                className="order-details-product-image"
                                                                src={
                                                                    lineItem
                                                                        ?.variant_product
                                                                        ?.images[0]
                                                                        ?.url
                                                                        ? lineItem
                                                                              ?.variant_product
                                                                              ?.images[0]
                                                                              ?.url
                                                                        : lineItem
                                                                              ?.variant_product
                                                                              ?.images[0]
                                                                              ?.image
                                                                }
                                                                alt=""
                                                            />
                                                        </td>
                                                        <td
                                                            width="30%"
                                                            className="text-center order-details-info-text max-width-td"
                                                        >
                                                            <div className="d-flex flex-column">
                                                                <p className="wrapped-table-row">
                                                                    {
                                                                        lineItem
                                                                            .variant_product
                                                                            .title
                                                                    }
                                                                </p>
                                                                <p className="">
                                                                    SKU:{" "}
                                                                    {
                                                                        lineItem
                                                                            .variant_product
                                                                            .sku
                                                                    }
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td className="text-center order-details-info-text">
                                                            {lineItem.quantity}
                                                        </td>
                                                        <td className="text-center order-details-info-text">
                                                            $
                                                            {lineItem.price /
                                                                100}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {orderData?.fulfillments &&
                            orderData?.fulfillments.length > 0 &&
                            orderData?.fulfillments.map((fulfillment) => (
                                <div
                                    key={fulfillment.id}
                                    className="dashboard-body orders-product-block"
                                >
                                    <div className="d-flex flex-column">
                                        <p className="order-table-title">
                                            <FormattedMessage id="fulfillmentWithDots" />{" "}
                                            {
                                                fulfillment
                                                    ?.amazon_fulfillment_shipments[0]
                                                    ?.amazon_shipment_id
                                            }
                                        </p>
                                        <table className="table">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <BsImage
                                                                className="me-1"
                                                                size={18}
                                                            />{" "}
                                                            <FormattedMessage id="image" />
                                                        </div>
                                                    </th>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <FaFingerprint
                                                                className="me-1"
                                                                size={18}
                                                            />{" "}
                                                            <FormattedMessage id="productName" />
                                                        </div>
                                                    </th>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <RiBarcodeFill
                                                                className="me-1"
                                                                size={19}
                                                            />{" "}
                                                            <FormattedMessage id="moreInformation" />
                                                        </div>
                                                    </th>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <FaLayerGroup
                                                                className="me-1"
                                                                size={18}
                                                            />{" "}
                                                            <FormattedMessage id="quantity" />
                                                        </div>
                                                    </th>
                                                    <th
                                                        className="text-center"
                                                        scope="col"
                                                    >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <BiDollar
                                                                size={20}
                                                            />{" "}
                                                            <FormattedMessage id="price" />
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fulfillment?.fulfillment_line_items &&
                                                    fulfillment
                                                        ?.fulfillment_line_items
                                                        .length > 0 &&
                                                    fulfillment?.fulfillment_line_items.map(
                                                        (
                                                            fulfillmentLineItem
                                                        ) => (
                                                            <tr
                                                                key={
                                                                    fulfillmentLineItem.id
                                                                }
                                                                className="table-row-body"
                                                            >
                                                                <td className="text-center order-details-info-text">
                                                                    <img
                                                                        className="order-details-product-image"
                                                                        src={
                                                                            fulfillmentLineItem
                                                                                ?.variant_product
                                                                                ?.images[0]
                                                                                ?.url
                                                                                ? fulfillmentLineItem
                                                                                      ?.variant_product
                                                                                      ?.images[0]
                                                                                      ?.url
                                                                                : fulfillmentLineItem
                                                                                      ?.variant_product
                                                                                      ?.images[0]
                                                                                      ?.image
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </td>

                                                                <td
                                                                    width="30%"
                                                                    className="text-center order-details-info-text max-width-td"
                                                                >
                                                                    <div className="d-flex flex-column">
                                                                        <p className="wrapped-table-row">
                                                                            {
                                                                                fulfillmentLineItem
                                                                                    ?.variant_product
                                                                                    ?.title
                                                                            }
                                                                        </p>
                                                                        <p className="">
                                                                            SKU:{" "}
                                                                            {
                                                                                fulfillmentLineItem
                                                                                    ?.variant_product
                                                                                    ?.sku
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </td>

                                                                <td
                                                                    width="30%"
                                                                    className="text-center order-details-info-text max-width-td"
                                                                >
                                                                    <div className="d-flex flex-column">
                                                                        <p className="wrapped-table-row">
                                                                            <FormattedMessage id="packingNumberWithDots" />{" "}
                                                                            {
                                                                                fulfillmentLineItem
                                                                                    ?.amazon_fulfillment_shipment_items[0]
                                                                                    ?.package_number
                                                                            }
                                                                        </p>
                                                                        <p className="">
                                                                            <FormattedMessage id="trackingNumberWithDots" />{" "}
                                                                            {
                                                                                fulfillmentLineItem
                                                                                    ?.amazon_fulfillment_shipment_items[0]
                                                                                    ?.amazon_fulfillment_shipment_package
                                                                                    .tracking_number
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center order-details-info-text">
                                                                    {
                                                                        fulfillmentLineItem?.quantity
                                                                    }
                                                                </td>
                                                                <td className="text-center order-details-info-text">
                                                                    $
                                                                    {fulfillmentLineItem?.price /
                                                                        100}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}

                        {orderData?.fulfillments &&
                            orderData?.fulfillments.length > 0 &&
                            orderData?.fulfillments.map((fulfillment) => (
                                <div>
                                    {fulfillment?.fulfillment_order
                                        ?.fulfilled_by === "MERCHANT" ? (
                                        <div
                                            key={fulfillment.id}
                                            className="dashboard-body orders-product-block"
                                        >
                                            <div className="d-flex flex-column">
                                                <p className="order-table-title">
                                                    <FormattedMessage id="trackingInformationWithDots" />
                                                </p>
                                                <OrderTracking
                                                    fulfillment={fulfillment}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                    </div>
                    <div className="dashboard-body order-shipment-details-wrapper">
                        {isSeller() || Subdomain === "" ? (
                            <div>
                                <h5 className="order-shipment-details-title-color">
                                    <FormattedMessage id="buyersCharge" />
                                </h5>
                                <table className="table order-shipment-detail-table">
                                    <thead></thead>
                                    <tbody>
                                        <tr>
                                            <td className="order-details-info-text">
                                                <FormattedMessage id="productCostWithDots" />
                                            </td>
                                            <td className="order-details-info-text">
                                                $
                                                {orderData?.raw_product_cost &&
                                                    orderData?.raw_product_cost /
                                                        100}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="order-details-info-text">
                                                <FormattedMessage id="buyerServiceFeeWithDots" />
                                            </td>
                                            <td className="order-details-info-text">
                                                $
                                                {orderData?.seller_margin &&
                                                    orderData?.seller_margin /
                                                        100}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="order-details-info-text">
                                                <FormattedMessage id="shippingCostWithDots" />
                                            </td>
                                            <td className="order-details-info-text">
                                                $
                                                {orderData?.shipping_cost &&
                                                    orderData?.shipping_cost /
                                                        100}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="order-details-info-text">
                                                <FormattedMessage id="customerRetailPriceWithDots" />
                                            </td>
                                            <td className="order-details-info-text">
                                                $
                                                {orderData?.end_customer_cost &&
                                                    orderData?.end_customer_cost /
                                                        100}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ) : null}
                        {!isSeller() || Subdomain === "" ? (
                            <div>
                                <h5 className="order-shipment-details-title-color">
                                    <FormattedMessage id="vendorsPayout" />
                                </h5>
                                <table className="table order-shipment-detail-table">
                                    <thead></thead>
                                    <tbody>
                                        <tr>
                                            <td className="order-details-info-text">
                                                <FormattedMessage id="productCostWithDots" />
                                            </td>
                                            <td className="order-details-info-text">
                                                $
                                                {orderData?.raw_product_cost &&
                                                    orderData?.raw_product_cost /
                                                        100}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="order-details-info-text">
                                                <FormattedMessage id="sellerServiceFeeWithDots" />
                                            </td>
                                            <td className="order-details-info-text">
                                                $
                                                {orderData?.get_total_vendor_margin &&
                                                    orderData?.get_total_vendor_margin /
                                                        100}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="order-details-info-text">
                                                <FormattedMessage id="shippingCostWithDots" />
                                            </td>
                                            <td className="order-details-info-text">
                                                $
                                                {orderData?.shipping_cost &&
                                                    orderData?.shipping_cost /
                                                        100}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="order-details-info-text">
                                                <FormattedMessage id="payoutToSellerWithDots" />
                                            </td>
                                            <td className="order-details-info-text">
                                                $
                                                {orderData?.get_total_vendor_payout &&
                                                    orderData?.get_total_vendor_payout /
                                                        100}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ) : null}
                        {isPublic && (
                            <>
                                <h5 className="order-shipment-details-title-color">
                                    <FormattedMessage id="totalMargin" />
                                </h5>
                                <table className="table order-shipment-detail-table">
                                    <thead></thead>
                                    <tbody>
                                        <tr>
                                            <td className="order-details-info-text">
                                                <FormattedMessage id="totalMarginWithDots" />
                                            </td>
                                            <td className="order-details-info-text">
                                                $
                                                {(Number(
                                                    orderData?.get_total_vendor_margin
                                                ) +
                                                    Number(
                                                        orderData?.seller_margin
                                                    )) /
                                                    100}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        )}
                        {orderData?.order_notes_by_tenant &&
                            orderData?.order_notes_by_tenant.length > 0 && (
                                <>
                                    <h5 className="order-shipment-details-title-color">
                                        <FormattedMessage id="notesUppercase" />
                                    </h5>
                                    <table className="table order-shipment-detail-table mb-1">
                                        <thead></thead>
                                        <tbody>
                                            {orderData.order_notes_by_tenant.map(
                                                (note, idx) => (
                                                    <tr key={idx}>
                                                        <td className="order-details-info-text pe-0 py-1">
                                                            <div className="note-block">
                                                                {note.text}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Control
                                    placeholder={`${intl.formatMessage({
                                        id: "enterANote",
                                    })}`}
                                    className="form-input-field order-note-textarea"
                                    as="textarea"
                                    type="text"
                                    {...register("text", {
                                        required: true,
                                    })}
                                />
                                {errors.text && (
                                    <p className="mb-0 form-field-error">
                                        {errors.text.message}
                                    </p>
                                )}
                            </Form.Group>
                            <div className="d-flex justify-content-end mt-2">
                                <button
                                    className="catalog-button-blue dashboard-catalog-button me-0"
                                    type="submit"
                                >
                                    <FormattedMessage id="addANote" />
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OrderDetails;
