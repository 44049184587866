import { TfiCommentsSmiley } from "react-icons/tfi";
import { Carousel, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import StarRatings from "react-star-ratings";
import { FC, useState } from "react";

import { TProduct } from "../../../api/api.types";

import "./DashboardCatalogProduct.css";

type TDashboardCatalogProductProps = {
	show: boolean;
	onHide: () => void;
	viewProduct: TProduct;
};

const DashboardCatalogProduct: FC<TDashboardCatalogProductProps> = ({
	show,
	onHide,
	viewProduct,
}) => {
	const [activeVariant, setActiveVariant] = useState(0);

	const handleChangeVariant = (variant: number) => {
		setActiveVariant(variant);
	};

	return (
		<Modal size="xl" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton></Modal.Header>
			<Modal.Body data-testid="quick-view-modal" className="pt-0">
				<div
					style={{ padding: "16px 0" }}
					className="catalog-product__wrapper"
				>
					<div className="catalog-product__content">
						<div className="catalog-product__information-block">
							<div
								className="catalog-product__images-wrapper"
								style={{ maxWidth: "382px" }}
							>
								<Carousel className="product-details-carousel">
									{viewProduct?.variants &&
									viewProduct?.variants[activeVariant].images
										.length > 0 ? (
										viewProduct?.variants[
											activeVariant
										].images.map((el) => (
											<Carousel.Item key={el.id}>
												<img
													className="d-block catalog-product__carousel-image"
													src={
														el.url
															? el.url
															: el.image
													}
													alt=""
												/>
											</Carousel.Item>
										))
									) : (
										<Carousel.Item>
											<div
												className="d-block catalog-product__carousel-image empty"
												style={{
													backgroundColor: "#ECECEC",
												}}
											/>
										</Carousel.Item>
									)}
								</Carousel>
								<div className="catalog-product__variants-list">
									{viewProduct?.variants &&
										viewProduct.variants.length > 0 &&
										viewProduct.variants.map(
											(variant, idx) => (
												<div
													key={idx}
													onClick={() =>
														handleChangeVariant(idx)
													}
													className={
														activeVariant === idx
															? "catalog-product__variant-image-active"
															: "catalog-product__variant-image"
													}
												>
													<img
														src={
															variant.images[0]
																?.url
																? variant
																		.images[0]
																		?.url
																: variant
																		.images[0]
																		?.image
														}
														alt=""
													/>
												</div>
											)
										)}
								</div>
							</div>
							<div className="catalog-product__details-block">
								<h5 className="catalog-product__details-title">
									{viewProduct?.title}
								</h5>
								<div className="catalog-product__details-price-block">
									<span className="catalog-product__details-price">
										{
											viewProduct?.variants[activeVariant]
												.prices[0].currency.sign
										}
										{viewProduct?.variants[activeVariant]
											?.prices[0]?.amount &&
											viewProduct?.variants[activeVariant]
												?.prices[0]?.amount / 100}
									</span>
									<span className="catalog-product__details-sku">
										{
											viewProduct?.variants[activeVariant]
												.sku
										}
									</span>
								</div>
								<div className="catalog-product__description-block">
									<p className="catalog-product__description">
										{
											viewProduct?.variants[activeVariant]
												.description
										}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="catalog-product__customer-reviews-block">
						{viewProduct?.variants &&
							viewProduct.variants[activeVariant].reviews.length >
								0 && (
								<h6 className="catalog-product__reviews-title">
									<FormattedMessage id="customerReviewsWithDots" />
								</h6>
							)}
						{viewProduct?.variants &&
						viewProduct.variants[activeVariant].reviews.length >
							0 ? (
							viewProduct?.variants[activeVariant].reviews.map(
								(review, idx) => (
									<div
										className="catalog-product__review-item"
										key={idx}
									>
										<div className="catalog-product__review-author-block">
											<span className="catalog-product__review-author">
												{review.review_author}
											</span>
											<span className="catalog-product__review-date">
												{review.review_date}
											</span>
										</div>
										<div className="catalog-product__review-rating-block">
											<StarRatings
												rating={parseFloat(
													review.review_rating
														? String(
																review.review_rating
															)
														: "0"
												)}
												numberOfStars={5}
												starRatedColor="var(--button)"
												starDimension="20px"
												starSpacing="1px"
											/>
										</div>
										<span className="catalog-product__review-title">
											{review.review_title}
										</span>
										<p className="catalog-product__review-content">
											{review.review_content}
										</p>
									</div>
								)
							)
						) : (
							<div className="catalog-product__no-reviews-text">
								<TfiCommentsSmiley
									color="var(--text)"
									size={60}
								/>
								<FormattedMessage id="thisProductDoNotHaveAnyReviewsYet" />
							</div>
						)}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default DashboardCatalogProduct;
