export const THIS_FIELD_IS_REQUIRED = 'This field is required'
export const LOGIN_PAGE_URL = '/login'

export const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z.,@#!$%^&*()-_=+;'\"`\\d]{8,}$")

export const CRON_TIME = {
    "* * * * *": "Every 1 minute",
    "0 * * * *": "Every 1 hour",
    "0 */6 * * *": "Every 6 hour",
    "0 1 * * *": "Every 1 day",
    "0 0 */3 * *": "Every 3 day",
    "0 0 * * 0": "Every week",
    "0 0 1 * *": "Every month",
    "0 0 1 1 *": "Every year"
}

export const TOTAL_ORDERS_DATES_TRANSLATION_OPTIONS = {
    'This Week': 'thisWeek',
    'This Month': 'thisMonth',
    'This Year': 'thisYear',
    'All Time': 'allTime',
    'Last Week': 'lastWeek',
    'Last 2 Weeks': 'last2Weeks',
    'Last 30 Days': 'last30Days',
    'Last 12 Months': 'last12Months',
}

export const ORDER_SYNCHRONIZATION_STATUS_TO_TEXT = {
    "success": "orderSynchronizedSuccessfully",
    "pending_synchronization": "orderIsPendingSynchronization",
    "synchronization_failed": "orderSynchronizationFailed",
    "status_update_failed": "orderStatusUpdateFailed",
    "failed": "orderSynchronizationFailed"
}

export const RATES_SHIPPING_METHODS_TRANSLATIONS = {
    "Standard": "standard",
    "Priority": "priority",
    "Expedited": "expedited",
}