import { FormattedMessage } from "react-intl";
import { FC } from "react";

import Empty from "../../../assets/images/empty-state.svg";

import "./EmptyState.css";

type EmptyStateProps = {
	message: string;
	buttonText?: string;
	callbackFunction?: () => void;
	customClass?: string;
};

const EmptyState: FC<EmptyStateProps> = ({
	message,
	buttonText,
	callbackFunction,
	customClass,
}) => {
	return (
		<div className={`empty-state__container ${customClass}`}>
			<img className="empty-state__icon" src={Empty} alt="" />
			<h6 data-testid="empty-title" className="empty-state__title">
				<FormattedMessage id={message} />
			</h6>
			{buttonText && callbackFunction && (
				<button
					onClick={callbackFunction}
					className="empty-state__button"
				>
					<FormattedMessage id={buttonText} />
				</button>
			)}
		</div>
	);
};

export default EmptyState;
