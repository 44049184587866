import { Carousel, Col, Dropdown, Form, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { Fragment, useRef, useState } from "react";
import { TbFileUpload } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { IoWarning } from "react-icons/io5";
import { BsBookmark } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

import {
    useAddProductNoteMutation,
    useGetProductVariantQuery,
    useUploadProductImagesMutation,
} from "../../../../api/productsAPI";
import {
    TProductCategoryParent,
    TProductNoteSubmitData,
} from "../../../../api/api.types";
import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";

import UpdateProductAmount from "../../../modals/UpdateProductAmount/UpdateProductAmount";
import UpdateProductPrice from "../../../modals/UpdateProductPrice/UpdateProductPrice";
import ModifyAutoUpdate from "../../../modals/ModifyAutoUpdate/ModifyAutoUpdate";
import ProductDetailsStatusBlock from "./ProductDetailsStatusBlock";

import "./ProductDetails.css";

const ProductDetails = () => {
    const [attachedImages, setAttachedImages] = useState<File[] | null>(null);
    const [updateAmount, setUpdateAmount] = useState(false);
    const [updatePrice, setUpdatePrice] = useState(false);
    const [modifyAuto, setModifyAuto] = useState(false);

    const intl = useIntl();
    const { id } = useParams();
    const [isPublic] = useDetectSubdomainOrPublic();

    const inputRef = useRef<HTMLInputElement>(null);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<TProductNoteSubmitData>();

    const { data, isLoading: isLoadingDetails } = useGetProductVariantQuery(
        Number(id)
    );

    const [uploadProductImages, { isLoading }] =
        useUploadProductImagesMutation();
    const [addProductNote] = useAddProductNoteMutation();

    const handleRemoveImage = (index: number) => {
        if (attachedImages) {
            setAttachedImages(attachedImages.filter((_, i) => i !== index));
        }
    };

    const handleSaveImages = () => {
        if (!attachedImages) return;

        const formData = new FormData();

        attachedImages.forEach((image) => {
            formData.append("images", image);
        });

        uploadProductImages({
            id: Number(id),
            data: formData,
        }).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({ id: "somethingWentWrong" })
                    );
                }
            } else {
                setAttachedImages(null);
            }
        });
    };

    const handleUploadImages = (files: FileList | null) => {
        const filesList = Array.from(files || []);

        if (filesList.length === 0) return;

        setAttachedImages(filesList);

        if (inputRef.current) {
            inputRef.current.value = "";
        }
    };

    const onSubmit = (data: TProductNoteSubmitData) => {
        addProductNote({
            product: Number(id),
            text: data.text,
        }).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({ id: "somethingWentWrong" })
                    );
                }
            } else {
                reset();
            }
        });
    };

    return (
        <div className="page-wrapper">
            <div className="dashboard-body">
                {data?.status && data.status !== "available" && (
                    <ProductDetailsStatusBlock status={data.status} />
                )}
                <Row className="d-flex align-items-center">
                    <Col className="col-lg-7">
                        <h4 className="mb-0 product-name-title">
                            {data?.title}
                        </h4>
                    </Col>
                    <Col className="col-lg-5 d-flex justify-content-end">
                        <Dropdown className="d-flex justify-content-center me-2">
                            <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                <BsBookmark size={17} className="me-1" />{" "}
                                <FormattedMessage id="category" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {data?.categories &&
                                data?.categories.length > 0 &&
                                Array.isArray(
                                    data?.categories[data.categories.length - 1]
                                        .parents
                                ) ? (
                                    <>
                                        {(
                                            data?.categories[
                                                data.categories.length - 1
                                            ]
                                                .parents as TProductCategoryParent[]
                                        ).map((category) => (
                                            <Dropdown.Item key={category.id}>
                                                {category.name}
                                            </Dropdown.Item>
                                        ))}
                                    </>
                                ) : (
                                    data?.categories &&
                                    data?.categories.length > 0 && (
                                        <Dropdown.Item
                                            key={
                                                (
                                                    data?.categories[0]
                                                        .parents as TProductCategoryParent
                                                ).id
                                            }
                                        >
                                            {
                                                (
                                                    data?.categories[0]
                                                        .parents as TProductCategoryParent
                                                ).name
                                            }
                                        </Dropdown.Item>
                                    )
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        {!isPublic && (
                            <Dropdown className="d-flex justify-content-center">
                                <Dropdown.Toggle className="catalog-button-blue dropdown-settings-button">
                                    <HiOutlineCog6Tooth
                                        size={21}
                                        className="me-1"
                                    />{" "}
                                    <FormattedMessage id="action" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => setModifyAuto(true)}
                                    >
                                        <FormattedMessage id="modifyAutoUpdateDefault" />
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => setUpdateAmount(true)}
                                    >
                                        <FormattedMessage id="updateAmount" />
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => setUpdatePrice(true)}
                                    >
                                        <FormattedMessage id="updatePrice" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-lg-4">
                        {data?.images && data?.images.length > 0 ? (
                            <Carousel className="product-details-carousel">
                                {data?.images.map((el) => (
                                    <Carousel.Item key={el.id}>
                                        <img
                                            className="d-block w-100"
                                            src={el.url ? el.url : el.image}
                                            style={{
                                                aspectRatio: "1 / 1",
                                                objectFit: "contain",
                                            }}
                                            alt=""
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        ) : (
                            <>
                                {!isPublic ? (
                                    <div className="no-product-images-block">
                                        {attachedImages &&
                                        attachedImages.length > 0 ? (
                                            <>
                                                <div className="no-product-images-attached-list">
                                                    {attachedImages.map(
                                                        (image, idx) => (
                                                            <div
                                                                className="bulk-uploaded-product-attached-image-block"
                                                                key={idx}
                                                            >
                                                                <img
                                                                    src={URL.createObjectURL(
                                                                        image
                                                                    )}
                                                                    alt=""
                                                                />
                                                                <div
                                                                    onClick={() =>
                                                                        handleRemoveImage(
                                                                            idx
                                                                        )
                                                                    }
                                                                    className="bulk-uploaded-product-attached-image-remove"
                                                                >
                                                                    <MdDelete
                                                                        size={
                                                                            26
                                                                        }
                                                                        color="#FFFFFF"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <button
                                                    className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0 mt-2"
                                                    onClick={handleSaveImages}
                                                    style={{ gap: "4px" }}
                                                    disabled={isLoading}
                                                >
                                                    <FormattedMessage id="saveImages" />
                                                </button>
                                            </>
                                        ) : (!attachedImages ||
                                              attachedImages?.length === 0) &&
                                          !isLoadingDetails ? (
                                            <>
                                                <IoWarning
                                                    size={50}
                                                    color="#ffcc00"
                                                />
                                                <span className="no-product-images-text">
                                                    <FormattedMessage id="thisProductHasNoUploadedImagesPleaseUpload" />
                                                </span>
                                                <button
                                                    className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0 mt-2"
                                                    onClick={() =>
                                                        inputRef.current?.click()
                                                    }
                                                    style={{ gap: "4px" }}
                                                >
                                                    <TbFileUpload size={18} />
                                                    <FormattedMessage id="uploadImages" />
                                                </button>
                                                <input
                                                    onChange={(e) =>
                                                        handleUploadImages(
                                                            e.target.files
                                                        )
                                                    }
                                                    className="d-none"
                                                    accept="image/*"
                                                    ref={inputRef}
                                                    type="file"
                                                    multiple
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div>
                                        <FormattedMessage id="noImages" />
                                    </div>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h5>
                            <FormattedMessage id="details" />
                        </h5>
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    {data?.properties.map((property) => (
                                        <th
                                            key={property.id}
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                            className="text-center"
                                            scope="col"
                                        >
                                            {property.name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {data?.properties.map((property) => (
                                        <td
                                            key={property.id}
                                            className="text-center"
                                        >
                                            {property.value}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <h5>
                            <FormattedMessage id="productInfo" />
                        </h5>
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className="text-center" scope="col">
                                        <FormattedMessage id="productNameUppercase" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        SKU
                                    </th>
                                    <th className="text-center" scope="col">
                                        ASIN
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FormattedMessage id="totalUppercase" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FormattedMessage id="inOrderUppercase" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FormattedMessage id="availableUppercase" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FormattedMessage id="productCostUppercase" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        MSRP
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center max-width-td">
                                        <span className="wrapped-table-row">
                                            {data?.title}
                                        </span>
                                    </td>
                                    <td className="text-center">{data?.sku}</td>
                                    <td className="text-center">
                                        {data?.amazon_product_variant?.asin}
                                    </td>
                                    <td className="text-center">
                                        {data?.inventories.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>{el.amount_total}</span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                    <td className="text-center">
                                        {data?.inventories.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>{el.amount_reserve}</span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                    <td className="text-center">
                                        {data?.inventories.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>
                                                    {el.amount_available}
                                                </span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                    <td className="text-center">
                                        {data?.prices.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>
                                                    {el.currency.sign}
                                                    {el.amount / 100}
                                                </span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                    <td className="text-center">
                                        {data?.prices.map((el, idx) => (
                                            <Fragment key={idx}>
                                                <span>
                                                    {el.currency.sign}
                                                    {el.msrp_amount / 100}
                                                </span>
                                                <br />
                                            </Fragment>
                                        ))}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <h5>
                            <FormattedMessage id="notesWithDots" />
                        </h5>
                        {data?.product_notes_by_tenant &&
                            data?.product_notes_by_tenant.length > 0 && (
                                <table className="table order-shipment-detail-table mb-1">
                                    <thead></thead>
                                    <tbody>
                                        {data.product_notes_by_tenant.map(
                                            (note, idx) => (
                                                <tr key={idx}>
                                                    <td className="pe-0">
                                                        <div className="note-block">
                                                            {note.text}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            )}
                        <Form
                            className="mt-1"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Form.Control
                                placeholder={`${intl.formatMessage({
                                    id: "enterANote",
                                })}`}
                                className="form-input-field product-details-textarea"
                                type="text"
                                as="textarea"
                                {...register("text", {
                                    required: true,
                                })}
                            />
                            {errors.text && (
                                <p className="mb-0 form-field-error">
                                    {errors.text.message}
                                </p>
                            )}
                            <div className="d-flex justify-content-end mt-2">
                                <button className="dashboard-menu-button">
                                    <FormattedMessage id="addNote" />
                                </button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
            {modifyAuto && (
                <ModifyAutoUpdate
                    show={modifyAuto}
                    onHide={() => setModifyAuto(false)}
                    product={data}
                />
            )}
            {updateAmount && (
                <UpdateProductAmount
                    show={updateAmount}
                    onHide={() => setUpdateAmount(false)}
                    product={data}
                />
            )}
            {updatePrice && (
                <UpdateProductPrice
                    show={updatePrice}
                    onHide={() => setUpdatePrice(false)}
                    product={data}
                />
            )}
        </div>
    );
};

export default ProductDetails;
