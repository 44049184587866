import { TbAdjustmentsCog, TbListSearch, TbReceipt } from "react-icons/tb";
import { RxHamburgerMenu, RxCross2 } from "react-icons/rx";
import { NavLink, useLocation } from "react-router-dom";
import { FiBarChart2, FiUsers } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { BsBank, BsBoxSeam } from "react-icons/bs";
import { AiOutlineLogout } from "react-icons/ai";
import { FC, useEffect, useState } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { RiPaypalLine } from "react-icons/ri";
import { FiHome } from "react-icons/fi";

import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { authApi, useLogoutMutation } from "../../../../api/authAPI";
import { signOut } from "../../../../redux/reducers/auth/authSlice";
import { notificationsApi } from "../../../../api/notificationsAPI";
import { integrationsApi } from "../../../../api/integrationsAPI";
import { categoriesApi } from "../../../../api/categoriesAPI";
import { affiliateApi } from "../../../../api/affiliateAPI";
import { paymentsApi } from "../../../../api/paymentsAPI";
import { productsApi } from "../../../../api/productsAPI";
import { settingsApi } from "../../../../api/settingsAPI";
import { useAppDispatch } from "../../../../redux/hooks";
import { ordersApi } from "../../../../api/ordersAPI";
import { statsApi } from "../../../../api/statsAPI";
import { jobsApi } from "../../../../api/jobsAPI";

import Logo from "../../../assets/images/logo.svg";

import "./LeftNav.css";

type TLeftNavProps = {
	expandNavigation: boolean;
	setExpandNavigation: (expand: boolean) => void;
};

const LeftNav: FC<TLeftNavProps> = ({
	expandNavigation,
	setExpandNavigation,
}) => {
	const [systemPage, setSystemPage] = useState(false);

	const location = useLocation();
	const dispatch = useAppDispatch();
	const [isPublic] = useDetectSubdomainOrPublic();

	const [logout] = useLogoutMutation();

	const handleLogoutUser = () => {
		const baseUrl = process.env.REACT_APP_PUBLIC_TENANT_URL;
		logout();
		dispatch(signOut());
		dispatch(authApi.util.resetApiState());
		dispatch(affiliateApi.util.resetApiState());
		dispatch(categoriesApi.util.resetApiState());
		dispatch(integrationsApi.util.resetApiState());
		dispatch(notificationsApi.util.resetApiState());
		dispatch(jobsApi.util.resetApiState());
		dispatch(ordersApi.util.resetApiState());
		dispatch(paymentsApi.util.resetApiState());
		dispatch(productsApi.util.resetApiState());
		dispatch(settingsApi.util.resetApiState());
		dispatch(statsApi.util.resetApiState());

		if (!isPublic && baseUrl) {
			window.location.href = baseUrl;
		}
	};

	useEffect(() => {
		if (location.pathname === "/dashboard/system-alerts/") {
			setSystemPage(true);
		} else {
			setSystemPage(false);
		}
	}, [location]);

	return (
		<div
			data-testid="navigation"
			className={`left-navbar ${expandNavigation ? "expanded" : ""}`}
		>
			<div style={{ gap: "40px" }} className="d-flex flex-column">
				<div className="left-navbar__logo-block">
					<img src={Logo} alt="" />
					{expandNavigation ? (
						<RxCross2
							data-testid="collapse-navigation"
							onClick={() => setExpandNavigation(false)}
							className="cursor-pointer"
							color="#666666"
							size={24}
						/>
					) : (
						<RxHamburgerMenu
							data-testid="expand-navigation"
							onClick={() => setExpandNavigation(true)}
							className="cursor-pointer"
							color="#666666"
							size={24}
						/>
					)}
				</div>
				<div className="left-navbar__items-block">
					<Nav className="left-navbar__navigation-links-block">
						<Nav.Item className="w-100">
							<NavLink
								className="left-nav-link"
								to="/dashboard/total-orders/"
							>
								<FiHome strokeWidth={1.5} size={24} />{" "}
								{expandNavigation && (
									<FormattedMessage id="dashboard" />
								)}
							</NavLink>
						</Nav.Item>
						<Nav.Item className="w-100">
							<NavLink
								data-testid="orders-link"
								className="left-nav-link"
								to="/dashboard/orders/"
							>
								<FiBarChart2 strokeWidth={1.5} size={24} />{" "}
								{expandNavigation && (
									<FormattedMessage id="orders" />
								)}
							</NavLink>
						</Nav.Item>
						<Nav.Item className="w-100">
							<NavLink
								data-testid="my-products-link"
								className="left-nav-link"
								to="/dashboard/my-products/"
							>
								<BsBoxSeam strokeWidth={0.05} size={24} />{" "}
								{expandNavigation && (
									<FormattedMessage id="myProducts" />
								)}
							</NavLink>
						</Nav.Item>
						<Nav.Item className="w-100">
							<NavLink
								data-testid="catalog-link"
								className="left-nav-link"
								to="/dashboard/catalog/"
							>
								<TbListSearch strokeWidth={1.5} size={24} />{" "}
								{expandNavigation && (
									<FormattedMessage id="catalogs" />
								)}
							</NavLink>
						</Nav.Item>
						<Dropdown className="w-100 nav-item">
							<Dropdown.Toggle
								data-testid="billing-dropdown"
								className="user-header-dropdown p-0 w-100 left-nav-link"
								id="dropdown-basic"
							>
								<Nav.Item className="w-100 left-nav-link">
									<TbReceipt strokeWidth={1.5} size={24} />{" "}
									{expandNavigation && (
										<FormattedMessage id="billing" />
									)}
								</Nav.Item>
							</Dropdown.Toggle>
							<Dropdown.Menu className="user-header-dropdown-menu left-nav-dropdown-menu-billing">
								<div
									data-testid="billing-menu"
									className="left-navbar__billing-dropdown-wrapper"
								>
									{!isPublic && (
										<Nav.Item className="w-100">
											<NavLink
												data-testid="billing-receipts-link"
												className="left-nav-link"
												to="/dashboard/billing-receipts/"
											>
												<TbReceipt
													strokeWidth={1.5}
													size={24}
												/>{" "}
												{expandNavigation && (
													<FormattedMessage id="receipts" />
												)}
											</NavLink>
										</Nav.Item>
									)}
									<Nav.Item className="w-100">
										<NavLink
											data-testid="billing-paypal-link"
											className="left-nav-link"
											to="/dashboard/billing-paypal-receipts/"
										>
											<RiPaypalLine
												strokeWidth={0.02}
												size={24}
											/>{" "}
											{expandNavigation && (
												<FormattedMessage id="paypalReceipts" />
											)}
										</NavLink>
									</Nav.Item>
									{!isPublic && (
										<Nav.Item className="w-100">
											<NavLink
												data-testid="billing-payouts-link"
												className="left-nav-link"
												to="/dashboard/billing-payouts/"
											>
												<BsBank
													strokeWidth={0.01}
													size={24}
												/>{" "}
												{expandNavigation && (
													<FormattedMessage id="payouts" />
												)}
											</NavLink>
										</Nav.Item>
									)}
								</div>
							</Dropdown.Menu>
						</Dropdown>
						{isPublic && (
							<>
								<Nav.Item className="w-100">
									<NavLink
										data-testid="tenants-link"
										className="left-nav-link"
										to="/dashboard/settings-tenants/"
									>
										<FiUsers strokeWidth={1.5} size={24} />{" "}
										{expandNavigation && (
											<FormattedMessage id="tenants" />
										)}
									</NavLink>
								</Nav.Item>
								<Nav.Item className="w-100">
									<NavLink
										data-testid="affiliate-management-link"
										className="left-nav-link"
										to="/dashboard/affiliate-management/"
									>
										<BsBank strokeWidth={0.01} size={24} />{" "}
										{expandNavigation && (
											<FormattedMessage id="affiliateManagement" />
										)}
									</NavLink>
								</Nav.Item>
								<Nav.Item className="w-100">
									<NavLink
										data-testid="system-schedule-link"
										className={
											systemPage
												? "left-nav-link active"
												: "left-nav-link"
										}
										to="/dashboard/system-schedule/"
									>
										<TbAdjustmentsCog
											strokeWidth={1.5}
											size={24}
										/>{" "}
										{expandNavigation && (
											<FormattedMessage id="system" />
										)}
									</NavLink>
								</Nav.Item>
							</>
						)}
					</Nav>
				</div>
			</div>
			<div className="left-navbar__items-block">
				<Nav className="left-navbar__navigation-links-block">
					<Nav.Item className="w-100">
						<NavLink
							data-testid="settings-link"
							className="left-nav-link"
							to="/dashboard/settings-users/"
						>
							<IoSettingsOutline strokeWidth={1.5} size={24} />{" "}
							{expandNavigation && (
								<FormattedMessage id="settings" />
							)}
						</NavLink>
					</Nav.Item>
					<Nav.Item
						data-testid="logout-btn"
						onClick={handleLogoutUser}
						className="w-100"
					>
						<span className="left-nav-link cursor-pointer">
							<AiOutlineLogout strokeWidth={1.5} size={24} />{" "}
							{expandNavigation && (
								<FormattedMessage id="logout" />
							)}
						</span>
					</Nav.Item>
				</Nav>
			</div>
		</div>
	);
};

export default LeftNav;
