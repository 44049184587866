import { FormattedMessage } from "react-intl";
import { FC } from "react";

import { ORDER_STATUS_TRANSLATIONS } from "../../../../helpers/constants";
import { TGetOrderResult } from "../../../../api/api.types";

type TOrderInfoBlockProps = {
	orderData: TGetOrderResult | undefined;
};

const OrderInfoBlock: FC<TOrderInfoBlockProps> = ({ orderData }) => {
	return (
		<div
			data-testid="information-block"
			className="order-details__information-block"
		>
			<div className="order-details__order-block-title">
				<p className="order-details__order-information-title">
					<FormattedMessage id="order" />
				</p>
				<div className="order-details__order-status">
					<div className="order-details__order-status-indicator"></div>
					<span className="order-details__order-status-text">
						<FormattedMessage
							id={
								ORDER_STATUS_TRANSLATIONS[
									(orderData?.status ??
										"New") as keyof typeof ORDER_STATUS_TRANSLATIONS
								] || orderData?.status
							}
						/>
					</span>
				</div>
			</div>
			<div className="order-details__order-info-text-block">
				<p className="order-details__order-info-text">
					<FormattedMessage id="orderCustomIdWithDots" />{" "}
					<span className="order-details__order-info-text-value">
						{orderData?.custom_order_id}
					</span>
				</p>
				<p className="order-details__order-info-text">
					<FormattedMessage id="shopifyOrderId" />{" "}
					<span className="order-details__order-info-text-value">
						{orderData?.store_order_id}
					</span>
				</p>
			</div>
			<div className="order-details__order-date">
				{orderData?.imported_date.split(".")[0]}
			</div>
		</div>
	);
};

export default OrderInfoBlock;
