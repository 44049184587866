import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useUpdateProductAmountMutation } from "../../../api/productsAPI";
import { TProductVariants } from "../../../api/api.types";

export type TUpdateProductAmountSubmitData = {
    amount_total: number;
};

type TUpdateProductAmountProps = {
    show: boolean;
    onHide: () => void;
    product: TProductVariants | undefined;
};

const UpdateProductAmount: FC<TUpdateProductAmountProps> = ({
    show,
    onHide,
    product,
}) => {
    const intl = useIntl();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<TUpdateProductAmountSubmitData>();
    const [updateProductAmount] = useUpdateProductAmountMutation();

    const onSubmit = (data: TUpdateProductAmountSubmitData) => {
        if (product) {
            updateProductAmount({
                id: product?.inventories[0].id,
                data,
            }).then((res) => {
                if ("error" in res) {
                    const status = (res.error as { status: number }).status;

                    if (status !== 403) {
                        toast.error(
                            intl.formatMessage({ id: "somethingWentWrong" })
                        );
                    }
                } else {
                    onHide();
                }
            });
        }
    };

    useEffect(() => {
        if (product) {
            setValue("amount_total", product.inventories[0].amount_total);
        }
    }, [product, setValue]);

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="updateProductAmount" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-2">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="totalAmount" />
                        </Form.Label>
                        <Form.Control
                            className="form-input-field"
                            type="number"
                            {...register("amount_total", { required: true })}
                        />
                        {errors.amount_total && (
                            <p className="mb-0 form-field-error">
                                {errors.amount_total.message}
                            </p>
                        )}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type="submit" className="dashboard-menu-button">
                            <FormattedMessage id="updateProductAmount" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateProductAmount;
