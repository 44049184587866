import { FC, useCallback, useEffect, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";
import useWebSocket from "react-use-websocket";
import { FormattedMessage } from "react-intl";
import { IoClose } from "react-icons/io5";

import {
	useGetPublicNotificationsQuery,
	useGetTenantNotificationsQuery,
	usePublicNotificationActionMutation,
	useTenantNotificationActionMutation,
} from "../../../../../api/notificationsAPI";
import useDetectSubdomainOrPublic from "../../../../../hooks/useDetectSubdomainOrPublic";
import { useTranslationsContext } from "../../../../../contexts/useTranslationsContext";
import { selectAccessToken } from "../../../../../redux/reducers/auth/authSlice";
import { useGetTenantDataQuery } from "../../../../../api/settingsAPI";
import { TNotification } from "../../../../../api/api.types";
import { useAppSelector } from "../../../../../redux/hooks";

import ChinaFlag from "../../../../assets/images/china-flag.png";
import UsaFlag from "../../../../assets/images/usa-flag.png";

type TDashboardHeaderProps = {
	expanded: boolean;
};

const DashboardHeader: FC<TDashboardHeaderProps> = ({ expanded }) => {
	const [notifications, setNotifications] = useState<TNotification[]>([]);
	const [showNotifications, setShowNotifications] = useState(false);
	const [unreadNotifications, setUnreadNotifications] = useState(0);

	const isStaff = useAppSelector((state) => state.auth.isStaff);
	const accessToken = useAppSelector(selectAccessToken);

	const { userLocale, updateUserLocale } = useTranslationsContext();
	const [isPublic, subdomain] = useDetectSubdomainOrPublic();

	const [tenantNotificationAction] = useTenantNotificationActionMutation();
	const [publicNotificationAction] = usePublicNotificationActionMutation();

	const socketUrl = `wss://${window.location.hostname}/wss/notifications/${isPublic ? "public/" : "tenant/"}?token=${accessToken}`;
	const { data } = useGetTenantDataQuery(subdomain, {
		skip: isPublic || !subdomain,
	});
	const { data: tenantNotifications } = useGetTenantNotificationsQuery(
		undefined,
		{ skip: isPublic || isStaff }
	);
	const { data: publicNotifications } = useGetPublicNotificationsQuery(
		undefined,
		{ skip: isPublic ? false : true }
	);

	const handleReadNotifications = useCallback(
		(list: number[]) => {
			if (isPublic) {
				publicNotificationAction({ ids_list: list, action: "READ" });
			} else {
				tenantNotificationAction({ ids_list: list, action: "READ" });
			}
		},
		[tenantNotificationAction, isPublic, publicNotificationAction]
	);

	const handleDeleteNotification = (id: number) => {
		if (isPublic) {
			publicNotificationAction({ ids_list: [id], action: "DELETE" });
		} else {
			tenantNotificationAction({ ids_list: [id], action: "DELETE" });
		}
	};

	const hideNotifications = () => {
		setShowNotifications(false);
	};

	const handleShowNotifications = () => {
		setShowNotifications((prev) => {
			return !prev;
		});
	};

	const { sendJsonMessage } = useWebSocket(socketUrl, {
		onOpen: () => {
			if (isPublic) {
				sendJsonMessage({
					action: "subscribe_to_notification_activity",
					request_id: new Date().getTime(),
					tenant_id: "None",
				});
			}
		},
		onMessage: (e) => {
			const message = JSON.parse(e.data);

			if (message.errors) return;

			setNotifications((prev) => {
				return [...prev, message];
			});
		},
	});

	useEffect(() => {
		if (showNotifications) {
			let list: number[] = [];

			notifications.forEach((el) => {
				if (el.checked === false) {
					list.push(el.id);
				}
			});

			if (list.length > 0) {
				setTimeout(() => {
					handleReadNotifications(list);
				}, 1500);
			}
		}
	}, [showNotifications, notifications, handleReadNotifications]);

	useEffect(() => {
		if (notifications.length > 0) {
			let count = 0;

			notifications.forEach((el) => {
				if (el.checked === false) {
					count = count + 1;
				}
			});

			setUnreadNotifications(count);
		} else {
			setUnreadNotifications(0);
		}
	}, [notifications]);

	useEffect(() => {
		if (tenantNotifications) {
			setNotifications(tenantNotifications);
		} else if (publicNotifications) {
			setNotifications(publicNotifications);
		}
	}, [tenantNotifications, publicNotifications]);

	useEffect(() => {
		if (data) {
			sendJsonMessage({
				action: "subscribe_to_notification_activity",
				request_id: new Date().getTime(),
				tenant_id: data.id,
			});
		}
	}, [data, sendJsonMessage, isPublic]);

	return (
		<Navbar data-testid="header" className="user-header">
			<Nav className="user-header__content">
				<div
					data-testid="language-switch"
					className="user-header__switch"
					onClick={() =>
						updateUserLocale(userLocale === "en" ? "zh" : "en")
					}
				>
					<div
						className={`user-header__switch-circle ${userLocale}`}
					></div>
					{userLocale === "en" ? (
						<img
							data-testid="switch-image-en"
							className="user-header__switch-image"
							src={UsaFlag}
							alt=""
						/>
					) : (
						<img
							data-testid="switch-image-zh"
							className="user-header__switch-image"
							src={ChinaFlag}
							alt=""
						/>
					)}
				</div>
				<NavItem className="me-1">
					<div
						data-testid="notifications"
						className={`dashboard-header__notifications ${unreadNotifications > 0 ? "notification-unread" : ""}`}
						onClick={handleShowNotifications}
					>
						<IoMdNotificationsOutline
							color={
								unreadNotifications > 0 ? "#D24023" : "#666666"
							}
							size={25}
						/>
						{unreadNotifications > 0 && (
							<div className="notifications-count-block">
								{unreadNotifications > 9
									? "9+"
									: unreadNotifications}
							</div>
						)}
					</div>
					{showNotifications && (
						<div
							data-testid="notifications-container"
							className="notifications-container"
						>
							<div className="notifications-header">
								<span className="notifications-title">
									<FormattedMessage id="notifications" />
								</span>
								<IoClose
									className="cursor-pointer"
									size={24}
									onClick={hideNotifications}
								/>
							</div>
							<div className="notification-body">
								{notifications && notifications.length > 0 ? (
									notifications
										.slice(0)
										.reverse()
										.map((el) => (
											<div
												data-testid="notification"
												id={`notification-${el.id}`}
												key={el.id}
												className={
													el.checked
														? "notification-block"
														: "notification-block notification-unchecked"
												}
											>
												<div className="notification-info">
													<div
														className={`notification-type-dot ${el?.kind?.toLowerCase()}`}
													></div>
													<div
														data-testid="notification-text"
														className="notification-text"
													>
														{el.text}
													</div>
												</div>
												<MdDeleteOutline
													data-testid="delete-notification"
													onClick={() =>
														handleDeleteNotification(
															el.id
														)
													}
													className="cursor-pointer mt-1"
													color="#666666"
													size={23}
												/>
												<div className="notification-created-at">
													{new Date(
														el.created_at
													).toLocaleDateString(
														"en-Us",
														{
															day: "2-digit",
															month: "short",
															year: "numeric",
															hour: "2-digit",
															minute: "2-digit",
														}
													)}
												</div>
											</div>
										))
								) : (
									<div className="d-flex h-100 justify-content-center align-items-center">
										<FormattedMessage id="thereAreNoNotificationsNow" />
									</div>
								)}
							</div>
						</div>
					)}
				</NavItem>
			</Nav>
		</Navbar>
	);
};

export default DashboardHeader;
