// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item-hover-green:hover {
    color: white !important;
    background-color: #55bb56;
}

.dropdown-item-hover-blue:hover {
    color: white !important;
    background-color: #4286f4;
}

.dropdown-item-hover-red:hover {
    color: white !important;
    background-color: #dc3545;
}

.nested-table-body td {
    border: none;
}

.rotate-expand-button {
    transform: rotate(90deg);
}

.my-product-details-link {
    color: #5e91dd !important;
    text-decoration: underline !important;
}

.padding-product-nested-table {
    padding-left: 35px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/MyProducts/MyProducts.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,qCAAqC;AACzC;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".dropdown-item-hover-green:hover {\n    color: white !important;\n    background-color: #55bb56;\n}\n\n.dropdown-item-hover-blue:hover {\n    color: white !important;\n    background-color: #4286f4;\n}\n\n.dropdown-item-hover-red:hover {\n    color: white !important;\n    background-color: #dc3545;\n}\n\n.nested-table-body td {\n    border: none;\n}\n\n.rotate-expand-button {\n    transform: rotate(90deg);\n}\n\n.my-product-details-link {\n    color: #5e91dd !important;\n    text-decoration: underline !important;\n}\n\n.padding-product-nested-table {\n    padding-left: 35px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
