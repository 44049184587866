import { useNavigate, useParams } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

import { useVerifyEmailMutation } from "../../../../../../api/authAPI";
import { LOGIN_PAGE_URL } from "../../../../../../helpers/constants";

import Loading from "../../../../../components/common/Loading/Loading";

import "./VerifyEmail.css";

const VerifyEmail = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [verifyEmail, { isLoading, isSuccess }] = useVerifyEmailMutation();

	const redirectToLogin = () => {
		navigate(LOGIN_PAGE_URL);
	};

	useEffect(() => {
		if (id) {
			verifyEmail(id);
		}
	}, [id, verifyEmail]);

	if (isLoading) {
		return (
			<div style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}>
				<Loading
					isLoading={isLoading}
					loadingText="confirmingEmailThreeDots"
				>
					<div></div>
				</Loading>
			</div>
		);
	} else if (isSuccess) {
		return (
			<div
				style={{ backgroundColor: "#FFFFFF", padding: "120px 0" }}
				className="d-flex flex-column align-items-center justify-content-center"
			>
				<CiCircleCheck className="mb-3" color="#4db8ff" size={80} />
				<span
					data-testid="email-verified"
					className="email-verified-text"
				>
					<FormattedMessage id="yourEmailSuccessfullyVerified" />
				</span>
				<button
					data-testid="login-button"
					className="ice-button"
					onClick={redirectToLogin}
				>
					<FormattedMessage id="goToLoginPage" />
				</button>
			</div>
		);
	}

	return (
		<div style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}>
			<Loading isLoading={true}>
				<div></div>
			</Loading>
		</div>
	);
};

export default VerifyEmail;
