import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useEffect } from "react";

import {
	useAcceptUserAsExistingMutation,
	useCheckAcceptCodeAsExistingQuery,
	useGetTenantsListMutation,
	useLogoutMutation,
} from "../../../../../api/authAPI";
import { selectAccessToken } from "../../../../../redux/reducers/auth/authSlice";
import { LOGIN_PAGE_URL } from "../../../../../helpers/constants";
import { useAppSelector } from "../../../../../redux/hooks";

import Loading from "../../../../components/common/Loading/Loading";
import TenantsModal from "../TenantsModal/TenantsModal";

const AcceptInvitedExistingUser = () => {
	const accessToken = useAppSelector(selectAccessToken);

	const cookies = new Cookies();

	const intl = useIntl();
	const { code } = useParams();
	const navigate = useNavigate();

	const [logout] = useLogoutMutation();
	const [acceptUser] = useAcceptUserAsExistingMutation();

	const [getTenants, { data: tenantsList, isSuccess }] =
		useGetTenantsListMutation();

	const { data } = useCheckAcceptCodeAsExistingQuery(code ? code : "");

	const redirectToLogin = () => {
		navigate(LOGIN_PAGE_URL);
	};

	const handleAcceptUser = () => {
		if (code && accessToken) {
			acceptUser(code).then((response) => {
				if ("error" in response) {
					const status = (response.error as { status: number })
						.status;

					if (status !== 403) {
						toast.error(
							intl.formatMessage({
								id: "somethingWentWrongPleaseTryAgainLater",
							})
						);
					}
				} else {
					cookies.remove("invitationLink", {
						path: "/",
						secure: true,
					});
					getTenants(accessToken);
				}
			});
		}
	};

	useEffect(() => {
		const cookies = new Cookies();
		const invitationLink = cookies.get("invitationLink");

		if (!invitationLink) {
			logout();
			cookies.set("invitationLink", code, {
				path: "/",
				secure: true,
			});
		} else {
			cookies.remove("invitationLink", {
				path: "/",
				secure: true,
			});
		}
	}, [code, logout, accessToken]);

	if (code && !accessToken) {
		return (
			<div
				style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}
				className="d-flex flex-column align-items-center justify-content-center"
			>
				<AiOutlineExclamationCircle
					fill="#4db8ff"
					size={80}
					className="mb-3"
				/>
				<span className="email-verified-text">
					<FormattedMessage id="youHaveBeenInvitedToOneOfTheTenantsOfCloudOrder" />
				</span>
				<span className="email-verified-text">
					<FormattedMessage id="toAcceptInvitationYouNeedToLogin" />
				</span>
				<button className="ice-button" onClick={redirectToLogin}>
					<FormattedMessage id="goToLogin" />
				</button>
			</div>
		);
	}

	if (code && accessToken && data && data?.expired === false) {
		return (
			<div
				style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}
				className="d-flex flex-column align-items-center justify-content-center"
			>
				<AiOutlineExclamationCircle
					fill="#4db8ff"
					size={80}
					className="mb-3"
				/>
				<span className="email-verified-text">
					<FormattedMessage id="youHaveBeenInvitedToOneOfTheTenantsOfCloudOrder" />
				</span>
				<button className="ice-button" onClick={handleAcceptUser}>
					<FormattedMessage id="acceptInvitation" />
				</button>
				<TenantsModal
					show={isSuccess}
					tenants={tenantsList ? tenantsList.results : null}
				/>
				<ToastContainer position="bottom-right" />
			</div>
		);
	}

	if (code && accessToken && data && data.expired === true) {
		return (
			<div
				style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}
				className="d-flex flex-column align-items-center justify-content-center"
			>
				<span className="email-verified-text">
					<FormattedMessage id="yourInvitationLinkIsOutOfDate" />
				</span>
			</div>
		);
	}

	return (
		<div style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}>
			<Loading isLoading={true}>
				<div></div>
			</Loading>
		</div>
	);
};

export default AcceptInvitedExistingUser;
