// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-integration-amazon-image {
	width: 30px;
	margin-right: 7px;
}

.amazon-login-button {
	border: 1px solid #134074;
	background-color: white;
	padding: 3px 15px;
	color: #134074;
}
`, "",{"version":3,"sources":["webpack://./src/app/modals/AddAmazonIntegration/AddAmazonIntegration.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,iBAAiB;AAClB;;AAEA;CACC,yBAAyB;CACzB,uBAAuB;CACvB,iBAAiB;CACjB,cAAc;AACf","sourcesContent":[".add-integration-amazon-image {\n\twidth: 30px;\n\tmargin-right: 7px;\n}\n\n.amazon-login-button {\n\tborder: 1px solid #134074;\n\tbackground-color: white;\n\tpadding: 3px 15px;\n\tcolor: #134074;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
