import { BsCalendarDate, BsInfoCircle } from "react-icons/bs";
import { AiOutlineBarcode } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { TbDiscount } from "react-icons/tb";
import { useState } from "react";

import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { useGetTenantAffiliateQuery } from "../../../../api/affiliateAPI";
import { useAppSelector } from "../../../../redux/hooks";

import ChangeAffiliateCode from "../../../modals/ChangeAffiliateCode/ChangeAffiliateCode";

const SettingsAffiliateBlock = () => {
    const [isPublic, subdomain] = useDetectSubdomainOrPublic();
    const [changeCode, setChangeCode] = useState(false);

    const isStaff = useAppSelector((state) => state.auth.isStaff);

    const { data: affiliateData } = useGetTenantAffiliateQuery(subdomain, {
        skip: isPublic || !subdomain || isStaff,
    });

    const handleChangeCode = () => {
        setChangeCode(true);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="settings-card px-3 mt-4 pb-2">
                    <div className="row dashboard-menu-sub d-flex align-items-center">
                        <div className="col-lg-7">
                            <div className="left">
                                <ul className="dashboard-menu-ul">
                                    <li className="dashboard-menu-li">
                                        <span className="dashboard-menu-a">
                                            <FormattedMessage id="affiliateCode" />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="right text-right">
                                <button
                                    onClick={handleChangeCode}
                                    className="dashboard-menu-button ms-2"
                                >
                                    {affiliateData ? (
                                        <FormattedMessage id="changeCode" />
                                    ) : (
                                        <FormattedMessage id="addCode" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {!affiliateData ? (
                            <div
                                className="not-have-affiliate"
                                id="no-active-affiliate"
                            >
                                <FormattedMessage id="yourDoNotHaveActiveAffiliateCode" />
                                <button
                                    onClick={handleChangeCode}
                                    className="dashboard-menu-button mt-2"
                                >
                                    <FormattedMessage id="addCode" />
                                </button>
                            </div>
                        ) : (
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="text-center" scope="col">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <AiOutlineBarcode
                                                    className="me-1"
                                                    size={20}
                                                />{" "}
                                                <FormattedMessage id="activeCode" />
                                            </div>
                                        </th>
                                        <th className="text-center" scope="col">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <TbDiscount
                                                    className="me-1"
                                                    size={20}
                                                />{" "}
                                                <FormattedMessage id="benefitsDiscount" />
                                            </div>
                                        </th>
                                        <th className="text-center" scope="col">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <BsCalendarDate
                                                    className="me-1"
                                                    size={18}
                                                />{" "}
                                                <FormattedMessage id="expirationDate" />
                                            </div>
                                        </th>
                                        <th className="text-center" scope="col">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <BsInfoCircle
                                                    className="me-1"
                                                    size={18}
                                                />{" "}
                                                <FormattedMessage id="active" />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={affiliateData.id}>
                                        <td className="text-center">
                                            {affiliateData.program.code}
                                        </td>
                                        <td className="text-center">
                                            {
                                                affiliateData.program
                                                    .benefits_discount
                                            }{" "}
                                            %
                                        </td>
                                        <td className="text-center">
                                            {affiliateData.get_period}
                                        </td>
                                        <td className="text-center">
                                            {affiliateData.is_active ? (
                                                <FormattedMessage id="yes" />
                                            ) : (
                                                <FormattedMessage id="no" />
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            {changeCode && (
                <ChangeAffiliateCode
                    show={changeCode}
                    onHide={() => setChangeCode(false)}
                />
            )}
        </div>
    );
};

export default SettingsAffiliateBlock;
