import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import StarRatings from "react-star-ratings";
import { Dropdown } from "react-bootstrap";
import { TbFilter } from "react-icons/tb";

import useClickOutsideOfBlock from "../../../../hooks/useClickOutsideOfBlock";
import { useGetPublicProductsQuery } from "../../../../api/productsAPI";
import { useGetCategoriesQuery } from "../../../../api/categoriesAPI";
import usePagination from "../../../../hooks/usePagination";
import useFilters from "../../../../hooks/useFilters";
import { TProduct } from "../../../../api/api.types";

import DashboardCatalogProduct from "../../../modals/DashboardCatalogProduct/DashboardCatalogProduct";
import SelectIntegration from "../../../modals/SelectIntegration/SelectIntegration";
import PagePagination from "../../../components/common/Pagination/PagePagination";
import FilterBlock from "../../../components/common/FilterBlock/FilterBlock";
import EmptyState from "../../../components/common/EmptyState/EmptyState";
import Loading from "../../../components/common/Loading/Loading";
import Search from "../../../components/common/Search/Search";

import Arrow from "../../../assets/images/arrow.svg";

import "./DashboardCatalog.css";

const DashboardCatalog = () => {
	const [showIntegrationsModal, setShowIntegrationsModal] = useState(false);
	const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
	const [viewProduct, setViewProduct] = useState<null | TProduct>(null);
	const [expandFilters, setExpandFilters] = useState(false);
	const [viewModal, setViewModal] = useState(false);

	const filterRef = useRef<HTMLDivElement>(null);

	const {
		search,
		handleSearch,
		handleSearchFilter,
		searchFilter,
		onKeyDownSearch,
		categoryFilter,
		handleCategoryFilter,
	} = useFilters();
	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		"12"
	);
	useClickOutsideOfBlock({
		blockRef: filterRef,
		showValue: expandFilters,
		setShowValue: setExpandFilters,
	});

	const { data: categories } = useGetCategoriesQuery();
	const {
		data: publicProductsData,
		isLoading,
		isFetching,
	} = useGetPublicProductsQuery({
		activePage,
		searchFilter,
		pageSize: "12",
		categoryFilter,
	});

	const handleOpenIntegrationModal = () => {
		setShowIntegrationsModal(true);
	};

	const handleQuickView = (product: TProduct) => {
		setViewProduct(product);
		setViewModal(true);
	};

	const handleSelectProduct = (
		e: ChangeEvent<HTMLInputElement>,
		id: number
	) => {
		if (e.target.checked) {
			setSelectedProducts((prev) => {
				return [...prev, id];
			});
		} else {
			setSelectedProducts((prev) => {
				return prev.filter((el) => el !== id);
			});
		}
	};

	useEffect(() => {
		if (publicProductsData) {
			handlePagesCount(publicProductsData.count);
		}
	}, [publicProductsData, handlePagesCount]);

	return (
		<div className="dashboard-catalog__content-wrapper">
			<Search
				search={search}
				handleSearch={handleSearch}
				handleSearchFilter={handleSearchFilter}
				onKeyDownSearch={onKeyDownSearch}
				handlePage={handlePage}
			/>
			<div className="dashboard-catalog__content">
				<div className="dashboard-catalog__content-filters">
					<h5 className="dashboard-catalog__title">
						<FormattedMessage id="catalog" />
					</h5>
					<div className="dashboard-catalog__content-filters-wrapper">
						{publicProductsData &&
							publicProductsData.results.length > 0 && (
								<PagePagination
									activePage={activePage}
									pages={pages}
									handlePage={handlePage}
								/>
							)}
						<Dropdown className="d-flex justify-content-center">
							<Dropdown.Toggle
								data-testid="action"
								className="user-header-dropdown orders__dropdown-toggle p-0"
							>
								<div className="orders__dropdown-button">
									<FormattedMessage id="action" />
									<img
										className="arrow-icon"
										src={Arrow}
										alt=""
									/>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu className="orders__dropdown-menu-wrapper">
								<div className="orders__dropdown-menu">
									<Dropdown.Item
										data-testid="export-products"
										className="orders__dropdown-item"
										onClick={handleOpenIntegrationModal}
									>
										<FormattedMessage id="exportProductsToStore" />
									</Dropdown.Item>
								</div>
							</Dropdown.Menu>
						</Dropdown>
						<div
							ref={filterRef}
							className="orders__page-filters-wrapper"
						>
							<div
								data-testid="filter-button"
								onClick={() => setExpandFilters(!expandFilters)}
								className={`orders__page-filters-button ${expandFilters ? "expanded" : ""}`}
							>
								<TbFilter
									size={22}
									color={
										expandFilters ? "#FFFFFF" : "#666666"
									}
								/>
							</div>
							{expandFilters && (
								<FilterBlock
									selectedFilters={{
										category: categoryFilter,
									}}
									filters={{
										filterByCategory: {
											field: "category",
											options:
												categories?.results?.map(
													(category) => ({
														id: category.id,
														name: category.name,
														value: String(
															category.id
														),
													})
												) || [],
										},
									}}
									handlers={{
										category: handleCategoryFilter,
									}}
									handlePage={handlePage}
									onClose={() => setExpandFilters(false)}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="dashboard-catalog__content-products">
					<Loading isLoading={isLoading || isFetching}>
						{publicProductsData &&
						publicProductsData.results.length > 0 ? (
							<div
								data-testid="products-list"
								className="dashboard-catalog__content-products-list"
							>
								{publicProductsData.results.map((product) => (
									<div
										data-testid="product"
										key={product.id}
										className="catalog__product-block"
									>
										<img
											className="catalog__product-image"
											src={
												product?.variants[0]?.images[0]
													?.url
													? product?.variants[0]
															?.images[0]?.url
													: product?.variants[0]
															?.images[0]?.image
											}
											alt=""
										/>
										<div className="catalog__product-details">
											<p className="catalog__product-title">
												{product.title}
											</p>
											<div className="d-flex align-items-center justify-content-between">
												<div className="me-1 mb-1">
													<StarRatings
														rating={parseFloat(
															product.rank
																? product.rank
																		.rating
																: "0"
														)}
														numberOfStars={5}
														starRatedColor="yellow"
														starDimension="20px"
														starSpacing="1px"
														svgIconPath="M11.4396 2.87017L12.9061 5.82742C13.1061 6.23908 13.6394 6.63394 14.0894 6.70954L16.7474 7.15481C18.4472 7.44046 18.8472 8.68385 17.6223 9.91044L15.5559 11.9939C15.2059 12.3468 15.0143 13.0273 15.1225 13.5146L15.7142 16.0938C16.1808 18.1353 15.1059 18.925 13.3145 17.858L10.823 16.371C10.3731 16.1022 9.63154 16.1022 9.17321 16.371L6.68185 17.858C4.89871 18.925 3.8155 18.1269 4.28212 16.0938L4.87372 13.5146C4.98203 13.0273 4.79039 12.3468 4.44043 11.9939L2.37399 9.91044C1.15746 8.68385 1.54908 7.44046 3.24889 7.15481L5.90693 6.70954C6.34855 6.63394 6.88183 6.23908 7.0818 5.82742L8.54829 2.87017C9.34821 1.26553 10.648 1.26553 11.4396 2.87017Z"
														svgIconViewBox="0 0 20 20"
														starEmptyColor="#666666"
													/>
												</div>
												<span
													style={{ marginTop: "3px" }}
													className="number-rating"
												>
													{product.rank
														? product.rank.rating
														: "0"}
												</span>
											</div>
										</div>
										<div className="catalog__hover-wrapper">
											<button
												data-testid="quick-view"
												onClick={() =>
													handleQuickView(product)
												}
												className="catalog__view-button"
											>
												<FormattedMessage id="quickView" />
											</button>
										</div>
										<div className="catalog__checkbox-wrapper">
											<input
												data-testid="product-checkbox"
												className="form-check-input"
												type="checkbox"
												checked={selectedProducts.includes(
													product.id
												)}
												onChange={(e) =>
													handleSelectProduct(
														e,
														product.id
													)
												}
											/>
										</div>
									</div>
								))}
							</div>
						) : (
							<EmptyState message="productsNotFound" />
						)}
					</Loading>
				</div>
			</div>
			{viewModal && viewProduct && (
				<DashboardCatalogProduct
					show={viewModal}
					onHide={() => setViewModal(false)}
					viewProduct={viewProduct}
				/>
			)}
			{showIntegrationsModal && (
				<SelectIntegration
					setSelectedProducts={setSelectedProducts}
					selectedProducts={selectedProducts}
					show={showIntegrationsModal}
					onHide={() => setShowIntegrationsModal(false)}
				/>
			)}
		</div>
	);
};

export default DashboardCatalog;
