// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-catalog-product-description {
	border-top: 2px solid rgba(116, 181, 175, 0.21);
	margin-top: 10px;
	padding-top: 5px;
}

.product-variants-choose-block {
	display: flex;
	overflow-x: auto;
	margin-top: 5px;
}

.product-variant-block {
	min-width: 50px;
	max-width: 50px;
	height: 50px;
	margin-right: 3px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
	border-radius: 5px;
	cursor: pointer;
	border: 2px solid rgba(187, 204, 201, 0.656);
	overflow: hidden;
}

.product-variant-block:hover,
.product-variant-block-active {
	min-width: 50px;
	max-width: 50px;
	height: 50px;
	margin-right: 3px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
	border-radius: 5px;
	cursor: pointer;
	border: 2px solid rgb(45, 101, 166);
	overflow: hidden;
}

.product-variant-block-active img,
.product-variant-block img {
	width: 50px;
	height: 50px;
}

.product-no-reviews-block {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modals/DashboardCatalogProduct/DashboardCatalogProduct.css"],"names":[],"mappings":"AAAA;CACC,+CAA+C;CAC/C,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,gBAAgB;CAChB,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,eAAe;CACf,YAAY;CACZ,iBAAiB;CACjB,0CAA0C;CAC1C,kBAAkB;CAClB,eAAe;CACf,4CAA4C;CAC5C,gBAAgB;AACjB;;AAEA;;CAEC,eAAe;CACf,eAAe;CACf,YAAY;CACZ,iBAAiB;CACjB,0CAA0C;CAC1C,kBAAkB;CAClB,eAAe;CACf,mCAAmC;CACnC,gBAAgB;AACjB;;AAEA;;CAEC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,eAAe;CACf,aAAa;AACd","sourcesContent":[".dashboard-catalog-product-description {\n\tborder-top: 2px solid rgba(116, 181, 175, 0.21);\n\tmargin-top: 10px;\n\tpadding-top: 5px;\n}\n\n.product-variants-choose-block {\n\tdisplay: flex;\n\toverflow-x: auto;\n\tmargin-top: 5px;\n}\n\n.product-variant-block {\n\tmin-width: 50px;\n\tmax-width: 50px;\n\theight: 50px;\n\tmargin-right: 3px;\n\tbox-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;\n\tborder-radius: 5px;\n\tcursor: pointer;\n\tborder: 2px solid rgba(187, 204, 201, 0.656);\n\toverflow: hidden;\n}\n\n.product-variant-block:hover,\n.product-variant-block-active {\n\tmin-width: 50px;\n\tmax-width: 50px;\n\theight: 50px;\n\tmargin-right: 3px;\n\tbox-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;\n\tborder-radius: 5px;\n\tcursor: pointer;\n\tborder: 2px solid rgb(45, 101, 166);\n\toverflow: hidden;\n}\n\n.product-variant-block-active img,\n.product-variant-block img {\n\twidth: 50px;\n\theight: 50px;\n}\n\n.product-no-reviews-block {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tfont-size: 1rem;\n\theight: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
