import { FormattedMessage } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { FC, useState } from "react";

import { ORDER_STATUS_TRANSLATIONS } from "../../../../helpers/constants";
import { TOrderFulfillment, TTenant } from "../../../../api/api.types";

import EditFulfillmentTrackingNumber from "../../../modals/EditFulfillmentTrackingNumber/EditFulfillmentTrackingNumber";
import EditFulfillmentStatus from "../../../modals/EditFulfillmentStatus/EditFulfillmentStatus";

import Arrow from "../../../assets/images/arrow.svg";

type TOrderFulfillmentBlockProps = {
	fulfillment: TOrderFulfillment;
	tenantData: TTenant | undefined;
};

const OrderFulfillmentBlock: FC<TOrderFulfillmentBlockProps> = ({
	fulfillment,
	tenantData,
}) => {
	const [showTrackingNumberEdit, setShowTrackingNumberEdit] = useState(false);
	const [showStatusEdit, setShowStatusEdit] = useState(false);

	const handleUpdateFulfillmentStatus = () => {
		setShowStatusEdit(true);
	};

	const handleUpdateFulfillmentTrackingNumber = () => {
		setShowTrackingNumberEdit(true);
	};

	return (
		<div
			data-testid="fulfillments-block"
			className="order-details__fulfillment-details-block"
		>
			<div className="order-details__choose-action">
				<p className="order-details__fulfillment-details-title">
					<FormattedMessage id="fulfillmentWithDots" />{" "}
					{
						fulfillment?.amazon_fulfillment_shipments[0]
							?.amazon_shipment_id
					}
				</p>
				<div className="d-flex align-items-center gap-3">
					<div className="order-details__order-status">
						<div className="order-details__order-status-indicator"></div>
						<span className="order-details__order-status-text">
							<FormattedMessage
								id={
									ORDER_STATUS_TRANSLATIONS[
										(fulfillment.status ??
											"New") as keyof typeof ORDER_STATUS_TRANSLATIONS
									] || fulfillment.status
								}
							/>
						</span>
					</div>
					{fulfillment.vendor_integration === null &&
						fulfillment.vendor_tenant === tenantData?.id && (
							<Dropdown className="d-flex justify-content-center">
								<Dropdown.Toggle className="user-header-dropdown orders__dropdown-toggle p-0">
									<div className="orders__dropdown-button">
										<FormattedMessage id="action" />
										<img
											className="arrow-icon"
											src={Arrow}
											alt=""
										/>
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu className="orders__dropdown-menu-wrapper">
									<div className="orders__dropdown-menu">
										<Dropdown.Item
											onClick={() =>
												handleUpdateFulfillmentStatus()
											}
											className="orders__dropdown-item"
										>
											<FormattedMessage id="updateStatus" />
										</Dropdown.Item>
										<Dropdown.Item
											onClick={() =>
												handleUpdateFulfillmentTrackingNumber()
											}
											className="orders__dropdown-item"
										>
											<FormattedMessage id="updateTrackingNumber" />
										</Dropdown.Item>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						)}
				</div>
			</div>
			<div className="order-details__fulfillment-details-table-wrapper">
				<table className="table mb-0">
					<thead className="thead-light">
						<tr>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="image" />
							</th>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="productName" />
							</th>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="moreInformation" />
							</th>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="quantity" />
							</th>
							<th className="text-center vertical-align-middle">
								<FormattedMessage id="price" />
							</th>
						</tr>
					</thead>
					<tbody>
						{fulfillment?.fulfillment_line_items &&
							fulfillment?.fulfillment_line_items.length > 0 &&
							fulfillment?.fulfillment_line_items.map(
								(fulfillmentLineItem) => (
									<tr key={fulfillmentLineItem.id}>
										<td className="text-center">
											<img
												height={70}
												src={
													fulfillmentLineItem
														?.variant_product
														?.images[0]?.url
														? fulfillmentLineItem
																?.variant_product
																?.images[0]?.url
														: fulfillmentLineItem
																?.variant_product
																?.images[0]
																?.image
												}
												alt=""
											/>
										</td>

										<td
											width="30%"
											className="text-center max-width-td"
										>
											<div className="d-flex flex-column">
												<p className="wrapped-table-row">
													{
														fulfillmentLineItem
															?.variant_product
															?.title
													}
												</p>
												<p className="">
													SKU:{" "}
													{
														fulfillmentLineItem
															?.variant_product
															?.sku
													}
												</p>
											</div>
										</td>

										<td
											width="30%"
											className="text-center max-width-td"
										>
											<div className="d-flex flex-column">
												<p className="order-details__fulfillment-two-rows wrapped-table-row">
													<FormattedMessage id="packingNumberWithDots" />{" "}
													<span>
														{
															fulfillmentLineItem
																?.amazon_fulfillment_shipment_items[0]
																?.package_number
														}
													</span>
												</p>
												<p className="order-details__fulfillment-two-rows">
													<FormattedMessage id="trackingNumberWithDots" />{" "}
													<span>
														{fulfillment.vendor_integration ===
															null &&
														fulfillment.vendor_tenant ===
															tenantData?.id
															? fulfillment.tracking_number
															: fulfillmentLineItem
																	?.amazon_fulfillment_shipment_items[0]
																	?.amazon_fulfillment_shipment_package
																	.tracking_number}
													</span>
												</p>
											</div>
										</td>
										<td className="text-center order-details-info-text">
											{fulfillmentLineItem?.quantity}
										</td>
										<td className="text-center order-details-info-text">
											${fulfillmentLineItem?.price / 100}
										</td>
									</tr>
								)
							)}
					</tbody>
				</table>
			</div>
			{showStatusEdit && (
				<EditFulfillmentStatus
					fulfillment={fulfillment}
					onHide={() => setShowStatusEdit(false)}
				/>
			)}
			{showTrackingNumberEdit && (
				<EditFulfillmentTrackingNumber
					fulfillment={fulfillment}
					onHide={() => setShowTrackingNumberEdit(false)}
				/>
			)}
		</div>
	);
};

export default OrderFulfillmentBlock;
