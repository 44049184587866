import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useState } from "react";

import { useCheckAcceptAsNewCodeQuery } from "../../../../../api/authAPI";

import SetInvitedUserPassword from "../../../../modals/SetInvitedUserPassword/SetInvitedUserPassword";
import Loading from "../../../../components/common/Loading/Loading";

const AcceptInvitedUser = () => {
	const [invitedUserPasswordModal, setInvitedUserPasswordModal] =
		useState(false);

	const { code } = useParams();

	const { data, isLoading, isSuccess } = useCheckAcceptAsNewCodeQuery(
		code ? code : ""
	);

	const acceptInvitation = () => {
		setInvitedUserPasswordModal(true);
	};

	if (isLoading) {
		return (
			<div style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}>
				<Loading
					isLoading={isLoading}
					loadingText="verifyingLinkThreeDots"
				>
					<div></div>
				</Loading>
			</div>
		);
	}

	if (isSuccess && data.expired === false) {
		return (
			<div
				style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}
				className="d-flex flex-column align-items-center justify-content-center"
			>
				<span className="email-verified-text">
					<FormattedMessage id="youHaveBeenInvitedToOneOfTheTenantsOfCloudOrder" />
				</span>
				<button className="ice-button" onClick={acceptInvitation}>
					<FormattedMessage id="acceptInvitation" />
				</button>
				<SetInvitedUserPassword
					show={invitedUserPasswordModal}
					onHide={() => setInvitedUserPasswordModal(false)}
					code={code ? code : ""}
				/>
			</div>
		);
	}

	if (isSuccess && data.expired === true) {
		return (
			<div
				style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}
				className="d-flex flex-column align-items-center justify-content-center"
			>
				<span className="email-verified-text">
					<FormattedMessage id="yourLinkIsOutOfDate" />
				</span>
			</div>
		);
	}

	return (
		<div style={{ padding: "120px 0", backgroundColor: "#FFFFFF" }}>
			<Loading isLoading={true}>
				<div></div>
			</Loading>
		</div>
	);
};

export default AcceptInvitedUser;
