import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

import {
	useGetActiveTenantsQuery,
	useGetCanceledTenantsQuery,
	useUpdateTenantStatusMutation,
} from "../../../../api/settingsAPI";
import usePagination from "../../../../hooks/usePagination";
import useFilters from "../../../../hooks/useFilters";
import { TTenant } from "../../../../api/api.types";

import PagePagination from "../../../components/common/Pagination/PagePagination";
import EmptyState from "../../../components/common/EmptyState/EmptyState";
import Loading from "../../../components/common/Loading/Loading";
import AddCredits from "../../../modals/AddCredits/AddCredits";
import Search from "../../../components/common/Search/Search";

import Arrow from "../../../assets/images/arrow.svg";

import "./SettingsTenants.css";

const SettingsTenants = () => {
	const [tenantId, setTenantId] = useState<number | null>(null);
	const [addCredits, setAddCredits] = useState(false);

	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		10
	);
	const [
		activeDisabledPage,
		disabledPages,
		handleDisabledPage,
		handleDisabledPagesCount,
	] = usePagination("disabled_page", 10);

	const {
		search,
		searchFilter,
		handleSearchFilter,
		handleSearch,
		onKeyDownSearch,
	} = useFilters();

	const [updateTenantStatus] = useUpdateTenantStatusMutation();

	const {
		data: activeTenants,
		isLoading: isLoadingActive,
		isFetching: isFetchingActive,
	} = useGetActiveTenantsQuery({
		page: activePage,
		searchFilter,
	});
	const {
		data: canceledTenants,
		isLoading: isLoadingCanceled,
		isFetching: isFetchingCanceled,
	} = useGetCanceledTenantsQuery({
		page: activeDisabledPage,
		searchFilter,
	});

	const goToDashboard = (url: string) => {
		window.location.href = url;
	};

	const handleAddCredits = (id: number) => {
		setTenantId(id);
		setAddCredits(true);
	};

	const handleCancelSubscription = (status: boolean, subdomain: string) => {
		updateTenantStatus({ data: { is_active: status }, subdomain });

		if (
			activeTenants &&
			status === false &&
			activeTenants.results?.length === 1 &&
			activePage !== "1"
		) {
			handlePage(String(Number(activePage) - 1));
		} else if (
			canceledTenants &&
			status === true &&
			canceledTenants.results?.length === 1 &&
			activeDisabledPage !== "1"
		) {
			handleDisabledPage(String(Number(activeDisabledPage) - 1));
		}
	};

	useEffect(() => {
		if (canceledTenants) {
			handleDisabledPagesCount(canceledTenants.count);
		}
	}, [canceledTenants, handleDisabledPagesCount]);

	useEffect(() => {
		if (activeTenants) {
			handlePagesCount(activeTenants.count);
		}
	}, [activeTenants, handlePagesCount]);

	return (
		<div className="content-wrapper-set-tenants">
			<div className="header-set-tenants">
				<Search
					search={search}
					handleSearchFilter={handleSearchFilter}
					handleSearch={handleSearch}
					onKeyDownSearch={onKeyDownSearch}
					handlePage={handlePage}
				/>
			</div>
			<div className="content-set-tenants" data-testid="tenants-table">
				<div className="tenant__page-paginate">
					<h5 className="tenant__page-title">
						<FormattedMessage id="tenants" />
					</h5>
					{activeTenants?.results &&
						activeTenants.results.length > 0 && (
							<PagePagination
								pages={pages}
								activePage={activePage}
								handlePage={handlePage}
							/>
						)}
				</div>
				<Loading
					isLoading={
						isLoadingActive ||
						isLoadingCanceled ||
						isFetchingActive ||
						isFetchingCanceled
					}
				>
					{activeTenants?.results?.length === 0 ? (
						<EmptyState message="noActiveTenants" />
					) : (
						<div className="tenant__table-wrapper">
							<table className="table" data-testid="tenant-table">
								<thead className="thead-light">
									<tr>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="subdomain" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="companyName" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="email" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="address" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="phone" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="action" />
										</th>
									</tr>
								</thead>
								<tbody>
									{activeTenants?.results?.map((tenant) => (
										<tr
											key={tenant.id}
											data-testid="tenant-row"
										>
											<td
												data-testid="tenant-subdomain"
												width="13%"
												className="text-center"
											>
												{tenant.subdomain}
											</td>
											<td className="text-center">
												{tenant.details.name
													? tenant.details.name
													: "-"}
											</td>
											<td className="text-center">
												{tenant.creator_email}
											</td>
											<td className="text-center">
												{tenant.details.country ||
												tenant.details.city ||
												tenant.details.state ? (
													<>
														{tenant.details.country
															? `${tenant.details.country},`
															: ""}{" "}
														{tenant.details.city
															? `${tenant.details.city},`
															: ""}{" "}
														{tenant.details.state
															? `${tenant.details.state}`
															: ""}
													</>
												) : (
													"-"
												)}
											</td>
											<td className="text-center">
												{tenant.details.phone
													? tenant.details.phone
													: "-"}
											</td>
											<td
												width="13%"
												className="text-center"
											>
												<Dropdown
													className="d-flex justify-content-center"
													data-testid="status-toggle"
												>
													<Dropdown.Toggle className="user-header-dropdown tenant__dropdown-toggle p-0">
														<div className="tenant__dropdown-button">
															<FormattedMessage id="select" />
															<img
																className="arrow-icon"
																src={Arrow}
																alt=""
															/>
														</div>
													</Dropdown.Toggle>
													<Dropdown.Menu className="tenant__dropdown-menu-wrapper">
														<div className="tenant__dropdown-menu">
															<Dropdown.Item
																onClick={() =>
																	goToDashboard(
																		tenant.full_url
																	)
																}
																className="tenant__dropdown-item"
																data-testid="goToDashboard"
															>
																<FormattedMessage id="goToDashboard" />
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() =>
																	handleCancelSubscription(
																		false,
																		tenant.subdomain
																	)
																}
																className="tenant__dropdown-item"
																data-testid="deactivate-tenant"
															>
																<FormattedMessage id="cancelSubscription" />
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() =>
																	handleAddCredits(
																		tenant.id
																	)
																}
																className="tenant__dropdown-item"
																data-testid="addCredit"
															>
																<FormattedMessage id="addCredit" />
															</Dropdown.Item>
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
					<div className="tenant__page-paginate">
						<h5 className="tenant__page-title">
							<FormattedMessage id="canceled" />
						</h5>
						{canceledTenants?.results &&
							canceledTenants.results.length > 0 && (
								<PagePagination
									pages={disabledPages}
									activePage={activeDisabledPage}
									handlePage={handleDisabledPage}
								/>
							)}
					</div>
					<div
						data-testid="canceled-table"
						className="tenant__table-wrapper"
					>
						{!canceledTenants?.results?.length ? (
							<EmptyState message="noCanceledTenants" />
						) : (
							<table className="table">
								<thead className="thead-light">
									<tr>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="subdomain" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="email" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="cancelledTenantName" />
										</th>
										<th className="text-center vertical-align-middle">
											<FormattedMessage id="action" />
										</th>
									</tr>
								</thead>
								<tbody>
									{canceledTenants.results.map(
										(tenant: TTenant) => (
											<tr
												key={tenant.id}
												data-testid="tenant-row"
											>
												<td
													data-testid="tenant-subdomain"
													className="text-center"
												>
													{tenant.subdomain}
												</td>
												<td className="text-center">
													{tenant.creator_email}
												</td>
												<td className="text-center">
													{tenant.details.name
														? tenant.details.name
														: "-"}
												</td>
												<td className="text-center">
													<Dropdown
														className="d-flex justify-content-center"
														align="end"
														data-testid="status-toggle"
													>
														<Dropdown.Toggle className="user-header-dropdown tenant__dropdown-toggle p-0">
															<div className="tenant__dropdown-button">
																<FormattedMessage id="select" />
																<img
																	className="arrow-icon"
																	src={Arrow}
																	alt=""
																/>
															</div>
														</Dropdown.Toggle>
														<Dropdown.Menu className="tenant__dropdown-menu-wrapper">
															<div className="tenant__dropdown-menu">
																<Dropdown.Item
																	onClick={() =>
																		handleCancelSubscription(
																			true,
																			tenant.subdomain
																		)
																	}
																	className="tenant__dropdown-item"
																	data-testid="enable"
																>
																	<FormattedMessage id="enable" />
																</Dropdown.Item>
															</div>
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr>
										)
									)}
								</tbody>
							</table>
						)}
					</div>
				</Loading>
			</div>
			{addCredits && (
				<AddCredits
					show={addCredits}
					onHide={() => setAddCredits(false)}
					tenantId={tenantId}
				/>
			)}
		</div>
	);
};

export default SettingsTenants;
