// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tenant-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.tenant-domain {
    font-weight: 500;
}

.tenant-visit-button {
    background-color: var(--button) !important;
}

.tenant-visit-button:hover {
    background-color: var(--button-hover) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/LendingPage/Auth/TenantsModal/TenantsModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,gDAAgD;AACpD","sourcesContent":[".tenant-block {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 10px;\n}\n\n.tenant-domain {\n    font-weight: 500;\n}\n\n.tenant-visit-button {\n    background-color: var(--button) !important;\n}\n\n.tenant-visit-button:hover {\n    background-color: var(--button-hover) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
