import { FormattedMessage, useIntl } from "react-intl";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Dropdown, Modal } from "react-bootstrap";
import { FC, useEffect, useState } from "react";
import { FaFileExcel } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { DndProvider } from "react-dnd";
import { toast } from "react-toastify";

import { useGetIntegrationsQuery } from "../../../api/integrationsAPI";
import {
	TBulkUploadError,
	TGetIntegrationsResults,
	TUploadedProductsResponse,
} from "../../../api/api.types";
import {
	useDownloadBulkUploadTemplateMutation,
	useProductsBulkUploadMutation,
} from "../../../api/productsAPI";

import TargetBox from "./TargetBox";

import Arrow from "../../assets/images/arrow.svg";

import "./BulkUpload.css";

type TUploadError = {
	data: {
		error: TBulkUploadError[];
	};
};

type TBulkUpload = {
	show: boolean;
	onHide: () => void;
	setShowErrorsModal: (value: TBulkUploadError[] | null) => void;
	setJustUploadedProducts: (value: TUploadedProductsResponse[]) => void;
};

const BulkUpload: FC<TBulkUpload> = ({
	show,
	onHide,
	setShowErrorsModal,
	setJustUploadedProducts,
}) => {
	const [bulkUploadIntegration, setBulkUploadIntegration] = useState<
		number | null
	>(null);
	const [availableIntegrations, setAvailableIntegrations] = useState<
		TGetIntegrationsResults[]
	>([]);
	const [file, setFile] = useState<File | null>(null);

	const intl = useIntl();

	const { data } = useGetIntegrationsQuery();
	const [productsBulkUpload] = useProductsBulkUploadMutation();
	const [downloadBulkTemplate] = useDownloadBulkUploadTemplateMutation();

	const handleDeleteFile = () => {
		setFile(null);
	};

	const handleUploadProducts = () => {
		if (!file) return;

		const formData = new FormData();
		formData.append("xlsx_file", file);

		if (bulkUploadIntegration) {
			formData.append("integration_id", bulkUploadIntegration.toString());
		}

		productsBulkUpload(formData).then((response) => {
			if ("error" in response) {
				const errors = response.error as TUploadError;
				setShowErrorsModal(errors.data.error as TBulkUploadError[]);
				onHide();
			} else {
				if (response.data.length === 0) {
					toast.success(
						intl.formatMessage({
							id: "allProductsAreAlreadyUploaded",
						})
					);
				} else {
					setJustUploadedProducts(response.data);
				}

				setFile(null);
				onHide();
			}
		});
	};

	const handleDownloadTemplate = () => {
		downloadBulkTemplate().then((response) => {
			if ("error" in response) {
				return;
			} else {
				const url = window.URL.createObjectURL(
					new Blob([response.data])
				);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "products_template.xlsx");
				document.body.appendChild(link);
				link.click();
			}
		});
	};

	useEffect(() => {
		if (data) {
			const filteredIntegrations = data.results.filter(
				(integration) =>
					integration.partner === "Off Amazon Integration"
			);

			setAvailableIntegrations(filteredIntegrations);
		}
	}, [data]);

	return (
		<Modal className="modal-500" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="bulkProductsUpload" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{ gap: "12px" }}
				className="pt-0 d-flex flex-column"
			>
				<p className="upload-orders__description">
					<FormattedMessage id="bulkProductsUploadFromAnExcelFile" />
				</p>
				<button
					onClick={handleDownloadTemplate}
					className="light-grey-button"
				>
					<FormattedMessage id="downloadTemplate" />
				</button>
				<p className="upload-orders__description">
					<FormattedMessage id="selectFulfillmentIntegrationWithDots" />
				</p>
				<Dropdown className="d-flex justify-content-end dropdown-without-triangle">
					<Dropdown.Toggle className="user-header-dropdown default-dropdown-toggle p-0 w-100">
						<div className="default-dropdown-button w-100 d-flex justify-content-between">
							{bulkUploadIntegration ? (
								availableIntegrations.find(
									(integration) =>
										integration.id === bulkUploadIntegration
								)?.name
							) : (
								<FormattedMessage id="none" />
							)}
							<img className="arrow-icon" src={Arrow} alt="" />
						</div>
					</Dropdown.Toggle>
					<Dropdown.Menu className="default-dropdown-menu-wrapper w-100">
						<div className="default-dropdown-menu">
							<Dropdown.Item
								onClick={() => setBulkUploadIntegration(null)}
								className="default-dropdown-item"
							>
								<FormattedMessage id="none" />
							</Dropdown.Item>
							{availableIntegrations &&
								availableIntegrations.length > 0 &&
								availableIntegrations.map((integration) => (
									<Dropdown.Item
										key={integration.id}
										onClick={() =>
											setBulkUploadIntegration(
												integration.id
											)
										}
										className="default-dropdown-item"
									>
										{integration.name} (
										{integration.partner})
									</Dropdown.Item>
								))}
						</div>
					</Dropdown.Menu>
				</Dropdown>
				<p className="upload-orders__description">
					<FormattedMessage id="afterYouHavePreparedTheFileDragAndDropOrClick" />
				</p>
				{file ? (
					<div className="bulk-upload-file-block">
						<div className="bulk-upload-file">
							<FaFileExcel color="#495057" size={24} />
							<div
								data-testid="file-info"
								className="bulk-upload-file-info"
							>
								<span data-testid="file-name">{file.name}</span>
								<span className="bulk-upload-file-size">
									{Math.round(file.size / 1024)} kb
								</span>
							</div>
						</div>
						<MdDelete
							data-testid="delete-file"
							className="bulk-upload-file-delete"
							onClick={handleDeleteFile}
							color="#d0d4d7"
							size={24}
						/>
					</div>
				) : (
					<DndProvider backend={HTML5Backend}>
						<TargetBox setFile={setFile} />
					</DndProvider>
				)}
				<div className="d-flex align-items-center justify-content-end mt-2">
					<button
						data-testid="upload-button"
						disabled={!file}
						onClick={handleUploadProducts}
						className="ice-button w-100"
					>
						<FormattedMessage id="upload" />
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default BulkUpload;
