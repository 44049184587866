import {
    FaInfoCircle,
    FaFingerprint,
    FaCogs,
    FaStoreAlt,
    FaCalendarAlt,
    FaDollarSign,
    FaCheck,
    FaExchangeAlt,
    FaWindowClose,
} from "react-icons/fa";
import { Col, Container, Dropdown, Form, Nav, Row } from "react-bootstrap";
import { MdLocalShipping, MdPayments } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { TbFileUpload } from "react-icons/tb";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { GoTrash } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";

import {
    useCancelOrderMutation,
    useFulfillOrderMutation,
    useGetOrdersQuery,
} from "../../../../api/ordersAPI";
import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { TBulkUploadError, TGetOrderResult } from "../../../../api/api.types";
import usePagination from "../../../../hooks/usePagination";
import useFilters from "../../../../hooks/useFilters";

import FulfillmentSelectModal from "../../../modals/CreateOrder/FulfillmentSelectModal";
import PagePagination from "../../../components/common/Pagination/PagePagination";
import UploadOrdersErrors from "../../../modals/UploadOrders/UploadOrdersErrors";
import PayPalPayment from "../../../modals/PayPalPayment/PayPalPayment";
import OrderStatus from "../../../components/layout/Orders/OrderStatus";
import UploadOrders from "../../../modals/UploadOrders/UploadOrders";
import CreateOrder from "../../../modals/CreateOrder/CreateOrder";

import "./Orders.css";

const Orders = () => {
    const [bulkUploadErrors, setBulkUploadErrors] = useState<
        TBulkUploadError[] | null
    >(null);
    const [showFulfillmentSelectModal, setShowFulfillmentSelectModal] =
        useState<string | null>(null);
    const [selectedFulfillmentMethod, setSelectedFulfillmentMethod] = useState<
        number | null
    >(null);
    const [orderPay, setOrderPay] = useState<TGetOrderResult | null>(null);
    const [showUploadOrders, setShowUploadOrders] = useState(false);
    const [showCreateOrder, setShowCreateOrder] = useState(false);
    const [paypalPayment, setPaypalPayment] = useState(false);

    const {
        pageSize,
        handlePageSize,
        clearFilters,
        defaultFilter,
        handleDefaultFilter,
        search,
        handleSearch,
        handleSearchFilter,
        onKeyDownSearch,
        searchFilter,
    } = useFilters("fulfillments__fulfillment_line_items__status");
    const [activePage, pages, handlePage, handlePagesCount] = usePagination(
        "page",
        pageSize
    );
    const [isPublic] = useDetectSubdomainOrPublic();

    const intl = useIntl();
    const navigate = useNavigate();

    const [cancelOrder] = useCancelOrderMutation();
    const [fulfillOrder] = useFulfillOrderMutation();

    const isCancellable = (status: String) => {
        if (
            status === "New" ||
            status === "In Progress" ||
            status === "Return In Progress"
        ) {
            return true;
        } else {
            return false;
        }
    };

    const isShippable = (status: String) => {
        if (status === "New") {
            return true;
        } else {
            return false;
        }
    };

    const { data: ordersData, refetch } = useGetOrdersQuery({
        activePage,
        pageSize,
        defaultFilter,
        searchFilter,
    });

    const handleCloseManuallPayWithRefetch = () => {
        setPaypalPayment(false);
        setOrderPay(null);
        refetch();
    };

    const handleClosePayManually = () => {
        setPaypalPayment(false);
        setOrderPay(null);
    };

    const handlePayManually = (order: TGetOrderResult) => {
        setOrderPay(order);
        setPaypalPayment(true);
    };

    const handleFulfillOrder = (id: number) => {
        fulfillOrder(id).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({
                            id: "somethingWentWrongPleaseTryAgainLater",
                        })
                    );
                }
            }
        });
    };

    const handleCancelOrder = (id: number) => {
        cancelOrder(id).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({
                            id: "somethingWentWrongPleaseTryAgainLater",
                        })
                    );
                }
            }
        });
    };

    const navigateToOrderDetails = (id: number) => {
        navigate(`/dashboard/order-details/${id}`);
    };

    const handleOpenFulfillmentSelectModal = (type: string) => {
        setShowFulfillmentSelectModal(type);
    };

    useEffect(() => {
        if (ordersData) {
            handlePagesCount(ordersData.count);
        }
    }, [ordersData, handlePagesCount]);

    return (
        <section className="p-0">
            <Container fluid className="header-dashboard">
                <Row className="ps-2 w-100">
                    <Col className="col-lg-12 d-flex justify-content-between align-items-center pe-0">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="search-box d-flex flex-row align-items-center m-0 me-2">
                                <div className="d-flex flex-row align-items-center justify-content-between w-100 px-2 m-0">
                                    <input
                                        value={search}
                                        onChange={(e) =>
                                            handleSearch(e.target.value)
                                        }
                                        onKeyDown={(e) =>
                                            onKeyDownSearch(e, handlePage)
                                        }
                                        className="catalog-search-input"
                                        placeholder={`${intl.formatMessage({
                                            id: "search",
                                        })}`}
                                    />
                                    <button
                                        onClick={() =>
                                            handleSearchFilter(handlePage)
                                        }
                                        className="catalog-search-button pb-1"
                                    >
                                        <BiSearch size={20} />
                                    </button>
                                </div>
                            </div>
                            <ul className="dashboard-menu-ul d-flex align-items-start ps-0 m-0">
                                <li className="max-height-38">
                                    <div className="inputBox dashboard-input-box max-height-38">
                                        <Dropdown
                                            onSelect={(e) =>
                                                handlePageSize(e, handlePage)
                                            }
                                        >
                                            <Dropdown.Toggle className="catalog-button-blue dashboard-catalog-button max-height-38">
                                                <FormattedMessage
                                                    id="resultsPerPage"
                                                    values={{
                                                        amount: pageSize,
                                                    }}
                                                />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    className="dashboard-header-dwopdown-item"
                                                    eventKey={5}
                                                >
                                                    <FormattedMessage
                                                        id="resultsPerPage"
                                                        values={{ amount: 5 }}
                                                    />
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="dashboard-header-dwopdown-item"
                                                    eventKey={10}
                                                >
                                                    <FormattedMessage
                                                        id="resultsPerPage"
                                                        values={{ amount: 10 }}
                                                    />
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="dashboard-header-dwopdown-item"
                                                    eventKey={20}
                                                >
                                                    <FormattedMessage
                                                        id="resultsPerPage"
                                                        values={{ amount: 20 }}
                                                    />
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="dashboard-header-dwopdown-item"
                                                    eventKey={50}
                                                >
                                                    <FormattedMessage
                                                        id="resultsPerPage"
                                                        values={{ amount: 50 }}
                                                    />
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </li>
                                <li>
                                    <button
                                        onClick={() => clearFilters(handlePage)}
                                        className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38"
                                    >
                                        <GoTrash size={18} className="me-2" />{" "}
                                        <FormattedMessage id="clearFilters" />
                                    </button>
                                </li>
                            </ul>
                        </div>
                        {!isPublic && (
                            <div className="d-flex align-items-center">
                                <button
                                    onClick={() =>
                                        handleOpenFulfillmentSelectModal(
                                            "manual"
                                        )
                                    }
                                    className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 gap-1"
                                >
                                    <FiPlus size={20} />
                                    <FormattedMessage id="createOrder" />
                                </button>
                                <button
                                    onClick={() =>
                                        handleOpenFulfillmentSelectModal("bulk")
                                    }
                                    className="btn btn-no-link catalog-button-blue dashboard-catalog-button max-height-38 me-0 gap-1"
                                >
                                    <TbFileUpload size={18} />
                                    <FormattedMessage id="uploadOrders" />
                                </button>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
            <div className="page-wrapper">
                <div className="dashboard-body">
                    <Row className="d-flex align-items-center justify-content-between mt-1">
                        <Col className="col-lg-12">
                            <Nav
                                className="orders-nav-links"
                                activeKey={
                                    defaultFilter ? defaultFilter : "All"
                                }
                                onSelect={(selectedKey) =>
                                    handleDefaultFilter(
                                        "fulfillments__fulfillment_line_items__status",
                                        selectedKey,
                                        handlePage
                                    )
                                }
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="NEW">
                                        <FormattedMessage id="new" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="IN_PROGRESS">
                                        <FormattedMessage id="inProgress" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="PARTIALLY_COMPLETED">
                                        <FormattedMessage id="partiallyCompleted" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="COMPLETED">
                                        <FormattedMessage id="completed" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="RETURN_IN_PROGRESS">
                                        <FormattedMessage id="returnInProgress" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="RETURNED">
                                        <FormattedMessage id="returned" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="CANCELED">
                                        <FormattedMessage id="canceled" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ERROR">
                                        <FormattedMessage id="ordersWithErrors" />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="All">
                                        <FormattedMessage id="all" />
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <table className="table">
                            <thead className="thead-light">
                                <tr>
                                    <th className="text-center" scope="col">
                                        <Form.Check />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaFingerprint size={18} />{" "}
                                        <FormattedMessage id="orderID" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaStoreAlt size={20} />{" "}
                                        <FormattedMessage id="orderStore" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaCalendarAlt size={20} />{" "}
                                        <FormattedMessage id="creationDate" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaDollarSign size={19} />{" "}
                                        <FormattedMessage id="retailPrice" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaCheck size={18} />{" "}
                                        <FormattedMessage id="orderIsPaid" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaExchangeAlt size={20} />{" "}
                                        <FormattedMessage id="status" />
                                    </th>
                                    <th className="text-center" scope="col">
                                        <FaCogs size={20} />{" "}
                                        <FormattedMessage id="action" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordersData &&
                                    ordersData.results.length > 0 &&
                                    ordersData.results.map((order) => (
                                        <tr key={order.id}>
                                            <td className="text-center">
                                                <Form.Check />
                                            </td>
                                            <td className="text-center">
                                                <div className="d-flex align-items-center gap-2">
                                                    <OrderStatus
                                                        status={
                                                            order.sync_status
                                                        }
                                                    />
                                                    {order.custom_order_id}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {order?.seller_integration?.name
                                                    ? order?.seller_integration
                                                          ?.name
                                                    : "-"}
                                            </td>
                                            <td className="text-center">
                                                {
                                                    order.imported_date.split(
                                                        "T"
                                                    )[0]
                                                }
                                            </td>
                                            <td className="text-center">
                                                ${order.end_customer_cost / 100}
                                            </td>
                                            <td className="text-center">
                                                <h6 className="d-flex align-items-center justify-content-center">
                                                    <div className="dashboard-status-color bg-success me-1"></div>
                                                    {order.is_paid ? (
                                                        <FormattedMessage id="paid" />
                                                    ) : (
                                                        <FormattedMessage id="notPaid" />
                                                    )}
                                                </h6>
                                            </td>
                                            <td className="text-center">
                                                {order.status}
                                            </td>
                                            <td className="text-center">
                                                <Dropdown className="d-flex justify-content-center">
                                                    <Dropdown.Toggle
                                                        style={{
                                                            width: "110px",
                                                        }}
                                                        className="catalog-button-blue dropdown-settings-button"
                                                    >
                                                        <FormattedMessage id="select" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                navigateToOrderDetails(
                                                                    order.id
                                                                )
                                                            }
                                                            className="d-flex align-items-center dropdown-item-hover-green"
                                                        >
                                                            <FaInfoCircle
                                                                className="me-1"
                                                                size={20}
                                                            />{" "}
                                                            <FormattedMessage id="details" />
                                                        </Dropdown.Item>
                                                        {!isPublic ? (
                                                            <>
                                                                {!order.is_paid ? (
                                                                    <Dropdown.Item
                                                                        onClick={() =>
                                                                            handlePayManually(
                                                                                order
                                                                            )
                                                                        }
                                                                        className="d-flex align-items-center dropdown-item-hover-green"
                                                                    >
                                                                        <MdPayments
                                                                            className="me-1"
                                                                            size={
                                                                                20
                                                                            }
                                                                        />{" "}
                                                                        <FormattedMessage id="payManually" />
                                                                    </Dropdown.Item>
                                                                ) : null}

                                                                {isShippable(
                                                                    order.status
                                                                ) ? (
                                                                    <Dropdown.Item
                                                                        onClick={() =>
                                                                            handleFulfillOrder(
                                                                                order.id
                                                                            )
                                                                        }
                                                                        className="dropdown-item-hover-green"
                                                                    >
                                                                        <MdLocalShipping
                                                                            className="me-1"
                                                                            size={
                                                                                20
                                                                            }
                                                                        />{" "}
                                                                        <FormattedMessage id="ship" />
                                                                    </Dropdown.Item>
                                                                ) : null}

                                                                {isCancellable(
                                                                    order.status
                                                                ) ? (
                                                                    <Dropdown.Item
                                                                        onClick={() =>
                                                                            handleCancelOrder(
                                                                                order.id
                                                                            )
                                                                        }
                                                                        className="dropdown-item-hover-green"
                                                                    >
                                                                        <FaWindowClose
                                                                            className="me-1"
                                                                            size={
                                                                                20
                                                                            }
                                                                        />{" "}
                                                                        <FormattedMessage id="cancel" />
                                                                    </Dropdown.Item>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </Row>
                    {pages.length > 1 && (
                        <PagePagination
                            pages={pages}
                            activePage={activePage}
                            handlePage={handlePage}
                        />
                    )}
                </div>
            </div>
            {paypalPayment && orderPay && (
                <PayPalPayment
                    show={paypalPayment}
                    onHide={handleClosePayManually}
                    orderPay={orderPay}
                    handleCloseManuallPayWithRefetch={
                        handleCloseManuallPayWithRefetch
                    }
                />
            )}
            {showUploadOrders && (
                <UploadOrders
                    show={showUploadOrders}
                    onHide={() => setShowUploadOrders(false)}
                    setBulkUploadErrors={setBulkUploadErrors}
                    selectedFulfillmentMethod={selectedFulfillmentMethod}
                />
            )}
            {showCreateOrder && (
                <CreateOrder
                    show={showCreateOrder}
                    onHide={() => setShowCreateOrder(false)}
                    selectedFulfillmentMethod={selectedFulfillmentMethod}
                />
            )}
            {bulkUploadErrors && bulkUploadErrors?.length > 0 && (
                <UploadOrdersErrors
                    show={bulkUploadErrors && bulkUploadErrors?.length > 0}
                    onHide={() => setBulkUploadErrors(null)}
                    errors={bulkUploadErrors}
                />
            )}
            {showFulfillmentSelectModal && (
                <FulfillmentSelectModal
                    type={showFulfillmentSelectModal}
                    onHide={() => setShowFulfillmentSelectModal(null)}
                    setSelectedFulfillmentMethod={setSelectedFulfillmentMethod}
                    handleOpenOrderCreationModal={
                        showFulfillmentSelectModal === "manual"
                            ? setShowCreateOrder
                            : setShowUploadOrders
                    }
                />
            )}
        </section>
    );
};

export default Orders;
