import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC, useEffect } from "react";

import {
    useGetWiseReceiptQuery,
    useUpdateWiseReceiptMutation,
} from "../../../api/paymentsAPI";

export type TEditWiseReceiptMethod = {
    default: string;
};

type TWiseReceiptProps = {
    show: boolean;
    onHide: () => void;
    id: number;
    refetchWiseReceiptsAfterUpdate: () => void;
};

const WiseReceipt: FC<TWiseReceiptProps> = ({
    show,
    onHide,
    id,
    refetchWiseReceiptsAfterUpdate,
}) => {
    const intl = useIntl();

    const { data } = useGetWiseReceiptQuery(id);
    const [updateWiseReceipt] = useUpdateWiseReceiptMutation();

    const { register, handleSubmit, setValue } =
        useForm<TEditWiseReceiptMethod>();

    const onSubmit = (data: TEditWiseReceiptMethod) => {
        updateWiseReceipt({
            data: { default: data.default === "true" ? true : false },
            id,
        }).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({ id: "somethingWentWrong" })
                    );
                }
            } else {
                onHide();
                setTimeout(() => {
                    refetchWiseReceiptsAfterUpdate();
                }, 3000);
            }
        });
    };

    useEffect(() => {
        if (data) {
            setValue("default", data.default ? "true" : "false");
        }
    }, [data, setValue]);

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="bankAccount" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column w-100 pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex mb-2">
                        <span className="me-2 bold-text">
                            <FormattedMessage id="accountNameWithDots" />
                        </span>
                        <span>{data?.full_name}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <span className="me-2 bold-text">
                            <FormattedMessage id="defaultAccountWithDots" />
                        </span>
                        <Form.Select
                            style={{ width: "110px" }}
                            {...register("default")}
                        >
                            <option value="true">
                                <FormattedMessage id="yes" />
                            </option>
                            <option value="false">
                                <FormattedMessage id="no" />
                            </option>
                        </Form.Select>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="dark-button">
                            <FormattedMessage id="save" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default WiseReceipt;
