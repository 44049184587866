// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-dashboard {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    background-color: #F3FAFF;
    height: 55px;
    box-shadow: 0px 0px 25px 5px rgb(66 147 208 / 25%);
    top: 0;
    left: 0;
}

.dashboard-catalog-button {
    background-color: transparent !important;
    color: #1C6FD1 !important;
    border: 1px solid rgba(28, 111, 209, 0.5) !important;
}

.dashboard-header-dwopdown-item {
    color: #1C6FD1 !important;
}

.catalog-dashboard-title-block {
    color: #1a4074;
    text-align: center;
    font-size: 1.6rem;
}

.dashboard-catalog-checkbox input {
    background-color: transparent;
    border: 2px solid rgb(255 250 250);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Dashboard/DashboardCatalog/DashboardCatalog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,kDAAkD;IAClD,MAAM;IACN,OAAO;AACX;;AAEA;IACI,wCAAwC;IACxC,yBAAyB;IACzB,oDAAoD;AACxD;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,kCAAkC;AACtC","sourcesContent":[".header-dashboard {\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: row;\n    background-color: #F3FAFF;\n    height: 55px;\n    box-shadow: 0px 0px 25px 5px rgb(66 147 208 / 25%);\n    top: 0;\n    left: 0;\n}\n\n.dashboard-catalog-button {\n    background-color: transparent !important;\n    color: #1C6FD1 !important;\n    border: 1px solid rgba(28, 111, 209, 0.5) !important;\n}\n\n.dashboard-header-dwopdown-item {\n    color: #1C6FD1 !important;\n}\n\n.catalog-dashboard-title-block {\n    color: #1a4074;\n    text-align: center;\n    font-size: 1.6rem;\n}\n\n.dashboard-catalog-checkbox input {\n    background-color: transparent;\n    border: 2px solid rgb(255 250 250);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
