// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-state__container {
	width: 100%;
	padding: 80px 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	background-color: #ffffff;
	border-radius: 10px;
}

.empty-state__icon {
	width: 90px;
}

.empty-state__title {
	font-size: 32px;
	font-weight: 40px;
	color: #00aacc;
	font-weight: 500;
	text-align: center;
}

.empty-state__button {
	min-width: 410px;
	height: 44px;
	padding: 0 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #4db8ff;
	transition: 0.3s;
	font-size: 16px;
	line-height: 20px;
	color: #ffffff;
}

.empty-state__button:hover {
	background-color: #1da1f2;
	transition: 0.3s;
}

.empty-state__container.modal-empty-state {
	padding: 30px 0;
}

.empty-state__container.modal-empty-state img {
	width: 70px;
}

.empty-state__container.modal-empty-state h6 {
	font-size: 24px;
	line-height: 30px;
	max-width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/EmptyState/EmptyState.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,kBAAkB;CAClB,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;CACnB,SAAS;CACT,yBAAyB;CACzB,mBAAmB;AACpB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,iBAAiB;CACjB,cAAc;CACd,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,YAAY;CACZ,eAAe;CACf,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,yBAAyB;CACzB,gBAAgB;CAChB,eAAe;CACf,iBAAiB;CACjB,cAAc;AACf;;AAEA;CACC,yBAAyB;CACzB,gBAAgB;AACjB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,iBAAiB;CACjB,gBAAgB;AACjB","sourcesContent":[".empty-state__container {\n\twidth: 100%;\n\tpadding: 80px 24px;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\tgap: 16px;\n\tbackground-color: #ffffff;\n\tborder-radius: 10px;\n}\n\n.empty-state__icon {\n\twidth: 90px;\n}\n\n.empty-state__title {\n\tfont-size: 32px;\n\tfont-weight: 40px;\n\tcolor: #00aacc;\n\tfont-weight: 500;\n\ttext-align: center;\n}\n\n.empty-state__button {\n\tmin-width: 410px;\n\theight: 44px;\n\tpadding: 0 16px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tbackground-color: #4db8ff;\n\ttransition: 0.3s;\n\tfont-size: 16px;\n\tline-height: 20px;\n\tcolor: #ffffff;\n}\n\n.empty-state__button:hover {\n\tbackground-color: #1da1f2;\n\ttransition: 0.3s;\n}\n\n.empty-state__container.modal-empty-state {\n\tpadding: 30px 0;\n}\n\n.empty-state__container.modal-empty-state img {\n\twidth: 70px;\n}\n\n.empty-state__container.modal-empty-state h6 {\n\tfont-size: 24px;\n\tline-height: 30px;\n\tmax-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
