import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
    useGetStripePaymentMethodQuery,
    useUpdateStripePaymentMethodMutation,
} from "../../../api/paymentsAPI";

export type TEditStripePaymentMethod = {
    default: string;
};

type TStripePaymentMethodProps = {
    show: boolean;
    onHide: () => void;
    id: number;
    refetchPaymentMethodsAfterUpdate: () => void;
};

const StripePaymentMethod: FC<TStripePaymentMethodProps> = ({
    show,
    onHide,
    id,
    refetchPaymentMethodsAfterUpdate,
}) => {
    const intl = useIntl();

    const [updateStripeMethod] = useUpdateStripePaymentMethodMutation();
    const { data } = useGetStripePaymentMethodQuery(id);

    const { register, handleSubmit, setValue } =
        useForm<TEditStripePaymentMethod>();

    const onSubmit = (data: TEditStripePaymentMethod) => {
        updateStripeMethod({
            data: { default: data.default === "true" ? true : false },
            id,
        }).then((res) => {
            if ("error" in res) {
                const status = (res.error as { status: number }).status;

                if (status !== 403) {
                    toast.error(
                        intl.formatMessage({ id: "somethingWentWrong" })
                    );
                }
            } else {
                onHide();
                setTimeout(() => {
                    refetchPaymentMethodsAfterUpdate();
                }, 3000);
            }
        });
    };

    useEffect(() => {
        if (data) {
            setValue("default", data.default ? "true" : "false");
        }
    }, [data, setValue]);

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="stripePaymentMethod" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column w-100 pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex mb-2">
                        <span className="me-2 bold-text">
                            <FormattedMessage id="stripeCardWithDots" />
                        </span>
                        <span>**** **** **** {data?.last4}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <span className="me-2 bold-text">
                            <FormattedMessage id="brandWithDots" />
                        </span>
                        <span>{data?.brand}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <span className="me-2 bold-text">
                            <FormattedMessage id="expirationMonthWithDots" />
                        </span>
                        <span>{data?.exp_month}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <span className="me-2 bold-text">
                            <FormattedMessage id="expirationYearWithDots" />
                        </span>
                        <span>{data?.exp_year}</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <span className="me-2 bold-text">
                            <FormattedMessage id="defaultCardWithDots" />
                        </span>
                        <Form.Select
                            style={{ width: "110px" }}
                            {...register("default")}
                        >
                            <option value="true">
                                <FormattedMessage id="yes" />
                            </option>
                            <option value="false">
                                <FormattedMessage id="no" />
                            </option>
                        </Form.Select>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="dark-button">
                            <FormattedMessage id="save" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default StripePaymentMethod;
