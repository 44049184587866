import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FC } from "react";

import { useCreateTaskValidationSchema } from "./validator";
import { useCreateJobMutation } from "../../../api/jobsAPI";

import "./CreateTask.css";

export type TCreateTaskSubmitData = {
    function_name: string;
    function_arguments: string | null;
    periodic_schedule: string;
    allow_retries: string | null;
    queue: string | null;
    is_scheduled_for_retry: string | null;
    retry_times: number | null;
    max_retries: number | null;
    retry_seconds_interval: number | null;
    disabled: boolean | null;
    is_one_off: boolean | null;
};

type TCreateTaskProps = {
    show: boolean;
    onHide: () => void;
};

const CreateTask: FC<TCreateTaskProps> = ({ show, onHide }) => {
    const intl = useIntl();
    const createTaskValidationSchema = useCreateTaskValidationSchema();

    const [createTask] = useCreateJobMutation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TCreateTaskSubmitData>({
        resolver: yupResolver(createTaskValidationSchema),
    });

    const onSubmit = (data: TCreateTaskSubmitData) => {
        const object = {
            ...data,
            function_arguments: data.function_arguments
                ? JSON.parse(data.function_arguments)
                : data.function_arguments,
        };
        createTask(object);
        onHide();
    };

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="createTask" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="functionName" />{" "}
                            <span className="red-color">*</span>
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({
                                id: "functionName",
                            })}`}
                            className="form-input-field"
                            type="string"
                            {...register("function_name")}
                        />
                        {errors.function_name && (
                            <p className="mb-0 form-field-error">
                                {errors.function_name.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="functionArguments" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({
                                id: "functionArguments",
                            })}`}
                            className="form-input-field"
                            as="textarea"
                            defaultValue=""
                            {...register("function_arguments")}
                        />
                        {errors.function_arguments && (
                            <p className="mb-0 form-field-error">
                                {errors.function_arguments.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="queue" />
                        </Form.Label>
                        <Form.Control
                            className="form-input-field"
                            type="string"
                            placeholder={`${intl.formatMessage({
                                id: "queue",
                            })}`}
                            {...register("queue")}
                        />
                        {errors.queue && (
                            <p className="mb-0 form-field-error">
                                {errors.queue.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="interval" />
                        </Form.Label>
                        <Form.Select
                            className="form-input-field"
                            {...register("periodic_schedule")}
                        >
                            <option value="* * * * *">
                                <FormattedMessage id="everyMinute" />
                            </option>
                            <option value="0 * * * *">
                                <FormattedMessage id="everyHour" />
                            </option>
                            <option value="0 */6 * * *">
                                <FormattedMessage id="every6Hours" />
                            </option>
                            <option value="0 1 * * *">
                                <FormattedMessage id="everyDay" />
                            </option>
                            <option value="0 0 */3 * *">
                                <FormattedMessage id="every3days" />
                            </option>
                            <option value="0 0 * * 0">
                                <FormattedMessage id="everyWeek" />
                            </option>
                            <option value="0 0 1 * *">
                                <FormattedMessage id="everyMonth" />
                            </option>
                            <option value="0 0 1 1 *">
                                <FormattedMessage id="everyYear" />
                            </option>
                        </Form.Select>
                        {errors.periodic_schedule && (
                            <p className="mb-0 form-field-error">
                                {errors.periodic_schedule.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="allowRetries" />
                        </Form.Label>
                        <Form.Select
                            defaultValue="false"
                            className="form-input-field"
                            {...register("allow_retries")}
                        >
                            <option value="true">
                                <FormattedMessage id="yes" />
                            </option>
                            <option value="false">
                                <FormattedMessage id="no" />
                            </option>
                        </Form.Select>
                        {errors.allow_retries && (
                            <p className="mb-0 form-field-error">
                                {errors.allow_retries.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="scheduledForRetry" />
                        </Form.Label>
                        <Form.Select
                            defaultValue="false"
                            className="form-input-field"
                            {...register("is_scheduled_for_retry")}
                        >
                            <option value="true">
                                <FormattedMessage id="yes" />
                            </option>
                            <option value="false">
                                <FormattedMessage id="no" />
                            </option>
                        </Form.Select>
                        {errors.is_scheduled_for_retry && (
                            <p className="mb-0 form-field-error">
                                {errors.is_scheduled_for_retry.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="retryTimes" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({
                                id: "retryTimes",
                            })}`}
                            className="form-input-field"
                            type="number"
                            {...register("retry_times")}
                        />
                        {errors.retry_times && (
                            <p className="mb-0 form-field-error">
                                {errors.retry_times.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="maximumRetries" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({
                                id: "maximumRetries",
                            })}`}
                            className="form-input-field"
                            type="number"
                            {...register("max_retries")}
                        />
                        {errors.max_retries && (
                            <p className="mb-0 form-field-error">
                                {errors.max_retries.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="retrySecondsInterval" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({
                                id: "retrySecondsInterval",
                            })}`}
                            className="form-input-field"
                            type="number"
                            {...register("retry_seconds_interval")}
                        />
                        {errors.retry_seconds_interval && (
                            <p className="mb-0 form-field-error">
                                {errors.retry_seconds_interval.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-2 d-flex">
                        <Form.Check type="checkbox" {...register("disabled")} />
                        <Form.Label
                            style={{ color: "#4a4b65" }}
                            className="ms-2 mb-0"
                        >
                            <FormattedMessage id="disabled" />
                        </Form.Label>
                    </Form.Group>
                    <Form.Group className="mt-1 d-flex">
                        <Form.Check
                            type="checkbox"
                            {...register("is_one_off")}
                        />
                        <Form.Label
                            style={{ color: "#4a4b65" }}
                            className="ms-2 mb-0"
                        >
                            <FormattedMessage id="runOnlyOnce" />{" "}
                            <span className="red-color">*</span>
                        </Form.Label>
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type="submit" className="dashboard-menu-button">
                            <FormattedMessage id="createTask" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateTask;
