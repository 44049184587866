import { FormattedMessage } from "react-intl";
import { FC, useRef, useState } from "react";
import { BsImageFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { Modal } from "react-bootstrap";

import { useUploadProductImagesMutation } from "../../../api/productsAPI";
import { TUploadedProductsResponse } from "../../../api/api.types";

type TFiles = {
	[key: string]: File[];
};

type TBulkUploadImages = {
	show: boolean;
	onHide: () => void;
	products: TUploadedProductsResponse[];
};

const BulkUploadImages: FC<TBulkUploadImages> = ({
	show,
	onHide,
	products,
}) => {
	const [uploadToProduct, setUploadToProduct] = useState<number | null>(null);
	const [files, setFiles] = useState<TFiles>({});

	const inputRef = useRef<HTMLInputElement>(null);

	const [uploadProductImages] = useUploadProductImagesMutation();

	const handleAttachImagesTo = (productId: number) => {
		setUploadToProduct(productId);
		inputRef.current?.click();
	};

	const handleRemoveImage = (productId: number, index: number) => {
		setFiles((prevFiles) => {
			const existingFiles = prevFiles[`${productId}`] || [];

			return {
				...prevFiles,
				[`${productId}`]: existingFiles.filter((_, i) => i !== index),
			};
		});
	};

	const onFilesAttach = (files: FileList | null) => {
		if (!files || !uploadToProduct) return;

		const uploadedFiles = Array.from(files);

		setFiles((prevFiles) => {
			const existingFiles = prevFiles[`${uploadToProduct}`] || [];

			return {
				...prevFiles,
				[`${uploadToProduct}`]: [...existingFiles, ...uploadedFiles],
			};
		});

		if (inputRef.current) {
			inputRef.current.value = "";
		}
	};

	const handleSaveAttachedImages = () => {
		const productIds = Object.keys(files).map((key) => parseInt(key));

		if (productIds.length === 0) {
			onHide();
			return;
		}

		productIds.forEach((productId) => {
			const formData = new FormData();

			const productFiles = files[`${productId}`];

			productFiles.forEach((file) => {
				formData.append("images", file);
			});

			uploadProductImages({ id: productId, data: formData });
		});

		onHide();
	};

	return (
		<Modal className="modal-600" centered show={show} onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="attachProductImages" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{ gap: "12px" }}
				className="pt-0 d-flex flex-column"
			>
				<p className="upload-orders__description">
					<FormattedMessage id="attachImagesToTheUploadedProducts" />
				</p>
				<div className="bulk-upload-images-wrapper">
					{products.map((product) => (
						<div
							className="bulk-upload-product-image-block"
							key={product.id}
						>
							<div className="bulk-upload-product-information-wrapper">
								<div className="bulk-upload-product-information">
									<p>{product.title}</p>
									<span>{product.sku}</span>
								</div>
								<button
									className="light-grey-button"
									onClick={() =>
										handleAttachImagesTo(product.id)
									}
									style={{ maxHeight: "40px" }}
								>
									<FormattedMessage id="attach" />
								</button>
							</div>
							<div
								className={`bulk-uploaded-product-attached-images ${files[`${product.id}`]?.length ? "attached" : ""}`}
							>
								{files[`${product.id}`]?.map((file, index) => (
									<div
										className="bulk-uploaded-product-attached-image-block"
										key={index}
									>
										<img
											src={URL.createObjectURL(file)}
											alt=""
										/>
										<div
											onClick={() =>
												handleRemoveImage(
													product.id,
													index
												)
											}
											className="bulk-uploaded-product-attached-image-remove"
										>
											<MdDelete
												size={26}
												color="#FFFFFF"
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
				<div className="d-flex justify-content-end w-100 mt-1">
					<button
						onClick={handleSaveAttachedImages}
						className="ice-button w-100"
					>
						<FormattedMessage id="save" />
					</button>
				</div>
				<input
					onChange={(e) => onFilesAttach(e.target.files)}
					className="d-none"
					ref={inputRef}
					type="file"
					multiple
					accept="image/*"
				/>
			</Modal.Body>
		</Modal>
	);
};

export default BulkUploadImages;
