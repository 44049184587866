import { FC } from "react"

type TIntroductionAnimationProps = {
    windowHeight: number,
    windowWidth: number
}

const IntroductionAnimation: FC<TIntroductionAnimationProps> = ({ windowHeight, windowWidth }) => {
    return (
        <div className="home__animation-wrapper">
            <svg width="100%" height="100%">
                <defs>
                    <linearGradient id="flyingGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style={{ stopColor: "#e7f4fb", stopOpacity: "1" }} />
                        <stop offset="30%" style={{ stopColor: "#4DB8FF", stopOpacity: "1" }} />
                        <stop offset="50%" style={{ stopColor: "#4DB8FF", stopOpacity: "1" }} />
                        <stop offset="80%" style={{ stopColor: "#4DB8FF", stopOpacity: "1" }} />
                        <stop offset="100%" style={{ stopColor: "#e7f4fb", stopOpacity: "1" }} />
                    </linearGradient>
                </defs>
                <path
                    id="introduction-flying-line-1"
                    d={`M0 ${windowHeight / 2} C${windowWidth / 4} -300, ${windowWidth / 2.5} ${windowHeight / 1}, ${windowWidth} ${windowHeight / 2}`}
                    stroke="url(#flyingGradient)"
                    fill="none"
                    strokeWidth="2"
                />
                <path
                    id="introduction-flying-line-2"
                    d={`M0 0 C${windowWidth / 7} ${windowHeight / 1.5}, ${windowWidth / 3} ${windowHeight / 1.7}, ${windowWidth / 4} ${windowHeight}`}
                    stroke="url(#flyingGradient)"
                    fill="none"
                    strokeWidth="2"
                />
                <path
                    id="introduction-flying-line-3"
                    d={`M${windowWidth} 0 C${windowWidth / 3} 700, ${windowWidth / 2} 600, ${windowWidth / 1.5} 1200`}
                    stroke="url(#flyingGradient)"
                    fill="none"
                    strokeWidth="2"
                />
                <path
                    id="introduction-flying-line-4"
                    d={`M0 ${windowHeight} C${windowWidth / 3} ${windowHeight / 1.1}, ${windowWidth / 2} ${windowHeight / 1.3}, ${windowWidth} ${windowHeight / 1.1}`}
                    stroke="url(#flyingGradient)"
                    fill="none"
                    strokeWidth="2"
                />
            </svg>
        </div>
    )
}

export default IntroductionAnimation