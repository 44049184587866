import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useState } from "react";

import useDetectSubdomainOrPublic from "../../../hooks/useDetectSubdomainOrPublic";

import BillingPaypalReceipts from "./Billing/BillingPaypalReceipts/BillingPaypalReceipts";
import AffiliateManagement from "./AffiliateManagement/AffiliateManagement";
import BillingReceipts from "./Billing/BillingReceipts/BillingReceipts";
import BillingPayouts from "./Billing/BillingPayouts/BillingPayouts";
import SystemSchedule from "./System/SystemSchedule/SystemSchedule";
import DashboardCatalog from "./DashboardCatalog/DashboardCatalog";
import SettingsTenants from "./SettingsTenants/SettingsTenants";
import LeftNav from "../../components/common/LeftNav/LeftNav";
import ProductDetails from "./ProductDetails/ProductDetails";
import Header from "../../components/layout/Header/Header";
import SettingsUsers from "./SettingsUsers/SettingsUsers";
import JobDetails from "./System/JobDetails/JobDetails";
import OrderDetails from "./OrderDetails/OrderDetails";
import TotalOrders from "./TotalOrders/TotalOrders";
import MyProducts from "./MyProducts/MyProducts";
import Orders from "./Orders/Orders";

import "./Dashboard.css";

const Dashboard = () => {
	const [expandNavigation, setExpandNavigation] = useState(true);
	const [isPublic] = useDetectSubdomainOrPublic();

	return (
		<div
			style={{
				gridTemplateColumns: `${expandNavigation ? "274px 1fr" : "88px 1fr"}`,
			}}
			className="dashboard-container"
		>
			<LeftNav
				expandNavigation={expandNavigation}
				setExpandNavigation={setExpandNavigation}
			/>
			<div className="dashboard-content">
				<Header />
				<Routes>
					<Route
						path="/dashboard/total-orders/"
						element={<TotalOrders />}
					></Route>
					<Route
						path="/dashboard/settings-users/"
						element={<SettingsUsers />}
					></Route>
					<Route
						path="/dashboard/catalog/"
						element={<DashboardCatalog />}
					></Route>
					<Route
						path="/dashboard/my-products/"
						element={<MyProducts />}
					></Route>
					<Route
						path="/dashboard/my-products/:id"
						element={<ProductDetails />}
					></Route>
					<Route
						path="/dashboard/orders/"
						element={<Orders />}
					></Route>
					<Route
						path="/dashboard/billing-paypal-receipts/"
						element={<BillingPaypalReceipts />}
					></Route>
					<Route
						path="*"
						element={<Navigate to="/dashboard/total-orders/" />}
					></Route>
					<Route
						path="/integrations/redirect-amazon-login/"
						element={<SettingsUsers />}
					></Route>
					<Route
						path="/dashboard/order-details/:id"
						element={<OrderDetails />}
					></Route>
					{isPublic ? (
						<>
							<Route
								path="/dashboard/system-schedule/"
								element={<SystemSchedule />}
							></Route>
							<Route
								path="/dashboard/system-schedule/:id"
								element={<JobDetails />}
							></Route>
							<Route
								path="/dashboard/settings-tenants/"
								element={<SettingsTenants />}
							></Route>
							<Route
								path="/dashboard/affiliate-management/"
								element={<AffiliateManagement />}
							></Route>
						</>
					) : (
						<>
							<Route
								path="/dashboard/billing-payouts/"
								element={<BillingPayouts />}
							></Route>
							<Route
								path="/dashboard/billing-receipts/"
								element={<BillingReceipts />}
							></Route>
						</>
					)}
				</Routes>
			</div>
			<ToastContainer position="bottom-right" />
		</div>
	);
};

export default Dashboard;
