import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { FC } from "react";

import "./PagePagination.css";

type TPagePaginationProps = {
	pages: string[];
	activePage: string;
	handlePage: (page: string) => void;
};

const PagePagination: FC<TPagePaginationProps> = ({
	pages,
	activePage,
	handlePage,
}) => {
	const isFirstPage = activePage === "1";
	const isLastPage = activePage === String(pages.length || 1);

	return (
		<div className="pagination-container">
			<div className="custom-pagination">
				<button
					className={`page-pagination-button ${isFirstPage ? "disabled" : ""}`}
					onClick={() => handlePage(String(Number(activePage) - 1))}
					disabled={isFirstPage}
				>
					<AiOutlineLeft size={20} />
				</button>
				<span className="pagination-info">
					<span className="active-page">{activePage || 1}</span>
					<FormattedMessage id="of" />
					<span className="total-pages">{pages.length || 1}</span>
				</span>
				<button
					className={`page-pagination-button ${isLastPage ? "disabled" : ""}`}
					onClick={() => handlePage(String(Number(activePage) + 1))}
					disabled={isLastPage}
				>
					<AiOutlineRight size={20} />
				</button>
			</div>
		</div>
	);
};

export default PagePagination;
