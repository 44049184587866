import { AiOutlineFieldNumber, AiOutlineNumber } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

import { useGetWiseTransfersQuery } from "../../../../../api/paymentsAPI";

import usePagination from "../../../../../hooks/usePagination";
import useFilters from "../../../../../hooks/useFilters";

import PagePagination from "../../../../components/common/Pagination/PagePagination";
import EmptyState from "../../../../components/common/EmptyState/EmptyState";
import Loading from "../../../../components/common/Loading/Loading";

const BillingPayouts = () => {
	const { pageSize } = useFilters();

	const [activePage, pages, handlePage, handlePagesCount] = usePagination(
		"page",
		pageSize
	);

	const {
		data: wiseTransfers,
		isLoading,
		isFetching,
	} = useGetWiseTransfersQuery({
		activePage,
		pageSize,
	});

	useEffect(() => {
		if (wiseTransfers) {
			handlePagesCount(wiseTransfers.count);
		}
	}, [wiseTransfers, handlePagesCount]);

	return (
		<div className="billing-content-wrapper">
			<div
				className="billing_content"
				data-testid="billing-payouts-table"
			>
				<div className="billing__page-filters">
					<h5 className="billing__page-title">
						<FormattedMessage id="payouts" />
					</h5>
					<div className="billing__page-filters-actions">
						{wiseTransfers?.results &&
							wiseTransfers?.results.length > 0 && (
								<PagePagination
									pages={pages}
									activePage={activePage}
									handlePage={handlePage}
								/>
							)}
					</div>
				</div>
				<Loading isLoading={isLoading || isFetching}>
					{wiseTransfers && wiseTransfers.results.length === 0 ? (
						<EmptyState message="noPayoutsFound" />
					) : (
						<div className="default-table">
							<table className="table">
								<thead className="thead-light">
									<tr>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<AiOutlineNumber size={21} />
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<AiOutlineFieldNumber
													className="me-1"
													size={24}
												/>{" "}
												Wise ID
											</div>
										</th>
										<th
											className="text-center vertical-align-middle"
											scope="col"
										>
											<div className="d-flex justify-content-center align-items-center">
												<FormattedMessage id="receipt" />
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									{wiseTransfers &&
										wiseTransfers.results.length > 0 &&
										wiseTransfers.results.map(
											(transfer) => (
												<tr
													key={transfer.id}
													data-testid="table-payouts-row"
												>
													<td className="text-center">
														{transfer.id}
													</td>
													<td className="text-center">
														{transfer.wise_id}
													</td>
													<td className="text-center">
														{transfer.receipt_url ? (
															<a
																target="_blank"
																rel="noreferrer"
																href={
																	transfer.receipt_url
																}
															>
																<button
																	className="ice-button"
																	style={{
																		minWidth:
																			"160px",
																	}}
																	data-testid="view-receipt-button"
																>
																	<FormattedMessage id="viewReceipt" />
																</button>
															</a>
														) : (
															<span>-</span>
														)}
													</td>
												</tr>
											)
										)}
								</tbody>
							</table>
						</div>
					)}
				</Loading>
			</div>
		</div>
	);
};

export default BillingPayouts;
