import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { FC, useEffect, useState } from "react";

import { useUpdateFulfillmentMutation } from "../../../api/ordersAPI";
import { TOrderFulfillment } from "../../../api/api.types";

type EditFulfillmentTrackingNumberProps = {
	onHide: () => void;
	fulfillment: TOrderFulfillment;
};

const EditFulfillmentTrackingNumber: FC<EditFulfillmentTrackingNumberProps> = ({
	onHide,
	fulfillment,
}) => {
	const [trackingNumber, setTrackingNumber] = useState<string>("");

	const intl = useIntl();

	const [updateFulfillmentTrackingNumber] = useUpdateFulfillmentMutation();

	const handleSave = () => {
		if (!trackingNumber) {
			return;
		}

		updateFulfillmentTrackingNumber({
			id: fulfillment.id,
			data: {
				tracking_number: trackingNumber,
			},
		}).then((res) => {
			if ("error" in res) {
				const error = (res.error as { data: { error: string[] } })?.data
					?.error[0];

				toast.error(error);
			} else {
				toast.success(
					intl.formatMessage({ id: "trackingNumberUpdated" })
				);
			}
			onHide();
		});
	};

	useEffect(() => {
		if (fulfillment.tracking_number) {
			setTrackingNumber(fulfillment.tracking_number);
		}
	}, [fulfillment.tracking_number]);

	return (
		<Modal className="modal-500" centered show onHide={onHide}>
			<Modal.Header className="modal__header" closeButton>
				<Modal.Title className="modal__title">
					<FormattedMessage id="editFulfillmentTrackingNumber" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0">
				<Form.Group className="mb-2">
					<Form.Label className="default-input-label">
						<FormattedMessage id="trackingNumber" />
					</Form.Label>
					<Form.Control
						onChange={(e) => setTrackingNumber(e.target.value)}
						className="default-input"
						value={trackingNumber}
						type="string"
						placeholder={`${intl.formatMessage({
							id: "trackingNumber",
						})}`}
					/>
				</Form.Group>
				<div className="d-flex justify-content-end mt-3">
					<button
						onClick={handleSave}
						type="button"
						className="ice-button w-100"
					>
						<FormattedMessage id="updateTrackingNumber" />
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default EditFulfillmentTrackingNumber;
