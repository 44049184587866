// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-catalog-product-description {
    border-top: 2px solid rgba(116, 181, 175, 0.21);
    margin-top: 10px;
    padding-top: 5px;
}

.product-variants-choose-block {
    display: flex;
    overflow-x: auto;
    margin-top: 5px;
}

.product-variant-block {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    margin-right: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgba(187, 204, 201, 0.656);
    overflow: hidden;
}

.product-variant-block:hover,
.product-variant-block-active {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    margin-right: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgb(45, 101, 166);
    overflow: hidden;
}

.product-variant-block-active img,
.product-variant-block img {
    width: 50px;
    height: 50px;
}

.product-no-reviews-block {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/DashboardCatalogProduct/DashboardCatalogProduct.css"],"names":[],"mappings":"AAAA;IACI,+CAA+C;IAC/C,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,0CAA0C;IAC1C,kBAAkB;IAClB,eAAe;IACf,4CAA4C;IAC5C,gBAAgB;AACpB;;AAEA;;IAEI,eAAe;IACf,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,0CAA0C;IAC1C,kBAAkB;IAClB,eAAe;IACf,mCAAmC;IACnC,gBAAgB;AACpB;;AAEA;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".dashboard-catalog-product-description {\n    border-top: 2px solid rgba(116, 181, 175, 0.21);\n    margin-top: 10px;\n    padding-top: 5px;\n}\n\n.product-variants-choose-block {\n    display: flex;\n    overflow-x: auto;\n    margin-top: 5px;\n}\n\n.product-variant-block {\n    min-width: 50px;\n    max-width: 50px;\n    height: 50px;\n    margin-right: 3px;\n    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;\n    border-radius: 5px;\n    cursor: pointer;\n    border: 2px solid rgba(187, 204, 201, 0.656);\n    overflow: hidden;\n}\n\n.product-variant-block:hover,\n.product-variant-block-active {\n    min-width: 50px;\n    max-width: 50px;\n    height: 50px;\n    margin-right: 3px;\n    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;\n    border-radius: 5px;\n    cursor: pointer;\n    border: 2px solid rgb(45, 101, 166);\n    overflow: hidden;\n}\n\n.product-variant-block-active img,\n.product-variant-block img {\n    width: 50px;\n    height: 50px;\n}\n\n.product-no-reviews-block {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 1rem;\n    height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
