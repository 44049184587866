import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGetAmazonConfirmLinkMutation } from '../../../../api/integrationsAPI'

import Home from '../Home/Home'

const AmazonHome = () => {
    const [searchParams] = useSearchParams()

    const [getAmazonConfirmLink, { data }] = useGetAmazonConfirmLinkMutation()

    useEffect(() => {
        const spapi_oauth_code = searchParams.get('spapi_oauth_code')
        const state = searchParams.get('state')
        const selling_partner_id = searchParams.get('selling_partner_id')

        if (spapi_oauth_code && state && selling_partner_id && !data) {
            const object = {
                spapi_oauth_code,
                state,
                selling_partner_id,
            }
            getAmazonConfirmLink(object)
        } else if (spapi_oauth_code && state && selling_partner_id && data) {
            window.location.href = `${data.link}/integrations/redirect-amazon-login/?spapi_oauth_code=${spapi_oauth_code}&state=${state}&selling_partner_id=${selling_partner_id}`
        }
    }, [searchParams, getAmazonConfirmLink, data])

    return (
        <Home />
    )
}

export default AmazonHome