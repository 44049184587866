import { useNavigate, useParams } from "react-router-dom";
import { CiCircleCheck } from 'react-icons/ci'
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";

import { useVerifyEmailMutation } from "../../../../../../api/authAPI";
import { LOGIN_PAGE_URL } from "../../../../../../helpers/constants";

import Loader from "../../../../../components/common/Loader/Loader";

import './VerifyEmail.css'

const VerifyEmail = () => {
    const intl = useIntl()
    const { id } = useParams();
    const navigate = useNavigate()

    const [verifyEmail, { isLoading, isSuccess }] = useVerifyEmailMutation()

    const redirectToLogin = () => {
        navigate(LOGIN_PAGE_URL)
    }

    useEffect(() => {
        if (id) {
            verifyEmail(id)
        }
    }, [id, verifyEmail])

    if (isLoading) {
        return <Loader text={`${intl.formatMessage({ id: "confirmingEmailThreeDots" })}`} />
    } else if (isSuccess) {
        return (
            <div
                style={{ backgroundColor: 'var(--background)' }}
                className="min-block-height d-flex flex-column align-items-center justify-content-center"
            >
                <CiCircleCheck className="mb-3" fill='rgb(18, 63, 115)' size={80} />
                <span className="email-verified-text">
                    <FormattedMessage id="yourEmailSuccessfullyVerified" />
                </span>
                <button className="dark-button" onClick={redirectToLogin}>
                    <FormattedMessage id="goToLoginPage" />
                </button>
            </div>
        )
    }

    return <Loader />
}

export default VerifyEmail