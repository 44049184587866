import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { useCompleteShopifyIntegrationMutation } from "../../../../api/integrationsAPI";

import Home from "../Home/Home";

const ShopifyHome = () => {
	const [searchParams] = useSearchParams();

	const [completeShopifyIntegration, { data: shopifyLink }] =
		useCompleteShopifyIntegrationMutation();

	useEffect(() => {
		if (shopifyLink) {
			window.location.href = `${shopifyLink}`;
		}
	}, [shopifyLink]);

	useEffect(() => {
		const code = searchParams.get("code");
		const hmac = searchParams.get("hmac");
		const host = searchParams.get("host");
		const shop = searchParams.get("shop");
		const state = searchParams.get("state");
		const timestamp = searchParams.get("timestamp");

		if (code && hmac && host && shop && state && timestamp) {
			const object = {
				code,
				hmac,
				host,
				shop,
				state,
				timestamp,
			};
			completeShopifyIntegration(object);
		} else if (!code && hmac && host && shop && !state && timestamp) {
			const object = {
				code: "",
				hmac,
				host,
				shop,
				state: "",
				timestamp,
			};
			completeShopifyIntegration(object);
		}
	}, [searchParams, completeShopifyIntegration]);

	return <Home />;
};

export default ShopifyHome;
