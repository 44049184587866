import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC, useEffect } from "react";

import { useModifyProductAutoUpdateMutation } from "../../../api/productsAPI";
import { TProductVariants } from "../../../api/api.types";

export type TModifyAutoUpdateSubmitData = {
    disallow_auto_update_price: boolean | string;
    disallow_auto_update_stock: boolean | string;
};

type TModifyAutoUpdate = {
    show: boolean;
    onHide: () => void;
    product: TProductVariants | undefined;
};

const ModifyAutoUpdate: FC<TModifyAutoUpdate> = ({ show, onHide, product }) => {
    const intl = useIntl();

    const [modifyAuto] = useModifyProductAutoUpdateMutation();

    const { register, handleSubmit, setValue } =
        useForm<TModifyAutoUpdateSubmitData>();

    const onSubmit = (data: TModifyAutoUpdateSubmitData) => {
        if (product) {
            const object = {
                data: {
                    disallow_auto_update_price:
                        data.disallow_auto_update_price === "true"
                            ? true
                            : false,
                    disallow_auto_update_stock:
                        data.disallow_auto_update_stock === "true"
                            ? true
                            : false,
                },
                id: product?.id,
            };

            modifyAuto(object).then((res) => {
                if ("error" in res) {
                    const status = (res.error as { status: number }).status;

                    if (status !== 403) {
                        toast.error(
                            intl.formatMessage({ id: "somethingWentWrong" })
                        );
                    }
                } else {
                    onHide();
                }
            });
        }
    };

    useEffect(() => {
        if (product) {
            setValue(
                "disallow_auto_update_price",
                product?.disallow_auto_update_price ? "true" : "false"
            );
            setValue(
                "disallow_auto_update_stock",
                product?.disallow_auto_update_stock ? "true" : "false"
            );
        }
    }, [product, setValue]);

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="modifyAutoUpdate" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-2">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="disallowAutoUpdatePrice" />
                        </Form.Label>
                        <Form.Select
                            className="form-input-field"
                            {...register("disallow_auto_update_price")}
                        >
                            <option value="true">
                                <FormattedMessage id="yes" />
                            </option>
                            <option value="false">
                                <FormattedMessage id="no" />
                            </option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="Disallow auto update stock" />
                        </Form.Label>
                        <Form.Select
                            className="form-input-field"
                            {...register("disallow_auto_update_stock")}
                        >
                            <option value="true">
                                <FormattedMessage id="yes" />
                            </option>
                            <option value="false">
                                <FormattedMessage id="no" />
                            </option>
                        </Form.Select>
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button type="submit" className="dashboard-menu-button">
                            <FormattedMessage id="modifyAutoUpdate" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModifyAutoUpdate;
