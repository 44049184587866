import { FormattedMessage } from "react-intl";
import { FC, useState } from "react";

import { TUser } from "../../../../redux/reducers/auth/authTypes";

import ChangePassword from "../../../modals/ChangePassword/ChangePassword";
import UpdateProfile from "../../../modals/UpdateProfile/UpdateProfile";

type TSettingsAccountBlockProps = {
	user: TUser | null;
};

const SettingsAccountBlock: FC<TSettingsAccountBlockProps> = ({ user }) => {
	const [changePasswordModal, setChangePasswordModal] = useState(false);
	const [updateProfileModal, setUpdateProfileModal] = useState(false);

	return (
		<div className="settings-users__block">
			<h5 className="settings-users__block-title">
				<FormattedMessage id="account" />
			</h5>
			<div className="settings-users__block-body">
				<div className="setting-users__block-info-wrapper">
					<div className="settings-users__block-info-row">
						<FormattedMessage id="emailWithDots" />{" "}
						<span className="settings-users__block-info-row-value">
							{user?.email}
						</span>
					</div>
					<div
						data-testid="user-name-row"
						className="settings-users__block-info-row"
					>
						<FormattedMessage id="nameWithDots" />{" "}
						<span
							data-testid="user-name"
							className="settings-users__block-info-row-value"
						>
							{user?.first_name} {user?.last_name}
						</span>
					</div>
					<div className="settings-users__block-info-row">
						<FormattedMessage id="userRoleWithDots" />{" "}
						<span className="settings-users__block-info-row-value">
							{user?.is_staff ? (
								<FormattedMessage id="admin" />
							) : (
								<FormattedMessage id="user" />
							)}
						</span>
					</div>
				</div>
				<div className="d-flex justify-content-between">
					<button
						data-testid="change-password-button"
						type="button"
						onClick={() => setChangePasswordModal(true)}
						className="ice-button"
					>
						<FormattedMessage id="changePassword" />
					</button>
					<button
						data-testid="update-profile-button"
						onClick={() => setUpdateProfileModal(true)}
						className="ice-button"
						type="button"
					>
						<FormattedMessage id="updateProfile" />
					</button>
				</div>
			</div>
			{changePasswordModal && (
				<ChangePassword
					show={changePasswordModal}
					onHide={() => setChangePasswordModal(false)}
				/>
			)}
			{updateProfileModal && (
				<UpdateProfile
					show={updateProfileModal}
					onHide={() => setUpdateProfileModal(false)}
				/>
			)}
		</div>
	);
};

export default SettingsAccountBlock;
