import { createApi } from "@reduxjs/toolkit/query/react";

import { TGetProductsResponse, TProduct, TProductNoteSubmitData, TProductVariants, TUploadedProductsResponse } from "./api.types";
import { baseQueryWithReAuth } from "./apiConfig";
import { TModifyAutoUpdateSubmitData } from "../app/modals/ModifyAutoUpdate/ModifyAutoUpdate";
import { TUpdateProductAmountSubmitData } from "../app/modals/UpdateProductAmount/UpdateProductAmount";
import { TUpdateProductPriceSubmitData } from "../app/modals/UpdateProductPrice/UpdateProductPrice";

export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Products', 'ProductVariant', 'ProductsForCreation'],
    endpoints: builder => ({
        getProducts: builder.query<TGetProductsResponse, {
            activePage: string,
            search: string,
            pageSize: string,
            statusFilters: { in_review: string, is_public: string },
            requiredAction: boolean
        }>({
            query: ({ activePage, search, pageSize, statusFilters, requiredAction }) => {
                return {
                    url: `products/?expand=rank,variants,variants.amazon_product_variant,variants.prices,variants.prices.currency,variants.inventories,variants.images,variants.reviews,variants.properties&page=${activePage}${search ? `&search=${search}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${statusFilters ? `&in_review=${statusFilters.in_review}&is_public=${statusFilters.is_public}` : ''}${requiredAction ? '&required_action=true' : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['Products']
        }),
        getPublicProducts: builder.query<TGetProductsResponse, { activePage: string, searchFilter: string, pageSize: string, categoryFilter: string }>({
            query: ({ activePage, searchFilter, pageSize, categoryFilter }) => {
                return {
                    url: `products/public/?expand=rank,variants,variants.prices,variants.prices.currency,variants.inventories,variants.images,variants.reviews,variants.properties&page=${activePage}${searchFilter ? `&search=${searchFilter}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${categoryFilter ? `&category_id=${categoryFilter}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['Products']
        }),
        getProductVariant: builder.query<TProductVariants, number>({
            query: (id) => {
                return {
                    url: `products/variant/${id}/?expand=amazon_product_variant,off_amazon_product_variant,prices,inventories,images,reviews,properties,prices.currency,categories`,
                    method: 'GET',
                }
            },
            providesTags: ['ProductVariant']
        }),
        makeProductPublic: builder.mutation<void, { ids: number[], select_all: boolean, product_status: string | null }>({
            query: (data) => {
                return {
                    url: `products/make_public/${data.product_status ? `?product_status=${data.product_status}` : ''}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Products']
        }),
        makeProductPrivate: builder.mutation<void, { ids: number[], select_all: boolean, product_status: string | null }>({
            query: (data) => {
                return {
                    url: `products/make_private/${data.product_status ? `?product_status=${data.product_status}` : ''}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Products']
        }),
        getPublicProductById: builder.query<TProduct, number>({
            query: (id) => {
                return {
                    url: `products/public/${id}/?expand=rank,variants,variants.prices,variants.prices.currency,variants.inventories,variants.images,variants.reviews,variants.properties`
                }
            }
        }),
        cancelProductReview: builder.mutation<void, { ids: number[], select_all: boolean, product_status: string | null }>({
            query: (data) => {
                return {
                    url: `products/cancel_review/${data.product_status ? `?product_status=${data.product_status}` : ''}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Products']
        }),
        exportProducts: builder.mutation<{ created: boolean }, { ids_list: number[], integration_id: number, override_existing_status: boolean }>({
            query: (data) => {
                return {
                    url: `coherence/integrations/shopify/export_products/`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        addProductNote: builder.mutation<void, TProductNoteSubmitData>({
            query: (data) => {
                return {
                    url: `products/notes/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['ProductVariant']
        }),
        modifyProductAutoUpdate: builder.mutation<void, { data: TModifyAutoUpdateSubmitData, id: number }>({
            query: ({ data, id }) => {
                return {
                    url: `/products/modify_auto_update/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['ProductVariant']
        }),
        updateProductAmount: builder.mutation<void, { id: number, data: TUpdateProductAmountSubmitData }>({
            query: ({ id, data }) => {
                return {
                    url: `products/update_amount/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['ProductVariant', 'Products']
        }),
        updateProductPrice: builder.mutation<void, { id: number, data: TUpdateProductPriceSubmitData }>({
            query: ({ id, data }) => {
                return {
                    url: `products/update_price/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['ProductVariant']
        }),
        deleteProducts: builder.mutation<void, { ids_list: number[] }>({
            query: (data) => {
                return {
                    url: `products/remove/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Products']
        }),
        getCsvFile: builder.mutation<string, void>({
            query: () => {
                return {
                    url: `products/csv/`,
                    method: 'GET',
                    responseHandler: (res) => res.text(),
                }
            }
        }),
        importCsvFile: builder.mutation<{ created: boolean }, FormData>({
            query: (data) => {
                return {
                    url: `products/csv/`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Products']
        }),
        downloadBulkUploadTemplate: builder.mutation<BlobPart, void>({
            query: () => {
                return {
                    url: `products/manual-import/`,
                    method: 'GET',
                    responseHandler: (res) => res.blob(),
                    responseType: 'blob',
                }
            }
        }),
        productsBulkUpload: builder.mutation<TUploadedProductsResponse[], FormData>({
            query: (data) => {
                return {
                    url: `products/manual-import/`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['Products']
        }),
        uploadProductImages: builder.mutation<void, { id: number, data: FormData }>({
            query: ({ id, data }) => {
                return {
                    url: `products/upload-images/${id}/`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['Products', 'ProductVariant']
        }),
        getOffAmazonProducts: builder.query<TGetProductsResponse, { integrationId: number }>({
            query: ({ integrationId }) => {
                return {
                    url: `products/for-orders-creation/?integration_id=${integrationId}&expand=variants,variants.amazon_product_variant,variants.images&page_size=1000&page=1`,
                    method: 'GET',
                }
            },
            providesTags: ['ProductsForCreation']
        }),
        assignIntegration: builder.mutation<void, { ids: number[], integration_id: number }>({
            query: (data) => {
                return {
                    url: `products/assign-integration/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['Products', 'ProductsForCreation']
        })
    })
})

export const {
    useGetProductsQuery,
    useGetPublicProductsQuery,
    useGetProductVariantQuery,
    useMakeProductPublicMutation,
    useMakeProductPrivateMutation,
    useGetPublicProductByIdQuery,
    useCancelProductReviewMutation,
    useExportProductsMutation,
    useAddProductNoteMutation,
    useModifyProductAutoUpdateMutation,
    useUpdateProductAmountMutation,
    useUpdateProductPriceMutation,
    useDeleteProductsMutation,
    useGetCsvFileMutation,
    useImportCsvFileMutation,
    useDownloadBulkUploadTemplateMutation,
    useProductsBulkUploadMutation,
    useUploadProductImagesMutation,
    useGetOffAmazonProductsQuery,
    useAssignIntegrationMutation
} = productsApi