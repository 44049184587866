import { createApi } from "@reduxjs/toolkit/query/react";

import {
    TLoginResponse,
    TGetTenantsResponse,
    TUser,
    RegisterUserResponse,
    TResetPasswordResponse,
    TResetPasswordConfirmRequest
} from "../redux/reducers/auth/authTypes";
import { TSetInvitedUserPasswordSubmitData } from "../app/modals/SetInvitedUserPassword/SetInvitedUserPassword";
import { TRegisterFirstStepForm } from "../app/pages/LendingPage/Auth/Register/RegisterFirstStep/RegisterFirstStep";
import { TResetPasswordForm } from "../app/pages/LendingPage/Auth/ResetPassword/ResetPassword";
import { TGetAcceptCheckResponse } from "./api.types";
import { baseQueryWithReAuth } from "./apiConfig";

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: builder => ({
        login: builder.mutation<TLoginResponse, { email: string, password: string }>({
            query: ({ email, password }) => {
                return {
                    url: `users/login/`,
                    method: 'POST',
                    body: { email, password }
                };
            },
        }),
        getTenantsList: builder.mutation<TGetTenantsResponse, string>({
            query: (token) => {
                return {
                    url: `users/tenants/`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            }
        }),
        getUser: builder.mutation<TUser, void>({
            query: () => {
                return {
                    url: `users/user/`,
                    method: 'GET',
                }
            },
        }),
        logout: builder.mutation<void, void>({
            query: () => {
                return {
                    url: `users/logout/`,
                    method: 'POST',
                }
            }
        }),
        registerUser: builder.mutation<RegisterUserResponse, TRegisterFirstStepForm>({
            query: (data) => {
                return {
                    url: `users/registration/`,
                    method: 'POST',
                    body: data
                }
            }
        }),
        verifyEmail: builder.mutation<void, string>({
            query: (key) => {
                return {
                    url: `users/registration/verify-email/`,
                    method: 'POST',
                    body: { key }
                }
            }
        }),
        resetPassword: builder.mutation<TResetPasswordResponse, TResetPasswordForm>({
            query: (data) => {
                return {
                    url: `users/password/reset/`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        resetPasswordConfirm: builder.mutation<TResetPasswordResponse, TResetPasswordConfirmRequest>({
            query: (data) => {
                return {
                    url: `users/password/reset/confirm/`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        checkAcceptAsNewCode: builder.query<TGetAcceptCheckResponse, string>({
            query: (key) => {
                return {
                    url: `/users/tenant_users/invite/accept_as_new/${key}/`,
                    method: 'GET',
                }
            }
        }),
        acceptUserAsNew: builder.mutation<void, { data: TSetInvitedUserPasswordSubmitData, key: string }>({
            query: ({ data, key }) => {
                return {
                    url: `/users/tenant_users/invite/accept_as_new/${key}/`,
                    method: 'PATCH',
                    body: data,
                }
            }
        }),
        checkAcceptCodeAsExisting: builder.query<TGetAcceptCheckResponse, string>({
            query: (key) => {
                return {
                    url: `/users/tenant_users/invite/accept_as_existing/${key}/`,
                    method: 'GET',
                }
            }
        }),
        acceptUserAsExisting: builder.mutation<void, string>({
            query: (key) => {
                return {
                    url: `/users/tenant_users/invite/accept_as_existing/${key}/`,
                    method: 'PATCH',
                    body: {},
                }
            }
        }),
        checkTenantAccess: builder.mutation<TGetTenantsResponse, { token: string, subdomain: string }>({
            query: ({ token, subdomain }) => {
                return {
                    url: `users/tenants/?subdomain=${subdomain}`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            }
        }),
        updateUser: builder.mutation<TUser, Partial<TUser>>({
            query: (data) => {
                return {
                    url: `users/user/`,
                    method: 'PATCH',
                    body: data
                }
            }
        })
    })
})

export const {
    useLoginMutation,
    useGetTenantsListMutation,
    useGetUserMutation,
    useLogoutMutation,
    useRegisterUserMutation,
    useVerifyEmailMutation,
    useResetPasswordMutation,
    useResetPasswordConfirmMutation,
    useCheckAcceptAsNewCodeQuery,
    useAcceptUserAsNewMutation,
    useCheckAcceptCodeAsExistingQuery,
    useAcceptUserAsExistingMutation,
    useCheckTenantAccessMutation,
    useUpdateUserMutation
} = authApi