import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FC } from "react";

import useHandleCustomRtkQueryError from "../../../hooks/useHandleCustomRtkQueryError";
import { useAddNewUserValidationSchema } from "./useAddNewUserValidationSchema";
import { useCreateUserMutation } from "../../../api/settingsAPI";

type TAddNewUserProps = {
    show: boolean;
    onHide: () => void;
};

export type TAddNewUserSubmitData = {
    first_name: string;
    last_name: string;
    email: string;
    role: "OWNER" | "STAFF";
};

const AddNewUser: FC<TAddNewUserProps> = ({ show, onHide }) => {
    const intl = useIntl();

    const [customError, setCustomError, handleCustomError] =
        useHandleCustomRtkQueryError();
    const addNewUserValidationSchema = useAddNewUserValidationSchema();

    const [addNewUser] = useCreateUserMutation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TAddNewUserSubmitData>({
        resolver: yupResolver(addNewUserValidationSchema),
    });

    const onSubmit = (data: TAddNewUserSubmitData) => {
        addNewUser(data).then((response) => {
            if ("error" in response) {
                handleCustomError(response.error);
            } else {
                setCustomError(null);
                onHide();
                toast.success(
                    intl.formatMessage({ id: "newUserAddedSuccessfully" })
                );
            }
        });
    };

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>
                    <FormattedMessage id="addNewUserCamelCase" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="firstName" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({
                                id: "firstName",
                            })}`}
                            className="form-input-field"
                            type="text"
                            {...register("first_name")}
                        />
                        {errors.first_name && (
                            <p className="mb-0 form-field-error">
                                {errors.first_name.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="lastName" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({
                                id: "lastName",
                            })}`}
                            className="form-input-field"
                            type="text"
                            {...register("last_name")}
                        />
                        {errors.last_name && (
                            <p className="mb-0 form-field-error">
                                {errors.last_name.message}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="email" />
                        </Form.Label>
                        <Form.Control
                            placeholder={`${intl.formatMessage({
                                id: "email",
                            })}`}
                            className="form-input-field"
                            type="text"
                            {...register("email")}
                        />
                        {(errors.email ||
                            customError ===
                                "A user with that email already exists.") && (
                            <p className="mb-0 form-field-error">
                                {errors.email ? (
                                    errors.email.message
                                ) : (
                                    <FormattedMessage id="aUserWithThatEmailAlreadyExists" />
                                )}
                            </p>
                        )}
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className="form-input-label">
                            <FormattedMessage id="userRole" />
                        </Form.Label>
                        <Form.Select
                            defaultValue="STAFF"
                            className="form-input-field"
                            {...register("role")}
                        >
                            <option>
                                <FormattedMessage id="selectUserRole" />
                            </option>
                            <option value="OWNER">
                                <FormattedMessage id="owner" />
                            </option>
                            <option value="STAFF">
                                <FormattedMessage id="staff" />
                            </option>
                        </Form.Select>
                        {errors.role && (
                            <p className="mb-0 form-field-error">
                                {errors.role.message}
                            </p>
                        )}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-3">
                        <button
                            type="submit"
                            className="dashboard-menu-button margin-top"
                        >
                            <FormattedMessage id="add" />
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddNewUser;
