import { FormattedMessage } from 'react-intl'

import emailVerification from '../../../../../assets/images/email_verification.png'
import SignIn from '../../../../../assets/images/sing-in.png'

import './RegisterPending.css'

const RegisterPending = () => {
    return (
        <div className='reset-password-wrapper'>
            <div className='reset-password-content'>
                <div className="register-pending-wrapper">
                    <h2 className="mb-2">
                        <FormattedMessage id="registration" />
                    </h2>
                    <div className="login-wrap">
                        <div>
                            <label className='mb-1'>
                                <FormattedMessage id="pleaseCheckYourEmailInbox" />
                            </label>
                            <div className="mt-3">
                                <img className="register-pending-img" src={emailVerification} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='register-right-side'>
                <img src={SignIn} alt="" />
            </div>
        </div>
    )
}

export default RegisterPending