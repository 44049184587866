import { useIntl } from 'react-intl';
import * as yup from 'yup'

import { passwordRegex } from "../../../helpers/constants";

export const usePasswordForInvitedUserValidationSchema = () => {
    const intl = useIntl()

    return yup.object().shape({
        password1: yup
            .string()
            .matches(passwordRegex, intl.formatMessage({ id: "passwordMustContainMinimumEightCharactersUpperCaseAndNumber" }))
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
        password2: yup
            .string()
            .oneOf([yup.ref("password1"), ""], intl.formatMessage({ id: "passwordsDoNotMatch" }))
            .required(intl.formatMessage({ id: "thisFieldIsRequired" })),
    });
}