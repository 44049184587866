import { FaChartPie, FaBoxOpen, FaFolderOpen, FaUserTag, FaReceipt, FaCoins, FaUniversity } from 'react-icons/fa'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { RiUserSettingsLine } from 'react-icons/ri'
import { AiOutlineBarChart } from 'react-icons/ai'
import { HiBuildingLibrary } from 'react-icons/hi2'
import { FC, useEffect, useState } from 'react'
import { Dropdown, Nav } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { FiSettings } from 'react-icons/fi'

import useDetectSubdomainOrPublic from '../../../../hooks/useDetectSubdomainOrPublic'

import './LeftNav.css'

type TLeftNavProps = {
    setShowSidebar: (value: boolean) => void;
    showSidebar: boolean
}

const LeftNav: FC<TLeftNavProps> = ({ setShowSidebar, showSidebar }) => {
    const [isPublic] = useDetectSubdomainOrPublic()
    const [systemPage, setSystemPage] = useState(false)
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === "/dashboard/system-alerts/") {
            setSystemPage(true)
        } else {
            setSystemPage(false)
        }
    }, [location])

    return (
        <div className={`left-navbar ${showSidebar && 'left-navbar-show'}`}>
            <div className='left-navbar-items-block'>
                <Nav>
                    <Nav.Item className='w-100'>
                        <NavLink className='left-nav-link' to='/dashboard/total-orders/' >
                            <FaChartPie size={16} className='me-1' /> <FormattedMessage id="dashboard" />
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item className='w-100'>
                        <NavLink className='left-nav-link' to='/dashboard/orders/'>
                            <AiOutlineBarChart size={16} className='me-1' /> <FormattedMessage id="orders" />
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item className='w-100'>
                        <NavLink className='left-nav-link' to='/dashboard/my-products/'>
                            <FaBoxOpen size={16} className='me-1' /> <FormattedMessage id="myProducts" />
                        </NavLink>
                    </Nav.Item>
                    <Nav.Item className='w-100'>
                        <NavLink className='left-nav-link' to='/dashboard/catalog/'>
                            <FaFolderOpen size={16} className='me-1' /> <FormattedMessage id="catalogs" />
                        </NavLink>
                    </Nav.Item>
                    {isPublic && (
                        <Nav.Item className='w-100'>
                            <NavLink className={systemPage ? 'left-nav-link active' : 'left-nav-link'} to='/dashboard/system-schedule/'>
                                <RiUserSettingsLine size={16} className='me-1' /> <FormattedMessage id="system" />
                            </NavLink>
                        </Nav.Item>
                    )}
                    <Dropdown className='w-100'>
                        <Dropdown.Toggle className='user-header-dropdown p-0 w-100' id="dropdown-basic">
                            <Nav.Item className='w-100 left-nav-link'>
                                <HiBuildingLibrary size={16} className='me-1' /> <FormattedMessage id="billing" />
                            </Nav.Item>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='user-header-dropdown-menu left-nav-dropdown-menu-billing'>
                            {!isPublic && (
                                <Nav.Item className='w-100'>
                                    <NavLink className='left-nav-link' to='/dashboard/billing-receipts/'>
                                        <FaReceipt size={16} className='me-1' /> <FormattedMessage id="receipts" />
                                    </NavLink>
                                </Nav.Item>
                            )}
                            <Nav.Item className='w-100'>
                                <NavLink className='left-nav-link' to='/dashboard/billing-paypal-receipts/'>
                                    <FaCoins size={16} className='me-1' /> <FormattedMessage id="paypalReceipts" />
                                </NavLink>
                            </Nav.Item>
                            {!isPublic && (
                                <Nav.Item className='w-100'>
                                    <NavLink className='left-nav-link' to='/dashboard/billing-payouts/'>
                                        <FaUniversity size={16} className='me-1' /> <FormattedMessage id="payouts" />
                                    </NavLink>
                                </Nav.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    {isPublic && (
                        <>
                            <Nav.Item className='w-100'>
                                <NavLink className='left-nav-link' to='/dashboard/settings-tenants/'>
                                    <FaUserTag size={16} className='me-1' /> <FormattedMessage id="tenants" />
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item className='w-100'>
                                <NavLink className='left-nav-link' to='/dashboard/affiliate-management/'>
                                    <FaUniversity size={16} className='me-1' /> <FormattedMessage id="affiliateManagement" />
                                </NavLink>
                            </Nav.Item>
                        </>
                    )}
                </Nav>
            </div>
            <div className='navbar-settings'>
                <Link className='navbar-settings-link' to='/dashboard/settings-users/'>
                    <FiSettings size={30} /> <span className='navbar-settings-link-text'><FormattedMessage id="settings" /></span>
                </Link>
            </div>
        </div>
    )
}

export default LeftNav