import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Fragment } from "react";

import useDetectSubdomainOrPublic from "../../../../hooks/useDetectSubdomainOrPublic";
import { useGetTenantDataQuery } from "../../../../api/settingsAPI";
import { useGetOrderByIdQuery } from "../../../../api/ordersAPI";

import OrderCustomerShippingInfoBlock from "../../../components/layout/Orders/OrderCustomerShippingInfoBlock";
import OrderFulfillmentBlock from "../../../components/layout/Orders/OrderFulfillmentBlock";
import OrderPricingDetails from "../../../components/layout/Orders/OrderPricingDetails";
import LineItemsTable from "../../../components/layout/Orders/LineItemsTable";
import OrderInfoBlock from "../../../components/layout/Orders/OrderInfoBlock";
import OrderTracking from "../../../components/layout/Orders/OrderTracking";
import Loading from "../../../components/common/Loading/Loading";

import "./OrderDetails.css";

const OrderDetails = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const [isPublic, subdomain] = useDetectSubdomainOrPublic();

	const { data: orderData, isLoading } = useGetOrderByIdQuery(
		id ? Number(id) : 0,
		{
			skip: !id,
		}
	);
	const { data: tenantData } = useGetTenantDataQuery(subdomain, {
		skip: isPublic || !subdomain,
	});

	const navigateToOrders = () => {
		navigate("/dashboard/orders/");
	};

	return (
		<div className="orders-details__content-wrapper">
			<div onClick={navigateToOrders} className="orders-details__back">
				<IoIosArrowRoundBack color="#666666" size={28} />
			</div>
			<Loading isLoading={isLoading}>
				<div className="orders-details__content">
					<div className="order-details__main-information">
						<OrderInfoBlock orderData={orderData} />
						<OrderCustomerShippingInfoBlock orderData={orderData} />
						<LineItemsTable orderData={orderData} />
						{orderData?.fulfillments &&
							orderData?.fulfillments.length > 0 &&
							orderData?.fulfillments.map((fulfillment) => (
								<OrderFulfillmentBlock
									key={fulfillment.id}
									fulfillment={fulfillment}
									tenantData={tenantData}
								/>
							))}
						{orderData?.fulfillments &&
							orderData?.fulfillments.length > 0 &&
							orderData?.fulfillments.map((fulfillment) => (
								<Fragment key={fulfillment.id}>
									{fulfillment?.fulfillment_order
										?.fulfilled_by === "MERCHANT" ? (
										<div>
											<OrderTracking
												fulfillment={fulfillment}
											/>
										</div>
									) : null}
								</Fragment>
							))}
					</div>
					<OrderPricingDetails
						orderData={orderData}
						tenantData={tenantData}
					/>
				</div>
			</Loading>
		</div>
	);
};

export default OrderDetails;
